import React from 'react'
import { styled } from '@mui/material/styles'
import { TextButton } from 'Components/shared/buttons'
import Funnel from 'Assets/images/funnel.svg'

const FlexContainer = styled('div')(() => ({
  display: 'flex',
  gap: '10px',
  fontSize: '0.875rem',
  lineHeight: '17px',
}))

interface Props {
  resetFilters: () => void
}

export const FilesFilterHeader = ({ resetFilters }: Props) => {
  return (
    <FlexContainer>
      <Funnel />
      FILTERS
      <TextButton onClick={resetFilters} label={'Reset'} />
    </FlexContainer>
  )
}
