import React from 'react'
import { styled } from '@mui/material/styles'

const successStatus = ['CONNECTED', 'DELIVERED', 'SUCCESS']

const StyledStatusCell = styled('div')(({ theme, status }) => ({
  color: successStatus.includes(status?.toUpperCase())
    ? theme.palette.success.main
    : theme.palette.error.main,
  textTransform: 'capitalize',
}))

export const TableRowStatus = ({ status }) => {
  return <StyledStatusCell status={status}>{status}</StyledStatusCell>
}
