import React from 'react'
import { styled } from '@mui/material/styles'
import { ArrowButton } from './ArrowButton'

const SidebarContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}))

interface SidebarHeaderProps {
  /** showSidebar determines whether full sidebar is shown */
  showSidebar?: boolean
  /** Toggles if full sidebar is shown or not */
  handleCloseSidebar?: () => void
  /**  Data that needs to be displayed in the open sidebar */
  children?: React.ReactNode
}

export const SidebarHeaderWrapper = ({
  showSidebar,
  handleCloseSidebar,
  children,
}: SidebarHeaderProps) => {
  return (
    <SidebarContainer>
      {children}
      <ArrowButton showSidebar={showSidebar} handleClick={handleCloseSidebar} />
    </SidebarContainer>
  )
}
