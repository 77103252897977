import { isRejectedWithValue } from '@reduxjs/toolkit'
import type { Middleware } from '@reduxjs/toolkit'

export const ForbiddenUnauthorizedMiddleware: Middleware =
  (_) => (next) => (action) => {
    if (isRejectedWithValue(action)) {
      const status = action.payload?.status
      if (status === 403 || status === 401) {
        if( window.location.pathname !== '/' ) {
          window.sessionStorage.clear()
          window.location.assign('/')
        }
      }
    }

    return next(action)
  }
