import { createSelector } from '@reduxjs/toolkit'
import type { RootState } from 'Utils/store'
import { multiSelectSlice } from 'Slices/multiSelectSlice'
import ExpectedTransferTerm from 'Configs/fields/expected_transfer_term.json'
import { ContractSource } from 'Types/contractTypes'

const contractSource = ContractSource.candidates

const transformValues = function (selectedValues) {
  return selectedValues.map((value) => (value === 'Null' ? '' : value))
}

export const ExpectedTransferTermSlice = multiSelectSlice(
  ExpectedTransferTerm.column,
  'setOnDemandExpectedTransferTerm',
  ExpectedTransferTerm.data,
  ExpectedTransferTerm.meta,
  transformValues
)

export const selectExpectedTransferTerm = createSelector(
  ({ onDemandFilter: { prospectProfile } }: RootState) =>
    prospectProfile.expectedTransferTerm.selectedValues,
  (
    selectedValues: string[]
  ): {
    selectedValues: string[]
    values: string[]
    audienceTypes: string[]
    label: string
  } => {
    return {
      selectedValues,
      values: ExpectedTransferTerm.data,
      audienceTypes: ExpectedTransferTerm.source[contractSource].audience_types,
      label: ExpectedTransferTerm.meta.label,
    }
  }
)

export const { setOnDemandExpectedTransferTerm } =
  ExpectedTransferTermSlice.actions

export default ExpectedTransferTermSlice.reducer
