import React, { useCallback } from 'react'
import { ForgotPassword } from 'Components/shared/forgotpassword'
import { useForgotPasswordMutation } from 'Services/global/authentication'
import { useNavigate } from 'react-router-dom'

export const ForgotPasswordContainer = (_) => {
  const navigate = useNavigate()
  const [forgotPassword] = useForgotPasswordMutation()

  const handleForgotPasswordClick = useCallback((email) => {
    forgotPassword(email)
  }, [])

  const handleBackToLoginClick = useCallback(() => {
    navigate('/')
  }, [])

  return (
    <ForgotPassword
      onForgotPasswordClick={handleForgotPasswordClick}
      onBackToLoginClick={handleBackToLoginClick}
    />
  )
}
