import React from 'react'
import { styled } from '@mui/material/styles'

const FlexContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}))

export const SidebarFieldWrapper = ({ ...props }) => {
  return <FlexContainer {...props} />
}
