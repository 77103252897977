import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { FilterBy } from 'Filter/properties'

interface SelectDropdownState {
  /* selectedValue is the value that is currently selected in the dropdown */
  selectedValue: string
  /* values is the list of values that are available in the dropdown */
  values: string[]
  /* filterQuery is the query that is used to filter the data in the backend */
  filterQuery: any
}

export const selectDropdownSlice = (
  column: string,
  values: string[],
  action: string,
  meta: { section: string; label: string }
) => {
  return createSlice({
    name: column,
    initialState: {
      values,
      selectedValue: values[0],
      filterQuery: '',
    } as SelectDropdownState,
    reducers: {
      [action]: (state, action: PayloadAction<string>) => {
        state.selectedValue = action.payload
        state.filterQuery =
          action.payload === 'Any'
            ? ''
            : {
                column: column,
                op: FilterBy.EQ,
                value: action.payload,
                meta,
              }
      },
    },
  })
}
