import React from 'react'
import { styled } from '@mui/material/styles'

const Date = styled('span')(({ theme }) => ({
    color: theme.palette.secondary['100'],
    fontSize: '0.875rem',
    fontWeight: theme.typography.fontWeightMedium,
    height: '18px',
    lineHeight: '16px',
}))

const InnerText = styled('span')(({ theme }) => ({
  color: theme.palette.primary.dark,
  fontSize: '0.875rem',
  fontWeight: theme.typography.fontWeightMedium,
  lineHeight: '16px',
  marginLeft: '6px',
  marginRight: '6px'
}))

export const DeliveryPeriod = ({ startDate, endDate}) => {
  return (
    <div>
      <Date>{startDate}</Date>
      <InnerText>to</InnerText>
      <Date>{endDate}</Date>
    </div>
  )
}