import React, { useState, useCallback, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { AddDestination } from 'Containers/configuration/destinations/addDestination'
import { TablePagination } from 'Containers/shared/tablePagination'
import { Destinations as DestinationsComponent } from 'Components/configuration/destinations'
import { DestinationContract } from './DestinationContract'
import { TableFooter, TableRowStatus } from 'Components/shared/table'
import {
  Body,
  BodyTitle,
  BodyActions,
  BodyHeader,
  BodyContent,
  BodyModals,
} from 'Components/shared/body'
import { ManageDestinationContainer as ManageDestination } from './ManageDestination'
import { DefaultButton } from 'Components/shared/buttons'
import { Loading } from 'Utils/loading'
import { useToggle } from 'Hooks/useToggle'
import { usePagination } from 'Hooks/usePagination'
import { useGetDestinationsQuery } from 'Services/configuration/destinations'
import { Permissions } from 'Configs/userPermissions'
import { useHasPermission } from 'Hooks/useHasPermission'

const columnData = [
  { columnName: 'Destination Name', columnWidth: 200, sortOrder: 'ASC' },
  { columnName: 'Contracts Using', columnWidth: 250, sortOrder: 'ASC' },
  { columnName: 'Destination Type', columnWidth: 75, sortOrder: 'ASC' },
  { columnName: 'Status', columnWidth: 80, sortOrder: 'ASC' },
]

export const Destinations = () => {
  const { ipedId } = useParams()
  const [addDestinationDialog, setAddDestinationDialog] = useToggle(false)
  const [manageDestinationDialog, setManageDestinationDialog] = useToggle(false)
  const { currentPage, handlePageChange } = usePagination()
  const [rowIdx, setRowIdx] = useState(-1)
  const { data: destinationsInfo, isLoading } = useGetDestinationsQuery({
    ipedId,
    page: currentPage,
  })
  let destination = destinationsInfo?.records?.[rowIdx]
  const destinationTypeMap = { sql_sftp: 'SFTP', sql_panto: 'Panto' }
  const { hasPermission: canUpdateDestination } = useHasPermission(
    Permissions.UpdateDestination
  )
  const { hasPermission: canCreateDestination } = useHasPermission(
    Permissions.CreateDestination
  )

  const handleRowClick = (rowIndex: number) => {
    if (canUpdateDestination) {
      setManageDestinationDialog()
      setRowIdx(rowIndex)
    }
  }

  const addDestination = useCallback((e: any) => {
    e.preventDefault()
    e.stopPropagation()
    setAddDestinationDialog()
  }, [])

  const flattenDestinations = useMemo(() => {
    let rows = []
    if (destinationsInfo) {
      destinationsInfo.records?.forEach((destination: any, idx: number) => {
        rows.push([
          destination.id,
          destination.label,
          <DestinationContract contractsFromServer={destination.contracts} />,
          destinationTypeMap[destination.connection_type],
          <TableRowStatus status={destination.status} />,
        ])
      })
    }

    return rows
  }, [destinationsInfo])

  if (isLoading) return <Loading />

  return (
    <Body>
      <BodyHeader>
        <BodyTitle>Destinations</BodyTitle>
        {canCreateDestination && (
          <BodyActions>
            <DefaultButton onClick={addDestination}>
              Add Destination
            </DefaultButton>
          </BodyActions>
        )}
      </BodyHeader>
      <BodyContent>
        {destinationsInfo?.total_records > 0 && (
          <>
            <DestinationsComponent
              columnData={columnData}
              rows={flattenDestinations}
              handleRowClick={handleRowClick}
            />
            <TableFooter>
              {destinationsInfo.total_pages > 1 && (
                <TablePagination
                  rowsPerPage={10}
                  currentPage={currentPage}
                  handlePageChange={handlePageChange}
                  totalRecords={destinationsInfo.total_records}
                  totalPages={destinationsInfo.total_pages}
                />
              )}
            </TableFooter>
          </>
        )}
      </BodyContent>
      <BodyModals>
        {addDestinationDialog && (
          <AddDestination
            addDestinationDialog={addDestinationDialog}
            setAddDestinationDialog={setAddDestinationDialog}
          />
        )}
        {manageDestinationDialog && (
          <ManageDestination
            destinationIdFromServer={destination.id}
            destinationNameFromServer={destination.label}
            destinationTypeFromServer={
              destinationTypeMap[destination.connection_type]
            }
            hostNameFromServer={destination['host_name']}
            userNameFromServer={destination['user_name']}
            sshKeyIdFromServer={destination['ssh_key_id']}
            destinationPathFromServer={destination['path']}
            leadDownloadPreferenceFromServer={
              destination.lead_download_preference
            }
            leadDownloadPreferenceIsZipFromServer={
              destination.lead_download_is_zip
            }
            manageDestinationDialog={manageDestinationDialog}
            setManageDestinationDialog={setManageDestinationDialog}
            contractsFromServer={destination.contracts}
          />
        )}
      </BodyModals>
    </Body>
  )
}
