import { createSelector } from '@reduxjs/toolkit'
import type { RootState } from 'Utils/store'
import { multiSelectSlice } from 'Slices/multiSelectSlice'
import ReligiousInstitution from 'Configs/fields/open_to_religious_institution.json'
import { ContractSource } from 'Types/contractTypes'

const contractSource = ContractSource.candidates

const transformValues = function (selectedValues) {
  return selectedValues.map((value) =>
    value === ReligiousInstitution?.meta.nullLabel ? "" : value
  )
}

const transformState = function (selectedValues) {
  return selectedValues.map((value) =>
    value === "" ? ReligiousInstitution?.meta.nullLabel : value
  )
}

export const ReligiousInstitutionSlice = multiSelectSlice(
  ReligiousInstitution.column,
  'setReligiousInstitution',
  ReligiousInstitution.data,
  ReligiousInstitution.meta,
  transformValues,
  transformState
)

export const selectReligiousInstitution = createSelector(
  ({ onDemandFilter: { prospectProfile } }: RootState) =>
    prospectProfile.religiousInstitution.selectedValues,
  (
    selectedValues: string[]
  ): {
    selectedValues: string[]
    values: string[]
    audienceTypes: string[]
    label: string
  } => {
    return {
      selectedValues,
      values: ReligiousInstitution.data,
      audienceTypes: ReligiousInstitution.source[contractSource].audience_types,
      label: ReligiousInstitution.meta.label,
    }
  }
)

export const { setReligiousInstitution } =
  ReligiousInstitutionSlice.actions

export default ReligiousInstitutionSlice.reducer
