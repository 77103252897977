import React, { memo } from 'react'
import {
  Dialog,
  DialogActionButtons,
  DialogField,
  DialogFieldContainer,
} from 'Components/shared/dialog'

import { styled, useTheme } from '@mui/material/styles'
import { alpha } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import Button from '@mui/material/Button'
import { Input } from 'Components/shared/input'
import { SingleSelectDropdown } from 'Components/shared/singleselectdropdown'

const StyledButton = styled(Button)(({ theme }) => ({
  border: `1.8px solid ${theme.palette.primary.main}`,
  borderRadius: '4px',
  fontSize: '0.875rem',
  fontWeight: theme.typography.fontWeightMedium,
  height: '40px',
  lineHeight: '18px',
  minWidth: 'calc(100% - 40px)',
  [theme.breakpoints.up('md')]: {
    minWidth: 'auto',
  },
}))

const CancelButton = styled(StyledButton)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  color: theme.palette.primary.main,
  lineHeight: '20px',
  '&:hover': {
    backgroundColor: `${alpha(theme.palette.primary.light, 0.4)}`,
  },
  [theme.breakpoints.up('md')]: {
    width: '110px',
  },
}))

const AddButton = styled(StyledButton)(({ theme }) => ({
  color: theme.palette.common.white,
  backgroundColor: theme.palette.primary.main,
  border: 'none',
  boxShadow: `0px 4px 20px ${alpha(theme.palette.common.white, 0.4)}`,
  '&:hover': {
    backgroundColor: `${alpha(theme.palette.primary.main, 0.85)}`,
  },
  '&.Mui-disabled': {
    color: theme.palette.common.white,
    backgroundColor: 'hsla(216, 79%, 72%, 1)',
  },
  [theme.breakpoints.up('md')]: {
    width: '104px',
  },
}))

interface AddUserProps {
  /** To show the add user dialog or not */
  addUserDialog: boolean
  /** To set the name of the user */
  setName: (value: string) => void
  /** To set the email of the user */
  setEmail: (value: string) => void
  /** To set the type of the user */
  setType: (value: string) => void
  /** Add all user values */
  addUserValues: () => void
  /** Clean up actions when closing the dialog */
  closeDialog: () => void
  /** All the user roles */
  roles: string[]
  /** Boolean that determines whether to disable submit  */
  disableSubmit: boolean
  /** Object that registers component into React Hook Form for validation*/
  control?: any
}

export const AddUser = memo(
  ({
    addUserDialog,
    setName,
    setEmail,
    setType,
    addUserValues,
    closeDialog,
    roles,
    disableSubmit,
    control,
  }: AddUserProps) => {
    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.up('md'))
    return (
      <Dialog onClose={closeDialog} open={addUserDialog} title='Add User'>
        <DialogFieldContainer>
          <DialogField id='add-user-name' label='Add User Name'>
            <Input
              id='add-user-name'
              name='userName'
              control={control}
              placeholder='Add Name'
              style={matches ? { width: '421px' } : {}}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setName(e.target.value)
              }
              rules={{
                required: true,
                pattern: {
                  value:
                    /^[a-zA-Z\u00C0-\u00FF_\s-]+ [a-zA-Z\u00C0-\u00FF_\s-]+$/,
                  message: 'Name must include First and Last',
                },
              }}
            />
          </DialogField>

          <DialogField id='add-user-email' label='Add User Email'>
            <Input
              id='add-user-email'
              name='userEmail'
              control={control}
              placeholder='Add Email'
              style={matches ? { width: '421px' } : {}}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setEmail(e.target.value)
              }
              rules={{
                required: true,
                pattern: {
                  value: /^\w+([\.\+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                  message: 'Must be a valid email',
                },
              }}
            />
          </DialogField>

          <DialogField id='add-user-type' label='Type'>
            <SingleSelectDropdown
              id='add-user-type'
              name={'userType'}
              control={control}
              style={matches ? { width: '279px' } : {}}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                setType(e.target.value)
              }
              values={roles}
              rules={{
                required: 'Type is a required field',
              }}
            />
          </DialogField>
        </DialogFieldContainer>

        <DialogActionButtons>
          <CancelButton onClick={closeDialog}>Cancel</CancelButton>
          <AddButton disabled={disableSubmit} onClick={addUserValues}>
            Add
          </AddButton>
        </DialogActionButtons>
      </Dialog>
    )
  }
)
