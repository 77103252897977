import React from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { alpha } from '@mui/material'
import TableRowAction from 'Assets/images/table_row_action.svg'
import { styled } from '@mui/material/styles'

const StyledTableRowAction = styled(TableRowAction)(({ theme }) => ({
  cursor: 'pointer',
  opacity: 1,
}))

const StyledGrid = styled(Grid)(({ theme }) => ({
  position: 'absolute',
  left: '120%',
  top: '50%',
  width: 'auto',
  backgroundColor: theme.palette.secondary['100'],
  borderRadius: '4px',
  color: theme.palette.common.white,
  whiteSpace: 'pre-line',
  cursor: 'pointer',
  '& > .MuiGrid-item': {
    width: '100%',
    paddingTop: '14px',
    paddingBottom: '14px',
    paddingLeft: '10px',
    paddingRight: '10px',
    '&:hover': {
      backgroundColor: `${alpha(theme.palette.primary.light, 0.2)}`,
    },
  },
}))

interface Props {
  institutionId: string
  segmentId: string
  segmentStatus: string
  showAction: boolean
  toggleAction: () => void
  handleActivation: (
    institutionId: string,
    segmentId: string,
    status: string
  ) => void
  handleEdit: () => void
}

export const SegmentActions = ({
  institutionId,
  segmentId,
  segmentStatus,
  showAction,
  toggleAction,
  handleActivation,
  handleEdit,
}: Props) => {
  return (
    <Box sx={{ position: 'relative' }}>
      <StyledTableRowAction onClick={toggleAction} />
      {showAction && (
        <StyledGrid container spacing={2}>
          {segmentStatus === 'active' && (
            <Grid item onClick={() => handleEdit()}>
              Edit
            </Grid>
          )}
          <Grid
            item
            onClick={() =>
              handleActivation(
                institutionId,
                segmentId,
                segmentStatus === 'active' ? 'inactive' : 'active'
              )
            }
          >
            {segmentStatus === 'active' ? 'Deactivate' : 'Activate'}
          </Grid>
        </StyledGrid>
      )}
    </Box>
  )
}
