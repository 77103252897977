import React from 'react'
import { styled } from '@mui/material/styles'

const ContractType = styled('div')(({ theme }) => ({
  color: theme.palette.primary.dark,
  fontSize: '0.75rem',
  fontWeight: theme.typography.fontWeightMedium,
  lineHeight: '15px',
}))

interface ContractTypeProp {
  /** The type of contract */
  type: string
}

export const SidebarContractType = ({ type }: ContractTypeProp) => {
  return <ContractType>{type}</ContractType>
}
