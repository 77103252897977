import { AnyAction, Dispatch, createSelector } from '@reduxjs/toolkit'
import { sliderSlice, buildSliderProperty } from 'Slices/sliderSlice'
import type { RootState } from 'Utils/store'
import GraduationyearConfig from 'Configs/fields/graduation_year.json'
import { ContractSource } from 'Types/contractTypes'

const contractSource = ContractSource.inquiries

export const GraduationYearSlice = sliderSlice(
  'high_school_grad_date',
  'setTimeBasedGraduationYearRange',
  [GraduationyearConfig.range.min, GraduationyearConfig.range.max],
  GraduationyearConfig.meta
)
export const selectGraduationYear = createSelector(
  ({ timeBasedFilter: { prospectProfile } }: RootState) =>
    prospectProfile.graduationYear['selectedMin'],
  ({ timeBasedFilter: { prospectProfile } }: RootState) =>
    prospectProfile.graduationYear['selectedMax'],
  (
    selectedMinGradYear: number,
    selectedMaxGradYear: number
  ): {
    step: number
    ariaLabel: string
    selectedMinGradYear: number
    selectedMaxGradYear: number
    minYear: number
    maxYear: number
    filterQuery: any
    audienceTypes: string[]
    label: string
  } => {
    return {
      selectedMinGradYear,
      selectedMaxGradYear,
      minYear: GraduationyearConfig.range.min,
      maxYear: GraduationyearConfig.range.max,
      step: GraduationyearConfig.range.step,
      ariaLabel: GraduationyearConfig.ARIA_LABEL,
      filterQuery: buildSliderProperty(
        GraduationyearConfig.column,
        [selectedMinGradYear, selectedMaxGradYear],
        GraduationyearConfig.meta
      ),
      audienceTypes: GraduationyearConfig.source[contractSource].audience_types,
      label: GraduationyearConfig.meta.label,
    }
  }
)

export const { setTimeBasedGraduationYearRange } = GraduationYearSlice.actions

export const setGraduationYearDefaultValue = (
  dispatch: Dispatch<AnyAction>,
  audienceType: string
) => {
  const audienceTypes =
    GraduationyearConfig.source[contractSource].audience_types
  if (audienceTypes.includes(audienceType)) {
    dispatch(
      setTimeBasedGraduationYearRange({
        min: GraduationyearConfig.range.min,
        max: GraduationyearConfig.range.max,
      })
    )
  }
}

export default GraduationYearSlice.reducer
