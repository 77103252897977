import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit'
import { FilterBy, properties, CombineBy } from 'Filter/properties'
import { processZipCodes } from 'Utils/zipCodes'
import type { RootState } from 'Utils/store'
import USAStatesTerritoriesConfig from 'Configs/fields/us_states_territories.json'
import GeographyConfig from 'Configs/fields/geography.json'

interface Geography {
  /* selectedValue is the value that is currently selected in the dropdown */
  selectedValue: string
  /* values is the list of values that are available in the dropdown */
  values: string[]
  /* selectedStates is the list of states that are selected in the dropdown */
  selectedStates: string[]
  /* file id and name */
  zipFile: { id: string; name: string }
  /* zip3Codes is the list of zip 3 codes that are entered in the text area */
  zip3Codes: string
  /* zip5Codes is the list of zip 5 codes that are entered in the text area */
  zip5Codes: string
  /* filterQuery is the query that is used to filter the data in the backend */
  filterQuery: any
}

const VALUE_USA = 'USA'

const buildUSAProperty = (
  states: string[],
  filterBy: FilterBy,
  selectedValue: string
) => {
  const USACountry = {
    column: GeographyConfig.columns.country,
    op: FilterBy.EQ,
    value: VALUE_USA,
    meta: { ...GeographyConfig.meta, selectedValue },
  }
  const stateProperty = {
    column: GeographyConfig.columns.state,
    op: filterBy,
    value: states,
    meta: { ...GeographyConfig.meta, selectedValue },
  }

  return properties([USACountry, stateProperty], CombineBy.AND)
}

const buildOutsideUSProperty = () => {
  return {
    column: GeographyConfig.columns.country,
    op: FilterBy.NE,
    value: VALUE_USA,
    meta: {
      ...GeographyConfig.meta,
      selectedValue: GeographyConfig.values.outsideUSA,
    },
  }
}

const buildZipCode5Property = (zipCodes: string, selectedValue: string) => {
  const USACountry = {
    column: GeographyConfig.columns.country,
    op: FilterBy.EQ,
    value: VALUE_USA,
    meta: { ...GeographyConfig.meta, selectedValue },
  }
  const zipCodesProperty = {
    column: GeographyConfig.columns.zipCode,
    op: FilterBy.IN,
    value: processZipCodes(zipCodes) || [],
    meta: { ...GeographyConfig.meta, selectedValue },
  }

  return properties([USACountry, zipCodesProperty], CombineBy.AND)
}

const buildZipCode3Property = (zipCodes: string, selectedValue: string) => {
  const USACountry = {
    column: GeographyConfig.columns.country,
    op: FilterBy.EQ,
    value: VALUE_USA,
    meta: { ...GeographyConfig.meta, selectedValue },
  }
  const zipCodesProperty = {
    column: GeographyConfig.columns.zipCode,
    op: FilterBy.LIKE_IN,
    value: processZipCodes(zipCodes) || [],
    meta: { ...GeographyConfig.meta, selectedValue },
  }

  return properties([USACountry, zipCodesProperty], CombineBy.AND)
}

const initialState: Geography = {
  values: Object.values(GeographyConfig.values),
  selectedValue: GeographyConfig.values.allUSA,
  selectedStates: [],
  zipFile: { id: '', name: '' },
  zip3Codes: '',
  zip5Codes: '',
  filterQuery: buildUSAProperty(
    USAStatesTerritoriesConfig.data,
    FilterBy.IN,
    GeographyConfig.values.allUSA
  ),
}

export const GeographySlice = createSlice({
  name: 'geography',
  initialState,
  reducers: {
    setOnDemandAllUSA: (state) => {
      state.selectedValue = GeographyConfig.values.allUSA
      state.selectedStates = USAStatesTerritoriesConfig.data
      state.zipFile = initialState.zipFile
      state.filterQuery = buildUSAProperty(
        USAStatesTerritoriesConfig.data,
        FilterBy.IN,
        GeographyConfig.values.allUSA
      )
    },

    setOnDemandInState: (state, action: PayloadAction<string>) => {
      state.selectedValue = GeographyConfig.values.inState
      state.zipFile = initialState.zipFile
      state.filterQuery = buildUSAProperty(
        [action.payload],
        FilterBy.IN,
        GeographyConfig.values.inState
      )
    },

    setOnDemandOutofState: (state, action: PayloadAction<string>) => {
      const includedStates = USAStatesTerritoriesConfig.data.filter(
        (state) => state !== action.payload
      )
      state.selectedValue = GeographyConfig.values.outOfState
      state.zipFile = initialState.zipFile
      state.filterQuery = buildUSAProperty(
        includedStates,
        FilterBy.IN,
        GeographyConfig.values.outOfState
      )
    },

    setOnDemandSpecificStates: (state, action: PayloadAction<string[]>) => {
      state.selectedValue = GeographyConfig.values.specificState
      state.selectedStates = action.payload
      state.zipFile = initialState.zipFile
      state.filterQuery = buildUSAProperty(
        action.payload || USAStatesTerritoriesConfig.data,
        FilterBy.IN,
        GeographyConfig.values.specificState
      )
    },
    setOnDemandZip5Codes: (state, action: PayloadAction<string>) => {
      state.selectedValue = GeographyConfig.values.zipCodeFive
      state.zip5Codes = action.payload
      state.zipFile = initialState.zipFile
      state.filterQuery = buildZipCode5Property(
        action.payload,
        GeographyConfig.values.zipCodeFive
      )
    },
    setOnDemandZip3Codes: (state, action: PayloadAction<string>) => {
      state.selectedValue = GeographyConfig.values.zipCodeThree
      state.zip3Codes = action.payload
      state.zipFile = initialState.zipFile
      state.filterQuery = buildZipCode3Property(
        action.payload,
        GeographyConfig.values.zipCodeThree
      )
    },
    setOnDemandZipFile: (
      state,
      action: PayloadAction<{ id: string; name: string }>
    ) => {
      state.selectedValue = GeographyConfig.values.Zipcodes
      state.zipFile = action.payload || initialState.zipFile
    },
    setOnDemandOutsideUS: (state) => {
      state.selectedValue = GeographyConfig.values.outsideUSA
      state.zipFile = initialState.zipFile
      state.filterQuery = buildOutsideUSProperty()
    },
  },
})

export const selectGeography = createSelector(
  ({ onDemandFilter: { demographics } }: RootState) =>
    demographics.geography.selectedValue,
  ({ onDemandFilter: { demographics } }: RootState) =>
    demographics.geography.selectedStates,
  ({ onDemandFilter: { demographics } }: RootState) =>
    demographics.geography.zipFile,
  ({ onDemandFilter: { demographics } }: RootState) =>
    demographics.geography.zip5Codes,
  ({ onDemandFilter: { demographics } }: RootState) =>
    demographics.geography.zip3Codes,
  (
    selectedValue: string,
    selectedStates: string[],
    zipFile: { id: string; name: string },
    zip5Codes: string,
    zip3Codes: string
  ): {
    selectedValue: string
    selectedStates: string[]
    zipFile: { id: string; name: string }
    zip5Codes: string
    zip3Codes: string
    values: string[]
    allUSAStatesTerritories
    label: string
  } => {
    return {
      selectedValue,
      selectedStates,
      zipFile,
      zip5Codes,
      zip3Codes,
      values: Object.values(GeographyConfig.values),
      allUSAStatesTerritories: USAStatesTerritoriesConfig.data,
      label: GeographyConfig.meta.label,
    }
  }
)

export const {
  setOnDemandAllUSA,
  setOnDemandInState,
  setOnDemandOutofState,
  setOnDemandSpecificStates,
  setOnDemandZipFile,
  setOnDemandZip5Codes,
  setOnDemandZip3Codes,
  setOnDemandOutsideUS,
} = GeographySlice.actions

export default GeographySlice.reducer
