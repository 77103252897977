import React, { useState } from 'react'
import { Typography, Box } from '@mui/material'

const VersionInfo = () => {
   const [isHovered, setIsHovered] = useState(false)
   return (
      <Box
         sx={{
            position: 'fixed',
            right: 2,
            bottom: 0
         }}
         onMouseEnter={() => setIsHovered(true)}
         onMouseLeave={() => setIsHovered(false)}
      >
         {!isHovered && (
            <Typography variant='caption'>v
               {process.env.REACT_APP_PACKAGE_VERSION}
            </Typography>
         )}
         {isHovered && (
            <Typography variant='caption'>
               {process.env.REACT_APP_BUILD_DATE}
            </Typography>
         )}
      </Box>
   )
}

export default VersionInfo