import React, { memo } from 'react'
import { styled } from '@mui/material/styles'
import { Table } from 'Components/shared/table'

const Row = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  gap: '32px',
}))

const CustomLabel = styled('label')(({ theme, alignSelf }) => ({
  flex: '1',
  color: theme.palette.primary.dark,
  fontSize: '0.875rem',
  fontWeight: theme.typography.fontWeightMedium,
  lineHeight: '21px',
  alignSelf: alignSelf,
  textAlign: 'right',
}))

const ElementWrapper = styled('div')(() => ({
  flex: '3',
}))

const TotalRow = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  gap: '32px',
  marginTop: '-24px',
}))

const Total = styled('label')(({ theme }) => ({
  flex: '1',
  color: theme.palette.primary.dark,
  fontSize: '0.75rem',
  fontWeight: theme.typography.fontWeightMedium,
  lineHeight: '15px',
  paddingTop: '19px',
  textAlign: 'right',
}))

const TotalValue = styled('div')(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.grey['200']}`,
  fontSize: '0.875rem',
  fontWeight: theme.typography.fontWeightMedium,
  lineHeight: '16px',
  padding: '19px 32px',
  width: '516px',
}))

const NoSegments = styled('div')(({ theme }) => ({
  fontSize: '0.875rem',
  fontWeight: theme.typography.fontWeightMedium,
  color: theme.palette.primary.dark,
}))

interface SegmentsListFieldProps {
  /** segments data from server */
  segments: any
  /** table row data from segments */
  flattenSegments: any
  /** table header column data */
  columnData: any
  /** function that handles opening segments detail modal on row click */
  handleRowClick: (rowIdx: number) => void
}

export const SegmentsListField = memo(
  ({
    segments,
    flattenSegments,
    columnData,
    handleRowClick,
  }: SegmentsListFieldProps) => {
    return (
      <>
        <Row>
          <CustomLabel>Segments</CustomLabel>
          <ElementWrapper>
            {segments?.length > 0 ? (
              <Table
                width={'95%'}
                /* prevents gap between table and total row when using height */
                maxHeight={'233px'}
                columnData={columnData}
                rows={flattenSegments}
                variant='striped'
                handleRowClick={handleRowClick}
                disabled={false}
              />
            ) : (
              <NoSegments>
                No segments have been added to this contract
              </NoSegments>
            )}
          </ElementWrapper>
        </Row>
      </>
    )
  }
)
