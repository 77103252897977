import React from 'react'
import EabLogo from 'Assets/images/eab_logo.svg'
import { ContractSource } from 'Types/index'
import { SSOContainer, SSOLoginBtnText, SSOLoginButton} from 'Components/connectedComponents/login'

export const SSOLogin = () => {
  const useHash = window.location.hostname === process.env.REACT_APP_HASH_ROUTING_DOMAIN
  const redirectPath = useHash ? '#inquiries' : ContractSource.inquiries
  const handleSSOClick = () => {
    window.location.assign(
      `${process.env.REACT_APP_API_BASE_URL}users/auth/sso/microsoft_o_auth?fwd=${process.env.REACT_APP_BASE_URL}${redirectPath}`
    )
  }

  return (
        <SSOContainer>
          <SSOLoginButton onPointerDown={handleSSOClick}>
            <SSOLoginBtnText>Login with Microsoft</SSOLoginBtnText>
          </SSOLoginButton>
          <EabLogo />
        </SSOContainer>
  )
}
