import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from '../getBaseQuery'

export interface Role {
  name: string
  description: string
}

interface GetRolesAPIResponse {
  data: Role[]
}

export const RolesApi = createApi({
  // reducerPath: 'roles',
  baseQuery,
  endpoints: (build) => ({
    /** Get all roles */
    getRoles: build.query<string[], void>({
      query: () => ({ url: `roles` }),
      transformResponse: (response: GetRolesAPIResponse): string[] => {
        return response.data.map((role) => role.name)
      },
    }),
  }),
})

export const { useGetRolesQuery } = RolesApi
