import { createSelector } from '@reduxjs/toolkit'
import { selectDropdownSlice } from 'Slices/selectDropdownSlice'
import type { RootState } from 'Utils/store'
import EmailVerifiedConfig from 'Configs/fields/email_verified.json'

export const EmailCleanlinessSlice = selectDropdownSlice(
  EmailVerifiedConfig.column,
  EmailVerifiedConfig.values,
  'setTimeBasedEmailCleanliness',
  EmailVerifiedConfig.meta
)

export const selectEmailCleanliness = createSelector(
  ({ timeBasedFilter: { verification } }: RootState) =>
    verification['emailCleanliness'].selectedValue,
  (selectedValue: string): { selectedValue: string; values: string[]; label } => {
    return { selectedValue, values: EmailVerifiedConfig.values, label: EmailVerifiedConfig.meta.label  }
  }
)

export const { setTimeBasedEmailCleanliness } = EmailCleanlinessSlice.actions

export default EmailCleanlinessSlice.reducer
