import React from 'react'
import { useController } from 'react-hook-form'
import { styled } from '@mui/material/styles'
import Select from '@mui/material/Select'
import InputBase from '@mui/material/InputBase'
import { alpha } from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import Tooltip from '@mui/material/Tooltip'
import SelectIcon from 'Assets/images/select_icon.svg'

const StyledSelect = styled(Select)(({ theme, style }) => ({
  width: style?.width || '100%',
  '.MuiMenu-paper': {
    boxShadow: 'none',
    borderRadius: '0px',
  },
  '& ul': {
    border: `1.5px solid ${theme.palette.secondary['80']}`,
    borderRadius: '4px',
    borderTop: '0',
    color: theme.palette.secondary['100'],

    minHeight: 'auto',
    maxHeight: '110px',
    overflowY: 'auto',
    padding: '0',
    top: '0',
    left: '0',
    width: style?.width || '100%',
    '& li': {
      borderRadius: '4px',
      display: 'block',
      fontSize: '0.875rem',
      lineHeight: '18px',
      padding: '10px 16px',
      textOverflow: 'ellipsis',
      overflowX: 'hidden',
      whiteSpace: 'nowrap',
      '&:hover': {
        backgroundColor: `${alpha(theme.palette.secondary['100'], 0.1)}`,
        overflowX: 'hidden',
      },
    },
  },
}))

const CustomInput = styled(InputBase)(({ theme, style }) => ({
  width: style?.width || '100%',
  '& .MuiInputBase-input': {
    position: 'relative',
    background: 'transparent',
    border: `1.5px solid ${theme.palette.secondary['80']}`,
    boxSizing: 'border-box',
    borderRadius: '4px',
    color: theme.palette.secondary['100'],
    fontSize: '0.875rem',
    fontWeight: theme.typography.fontWeightMedium,
    height: '40px',
    lineHeight: '21px',
    padding: '9.5px 16px',
    textOverflow: 'ellipsis',
    overflowX: 'hidden',
    whiteSpace: 'nowrap',
    width: style?.width || '100%',
    transition: theme.transitions.create(['box-shadow']),
    '&:focus': {
      boxShadow: `0 0 0 0.2rem ${theme.palette.primary.light}`,
    },
  },
  '& .MuiSelect-icon': {
    marginRight: '10px',
    top: '45%',
    '& path': {
      fill: theme.palette.primary.dark,
    },
  },
}))

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip
    children={undefined}
    title={''}
    {...props}
    classes={{ popper: className }}
  />
))(() => ({
  '&[data-popper-reference-hidden]': {
    opacity: 0,
    pointerEvents: 'none',
  },
}))

const ErrorMessage = styled('p')(({ theme }) => ({
  fontWeight: theme.typography.fontWeightMedium,
  fontSize: '0.7rem',
  lineHeight: '16px',
  color: theme.palette.error.main,
  marginBottom: '0px',
}))

interface SingleSelectDropdownProps {
  id: string
  name?: string
  control?: any
  rules?: any
  style?: { width?: string; height?: string }
  handleScroll?: (e: any) => void
  values: string[]
  value?: string
  disabled?: boolean
  onOpen?: () => void
  onChange: (event: React.ChangeEvent<{ value: unknown }>) => void
}

export const SingleSelectDropdown = ({
  values,
  value: propValue,
  name,
  control,
  rules,
  handleScroll,
  onChange,
  disabled = false,
  ...props
}: SingleSelectDropdownProps) => {
  if (control) {
    const {
      field: { onChange: handleFieldChange, value, ref },
      formState: { errors },
    } = useController({ name, control, rules, defaultValue: propValue })
    return (
      <>
        <StyledSelect
          input={<CustomInput />}
          disabled={disabled}
          value={value}
          name={name}
          displayEmpty
          IconComponent={SelectIcon}
          MenuProps={{
            onScroll: handleScroll,
            disablePortal: true,
            MenuListProps: {
              'data-testid': 'select-options',
            },
          }}
          onChange={(event) => {
            handleFieldChange(event.target.value)
            onChange(event)
          }}
          inputRef={ref}
          {...props}
        >
          {values?.map((type: string) => (
            <MenuItem value={`${type}`} key={`${type}`}>
              <StyledTooltip
                className='custom-tooltip'
                title={`${type}`}
                placement='left-start'
                PopperProps={{
                  style: {
                    zIndex: '9999',
                    overflow: 'hidden',
                  },
                }}
              >
                <div
                  style={{
                    overflowX: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                  }}
                >{`${type}`}</div>
              </StyledTooltip>
            </MenuItem>
          ))}
        </StyledSelect>

        {errors[name]?.message && (
          <ErrorMessage>{errors[name]?.message}</ErrorMessage>
        )}
        {errors['root']?.[name]?.message && (
          <ErrorMessage>{errors['root']?.[name]?.message}</ErrorMessage>
        )}
      </>
    )
  } else {
    return (
      <StyledSelect
        input={<CustomInput />}
        disabled={disabled}
        value={propValue}
        name={name}
        IconComponent={SelectIcon}
        MenuProps={{
          onScroll: handleScroll,
          disablePortal: true,
          MenuListProps: {
            'data-testid': 'select-options',
          },
        }}
        onChange={onChange}
        {...props}
      >
        {values?.map((type: string) => (
          <MenuItem value={`${type}`} key={`${type}`}>{`${type}`}</MenuItem>
        ))}
      </StyledSelect>
    )
  }
}
