import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from '../getBaseQuery'
import { ContractSource } from 'Types/index'

export interface AudienceType {
  id: string
  name: string
}

interface GetAudienceTypesResponse {
  data: AudienceType[]
}

export const AudienceTypesAPI = createApi({
  reducerPath: 'audienceTypes',
  baseQuery,
  endpoints: (build) => ({
    getAudienceTypes: build.query<AudienceType[], ContractSource>({
      query: (source: ContractSource) => ({ url: `audience-types/${source}` }),
      transformResponse: (
        response: GetAudienceTypesResponse
      ): AudienceType[] => {
        return response.data
      },
    }),
  }),
})

export const { useGetAudienceTypesQuery } = AudienceTypesAPI
