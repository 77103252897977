import React, { useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { CreateDeleteDialog } from 'Components/shared/createdeletedialog'
import { useToggle } from 'Hooks/useToggle'
import { Loading } from 'Utils/loading'
import { useDeleteZipCodesMutation } from 'Services/configuration/zipCodes'
import Trash from 'Assets/images/trash.svg'

interface DeleteKeyProps {
  fileName: string
  fileId: string
}

export const DeleteZipCode = ({ fileName, fileId }: DeleteKeyProps) => {
  const [deleteKeyDialog, setDeleteKeyDialog] = useToggle(false)
  const { ipedId } = useParams()
  const [deleteZipCode, { isLoading }] = useDeleteZipCodesMutation()

  const handleDeleteAction = useCallback((e: any) => {
    e.preventDefault()
    e.stopPropagation()
    setDeleteKeyDialog()
  }, [])

  const handleDeleteKey = useCallback((e: any, fileId: string) => {
    handleDeleteAction(e)
    deleteZipCode({ ipedId, fileId })
  }, [])

  if (isLoading) return <Loading />

  return (
    <>
      <Trash
        style={{ cursor: 'pointer' }}
        role='button'
        onClick={handleDeleteAction}
        onKeyDown={(e) =>
          (e.key === 'Enter' || e.key === ' ') && handleDeleteAction(e)
        }
      />
      {deleteKeyDialog && (
        <CreateDeleteDialog
          title='Remove Zip Code File'
          bodyText={`Are you sure you want to permanently remove ${fileName}?`}
          actionText={`Remove`}
          handleAction={(e: any) => handleDeleteKey(e, fileId)}
          toggleDialog={setDeleteKeyDialog}
          isDialogOpen={deleteKeyDialog}
        />
      )}
    </>
  )
}
