import { combineReducers } from '@reduxjs/toolkit'

import { verificationReducer } from './ondemand/verification'
import { demographicsReducer } from './ondemand/demographics'
import { prospectProfileReducer } from './ondemand/prospectprofile'
import { smartCandidatesReducer } from './ondemand/smartcandidates'

export const appReducer = combineReducers({
  smartCandidates: smartCandidatesReducer,
  verification: verificationReducer,
  demographics: demographicsReducer,
  prospectProfile: prospectProfileReducer,
})

export const onDemandReducer = (state, action) => {
  if (action.type === 'onDemandFilter/reset') {
    return appReducer(undefined, action)
  }
  return appReducer(state, action)
}
