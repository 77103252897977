import React from 'react'
import { styled } from '@mui/material/styles'
import { default as MUIAccordion } from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Expand from 'Assets/images/expand.svg'
import Collapse from 'Assets/images/collapse.svg'

interface AccordionProps {
  /** expand is the boolean value to expand the accordion */
  expand: boolean
  /** setExpand is the function to set the expand value */
  setExpand: () => void
  /** title is the title of the accordion */
  title: string
  /** children is the accordion details */
  children: React.ReactNode
  /** disabled is the boolean value to disable the accordion */
  disabled?: boolean
}

const Title = styled('label')(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontSize: '0.75rem',
  fontWeight: theme.typography.fontWeightMedium,
  lineHeight: '16px',
  textAlign: 'left',
}))

const Divider = styled('div')(({ theme }) => ({
  border: `1px solid hsla(220, 21%, 87%, 1)`,
  width: '100%',
  height: '1px',
  marginTop: '16px',
}))

export const Accordion = ({
  expand,
  setExpand,
  title,
  children,
  disabled = false,
}: AccordionProps) => {
  return (
    <React.Fragment>
      <Divider />
      <MUIAccordion
        onChange={setExpand}
        elevation={0}
        disableGutters
        sx={{
          '& .MuiAccordionSummary-root': {
            padding: '0px',
            height: 'auto',
            minHeight: 'auto',
            '& > *': {
              marginBottom: '0px',
              marginTop: '14px',
            },
          },
          '& .MuiAccordionDetails-root': {
            padding: '0px',
            marginBottom: '32px',
          },
          '&:before': {
            display: 'none',
          },
        }}
      >
        <AccordionSummary
          expandIcon={expand ? <Collapse /> : <Expand />}
          disabled={disabled}
          aria-controls={title}
        >
          <Title>{title}</Title>
        </AccordionSummary>
        <AccordionDetails>{children}</AccordionDetails>
      </MUIAccordion>
    </React.Fragment>
  )
}
