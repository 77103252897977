import React, { memo }  from 'react'
import { styled, useTheme } from '@mui/material/styles'
import { alpha } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import Button from '@mui/material/Button'
import {
  Dialog,
  DialogActionButtons,
  DialogField,
  DialogFieldContainer,
} from 'Components/shared/dialog'
import { Input } from 'Components/shared/input'

const StyledButton = styled(Button)(({ theme }) => ({
  border: `1.8px solid ${theme.palette.primary.main}`,
  borderRadius: '4px',
  fontSize: '0.875rem',
  fontWeight: theme.typography.fontWeightMedium,
  height: '40px',
  lineHeight: '18px',
  minWidth: 'calc(100% - 40px)',
  [theme.breakpoints.up('md')]: {
    minWidth: 'auto',
  },
}))

const CancelButton = styled(StyledButton)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  color: theme.palette.primary.main,
  lineHeight: '20px',
  '&:hover': {
    backgroundColor: `${alpha(theme.palette.primary.light, 0.4)}`,
  },
  [theme.breakpoints.up('md')]: {
    width: '110px',
  },
}))

const CreateButton = styled(StyledButton)(({ theme }) => ({
  color: theme.palette.common.white,
  backgroundColor: theme.palette.primary.main,
  border: 'none',
  boxShadow: `0px 4px 20px ${alpha(theme.palette.common.white, 0.4)}`,
  '&:hover': {
    backgroundColor: `${alpha(theme.palette.primary.main, 0.85)}`,
  },
  '&.Mui-disabled': {
    color: theme.palette.common.white,
    backgroundColor: 'hsla(216, 79%, 72%, 1)',
  },
  [theme.breakpoints.up('md')]: {
    width: '104px',
  },
}))

interface CreateKeyProps {
  /** Sets the name for the SSH key pair */
  setPairName: (value: string) => void
  /** Boolean that determines whether to show create key dialog */
  createKeyDialog: boolean
  /** Function that closes create key dialog */
  closeDialog: () => void
  /** Function that adds key pair on submit */
  addKeyPair: () => void
  /** Boolean that determines whether to disable submit  */
  disableSubmit: boolean
  /** Object that registers component into React Hook Form for validation */
  control?: any
}

export const CreateKey = memo(
  ({ 
    setPairName,
    createKeyDialog, 
    closeDialog, 
    addKeyPair,
    disableSubmit,
    control
  }: CreateKeyProps) => {
    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.up('md'))
    return (
    <Dialog onClose={closeDialog} open={createKeyDialog} title='Create New Key'>
      <DialogFieldContainer>
        <DialogField id='add-key-pair-name' label='Key Pair Name'>
          <Input 
            id='add-key-pair-name'
            name='keyPairName'
            control={control}
            rules={{
              required: true
            }}
            placeholder='Add Name'
            style={matches ? { width: '421px' } : {}}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setPairName(e.target.value)
            }
          />
        </DialogField>
      </DialogFieldContainer>

      <DialogActionButtons>
        <CancelButton onClick={closeDialog}>Cancel</CancelButton>
        <CreateButton disabled={disableSubmit} onClick={addKeyPair}>Add</CreateButton>
      </DialogActionButtons>
    </Dialog>
    )
  }
)
