import React from 'react'
import { Link } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom'

const Container = styled('div')(({ theme }) => ({
  width: '364px',
  height: '58px',
  background: theme.palette.secondary.main,
  borderRadius: '4px',
  padding: '16px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

const Message = styled('div')(({ theme }) => ({
  color: theme.palette.common.white,
  fontWeight: theme.typography.fontWeightMedium,
  fontSize: '0.75rem',
  lineHeight: '16px',
  letterSpacing: '0.01em',
}))

const AddContract = styled(Link)(({ theme }) => ({
  color: 'hsla(40, 93%, 50%, 1)',
  fontWeight: theme.typography.fontWeightMedium,
  fontSize: '0.75rem',
  lineHeight: '18px',
  flex: '0.4',
}))

export const NoContractMessage = () => {
  return (
    <Container>
      <Message>
        No Contracts available. Add a Contract before setting up Segments.
      </Message>
      <AddContract to={`contracts/new`}>Add Contract</AddContract>
    </Container>
  )
}
