import { createSelector } from '@reduxjs/toolkit'
import type { RootState } from 'Utils/store'
import { multiSelectSlice } from 'Slices/multiSelectSlice'
import GenderConfig from 'Configs/fields/gender.json'
import { ContractSource } from 'Types/contractTypes'

const contractSource = ContractSource.inquiries

const transformValues = function (selectedValues) {
  return selectedValues.map((value) => GenderConfig.value_to_key[value] ?? value)
}

export const GenderSlice = multiSelectSlice(
  GenderConfig.column,
  'setTimeBasedGender',
  GenderConfig.values,
  GenderConfig.meta,
  transformValues
)
export const selectGender = createSelector(
  ({ timeBasedFilter: { demographics } }: RootState) =>
    demographics.gender.selectedValues,
  (
    selectedValues: string[]
  ): {
    selectedValues: string[]
    values: string[]
    audienceTypes: string[]
    label: string
  } => {
    return {
      selectedValues: selectedValues.map(
        (value) => GenderConfig.key_to_value[value] ?? value
      ),
      values: GenderConfig.values,
      audienceTypes: GenderConfig.source[contractSource].audience_types,
      label: GenderConfig.meta.label,
    }
  }
)

export const { setTimeBasedGender } = GenderSlice.actions

export default GenderSlice.reducer
