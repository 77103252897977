import React from 'react'
import { styled } from '@mui/material/styles'

const FlexFooter = styled('div')(() => ({
  display: 'flex',
  flexDirection:'row-reverse',
  justifyContent: 'space-between',
  alignItems: 'center',
}))

interface TableFooterProps {
  /**  Data that needs to be displayed in the table footer */
  children: React.ReactNode
}

export const TableFooter = ({ children }: TableFooterProps) => {
  return (
    <FlexFooter>{children}</FlexFooter>
  )
}