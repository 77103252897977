import React, { useEffect } from 'react'
import { BrowserRouter, HashRouter, useLocation } from 'react-router-dom'

// Remove or customize if you need more advanced scroll behavior
// and don't want to always scroll to top when location.pathname changes.
const ScrollToTop = () => {
  const location = useLocation()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])
  return null
}

export function Router({ children }) {
  // use hash routing based off of domain //
  let useHash =
    window.location.hostname === process.env.REACT_APP_HASH_ROUTING_DOMAIN

  if (useHash) {
    return (
      <HashRouter>
        <ScrollToTop />
        {children}
      </HashRouter>
    )
  } else {
    return (
      <BrowserRouter>
        <ScrollToTop />
        {children}
      </BrowserRouter>
    )
  }
}
