import { AnyAction, Dispatch, createSelector } from '@reduxjs/toolkit'
import type { RootState } from 'Utils/store'
import { multiSelectSlice } from 'Slices/multiSelectSlice'
import { ContractSource } from 'Types/contractTypes'
import LevelOfDegreeSeekingConfig from 'Configs/fields/level_of_degree_seeking.json'

const contractSource = ContractSource.inquiries

const transformValues = function (selectedValues) {
  return selectedValues.map((value) => (value === 'Any' ? '' : value))
}

export const LevelOfDegreeSeekingSlice = multiSelectSlice(
  LevelOfDegreeSeekingConfig.column,
  'setLevelOfDegreeSeeking',
  LevelOfDegreeSeekingConfig.source[contractSource].values,
  LevelOfDegreeSeekingConfig.meta,
  transformValues
)

export const selectLevelOfDegreeSeeking = createSelector(
  ({ timeBasedFilter: { prospectProfile } }: RootState) =>
    prospectProfile.levelOfDegreeSeeking.selectedValues,
  (
    selectedValues: string[]
  ): {
    selectedValues: string[]
    values: string[]
    audienceTypes: string[]
    label: string
  } => {
    return {
      selectedValues,
      values: LevelOfDegreeSeekingConfig.source[contractSource].values,
      audienceTypes: LevelOfDegreeSeekingConfig.source[contractSource].audience_types,
      label: LevelOfDegreeSeekingConfig.meta.label,
    }
  }
)

export const { setLevelOfDegreeSeeking } = LevelOfDegreeSeekingSlice.actions

export const setLevelOfDegreeSeekingDefaultValue = (
  dispatch: Dispatch<AnyAction>,
  audienceType: string
) => {
  const config = LevelOfDegreeSeekingConfig.source[contractSource]
  if (config?.default_selected[audienceType])
     dispatch(setLevelOfDegreeSeeking(config?.default_selected[audienceType]))
  else dispatch(setLevelOfDegreeSeeking([]))
}

export default LevelOfDegreeSeekingSlice.reducer
