import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { CreateDeleteDialog } from 'Components/shared/createdeletedialog'
import { useToggle } from 'Hooks/useToggle'
import { Loading } from 'Utils/loading'
import { useDeleteSSHKeyPairMutation } from 'Services/configuration/sshkeys'
import Trash from 'Assets/images/trash.svg'

interface DeleteKeyProps {
  /** Key Pair id of the key to be deleted */
  keyPairId: string
  /** Key Pair name of the key to be deleted */
  keyPairName: string
}

export const DeleteKey = ({ keyPairId, keyPairName }: DeleteKeyProps) => {
  const [deleteKeyDialog, setDeleteKeyDialog] = useToggle(false)
  const { ipedId } = useParams()
  const [deleteKey, { isLoading }] = useDeleteSSHKeyPairMutation()

  const handleDeleteAction = useCallback((e: any) => {
    e.preventDefault()
    e.stopPropagation()
    setDeleteKeyDialog()
  }, [])

  const handleDeleteKey = useCallback((e: any, keyPairId: string) => {
    handleDeleteAction(e)
    deleteKey({ ipedId, keyId: keyPairId })
  }, [])

  if (isLoading) return <Loading />

  return (
    <>
      <Trash
        style={{ cursor: 'pointer' }}
        role='button'
        onClick={handleDeleteAction}
        onKeyDown={(e) =>
          (e.key === 'Enter' || e.key === ' ') && handleDeleteAction(e)
        }
      />
      {deleteKeyDialog && (
        <CreateDeleteDialog
          title='Remove Key Pair'
          bodyText={`Are you sure you want to permanently remove ${keyPairName}?`}
          actionText={`Remove`}
          handleAction={(e: any) => handleDeleteKey(e, keyPairId)}
          toggleDialog={setDeleteKeyDialog}
          isDialogOpen={deleteKeyDialog}
        />
      )}
    </>
  )
}
