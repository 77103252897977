import { createSlice, createSelector, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'Utils/store'

interface Snackbar {
  message: string
  open: boolean
  error: boolean
}
const initialState: Snackbar = {
  message: '',
  open: false,
  error: false
}

export const SnackbarSlice = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    setSnackbar: (state, action: PayloadAction<Partial<Snackbar>>) => {
      state.message = action.payload.message
      state.error = action.payload.error ?? false
      state.open = true
    },
    closeSnackbar: (state) => {
      state.open = false
      state.error = false
    }
  }
})

export const selectSnackbar = createSelector(
  ({ snackbar }: RootState) => snackbar.message,
  ({ snackbar }: RootState) => snackbar.open,
  ({ snackbar }: RootState) => snackbar.error,
  (message, open, error) => ({ message, open, error})
)

export const { setSnackbar, closeSnackbar } = SnackbarSlice.actions

export default SnackbarSlice.reducer