export default {
  zIndex: {
    loading: 10,
    dialog: 9,
    header: 8,
    snackbar: 7,
    tooltip: 6,
    body: 5,
  },
}
