import { combineReducers } from '@reduxjs/toolkit'

import GeographyReducer from './GeographySlice'
import GenderReducer from './GenderSlice'
import AgeReducer from './AgeSlice'
import GreenlightReducer from './GreenlightSlice'

export const demographicsReducer = combineReducers({
  geography: GeographyReducer,
  gender: GenderReducer,
  age: AgeReducer,
  greenlight: GreenlightReducer,
})
