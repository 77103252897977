import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from '../getBaseQuery'

interface GetFileStatusResponse {
  data: {
    records: any[]
    total_pages: number
    total_records: number
    current_page: number
    current_size: number
  }
}

interface GetLeadFileStatusTransformedResponse {
  records: any[]
  total_pages: number
  total_records: number
}

export const LeadFileStatusApi = createApi({
  reducerPath: 'leadFileStatus',
  baseQuery,
  endpoints: (build) => ({
    getLeadFileStatus: build.query<
      GetLeadFileStatusTransformedResponse,
      { page: number; sortBy: string; order: string; filter?: string | object }
    >({
      query: ({ page, sortBy, order, filter }) => ({
        url: `lead-file-status?page=${page}&size=10&sort_by=${sortBy}::${order}&filter=${filter}`,
      }),
      transformResponse: (response: GetFileStatusResponse) => {
        const leadFileStatus: GetLeadFileStatusTransformedResponse = {
          total_pages: response.data.total_pages,
          total_records: response.data.total_records,
          records: response.data.records,
        }
        return leadFileStatus
      },
    }),
  }),
})

export const { useGetLeadFileStatusQuery } = LeadFileStatusApi
