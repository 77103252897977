import React, { memo } from 'react'
import { Slider } from 'Components/shared/slider'
import { SidebarField } from 'Components/shared/sidebar'
import { FilterSingleSelectDropdown } from 'Components/shared/singleselectdropdown'

interface TestScoresProps {
  testScoresState: any
  dispatchAnyScore: () => void
  dispatchSat: ({ min, max }) => void
  dispatchAct: ({ min, max }) => void
  dispatchSatOrAct: ({ minSat, maxSat, minAct, maxAct }) => void
  dispatchSatAndAct: ({ minSat, maxSat, minAct, maxAct }) => void
}

export const TestScores = memo(
  ({
    testScoresState,
    dispatchAnyScore,
    dispatchSat,
    dispatchAct,
    dispatchSatOrAct,
    dispatchSatAndAct,
  }: TestScoresProps) => {
    const {
      selectedValue,
      values,
      satAriaLabel,
      actAriaLabel,
      minSat,
      maxSat,
      minAct,
      maxAct,
      selectedMinSat,
      selectedMaxSat,
      satStep,
      selectedMinAct,
      selectedMaxAct,
      actStep,
    } = testScoresState

    return (
      <div>
        <FilterSingleSelectDropdown
          label='Test Scores'
          values={values}
          value={selectedValue}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
            const chosenValue = e.target.value
            if (chosenValue === 'Any') dispatchAnyScore()
            else if (chosenValue === 'SAT')
              dispatchSat({ min: selectedMinSat, max: selectedMaxSat })
            else if (chosenValue === 'ACT')
              dispatchAct({ min: selectedMinAct, max: selectedMaxAct })
            else if (chosenValue === 'SAT or ACT')
              dispatchSatOrAct({
                minSat: minSat,
                maxSat: maxSat,
                minAct: minAct,
                maxAct: maxAct,
              })
            else if (chosenValue === 'SAT and ACT')
              dispatchSatAndAct({
                minSat: minSat,
                maxSat: maxSat,
                minAct: minAct,
                maxAct: maxAct,
              })
          }}
        />
        {selectedValue !== 'Any' && (
          <>
            {selectedValue !== 'ACT' && (
              <SidebarField id='sat' label='SAT Composite Score'>
                <div style={{ marginTop: '16px', marginLeft: '10px' }}>
                  <Slider
                    ariaLabel={satAriaLabel}
                    min={minSat}
                    max={maxSat}
                    selectedMin={selectedMinSat}
                    selectedMax={selectedMaxSat}
                    step={satStep}
                    handleChange={(range: number[]) => {
                      selectedValue === 'SAT' &&
                        dispatchSat({ min: range[0], max: range[1] })
                      selectedValue === 'SAT or ACT' &&
                        dispatchSatOrAct({
                          minSat: range[0],
                          maxSat: range[1],
                          minAct: selectedMinAct,
                          maxAct: selectedMaxAct,
                        })
                      selectedValue === 'SAT and ACT' &&
                        dispatchSatAndAct({
                          minSat: range[0],
                          maxSat: range[1],
                          minAct: selectedMinAct,
                          maxAct: selectedMaxAct,
                        })
                    }}
                  />
                </div>
              </SidebarField>
            )}

            {selectedValue !== 'SAT' && (
              <SidebarField id='act' label='ACT Composite Score'>
                <div style={{ marginTop: '16px', marginLeft: '10px' }}>
                  <Slider
                    ariaLabel={actAriaLabel}
                    min={minAct}
                    max={maxAct}
                    selectedMin={selectedMinAct}
                    selectedMax={selectedMaxAct}
                    step={actStep}
                    handleChange={(range: number[]) => {
                      selectedValue === 'ACT' &&
                        dispatchAct({ min: range[0], max: range[1] })
                      selectedValue === 'SAT or ACT' &&
                        dispatchSatOrAct({
                          minSat: selectedMinSat,
                          maxSat: selectedMaxSat,
                          minAct: range[0],
                          maxAct: range[1],
                        })
                      selectedValue === 'SAT and ACT' &&
                        dispatchSatAndAct({
                          minSat: selectedMinSat,
                          maxSat: selectedMaxSat,
                          minAct: range[0],
                          maxAct: range[1],
                        })
                    }}
                  />
                </div>
              </SidebarField>
            )}
          </>
        )}
      </div>
    )
  }
)
