import React, { memo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { AccountSettings } from 'Components/header/accountsettings'
import { useGetInstitutionsInfoQuery } from 'Services/global/institution'
import { useToggle } from 'Hooks/useToggle'
import { CombineBy, FilterBy, properties } from 'Filter/properties'
import { buildFilterQuery } from 'Filter/buildFilterQuery'
import { useGetCandidatesContractsQuery } from 'Services/contracts/candidatesContracts'
import { selectNavigation, setNavigation } from 'Containers/header/NavSlice'
import { Permissions } from 'Configs/userPermissions'
import { useHasPermission } from 'Hooks/useHasPermission'
import { ContractSource, ContractStatus } from 'Types/index'
import { useLoginStatusQuery } from 'Services/global/authentication'

interface AccountSettingsProps {
  /** User's first name */
  firstName: string
  /** User's last name */
  lastName: string
  /** User's email */
  email: string
  /** When the user clicks log out */
  handleLogout: () => void
  /** Determines whether user is in admin portal */
  isAdmin?: boolean
}

const contractQuery = {
  query: {
    filterQuery: properties(
      [
        {
          column: 'source',
          op: FilterBy.EQ,
          value: ContractSource.candidates,
        },
        {
          column: 'status',
          op: FilterBy.EQ,
          value: ContractStatus.pending,
        },
      ],
      CombineBy.AND
    ),
  },
}
const PAGE_SIZE = 6

export const AccountSettingsContainer = memo(
  ({ isAdmin, ...props }: AccountSettingsProps) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { ipedId } = useParams()
    const [showMenu, setShowMenu] = useToggle()
    const navConfigIdxs = useSelector(selectNavigation)
    const { hasPermission: canSwitchInstitution } = useHasPermission(
      Permissions.SwitchInstitution
    )
    const { data: userData } = useLoginStatusQuery()
    console.log('userData', userData, userData?.roles, userData?.roles.find((role: any) => role === 'admin'))
    const isAdminFromNetwork = !!userData?.roles.find((role: any) => role === 'admin')
    console.log('about to run! from geography cotnainer', isAdmin, isAdminFromNetwork)
    const { data } = useGetInstitutionsInfoQuery(isAdminFromNetwork ? undefined : ipedId, {skip: !userData?.isLoggedIn})
    const { data: candidatesInfo } = useGetCandidatesContractsQuery({
      page: 0,
      size: PAGE_SIZE,
      ipedId,
      order: 'desc',
      filter: buildFilterQuery(contractQuery),
    })

    const institution = data?.find((institution) => institution.id === ipedId)
    const institutionName = isAdmin ? '' : institution?.name

    const changeInstitution = (selectedValue: string) => {
      /** Reset all the redux state except authentication */
      dispatch({
        type: 'resetExceptAuth',
      })

      const storedPath = window.sessionStorage.getItem('path')

      const instId = selectedValue.match(/\d+/)
      const institution = data.find(
        (institution) => institution.id === instId?.[0]
      )

      if (isAdmin || !storedPath) {
        navigate(`/institution/${institution.id}/inquiries`)
        dispatch(
          setNavigation({
            mainNavIdx: 0,
            subNavIdx: 0,
          })
        )
      } else {
        if (storedPath) {
          const newPath = storedPath
            .replace(/institution\/\d+/, `institution/${institution.id}`)
            .replace(/contracts\/.*/, 'contracts')
          navigate(newPath)
          dispatch(
            setNavigation({
              mainNavIdx: navConfigIdxs.mainNavIdx,
              subNavIdx: navConfigIdxs.subNavIdx,
            })
          )
        }
      }
    }

    return (
      <>
        <AccountSettings
          {...props}
          showMenu={showMenu}
          setShowMenu={setShowMenu}
          institutionsInfo={data || []}
          ipedId={ipedId}
          institutionName={institutionName}
          changeInstitution={changeInstitution}
          canSwitchInstitution={canSwitchInstitution}
          hasPending={candidatesInfo?.total_records > 0}
          isAdmin={isAdmin}
        />
      </>
    )
  }
)
