import React from 'react'
import { styled } from '@mui/material/styles'

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  gap: '20px',
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
  },
}))

interface BodyActionsProps {
  children: React.ReactNode
}

export const BodyActions = ({ children }: BodyActionsProps) => {
  return <Container>{children}</Container>
}
