import React, { memo } from 'react'
import { styled } from '@mui/material/styles'

interface GpaTestScoresProps {
  children?: React.ReactNode
}

const GpaTestScoresWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  border: `1.5px solid ${theme.palette.primary.light}`,
  borderRadius: '4px',
  padding: '0px 16px 27px 16px',
  width: '240px',
}))

export const GPATestScores = memo(({ children }: GpaTestScoresProps) => {
  return <GpaTestScoresWrapper>{children}</GpaTestScoresWrapper>
})
