import React from 'react'
import { SidebarField } from 'Components/shared/sidebar'
import { styled } from '@mui/material/styles'
import Select from '@mui/material/Select'
import InputBase from '@mui/material/InputBase'
import { alpha } from '@mui/material'
import SelectMenuItem from '@mui/material/MenuItem'
import SelectIcon from 'Assets/images/select_icon.svg'

const StyledSelect = styled(Select)(({ theme }) => ({
  width: '200px',
  '.MuiMenu-paper': {
    boxShadow: 'none',
    borderRadius: '0px',
  },
  '& ul': {
    border: `1.5px solid ${theme.palette.primary.light}`,
    borderTop: '0',
    color: theme.palette.primary.main,

    minHeight: 'auto',
    maxHeight: '110px',
    overflowY: 'auto',
    padding: '0',
    top: '0',
    left: '0',
    width: '100%',
    '& li': {
      display: 'block',
      fontSize: '0.75rem',
      lineHeight: '18px',
      textOverflow: 'ellipsis',
      overflowX: 'hidden',
      padding: '6px 8px 6px 8px',
      whiteSpace: 'nowrap',
      '&:hover': {
        backgroundColor: `${alpha(theme.palette.primary.light, 0.1)}`,
        overflowX: 'hidden',
      },
    },
  },
}))

const CustomInput = styled(InputBase)(({ theme }) => ({
  width: '200px',
  '& .MuiInputBase-input': {
    position: 'relative',
    background: 'transparent',
    border: `1.5px solid ${theme.palette.primary.light}`,
    boxSizing: 'border-box',
    borderRadius: '4px',
    color: theme.palette.primary.main,
    fontSize: '0.75rem',
    fontWeight: theme.typography.fontWeightMedium,
    height: '32px',
    lineHeight: '15px',
    padding: '8px 13px 8px 8px',
    width: '100%',
    transition: theme.transitions.create(['box-shadow']),
    '&:focus': {
      boxShadow: `0 0 0 0.2rem ${theme.palette.primary.light}`,
    },
  },
  '& .MuiSelect-icon': {
    marginRight: '10px',
    top: '45%',
    '& path': {
      fill: theme.palette.primary.main,
    },
  },
}))

interface FilterSingleSelectDropdownProps {
  /** label is the label of the dropdown */
  label: string
  /** values is the array of values to be displayed in the dropdown */
  values: string[]
  /** value is the value of the dropdown */
  value: string
  /** onChange is the function to be called on change of the dropdown */
  onChange: (event: React.ChangeEvent<{ value: unknown }>) => void
}

const CustomSelect = ({ id, values, value, ...props }) => {
  return (
    <StyledSelect
      defaultValue={values[0]}
      input={<CustomInput />}
      value={value}
      IconComponent={SelectIcon}
      MenuProps={{
        disablePortal: true,
        MenuListProps: {
          'data-testid': 'select-options',
        },
      }}
      {...props}
    >
      {values?.map((type: string) => (
        <SelectMenuItem
          value={`${type}`}
          key={`${type}`}
        >{`${type}`}</SelectMenuItem>
      ))}
    </StyledSelect>
  )
}

export const FilterSingleSelectDropdown = ({
  label,
  ...props
}: FilterSingleSelectDropdownProps) => {
  return (
    <SidebarField id={`label-${label}`} label={label}>
      <CustomSelect id={label} {...props} />
    </SidebarField>
  )
}
