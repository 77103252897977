import React from 'react'
import { styled } from '@mui/material/styles'
import { alpha } from '@mui/material'
import Button from '@mui/material/Button'
import { SegmentAction, SegmentActionLabel } from 'Types/index'

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'fixed',
  gap: '40px',
  bottom: '0',
  left: '0',
  width: '509px',
  height: '88px',
  background: theme.palette.common.white,
  boxShadow: '0px -2px 4px rgba(0, 0, 0, 0.1)',
}))

const StyledButton = styled(Button)(({ theme }) => ({
  border: `1.8px solid ${theme.palette.primary.main}`,
  borderRadius: '4px',
  fontSize: '0.875rem',
  fontWeight: theme.typography.fontWeightMedium,
  height: '40px',
  lineHeight: '18px',
  minWidth: 'calc(100% - 40px)',
  [theme.breakpoints.up('md')]: {
    minWidth: 'auto',
  },
}))

const CancelButton = styled(StyledButton)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  color: theme.palette.primary.main,
  lineHeight: '20px',
  '&:hover': {
    backgroundColor: `${alpha(theme.palette.primary.light, 0.4)}`,
  },
  [theme.breakpoints.up('md')]: {
    width: '110px',
  },
}))

const ActivateButton = styled(StyledButton)(({ theme }) => ({
  color: theme.palette.common.white,
  border: 'none',
  backgroundColor: theme.palette.primary.main,
  boxShadow: `0px 4px 20px ${alpha(theme.palette.common.white, 0.4)}`,
  '&:hover': {
    backgroundColor: `${alpha(theme.palette.primary.main, 0.85)}`,
  },
  '&.Mui-disabled': {
    color: theme.palette.common.white,
    backgroundColor: 'hsla(216, 79%, 72%, 1)',
    border: 'none',
  },
  [theme.breakpoints.up('md')]: {
    width: '160px',
  },
}))

interface SidebarActionButtonsProps {
  handleSegmentAction?: () => void
  handleCloseSidebar: () => void
  disableFormSubmit: boolean
  segmentAction?: SegmentAction
}

export const SidebarActionButtons = ({
  handleSegmentAction,
  handleCloseSidebar,
  disableFormSubmit,
  segmentAction = SegmentAction.new,
}: SidebarActionButtonsProps) => {
  return (
    <Container>
      <CancelButton onClick={handleCloseSidebar}>Cancel</CancelButton>
      <ActivateButton
        onClick={handleSegmentAction}
        disabled={disableFormSubmit}
      >
        {SegmentActionLabel[segmentAction] || 'Calculate'}
      </ActivateButton>
    </Container>
  )
}
