import { createApi } from '@reduxjs/toolkit/query/react'
import { Destination } from 'Services/configuration/destinations'
import {
  contractsQuery,
  transformContractsQueryResponse,
  Contract,
  transformContractByIdQueryResponse,
  getContractByIdquery,
  GetContractsTransformedResponse,
  getSubscribedNotifications,
  ContractApiResponse,
  GetContractsApiResponse,
  createContract,
  addDestinationsToContract,
  subscribeUsertoContractNotifications,
  updateContract,
  searchContractByLabel,
} from './contractsUtils'

import { GetUsers } from 'Services/configuration/users'
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import { baseQuery } from '../getBaseQuery'

interface GetDestinationsForContract {
  data: Destination[]
}

const CONTRACTS = 'INQUIRIES_CONTRACTS'
const CONTRACT_DESTINATIONS = 'INQUIRIES_CONTRACT_DESTINATIONS'
const CONTRACT_NOTIFICATIONS = 'INQUIRIES_CONTRACT_NOTIFICATIONS'

export const InquiriesContractsApi = createApi({
  reducerPath: 'inquiriesContracts',
  baseQuery,
  tagTypes: [CONTRACTS, CONTRACT_DESTINATIONS, CONTRACT_NOTIFICATIONS],
  endpoints: (build) => ({
    /** gets contracts for an institution by page */
    getInquiriesContracts: build.query<
      GetContractsTransformedResponse,
      {
        ipedId: string
        page: number
        size: number
        order: string
        filter?: string | object
      }
    >({
      query: contractsQuery,
      providesTags: [CONTRACTS],
      transformResponse: transformContractsQueryResponse,
    }),
    /** gets contracts for an institution */
    getAllInquiriesContracts: build.query<
      Contract[],
      {
        ipedId: string
        filterQuery?: string | object
      }
    >({
      queryFn: async (
        { ipedId, filterQuery },
        _queryApi,
        _extraOptions,
        fetchWithBQ
      ) => {
        const firstPageContracts = await fetchWithBQ({
          url: `institutions/${ipedId}/contracts?page=0&size=10&sort_by=updated_at::desc&filter=${filterQuery}`,
        })
        if (firstPageContracts?.error)
          return { error: firstPageContracts?.error as FetchBaseQueryError }

        let allContracts =
          (firstPageContracts?.data?.['data']?.['records'] as Contract[]) ?? []

        const totalPages =
          firstPageContracts?.data?.['data']?.['total_pages'] ?? 0

        for (let page = 1; page < totalPages; page++) {
          const contracts = await fetchWithBQ({
            url: `institutions/${ipedId}/contracts?page=${page}&size=10&sort_by=updated_at::desc&filter=${filterQuery}`,
          })
          allContracts = [
            ...allContracts,
            ...contracts?.data?.['data']?.['records'],
          ]
        }

        return { data: allContracts }
      },
      providesTags: [CONTRACTS],
    }),

    searchContractByLabel: build.query<
      GetContractsApiResponse,
      { ipedId: string; filterQuery: any }
    >({
      query: searchContractByLabel,
      providesTags: [CONTRACTS],
    }),

    getInquiryContractById: build.query<
      Contract,
      { ipedId: string; contractId: string }
    >({
      query: getContractByIdquery,
      providesTags: [CONTRACTS],
      transformResponse: transformContractByIdQueryResponse,
    }),

    createContract: build.mutation<ContractApiResponse, Partial<Contract>>({
      query: createContract,
      invalidatesTags: [CONTRACTS],
    }),

    updateContract: build.mutation<
      ContractApiResponse,
      { ipedId: string; contractId: string; contract: Partial<Contract> }
    >({
      query: updateContract,
      invalidatesTags: [CONTRACTS],
    }),

    /** Get Destinations for a Contract */
    getDestinationsForContract: build.query<
      GetDestinationsForContract,
      { ipedId: string; contractId: string }
    >({
      query: ({ ipedId, contractId }) => ({
        url: `institutions/${ipedId}/contracts/${contractId}/destinations`,
        method: 'GET',
      }),
      providesTags: [CONTRACT_DESTINATIONS],
    }),

    addDestinationsToContract: build.mutation<
      null,
      { ipedId: string; contractId: string; destinationIds: string[] }
    >({
      query: addDestinationsToContract,
      invalidatesTags: [CONTRACT_DESTINATIONS],
    }),

    getSubscribedNotifications: build.query<
      GetUsers,
      { ipedId: string; contractId: string }
    >({
      query: getSubscribedNotifications,
      providesTags: [CONTRACT_NOTIFICATIONS],
    }),

    subscribeUsertoContractNotifications: build.mutation<
      null,
      {
        ipedId: string
        contractId: string
        accountIdsEnabled: string[]
      }
    >({
      query: subscribeUsertoContractNotifications,
      invalidatesTags: [CONTRACT_NOTIFICATIONS],
    }),
  }),
})

export const {
  useGetInquiriesContractsQuery,
  useLazySearchContractByLabelQuery,
  useGetAllInquiriesContractsQuery,
  useGetDestinationsForContractQuery,
  useLazyGetInquiriesContractsQuery,
  useGetInquiryContractByIdQuery,
  useLazyGetInquiryContractByIdQuery,
  useGetSubscribedNotificationsQuery,
  useCreateContractMutation,
  useAddDestinationsToContractMutation,
  useSubscribeUsertoContractNotificationsMutation,
  useUpdateContractMutation,
} = InquiriesContractsApi
