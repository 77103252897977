import { createListenerMiddleware, isAnyOf } from '@reduxjs/toolkit'

import { AudienceTypesAPI } from 'Services/global/audienceTypes'
import { AuthenticationApi } from 'Services/global/authentication'
import { CandidateContractsApi } from 'Services/contracts/candidatesContracts'
import { ContractConfigAPI } from 'Services/global/contractConfig'
import { DeduplicatesApi } from 'Services/contracts/deduplicates'
import { DestinationsApi } from 'Services/configuration/destinations'
import { FilesApi } from 'Services/files/files'
import { HipLeadsApi } from 'Services/admin/hipLeads'
import { InquiriesContractsApi } from 'Services/contracts/inquiriesContracts'
import { InstitutionsApi } from 'Services/global/institution'
import { IntrospectionApi } from 'Services/global/introspection'
import { LeadFileStatusApi } from 'Services/admin/leadFileStatus'
import { PermissionsAPI } from 'Services/global/permissions'
import { RolesApi } from 'Services/configuration/roles'
import { SegmentsApi } from 'Services/segments/segments'
import { SSHKeyApi } from 'Services/configuration/sshkeys'
import { UsersApi } from 'Services/configuration/users'
import { ZipCodesApi } from 'Services/configuration/zipCodes'

const fifteenMinutesAsMilliseconds = 900000
let logoutIntervalID
let ApisMatchArray = [];

//ideally our API would be one call to rtk-query's createApi since they all use the same baseQuery
//but for now we have to add them all individually
const Apis = [
AudienceTypesAPI,
CandidateContractsApi,
ContractConfigAPI,
DeduplicatesApi,
DestinationsApi,
FilesApi,
HipLeadsApi,
InquiriesContractsApi,
InstitutionsApi,
IntrospectionApi,
LeadFileStatusApi,
PermissionsAPI,
RolesApi,
SegmentsApi,
SSHKeyApi,
UsersApi,
ZipCodesApi
]

export const SessionExpirationMiddleware = createListenerMiddleware()

Apis.forEach((api) => {
  Object.keys(api.endpoints).map((endpoint) => {
    ApisMatchArray.push(api.endpoints[endpoint].matchPending)
  })
})

const logOut = async (listenerApi) => {
  const logOutRequest = AuthenticationApi.endpoints.logOut.initiate()
  await logOutRequest(listenerApi.dispatch, listenerApi.getState, undefined)
  clearInterval(logoutIntervalID)
}

SessionExpirationMiddleware.startListening({
  matcher: isAnyOf.apply(null, ApisMatchArray), 
  effect: async (action, listenerApi) => {
    clearInterval(logoutIntervalID)
    logoutIntervalID = setInterval(() => logOut(listenerApi), fifteenMinutesAsMilliseconds)
  },
})