import React from 'react'
import { styled } from '@mui/material/styles'

const Container = styled('div')(() => ({
  marginTop: '16px',
}))

const Divider = styled('div')(() => ({
  height: '0.5px',
  width: '229px',
  border: '0.5px solid hsla(220, 21%, 87%, 1)',
  marginBottom: '16px',
}))

const Label = styled('div')(({ theme }) => ({
  fontWeight: theme.typography.fontWeightMedium,
  fontSize: '1rem',
  lineHeight: '19px',
  color: theme.palette.secondary.main,
}))

interface SegmentDetailsSectionProps {
  label: string
}

export const SegmentDetailsSection = ({
  label,
}: SegmentDetailsSectionProps) => {
  return (
    <Container>
      <Divider />
      <Label>{label}</Label>
    </Container>
  )
}
