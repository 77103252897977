import React, { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import {
  setAudienceType,
  selectAudienceType,
} from './InquiriesAudienceTypeSlice'
import { setTimePeriod, selectTimePeriod } from './InquiriesTimePeriodSlice'
import { SidebarField, SidebarFieldWrapper } from 'Components/shared/sidebar'
import { SingleSelectDropdown } from 'Components/shared/singleselectdropdown'
import { useGetAudienceTypesQuery } from 'Services/global/audienceTypes'
import { AudienceTypeHelper } from 'Types/audienceType'
import { ContractSource } from 'Types/index'
import { Loading } from 'Utils/loading'

export const InquiriesFilesFilter = () => {
  const dispatch = useDispatch()
  const { data: audienceTypes, isFetching: isFetchingAudienceTypes } =
    useGetAudienceTypesQuery(ContractSource.inquiries)

  const inquiryAudienceType = useSelector(selectAudienceType)
  const dispatchAudienceType = useCallback(
    (value: string) => {
      const key = AudienceTypeHelper.getAudienceType(audienceTypes, value)
      dispatch(setAudienceType(key))
    },
    [audienceTypes]
  )

  const timePeriod = useSelector(selectTimePeriod)
  const dispatchTimePeriod = useCallback((value: string) => {
    dispatch(setTimePeriod(value))
  }, [])

  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('md'))

  if (isFetchingAudienceTypes) return <Loading />
  return (
    <SidebarFieldWrapper>
      <SidebarField id='select-audience-type' label='Audience Type' primary>
        <SingleSelectDropdown
          id='select-audience-type'
          style={matches ? { width: '200px' } : {}}
          values={AudienceTypeHelper.getAudienceReadableList(audienceTypes)}
          value={AudienceTypeHelper.getReadableString(
            audienceTypes,
            inquiryAudienceType.selectedValue
          )}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
            dispatchAudienceType(e.target.value)
          }}
        />
      </SidebarField>
      <SidebarField id='select-time-period' label='Time Period' primary>
        <SingleSelectDropdown
          id='select-time-period'
          style={matches ? { width: '200px' } : {}}
          values={timePeriod.values}
          value={timePeriod.selectedValue}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
            dispatchTimePeriod(e.target.value)
          }}
        />
      </SidebarField>
    </SidebarFieldWrapper>
  )
}
