import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface EditSegment {
  leadTotal: number
}

const initialState: EditSegment = {
  leadTotal: 0,
}

export const EditSegmentSlice = createSlice({
  name: 'editSegment',
  initialState,
  reducers: {
    setLeadTotal: (state, action: PayloadAction<number>) => {
      state.leadTotal = action.payload
    },
    clearEditSegment: (state) => {
      state.leadTotal = 0
    },
  },
})

export const { setLeadTotal, clearEditSegment } = EditSegmentSlice.actions

export default EditSegmentSlice.reducer
