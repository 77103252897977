import React from 'react'
import { styled } from '@mui/material/styles'
import { alpha } from '@mui/material'
import Button from '@mui/material/Button'
import Download from 'Assets/images/download.svg'

const DownloadButton = styled(Button)(({ theme }) => ({
  backgroundColor: `${alpha(theme.palette.primary.main, 0.1)}`,
  borderRadius: '4px',
  height: '40px',
  minWidth: '40px'
}))

interface DownloadFileProps {
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
}

export const DownloadFile = ( { onClick }: DownloadFileProps) => {
  return (
    <DownloadButton onClick={onClick}>
        <Download />
    </DownloadButton>
  )
}