import React from 'react'
import { styled } from '@mui/material/styles'
import TablePagination from '@mui/material/TablePagination'
import { TablePaginationActions } from 'Containers/shared/tablePagination'

const PaginationContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'right',
  gap: '5px',
  color: theme.palette.secondary['100'],
  fontSize: '0.875rem',
  fontWeight: theme.typography.fontWeightMedium,
  lineHeight: '17px'
}))

const StyledPagination = styled(TablePagination)(({ theme }) => ({
  '& .MuiTablePagination-toolbar': {
    padding: '0'
  },
  '& .MuiTablePagination-displayedRows': {
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.secondary['100'],
    fontSize: '0.875rem',
    lineHeight: '17px'
  },
}))

interface TablePaginationProps {
  /** rowsPerPage is the max number of rows per page */
  rowsPerPage: number
  /** currentPage is the current page in pagination */
  currentPage: number
  /** totalRecords is the total number of list items */
  totalRecords: number
  /** totalPages is the total number of pages */
  totalPages: number
  /** sets page number of prev or next click */
  handlePageChange: (e, number) => any
}

export const TablePaginationComponent = ({ 
  rowsPerPage,
  currentPage, 
  totalRecords,
  totalPages,
  handlePageChange
}: TablePaginationProps) => {
  return (
    <PaginationContainer>
      Showing
      <StyledPagination 
        component='div'
        onPageChange={handlePageChange} 
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[]}
        count={totalRecords}
        page={currentPage}
        ActionsComponent={() => <TablePaginationActions page={currentPage} onPageChange={handlePageChange} totalPages={totalPages}/>}
        />
    </PaginationContainer>
   
    )
}