import { useCallback, useState } from 'react'

export const usePopover = () => {
  const [anchorEl, setAnchorEl] = useState(null)

  const onOpen = useCallback((event): void => {
    setAnchorEl(event.target)
  }, [])

  const onClose = useCallback((): void => {
    setAnchorEl(null)
  }, [])

  return {
    anchorEl,
    onOpen,
    onClose,
  }
}
