import React from 'react'
import { useTheme } from '@mui/material/styles'
import { styled } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import {
  SidebarField,
  SidebarContractType,
  SidebarFieldWrapper,
} from 'Components/shared/sidebar'
import { SingleSelectDropdown } from 'Components/shared/singleselectdropdown'
import { Contract } from 'Services/contracts/contractsUtils'
import { Input } from 'Components/shared/input'
import { NoContractMessage } from 'Components/shared/contracts/NoContractMessage'
import Alert from 'Assets/images/alert.svg'
import { ContractSource, ContractTypeReadable } from 'Types/index'

const LeadCapTotal = styled('div')(({ theme, remainingLeads, noOfLeads }) => ({
  fontWeight: theme.typography.fontWeightMedium,
  fontSize: '0.7rem',
  lineHeight: '16px',
  color:
    remainingLeads - Number(noOfLeads) >= 0 || isNaN(noOfLeads)
      ? theme.palette.primary.dark
      : theme.palette.error.main,
  marginTop: '3px',
}))

const NoContractContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginTop: '10px',
}))

interface SegmentInputProps {
  setSelectedContract: (contract: Contract) => void
  handleNoOfLeadsChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleSegmentNameChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  handlePricePerLeadChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  relatedContracts?: Contract[]
  chosenContract: Partial<Contract>
  remainingLeads: number
  noOfLeads: number
  segmentName: string
  pricePerLead?: number
  pricePerLeadString?: string
  control: any
  source: ContractSource
}

export const SegmentInput = ({
  setSelectedContract,
  handleNoOfLeadsChange,
  handleSegmentNameChange,
  handlePricePerLeadChange,
  relatedContracts,
  chosenContract,
  remainingLeads,
  noOfLeads,
  pricePerLead,
  pricePerLeadString,
  segmentName,
  control,
  source,
}: SegmentInputProps) => {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <SidebarFieldWrapper>
      <SidebarField id='contract-type' label='Contract Type'>
        <SidebarContractType
          type={source === ContractSource.inquiries ? ContractTypeReadable.annual : ContractTypeReadable.on_demand}
        />
      </SidebarField>
      <SidebarField id='select-contract-related' label='Contract Related'>
        {relatedContracts?.length > 0 ? (
          <SingleSelectDropdown
            id='select-contract-related'
            name='relatedContracts'
            control={control}
            value={chosenContract?.label || ''}
            style={matches ? { width: '229px' } : {}}
            values={relatedContracts.map(
              (contract: Contract) => contract.label
            )}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              const chosenContract = relatedContracts.find(
                (contract: Contract) => contract.label === e.target.value
              )
              if (chosenContract) {
                setSelectedContract(chosenContract)
              }
            }}
          />
        ) : (
          <>
            <Input
              style={matches ? { width: '229px' } : {}}
              value={chosenContract?.label || 'No Contract Available'}
              disabled={true}
            />
            {!chosenContract?.label && (
              <NoContractContainer>
                <Alert />
                <NoContractMessage />
              </NoContractContainer>
            )}
          </>
        )}
      </SidebarField>
      <SidebarField
        id='add-segment-leads-request'
        label='# of Segment Leads Request'
      >
        <Input
          id='add-segment-leads-request'
          value={noOfLeads}
          name='noOfLeads'
          placeholder='0'
          control={control}
          rules={{
            required: true,
            pattern: {
              value: /^[1-9][0-9]*$/,
              message: 'Only numbers greater than zero are allowed',
            },
          }}
          style={matches ? { width: '124px' } : {}}
          onChange={handleNoOfLeadsChange}
          disabled={!chosenContract?.id}
        >
          {chosenContract?.id && source === ContractSource.inquiries && (
            <LeadCapTotal
              remainingLeads={remainingLeads}
              noOfLeads={noOfLeads}
            >{`${remainingLeads} leads available on contract`}</LeadCapTotal>
          )}
        </Input>
      </SidebarField>

      {source === ContractSource.candidates && (
        <SidebarField id='add-price-per-lead' label='Price per Lead'>
          <Input
            id='add-price-per-lead'
            value={pricePerLeadString}
            name='pricePerLead'
            control={control}
            rules={{
              required: true,
              pattern: {
                value: /^[0-9\.]*$/,
                message: 'Only numbers and decimal points are allowed',
              },
            }}
            placeholder='$ 0.00'
            style={matches ? { width: '124px' } : {}}
            onChange={handlePricePerLeadChange}
            disabled={!chosenContract?.id}
          />
        </SidebarField>
      )}

      <SidebarField id='add-segment-name' label='Segment Name'>
        <Input
          id='add-segment-name'
          name='segmentName'
          value={segmentName}
          control={control}
          rules={{
            required: true,
            maxLength: {
              value: 100,
              message: 'Segment name should not exceed 100 characters',
            },
          }}
          placeholder='Add Segment Name'
          style={matches ? { width: '396px' } : {}}
          onChange={handleSegmentNameChange}
          disabled={!chosenContract?.id}
        />
      </SidebarField>
    </SidebarFieldWrapper>
  )
}
