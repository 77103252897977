import React from 'react'
import { Table } from 'Components/shared/table'

interface ManageUsersProps {
  columnData: {
    /**  columnName is the name of the column */
    columnName: string
    /**  columnWidth is the width of the column */
    columnWidth?: number
    /** sortOrder can be 'ASC', 'DESC', or 'NONE', make this an ENUM */
    sortOrder?: string
  }[]
  /** rows is an array of arrays that contain the data for each row */
  rows: any[]
}

export const ManageUsers = ({ columnData, rows }: ManageUsersProps) => {
  return <Table columnData={columnData} rows={rows} />
}
