import React from 'react'
import { createRoot } from 'react-dom/client'
import { App } from './App'
import workerSetup from 'Mocks/mswWorkerSetup'

if (process.env.NODE_ENV === 'local') {
  workerSetup()
}

const rootDOM = document.getElementById('root')
const root = createRoot(rootDOM)

root.render(<App />)
