import { createSelector } from '@reduxjs/toolkit'
import type { RootState } from 'Utils/store'
import { multiSelectSlice } from 'Slices/multiSelectSlice'
import StudyAbroad from 'Configs/fields/interest_in_study_abroad.json'
import { ContractSource } from 'Types/contractTypes'

const contractSource = ContractSource.candidates

const transformValues = function (selectedValues) {
  return selectedValues.map((value) =>
    value === StudyAbroad?.meta.nullLabel ? "" : value
  )
}

const transformState = function (selectedValues) {
  return selectedValues.map((value) =>
    value === "" ? StudyAbroad?.meta.nullLabel : value
  )
}

export const StudyAbroadSlice = multiSelectSlice(
  StudyAbroad.column,
  'setStudyAbroad',
  StudyAbroad.data,
  StudyAbroad.meta,
  transformValues,
  transformState
)

export const selectStudyAbroad = createSelector(
  ({ onDemandFilter: { prospectProfile } }: RootState) =>
    prospectProfile.studyAbroad.selectedValues,
  (
    selectedValues: string[]
  ): {
    selectedValues: string[]
    values: string[]
    audienceTypes: string[]
    label: string
  } => {
    return {
      selectedValues,
      values: StudyAbroad.data,
      audienceTypes: StudyAbroad.source[contractSource].audience_types,
      label: StudyAbroad.meta.label,
    }
  }
)

export const { setStudyAbroad } =
  StudyAbroadSlice.actions

export default StudyAbroadSlice.reducer
