export default {
  palette: {
    primary: {
      main: 'hsl(216,93%,46%)',
      dark: 'hsl(225,26%,52%)',
      light: 'hsl(216,81%,84%)',
    },
    secondary: {
      main: 'hsla(212,84%,12%,1)',
      100: 'hsla(212,84%,12%,1)',
      80: 'hsla(225,26%,76%,1)',
      40: 'hsla(223,70%,92%,1)',
      20: 'hsla(223,64%,96%,1)',
    },
    background: {
      main: 'hsla(220,27%,98%,1)',
    },
    success: {
      main: 'hsl(144,65%,47%)',
    },
    error: {
      main: 'hsl(0,96%,65%)',
    },
  },
}
