import React, { useState, useCallback } from 'react'
import { Input, ErrorMessage } from 'Components/shared/input'
import { isValidEmail } from 'Utils/validation'

import { useLogInMutation } from 'Services/global/authentication'
import { LoginSubHeading, LoginBtnText, InputFlexContainer, ForgotPassword, ForgotPasswordText, LoginButton, CustomLink } from 'Components/connectedComponents/login'

interface LoginCredentials {
  email: string;
  password: string;
}

interface LoginProps {
  /** Function to handle login with Microsoft SSO */
  onLoginClick?: (credentials: LoginCredentials) => void;
  loginError?: boolean
  exitPasswordLogin: () => void
}

export const PasswordLogin = ({ exitPasswordLogin }: LoginProps) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [validationError, setValidationError] = useState(undefined);
  const [login, { error: loginError }] = useLogInMutation()


  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const handleLoginClick = useCallback(() => {
    if (!email || !password) {
      setValidationError('Missing required fields')
    } else if(!isValidEmail(email)) {
      setValidationError('Invalid email')
    }else {
      setValidationError(undefined)
      login({email, password})
    }
  }, [email, password]);

  const border = (loginError || validationError) ? '1px solid #e34c4c' : undefined

  return (
    <><LoginSubHeading>Enter your email and address below</LoginSubHeading>
          <InputFlexContainer>
            <Input
              placeholder='Email'
              style={{ width: '100%' }}
              border={border}
              value={email}
              onChange={handleEmailChange}
            />
            {loginError && <ErrorMessage style={{ margin: 0 }}>Invalid credentials</ErrorMessage>}
            {validationError && <ErrorMessage style={{ margin: 0 }}>{validationError}</ErrorMessage>}
            <Input
              type='password'
              placeholder='Password'
              style={{ width: '100%' }}
              border={border}
              value={password}
              onChange={handlePasswordChange}
            />
          </InputFlexContainer>
          <ForgotPassword>
            Did you <CustomLink onClick={exitPasswordLogin}><ForgotPasswordText>forget your password?</ForgotPasswordText></CustomLink>
          </ForgotPassword>

          <LoginButton onClick={handleLoginClick}>
            <LoginBtnText>Login</LoginBtnText>
          </LoginButton>
        </>
  )
}
