import { combineReducers } from '@reduxjs/toolkit'

import AddressVerifiedReducer from './AddressVerifiedSlice'
import NameCleanlinessReducer from './NameCleanlinessSlice'
import EmailCleanlinessReducer from './EmailCleanlinessSlice'

export const verificationReducer = combineReducers({
  addressVerified: AddressVerifiedReducer,
  nameCleanliness: NameCleanlinessReducer,
  emailCleanliness: EmailCleanlinessReducer,
})
