import React, { useRef, useMemo } from 'react'
import { usePopover } from 'Hooks/usePopover'
import { useIsOverflow } from 'Hooks/useIsOverflow'
import { DestinationContract as DestinationContractComponent } from 'Components/configuration/destinations/DestinationContract'

interface DestinationContractProps {
  /** contracts results from server */
  contractsFromServer: any[]
}

export const DestinationContract = ({
  contractsFromServer,
}: DestinationContractProps) => {
  const { anchorEl, onOpen, onClose } = usePopover()
  const contractRef = useRef()
  const isOverFlow = useIsOverflow(contractRef)

  const flattenContracts = useMemo(() => {
    let contracts = []
    contractsFromServer?.forEach((contract: any) => {
      contracts.push(contract.label)
    })
    return contracts
  }, [contractsFromServer])

  return (
    <DestinationContractComponent
      contracts={flattenContracts}
      anchorEl={anchorEl}
      anchorOrigin={{ horizontal: 'left', vertical: 35 }}
      handleOpen={onOpen}
      handleClose={onClose}
      isOverFlow={isOverFlow}
      ref={contractRef}
    />
  )
}
