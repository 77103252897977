import { CandidateContractsApi } from './candidatesContracts'
import { InquiriesContractsApi } from './inquiriesContracts'
import { isInquiries } from 'Utils/urlUtils'

type UseGetContractsQueryType =
  typeof InquiriesContractsApi.useGetInquiriesContractsQuery
export const useGetContractsQuery: UseGetContractsQueryType = (
  arg,
  options?: any
) => {
  if (isInquiries)
    return InquiriesContractsApi.useGetInquiriesContractsQuery(arg, options)
  return CandidateContractsApi.useGetCandidatesContractsQuery(arg, options)
}

export const useLazySearchContractByLabelQuery = (options?: any) => {
  if (isInquiries)
    return InquiriesContractsApi.useLazySearchContractByLabelQuery(options)
  return CandidateContractsApi.useLazySearchContractByLabelQuery(options)
}

type UseGetAllContractsQueryType =
  typeof InquiriesContractsApi.useGetAllInquiriesContractsQuery
export const useGetAllContractsQuery: UseGetAllContractsQueryType = (
  arg,
  options?: any
) => {
  if (isInquiries)
    return InquiriesContractsApi.useGetAllInquiriesContractsQuery(arg, options)
  return CandidateContractsApi.useGetAllCandidatesContractsQuery(arg, options)
}

type UseGetDestinationsForContractQueryType =
  typeof InquiriesContractsApi.useGetDestinationsForContractQuery
export const useGetDestinationsForContractQuery: UseGetDestinationsForContractQueryType = (arg, options?: any) => {
    if (isInquiries)
      return InquiriesContractsApi.useGetDestinationsForContractQuery(
        arg,
        options
      )
    return CandidateContractsApi.useGetDestinationsForContractQuery(
      arg,
      options
    )
  }

export const useLazyGetContractsQuery = (options?: any) => {
  if (isInquiries)
    return InquiriesContractsApi.useLazyGetInquiriesContractsQuery(options)
  return CandidateContractsApi.useLazyGetCandidatesContractsQuery(options)
}

type UseGetContractByIdQueryType =
  typeof InquiriesContractsApi.useGetInquiryContractByIdQuery
export const useGetContractByIdQuery: UseGetContractByIdQueryType = (
  arg,
  options?: any
) => {
  if (isInquiries)
    return InquiriesContractsApi.useGetInquiryContractByIdQuery(arg, options)
  return CandidateContractsApi.useGetCandidatesContractsByIdQuery(arg, options)
}

export const useLazyGetContractByIdQuery = (options?: any) => {
  if (isInquiries)
    return InquiriesContractsApi.useLazyGetInquiryContractByIdQuery(options)
  return CandidateContractsApi.useLazyGetCandidatesContractsByIdQuery(options)
}

type UseGetSubscribedNotificationsQueryType =
  typeof InquiriesContractsApi.useGetSubscribedNotificationsQuery
export const useGetSubscribedNotificationsQuery: UseGetSubscribedNotificationsQueryType =
  (arg, options?: any) => {
    if (isInquiries)
      return InquiriesContractsApi.useGetSubscribedNotificationsQuery(
        arg,
        options
      )
    return CandidateContractsApi.useGetSubscribedNotificationsQuery(
      arg,
      options
    )
  }

export const useCreateContractMutation = (options?: any) => {
  if (isInquiries)
    return InquiriesContractsApi.useCreateContractMutation({...options, fixedCacheKey: 'sharedInquiryCreation'})
  return CandidateContractsApi.useCreateContractMutation({...options, fixedCacheKey: 'sharedCandidateCreation'})
}

export const useAddDestinationsToContractMutation = (options?: any) => {
  if (isInquiries)
    return InquiriesContractsApi.useAddDestinationsToContractMutation(options)
  return CandidateContractsApi.useAddDestinationsToContractMutation(options)
}

export const useSubscribeUsertoContractNotificationsMutation = (
  options?: any
) => {
  if (isInquiries)
    return InquiriesContractsApi.useSubscribeUsertoContractNotificationsMutation(
      options
    )
  return CandidateContractsApi.useSubscribeUsertoContractNotificationsMutation(
    options
  )
}

export const useUpdateContractMutation = (options?: any) => {
  if (isInquiries)
    return InquiriesContractsApi.useUpdateContractMutation(options)
  return CandidateContractsApi.useUpdateContractMutation(options)
}

export const ContractsApi = () => {
  if (isInquiries)
    return InquiriesContractsApi
  return CandidateContractsApi
}
