import { createSelector, createSlice } from '@reduxjs/toolkit'
import type { RootState } from 'Utils/store'

const initialState = {
  contract: null,
  segmentsRelatedToContract: [],
  segmentsLeadsTotal: 0,
  noOfLeads: null,
  segmentName: '',
}

export const NewAnnualSegmentSlice = createSlice({
  name: 'newAnnualSegment',
  initialState,
  reducers: {
    setContract: (state, action) => {
      state.contract = action.payload
    },
    setSegmentsForContract: (state, action) => {
      state.segmentsRelatedToContract = action.payload
    },
    setSegmentsLeadsTotal: (state, action) => {
      state.segmentsLeadsTotal = action.payload
    },

    setNoOfLeads: (state, action) => {
      state.noOfLeads = action.payload
    },
    setSegmentName: (state, action) => {
      state.segmentName = action.payload
    },
    clearNewAnnualSegment: () => {
      return initialState
    },
  },
})

export const {
  setContract,
  setSegmentsForContract,
  setSegmentsLeadsTotal,
  setNoOfLeads,
  setSegmentName,
  clearNewAnnualSegment,
} = NewAnnualSegmentSlice.actions

export default NewAnnualSegmentSlice.reducer

export const selectNewAnnualSegment = createSelector(
  ({ newAnnualSegment }: RootState) => newAnnualSegment.contract,
  ({ newAnnualSegment }: RootState) =>
    newAnnualSegment.segmentsRelatedToContract,
  ({ newAnnualSegment }: RootState) => newAnnualSegment.segmentsLeadsTotal,
  ({ newAnnualSegment }: RootState) => newAnnualSegment.noOfLeads,
  ({ newAnnualSegment }: RootState) => newAnnualSegment.segmentName,
  (
    contract,
    segmentsRelatedToContract,
    segmentsLeadsTotal,
    noOfLeads,
    segmentName
  ) => ({
    contract,
    segmentsRelatedToContract,
    segmentsLeadsTotal,
    noOfLeads,
    segmentName,
  })
)
