import React from 'react'
import Button from '@mui/material/Button'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import { styled } from '@mui/material/styles'
import { Input } from 'Components/shared/input'
import { useToggle } from 'Hooks/useToggle'
import PasswordHidden from 'Assets/images/password_hidden.svg'
import PasswordShown from 'Assets/images/password_shown.svg'

interface PasswordAuthenticationTypeProps {
  userName: string
  password: string
  setDestinationBasicAuthUserName: (value: string) => void
  setDestinationBasicAuthPassword: (value: string) => void
  control: any
}

const AuthContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  width: '279px',
  marginTop: '30px',
  marginLeft: ' 10px',
}))

const PasswordIcon = styled(IconButton)(({ theme }) => ({
  position: 'relative',
  marginRight: '50px',
}))

const FlexContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
}))

const TestConnection = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: '.875rem',
  fontWeight: theme.typography.fontWeightMedium,
  letterSpacing: '-0.2px',
  lineHeight: '21px',
  padding: '0px',
  width: '105px',
}))

const ConnectionStatus = styled('span')(({ theme }) => ({
  color: theme.palette.success.main,
  fontWeight: theme.typography.fontWeightMedium,
  fontSize: '0.875rem',
  lineHeight: '21px',
}))

export const PasswordAuthenticationType = ({
  userName,
  password,
  setDestinationBasicAuthUserName,
  setDestinationBasicAuthPassword,
  control,
}: PasswordAuthenticationTypeProps) => {
  const [showPassword, togglePasswordVisibility] = useToggle(false)
  return (
    <AuthContainer>
      <Input
        id='basic-auth-username'
        name='basicUsername'
        control={control}
        rules={{
          required: true
        }}
        placeholder='Username'
        style={{ width: '100%' }}
        value={userName}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setDestinationBasicAuthUserName(e.target.value)
        }
      />
      <Input
        id='basic-auth-password'
        placeholder='Password'
        name='basicPassword'
        control={control}
        rules={{
          required: true
        }}
        type={showPassword ? 'text' : 'password'}
        value={password}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setDestinationBasicAuthPassword(e.target.value)
        }
        inputProps={{
          disableUnderline: true,
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton
                edge='end'
                aria-label='toggle password visibility'
                onClick={togglePasswordVisibility}
              >
                {showPassword ? <PasswordHidden /> : <PasswordShown />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </AuthContainer>
  )
}
