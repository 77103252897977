export enum ContractType {
  annual = 'annual',
  on_demand = 'on_demand',
}

export enum ContractTypeReadable {
  annual = 'Time-Based',
  on_demand = 'On-Demand',
}

export namespace ContractType {

  export function toHumanName(
    key: string
  ): keyof typeof ContractTypeReadable | undefined {
    return ContractTypeReadable[key] || undefined
  }

  export function fromHumanName(
    value: string
  ): keyof typeof ContractType | undefined {
    return Object.keys(ContractType).find(
      (key) => ContractTypeReadable[key as keyof typeof ContractType] === value
    ) as keyof typeof ContractType | undefined
  }

  export function getHumanList(): string[] {
    return Object.values(ContractTypeReadable)
  }
}

export enum ContractSource {
  candidates = 'candidates',
  inquiries = 'inquiries',
}
export const ContractSources = [
  ContractSource.candidates,
  ContractSource.inquiries,
]
export enum ContractSourceReadable {
  candidates = 'Candidates',
  inquiries = 'Inquiries',
}

export namespace ContractSource {

  export function toHumanName(
    key: string
  ): keyof typeof ContractSourceReadable | undefined {
    return ContractSourceReadable[key] || undefined
  }

  export function fromHumanName(
    value: string
  ): keyof typeof ContractSource | undefined {
    return Object.keys(ContractSource).find(
      (key) => ContractSourceReadable[key as keyof typeof ContractSource] === value
    ) as keyof typeof ContractSource | undefined
  }

  export function getHumanList(): string[] {
    return Object.values(ContractSourceReadable)
  }
}

export enum ContractStatus {
  pending = 'pending',
  in_progress = 'in_progress',
  completed = 'completed',
  expired = 'expired',
  paused = 'paused',
  flagged = 'flagged',
}

export enum PurchaseType {
  direct_purchase = 'Direct Purchase',
  eab_internal = 'EAB Internal',
}

export namespace PurchaseType {
  export function toList(): string[] {
    return [PurchaseType.direct_purchase, PurchaseType.eab_internal]
  }
}