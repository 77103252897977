import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit'
import { FilterBy } from 'Filter/properties'
import type { RootState } from 'Utils/store'
import { findDateFromDays } from 'Utils/dateUtils'

export interface TimePeriod {
  label: string
  value: number
}
interface TimePeriodProps {
  /* selectedValue is the value that is currently selected in the dropdown */
  selectedValue: string
  /* values is the list of values that are available in the dropdown */
  values: string[]
  /* filterQuery is the query that is used to filter the data in the backend */
  filterQuery: any
}

const TIME_PERIOD = [
  'All',
  'Within Last 3 Days',
  'Within Last 7 Days',
  'Within Last 30 Days',
  'Within Last 3 Months',
  'Within Last 12 Months',
]

const initialState: TimePeriodProps = {
  values: TIME_PERIOD,
  selectedValue: TIME_PERIOD[0],
  filterQuery: '',
}

export const TimePeriodSlice = createSlice({
  name: 'candidatesTimePeriod',
  initialState,
  reducers: {
    setTimePeriod: (state, action: PayloadAction<string>) => {
      state.selectedValue = action.payload

      if (action.payload !== 'All') {
        state.filterQuery = {
          column: 'delivered_date',
          op: FilterBy.GE,
          value: findDateFromDays(action.payload),
        }
      } else {
        state.filterQuery = ''
      }
    },
  },
})

export const selectTimePeriod = createSelector(
  ({ candidatesFilesFilter }: RootState) =>
    candidatesFilesFilter.timePeriod.selectedValue,
  (selectedValue: string): { selectedValue: string; values: string[] } => {
    return { selectedValue, values: TIME_PERIOD }
  }
)

export const { setTimePeriod } = TimePeriodSlice.actions

export default TimePeriodSlice.reducer
