import {
  useCreateSegmentMutation,
  useUpdateSegmentMutation,
} from 'Services/segments/segments'

export const useSegmentService = () => {
  const [createSegment, { isLoading: isCreatingSegment }] =
    useCreateSegmentMutation()

  const [updateSegment, { isLoading: isUpdatingSegment }] =
    useUpdateSegmentMutation()

  const isLoading = isCreatingSegment || isUpdatingSegment

  return {
    isLoading,
    isCreatingSegment,
    isUpdatingSegment,
    createSegment,
    updateSegment,
  }
}
