import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { InstitutionResponse } from 'Services/global/institution'
import { Contract } from 'Services/contracts/contractsUtils'
import { Segment } from 'Services/segments/segmentUtils'

interface AddSegment {
  institution: InstitutionResponse
  segment: Segment
  contract: Contract
  leadTotal: number
}

const initialState: AddSegment = {
  institution: null,
  segment: null,
  contract: null,
  leadTotal: 0,
}

export const AddSegmentSlice = createSlice({
  name: 'addSegment',
  initialState,
  reducers: {
    setInstitution: (state, action: PayloadAction<InstitutionResponse>) => {
      state.institution = action.payload
      state.segment = null
      state.contract = null
      state.leadTotal = 0
    },
    setContract: (state, action: PayloadAction<Contract>) => {
      state.contract = action.payload
      state.segment = null
    },
    setSegment: (state, action: PayloadAction<Segment>) => {
      state.segment = action.payload
    },
    setLeadTotal: (state, action: PayloadAction<number>) => {
      state.leadTotal = action.payload
    },
    clearAddSegment: (state) => {
      state.institution = null
      state.segment = null
      state.contract = null
      state.leadTotal = 0
    },
  },
})

export const {
  setInstitution,
  setSegment,
  setContract,
  setLeadTotal,
  clearAddSegment,
} = AddSegmentSlice.actions

export default AddSegmentSlice.reducer
