import { createSlice, createSelector } from '@reduxjs/toolkit'
import type { RootState } from 'Utils/store'
import { FilterBy } from 'Filter/properties'
import GreenlightConfig from 'Configs/fields/greenlight.json'
import { ContractSource } from 'Types/contractTypes'

const contractSource = ContractSource.candidates

export const initialState: any = {
  values: Object.values(GreenlightConfig.values),
  selectedValue: GreenlightConfig.values.no,
  filterQuery: '',
}

const greenlightSelectedQuery = ({ value, meta }) => {
  return {
    column: GreenlightConfig.column,
    value,
    op: FilterBy.EQ,
    meta: {
      section: meta.section,
      label: meta.label,
      selectedValue: value,
    },
  }
}

const createFilterQuery = (selectedValue: string) => {
  switch (selectedValue) {
    case GreenlightConfig.values.no:
      return initialState.filterQuery
    case GreenlightConfig.values.yes:
      return greenlightSelectedQuery({
        value: selectedValue,
        meta: GreenlightConfig.meta,
      })
    default:
      return initialState.filterQuery
  }
}

export const GreenlightSlice = createSlice({
  name: 'greenlight',
  initialState,
  reducers: {
    setOnDemandGreenLightValue: (state, action) => {
      state.selectedValue = action.payload
      state.filterQuery = createFilterQuery(action.payload)
    },
  },
})

export const selectGreenlight = createSelector(
  ({ onDemandFilter: { demographics } }: RootState) =>
    demographics.greenlight.selectedValue,
  (
    selectedValue: string
  ): {
    selectedValue: string
    values: string[]
    audienceTypes: string[]
    label: string
    filterQuery: any
  } => {
    return {
      selectedValue: selectedValue,
      values: Object.values(GreenlightConfig.values),
      audienceTypes: GreenlightConfig.source[contractSource].audience_types,
      label: GreenlightConfig.meta.label,
      filterQuery: createFilterQuery(selectedValue),
    }
  }
)

export const { setOnDemandGreenLightValue } = GreenlightSlice.actions

export default GreenlightSlice.reducer
