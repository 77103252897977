import type { RootState } from 'Utils/store'
import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit'
import { Destination } from 'Services/configuration/destinations'
import { FileFormat } from 'Types/fileFormat'
import { ContractSource } from 'Types/contractTypes'
import { LookBackPeriod } from 'Types/lookBackPeriod'
import { resetContractStates } from 'Slices/contracts'

export const allDeliveryCadence = [
  'Weekly, Monday',
  'Weekly, Tuesday',
  'Weekly, Wednesday',
  'Weekly, Thursday',
  'Weekly, Friday',
  'Weekly, Saturday',
  'Weekly, Sunday',
]

export const allDeliveryModelValues = ['Deliver inquiries until cap is hit', 'Deliver to Weekly Cap', 'Deliver to Monthly Cap']


export const CreateNewDefaultFieldValues = {
  [ContractSource.inquiries]: {
    selectedDeliveryCadence: [allDeliveryCadence[0]],
    selectedDeliveryModel: allDeliveryModelValues[0],
  },
}

interface ContractConfigurationState {
  disableUpdate: boolean
  isDuplicating: boolean
  canDuplicateContract: boolean

  fieldsAvailableForContract: string[]
  sectionsAvailablForContract: string[]

  allFileFormats: string[]
  allDeliveryCadence: string[]
  allDeliveryModels: string[]
  allLookbackPeriods: string[]
  allDestinations: Destination[]
  allLeads: string[]
}

export const ContractStateInitialState = {
  disableUpdate: true,
  isDuplicating: false,
  canDuplicateContract: false,

  fieldsAvailableForContract: [],
  sectionsAvailablForContract: [],

  allFileFormats: Object.values(FileFormat),
  allDeliveryCadence: allDeliveryCadence,
  allDeliveryModels: allDeliveryModelValues,
  allLookbackPeriods: LookBackPeriod.getHumanList(),
  allDestinations: [],
  allLeads: [],
}

export const ContractStateSlice = createSlice({
  name: 'contractState',
  initialState: ContractStateInitialState,
  reducers: {
    setContractState: (
      state,
      action: PayloadAction<Partial<ContractConfigurationState>>
    ) => {
      for (const key in action.payload) {
        if (key in ContractStateInitialState) {
          state[key] = action.payload[key]
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetContractStates, () => {
      return ContractStateInitialState
    })
  },
})
export const selectContractState = createSelector(
  (state: RootState) => state.contractState,
  (contractState): ContractConfigurationState => {
    return contractState
  }
)

export const { setContractState } = ContractStateSlice.actions

export default ContractStateSlice.reducer
