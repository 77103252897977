import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import { alpha } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useSelector } from 'react-redux'

import { Dialog, DialogActionButtons } from 'Components/shared/dialog'

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: '4px',
  fontSize: '0.875rem',
  fontWeight: theme.typography.fontWeightMedium,
  height: '40px',
  lineHeight: '18px',
  minWidth: 'calc(100% - 40px)',
  [theme.breakpoints.up('md')]: {
    minWidth: 'auto',
  },
  color: theme.palette.common.white,
  backgroundColor: theme.palette.primary.main,
  marginTop: '32px',
  border: 'none',
  boxShadow: `0px 4px 20px ${alpha(theme.palette.common.white, 0.4)}`,
  '&:hover': {
    backgroundColor: `${alpha(theme.palette.primary.main, 0.85)}`,
  },
  '&.Mui-disabled': {
    color: theme.palette.common.white,
    backgroundColor: 'hsla(216, 79%, 72%, 1)',
  },
  [theme.breakpoints.up('md')]: {
    width: '104px',
  },
}))

const StyledContainer = styled('div')`
  margin-top: -32px;
  margin-bottom: 17px;
`

export const SessionExpirationWarning = () => {
  const [isDismissed, setIsDismissed] = useState(true)
  const handleDismissal = () => {
    setIsDismissed(true)
  }
  const isLoggedIn = useSelector((state: any) => {
    const queryKey = Object.keys(state?.authentication.queries).find((key) => !!state.authentication.queries[key]?.data)
    return state?.authentication.queries[queryKey]?.data?.isLoggedIn
  })
  useEffect(() => {
    if(isLoggedIn === true) {
      setIsDismissed(false)
    }
  }, [isLoggedIn])

  return (
    <Dialog
      onClose={handleDismissal}
      open={isLoggedIn === false && !isDismissed}
      title='Session Expired'
    >
      <StyledContainer>Your Session Is Expired</StyledContainer>
      <DialogActionButtons>
        <StyledButton onClick={handleDismissal}>Ok</StyledButton>
      </DialogActionButtons>
    </Dialog>
  )
}