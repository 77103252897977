import React, { FC } from 'react'
import { Provider } from 'react-redux'
import { ThemeProvider, StyleSheetManager } from 'styled-components'
import { AppStore, setupStore } from 'Utils/store'
import { theme } from '../theme'

const originalStore = setupStore()

interface ProvidersProps {
  store?: AppStore
  children: React.ReactNode
}

export const Providers: FC<ProvidersProps> = ({
  children,
  store = originalStore,
}) => (
  <Provider store={store}>
    <StyleSheetManager disableVendorPrefixes={process.env.NODE_ENV === 'local'}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </StyleSheetManager>
  </Provider>
)
