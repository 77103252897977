import Button from '@mui/material/Button'
import { alpha } from '@mui/material'
import { styled } from '@mui/material/styles'

export { PasswordLogin } from './PasswordLogin'
export { OneTimePasswordLogin } from './OneTimePassword'
export { SSOLogin } from './SSOLogin'
import { Login } from './Login'

export default Login

export const LoginSubHeading = styled('div')(({ theme }) => ({
    color: theme.palette.primary.dark,
    fontSize: '0.875rem',
    lineHeight: '18px',
    marginTop: '13px',
    marginBottom: '42px',
  }))
  
export const LoginBtnText = styled('span')(({ theme }) => ({
    color: theme.palette.common.white,
    fontSize: '0.875rem',
    height: '18px',
    lineheight: '18px',
    textalign: 'center',
  }))
  
export const InputFlexContainer = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    width: '100%',
  }))
  
export const ForgotPassword = styled('div')(({ theme }) => ({
    color: theme.palette.primary.dark,
    fontSize: '0.875rem',
    lineHeight: '18px',
    marginTop: '7px',
    marginBottom: '20px',
    textDecoration: 'none',
    cursor: 'pointer',
  }))
  
export const ForgotPasswordText = styled('b')(({ theme }) => ({
    color: theme.palette.primary.dark
  }))
  
export const LoginButton = styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    width: '100%',
    height: '48px',
    '&:hover': {
      backgroundColor: `${alpha(theme.palette.primary.main, 0.9)}`,
    },
  }))
  
export const CustomLink = styled('span')(() => ({
    textDecoration: 'none',
  }))

export const Container = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    minHeight: '670px',
  }))
  
export const LoginHeading = styled('div')(({ theme }) => ({
    color: theme.palette.secondary['100'],
    fontSize: '1.5rem',
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: '30px',
  }))
  
export const CustomLogin = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
  }))
  
export const LoginBox = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    gap: '30px',
    borderRadius: '8px',
    boxShadow: `0px 4px 8px ${alpha(theme.palette.secondary['100'], 0.1)}`,
    padding: '30px',
    width: 'min(885px, 80%)',
  
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      gap: '63px',
      padding: '84px 99px 66px 99px',
      '& > *': {
        width: '280px',
      },
    },
  }))
  
export const Divider = styled('div')(({ theme }) => ({
    background: theme.palette.grey['300'],
    height: '1px',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      alignSelf: 'center',
      height: '254px',
      width: '1px',
    },
  }))

export const SSOContainer = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '30px',
  }))
  
export const SSOLoginBtnText = styled('span')(({ theme }) => ({
    color: theme.palette.primary.main,
    fontSize: '0.875rem',
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: '18px',
    textAlign: 'center',
  }))
  
export const SSOLoginButton = styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.common.white,
    border: `1.5px solid ${theme.palette.primary.main}`,
    height: '42px',
    width: '100%',
    '&:hover': {
      backgroundColor: `${alpha(theme.palette.primary.light, 0.2)}`,
    },
    '&:active': {
      position: 'relative',
      top: '1px',
      left: '1px',
    },
  }))