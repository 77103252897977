import React from 'react'
import { styled } from '@mui/material/styles'
import { default as MUITable } from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import Sort from 'Assets/images/sort.svg'
import Checkbox from '@mui/material/Checkbox'
import CheckboxUnchecked from 'Assets/images/checkbox_unchecked.svg'
import CheckboxChecked from 'Assets/images/checkbox_checked.svg'

const StyledTableHead = styled(TableHead)(({ theme }) => ({
  background: 'transparent',
  '& th': {
    border: 0,
    color: theme.palette.primary.dark,
    fontSize: '0.75rem',
    fontWeight: theme.typography.fontWeightMedium,
    letterSpacing: '0.01em',
    lineHeight: '16px',
    padding: '2px 0px 11px 32px',
  },
}))

const StyledTableSortHead = styled(TableSortLabel)(({ theme }) => ({
  background: 'transparent',
  '&.Mui-active': {
    border: 0,
    color: theme.palette.primary.dark,
    fontSize: '0.75rem',
    fontWeight: theme.typography.fontWeightMedium,
    letterSpacing: '0.01em',
    lineHeight: '16px',
  },
}))

const StyledTableBody = styled(TableBody)(({ theme, disabled }) => ({
  background: 'white',
  '& td': {
    borderBottom: `1px solid ${theme.palette.grey['200']}`,
    color: disabled
      ? theme.palette.primary.dark
      : theme.palette.secondary['100'],
    fontSize: '0.875rem',
    fontWeight: theme.typography.fontWeightMedium,
    height: '18px',
    lineHeight: '16px',
    paddingLeft: '32px',
    paddingTop: '19.75px',
    paddingBottom: '19.75px',
  },
}))

const StyledTableCell = styled(TableCell)(({ width }) => ({
  maxWidth: width,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}))

const StyledTableRow = styled(TableRow)(({ theme, onClick, variant }) => ({
  height: '68px',
  '&:hover': {
    cursor: onClick ? 'pointer' : 'auto',
  },
  '&:nth-of-type(odd)': {
    backgroundColor:
      variant === 'striped'
        ? theme.palette.background.main
        : theme.palette.common.white,
  },
  '&:last-child': {
    '& td': {
      borderBottom:
        variant === 'striped'
          ? theme.palette.common.white
          : `1px solid ${theme.palette.grey['200']}`,
    },
  },
}))

interface TableProps {
  width?: string
  height?: string
  maxHeight?: string
  columnData: {
    /**  columnName is the name of the column */
    columnName: string
    /**  columnWidth is the width of the column */
    columnWidth?: number
    /** sortOrder can be 'ASC', 'DESC', or 'NONE', make this an ENUM */
    sortOrder?: string
    /** Sort by this column */
    sortBy?: string
  }[]
  /** rows is an array of arrays that contain the data for each row */
  rows: any[]
  /** handleRowClick is a function that is called when a row is clicked */
  handleRowClick?: (rowIdx: number) => void
  /** variant can be 'default' or 'striped' */
  variant?: 'default' | 'striped'
  /** setSortOrder is a function that is called when a column is clicked */
  setSortOrder?: (sortOrder: string) => void
  /** setSortBy is a function that is called when a column is clicked */
  setSortBy?: (sortBy: string) => void
  /** selectable determines if rows are selectable using a checkbox*/
  isSelectable?: boolean
  /** selectedAll determines if all rows are selected */
  selectedAll?: boolean
  /** handleAllClick is a function that is called when the select all checkbox is clicked */
  handleAllClick?: (flag: boolean) => void
  /** handleCheckboxClick is a function called when a checkbox is clicked*/
  handleCheckboxClick?: any
  /** rowIndexes is an array of booleans that determines if a row is selected */
  rowIndexes?: boolean[]
  /** boolean that determines text color if contract is expired */
  disabled?: boolean
  /** boolean that determines if table has overflow */
  tableOverflow?: boolean
  /** renderTableCell is a function that is called when a cell is rendered */
  renderTableCell?: ({ idx, row, columnData }) => React.ReactNode
  /* renderTableRow is a function that is called when a row is rendered */
  renderTableRow?: ({
    handleRowClick,
    handleCheckboxClick,
    row,
    idx,
    disabled,
    variant,
    rowIndexes,
    isSelectable,
    renderTableCell,
    columnData,
  }) => React.ReactNode
}

export const CustomTableCell = ({ idx, row, columnData, cellStyle = {} }) => {
  return (
    <StyledTableCell
      sx={cellStyle}
      key={idx}
      align='left'
      width={columnData[idx - 1].columnWidth}
    >
      {row[idx]}
    </StyledTableCell>
  )
}

export const CustomTableRow = ({
  handleRowClick,
  handleCheckboxClick,
  row,
  idx,
  disabled,
  variant,
  rowIndexes,
  isSelectable,
  renderTableCell,
  columnData,
  rowStyle = {},
}) => {
  return (
    <StyledTableRow
      sx={rowStyle}
      key={row[0]}
      onClick={
        handleRowClick && !disabled ? () => handleRowClick(idx) : undefined
      }
      hover={handleRowClick ? true : false}
      variant={variant}
    >
      {isSelectable && (
        <TableCell align='left' width={40}>
          <Checkbox
            key={rowIndexes[idx] ? 'checked' : 'unchecked'}
            icon={<CheckboxUnchecked />}
            checkedIcon={<CheckboxChecked />}
            checked={rowIndexes[idx]}
            disabled={disabled}
            onClick={(e) => handleCheckboxClick(e, idx)}
          />
        </TableCell>
      )}
      {row.map((_, idx) => {
        return (
          idx !== 0 &&
          (renderTableCell ? (
            renderTableCell({ idx, row, columnData })
          ) : (
            <CustomTableCell
              key={idx}
              idx={idx}
              row={row}
              columnData={columnData}
            />
          ))
        )
      })}
    </StyledTableRow>
  )
}

export const Table = ({
  width = '100%',
  height = 'auto',
  maxHeight,
  columnData,
  rows,
  handleRowClick,
  variant = 'default',
  setSortBy = () => {},
  setSortOrder = () => {},
  isSelectable = false,
  selectedAll = true,
  handleAllClick,
  handleCheckboxClick,
  rowIndexes,
  disabled,
  tableOverflow,
  renderTableRow,
  renderTableCell,
}: TableProps) => {
  return (
    <TableContainer
      sx={{
        width: width,
        height: height,
        maxHeight: maxHeight,
        overflowX: tableOverflow ? 'visible' : 'auto',
      }}
    >
      <MUITable aria-label='Table'>
        <StyledTableHead>
          <TableRow>
            {isSelectable && (
              <TableCell align='left' width={40}>
                <Checkbox
                  icon={<CheckboxUnchecked />}
                  checkedIcon={<CheckboxChecked />}
                  checked={selectedAll}
                  disabled={disabled}
                  onChange={() => handleAllClick(!selectedAll)}
                />
              </TableCell>
            )}
            {columnData.map((data, idx) => {
              return (
                <TableCell key={idx} align='left' width={data.columnWidth}>
                  {data.sortBy ? (
                    <StyledTableSortHead
                      active={data.columnName == data.sortBy}
                      direction={data.sortOrder}
                      IconComponent={Sort}
                      onClick={() => {
                        setSortOrder(data.sortOrder === 'desc' ? 'asc' : 'desc')
                        setSortBy(data.sortBy)
                      }}
                    >
                      {data.columnName}
                    </StyledTableSortHead>
                  ) : (
                    data.columnName
                  )}
                </TableCell>
              )
            })}
          </TableRow>
        </StyledTableHead>
        <StyledTableBody>
          {rows.map((row, idx) => {
            {
              return renderTableRow ? (
                renderTableRow({
                  handleRowClick,
                  handleCheckboxClick,
                  row,
                  idx,
                  disabled,
                  variant,
                  rowIndexes,
                  isSelectable,
                  renderTableCell,
                  columnData,
                })
              ) : (
                <CustomTableRow
                  key={idx}
                  handleRowClick={handleRowClick}
                  handleCheckboxClick={handleCheckboxClick}
                  row={row}
                  idx={idx}
                  disabled={disabled}
                  variant={variant}
                  rowIndexes={rowIndexes}
                  isSelectable={isSelectable}
                  renderTableCell={renderTableCell}
                  columnData={columnData}
                />
              )
            }
          })}
        </StyledTableBody>
      </MUITable>
    </TableContainer>
  )
}
