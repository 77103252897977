import React, { useEffect } from 'react'
import { styled } from '@mui/material/styles'
import { useController } from 'react-hook-form'
import TextField from '@mui/material/TextField'
import { ErrorMessage } from './Error'

const StyledInput = styled(TextField)(({ theme, border }) => ({
  '& .MuiInputBase-input': {
    border: border ?? `1.5px solid ${theme.palette.secondary['80']}`,
    borderRadius: '4px',
    boxSizing: 'border-box',
    color: theme.palette.secondary['100'],
    fontSize: '0.875rem',
    fontWeight: theme.typography.fontWeightMedium,
    height: '40px',
    lineHeight: '21px',
    padding: '9.5px 28px 9.5px 16px',
    width: '100%',
    transition: theme.transitions.create(['box-shadow']),
    '&:focus': {
      boxShadow: `0 0 0 0.2rem ${theme.palette.primary.light}`,
    },
    '& ::placeholder': {
      color: theme.palette.secondary['100'],
      fontWeight: theme.typography.fontWeightMedium,
      opacity: 1,
    },
  },
  '& .MuiInputAdornment-root': {
    position: 'absolute',
    right: '14px',
  },
  '& .Mui-disabled': {
    backgroundColor: theme.palette.common.white,
    '-webkit-text-fill-color': theme.palette.primary.dark,
    '& ::placeholder': {
      opacity: 0,
    },
  },
}))

interface InputProps {
  id?: string
  name?: string
  value?: string | number
  defaultValue?: string | number
  placeholder?: string
  disabled?: boolean
  control?: any
  type?: string
  rules?: any
  inputProps?: any
  multiline?: boolean
  rows?: number
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  children?: React.ReactNode
  style?: React.CSSProperties
  border?: string
}

export const Input = ({
  name,
  control,
  rules = {},
  disabled = false,
  onChange,
  multiline = false,
  value: propValue,
  rows = 1,
  inputProps,
  children,
  border,
  ...props
}: InputProps) => {
  if (control) {
    const { 
      field: { onChange: handleFieldChange, value, ref },
      formState: { errors },
    } = useController({ name, control, rules, defaultValue: propValue })

    useEffect(() => {
      //handles when the api loads after the field is on the page
      if (propValue !== value) {
        handleFieldChange(propValue)
      }
    }, [propValue])

    return (
      <>
        <StyledInput
          variant='standard'
          InputProps={{
            disableUnderline: true,
            ...inputProps,
          }}
          onChange={(event) => {
            handleFieldChange(event.target.value)
            onChange(event)
          }}
          value={value}
          name={name}
          disabled={disabled}
          inputRef={ref}
          multiline={multiline}
          rows={rows}
          border={border}
          {...props}
        />

        {children}

        {errors[name]?.message && (
          <ErrorMessage>{errors[name]?.message}</ErrorMessage>
        )}
        {errors['root']?.[name]?.message && (
          <ErrorMessage>{errors['root']?.[name]?.message}</ErrorMessage>
        )}
      </>
    )
  } else {
    return (
      <>
        <StyledInput
          variant='standard'
          InputProps={{
            disableUnderline: true,
            ...inputProps,
          }}
          onChange={onChange}
          name={name}
          disabled={disabled}
          multiline={multiline}
          rows={rows}
          border={border}
          value={propValue}
          {...props}
        />
      </>
    )
  }
}
