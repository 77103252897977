import React, { useState, useEffect } from 'react'
import { OneTimePasswordLogin, PasswordLogin, SSOLogin, Container, LoginBox, CustomLogin, LoginHeading, Divider } from 'Components/connectedComponents/login'

import { useLoginStatusQuery } from 'Services/global/authentication'
import { selectUserInfo } from 'Containers/shared/login/UserInfoSlice'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Loading } from 'Utils/loading'
import { Permissions } from 'Configs/userPermissions'

declare var heap: any

export const Login = () => {
  const [isPasswordLogin, setIsPasswordLogin] = useState(false);
  const navigate = useNavigate()
  const { data: loginInfo, isLoading } = useLoginStatusQuery()
  const userInfo = useSelector(selectUserInfo)
  const getDefaultURL = (isAdmin: boolean) => {
    if (isAdmin) {
      return `/admin/segments-queue`
    } else {
      return `/institution/${userInfo.ipedId}/inquiries`
    }
  }
  useEffect(() => {
    if (loginInfo?.isLoggedIn) {
      if(heap?.loaded) {
        try {
          heap.addUserProperties({ role: loginInfo?.roles[0]})
        } catch (e) {
          console.error('Error setting heap user properties', e)
        }
      }
      const isAdmin = loginInfo.permissions.includes(Permissions.SwitchInstitution)
      const storedPath = window.sessionStorage.getItem('path')
      storedPath ? navigate(storedPath) : navigate(getDefaultURL(isAdmin))
    }
  }, [loginInfo?.isLoggedIn])
  
  if (isLoading) return <Loading />

  return (
    !loginInfo?.isLoggedIn && (
    <Container>
      <LoginBox>
        <CustomLogin>
          <LoginHeading>Login</LoginHeading>
          {!isPasswordLogin && <OneTimePasswordLogin exitPasscodeLogin={() => setIsPasswordLogin(true)}/>}
          {isPasswordLogin && <PasswordLogin exitPasswordLogin={() => setIsPasswordLogin(false)} />}
        </CustomLogin>
        <Divider />
        <SSOLogin />
      </LoginBox>
    </Container>
  )
  )
}
