import React from 'react'
import { styled } from '@mui/material/styles'

const Header = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '40px',
  width: '100%',
  position: 'relative',
}))

interface BodyHeaderProps {
  /** This will include body title, action buttons, etc.. */
  children: React.ReactNode
}

export const BodyHeader = ({ children }: BodyHeaderProps) => {
  return <Header>{children}</Header>
}
