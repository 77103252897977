import zipCodes from 'Configs/data/zip_codes.json'
import uniq from 'lodash.uniq'

const MAX_ZIP_CODES = 100

/** removes the newlines, commas */
const cleanUpZipCodes = (value: string = '') => {
  return value
    .replace(/[\s+\r\n]+/g, ',')
    .replace(/,$/, '')
    .split(',')
    .filter((zip) => zip)
    .map((zip) => zip.trim())
}

export const processZipCodes = (value: string = '') => {
  /** clean up the zip code input */
  const zipCodes = cleanUpZipCodes(value)

  /** If the zip code length is 3, add a % at the end */
  const result = zipCodes.map((zipCode) => {
    return zipCode.length === 3 ? zipCode + '%' : zipCode
  })

  /** Remove duplicates and return */
  return uniq(result)
}

const isInvalidZip5Code = (zipCode: string) => {
  if (!zipCode) return true

  /** If it's not a number */
  if (isNaN(Number(zipCode))) return true

  /** If the zip code length is 5 */
  if (zipCode.length === 5) {
    if (zipCodes.data.includes(zipCode)) {
      return false
    }
  }

  return true
}

const isInvalidZip3Code = (zipCode: string) => {
  if (!zipCode) return true

  /** If it's not a number */
  if (isNaN(Number(zipCode))) return true

  /** If the zip code length is 3 */
  if (zipCode.length === 3) {
    for (let i = 0; i < zipCodes.data.length; i++) {
      if (zipCodes.data[i].startsWith(zipCode)) {
        return false
      }
    }
  }
  return true
}

export const validateZip5Codes = (value: string = '') => {
  if (!value) return false

  const inputZipCodes = cleanUpZipCodes(value)

  if (inputZipCodes.length === 0) return false

  if (inputZipCodes.length > MAX_ZIP_CODES)
    return `${MAX_ZIP_CODES} zip codes are allowed in the input`

  for (let i = 0; i < inputZipCodes.length; i++) {
    if (isInvalidZip5Code(inputZipCodes[i])) {
      return 'Invalid zip code in the input'
    }
  }
  return true
}

export const validateZip3Codes = (value: string = '') => {
  if (!value) return false

  const inputZipCodes = cleanUpZipCodes(value)

  if (inputZipCodes.length === 0) return false

  if (inputZipCodes.length > MAX_ZIP_CODES)
    return `${MAX_ZIP_CODES} zip codes are allowed in the input`

  for (let i = 0; i < inputZipCodes.length; i++) {
    if (isInvalidZip3Code(inputZipCodes[i])) {
      return 'Invalid zip code in the input'
    }
  }
  return true
}
