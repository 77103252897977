import React, { useCallback, memo } from 'react'
import { useDispatch } from 'react-redux'
import {
  setOnDemandAnyScore,
  setOnDemandSat,
  setOnDemandAct,
  setOnDemandSatOrAct,
  setOnDemandSatAndAct,
} from './TestScoresSlice'
import { TestScores as TestScoresComponent } from 'Components/shared/segmentfilters/prospectprofile/TestScores'

interface TestScoresProps {
  testScoresState: any
}

export const TestScores = memo(({ testScoresState }: TestScoresProps) => {
  const dispatch = useDispatch()

  const dispatchAnyScore = useCallback(() => {
    dispatch(setOnDemandAnyScore())
  }, [])

  const dispatchSat = useCallback(({ min, max }) => {
    dispatch(setOnDemandSat({ min, max }))
  }, [])

  const dispatchAct = useCallback(({ min, max }) => {
    dispatch(setOnDemandAct({ min, max }))
  }, [])

  const dispatchSatOrAct = useCallback(({ minSat, maxSat, minAct, maxAct }) => {
    dispatch(
      setOnDemandSatOrAct({
        minSat,
        maxSat,
        minAct,
        maxAct,
      })
    )
  }, [])

  const dispatchSatAndAct = useCallback(
    ({ minSat, maxSat, minAct, maxAct }) => {
      dispatch(
        setOnDemandSatAndAct({
          minSat,
          maxSat,
          minAct,
          maxAct,
        })
      )
    },
    []
  )

  return (
    <TestScoresComponent
      dispatchAnyScore={dispatchAnyScore}
      dispatchSat={dispatchSat}
      dispatchAct={dispatchAct}
      dispatchSatOrAct={dispatchSatOrAct}
      dispatchSatAndAct={dispatchSatAndAct}
      testScoresState={testScoresState}
    />
  )
})
