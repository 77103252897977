import React from 'react'
import { styled } from '@mui/material/styles'
import { formatDate } from 'Utils/dateUtils'

interface ContractHeaderSubLabelProps {
  /** label */
  label: string
  /** value */
  value: string
}

const Label = styled('span')(({ theme }) => ({
  color: theme.palette.primary.dark,
  fontWeight: theme.typography.fontWeightMedium,
  fontSize: '0.625rem',
  marginRight: '6px',
}))

const Value = styled('span')(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontWeight: theme.typography.fontWeightMedium,
  fontSize: '0.875rem',
  marginRight: '18px',
}))

export const ContractHeaderSubLabel = ({
  label,
  value,
}: ContractHeaderSubLabelProps) => {
  return (
    <>
      <Label>{label}</Label>
      <Value>{formatDate(value) || 'N/A'}</Value>
    </>
  )
}
