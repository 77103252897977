import React from 'react'
import Dialog from '@mui/material/Dialog'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import { alpha } from '@mui/material'

const CustomDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    background: theme.palette.common.white,
    borderRadius: '8px',
    boxShadow: `0px 4px 20px ${alpha(theme.palette.common.black, 0.4)}`,
    padding: '32px',
    width: '90%',
    [theme.breakpoints.up('md')]: {
      padding: '34px 32px 32px 32px',
      /* this is required to override the default max-width of the dialog */
      maxWidth: '560px',
      width: '560px',
    },
  },
}))

const FlexContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}))

const Title = styled('div')(({ theme }) => ({
  color: theme.palette.secondary['100'],
  fontSize: '1.5rem',
  fontWeight: theme.typography.fontWeightBold,
  height: '30px',
  lineHeight: '30px',
  marginBottom: '32px',
}))

const BodyText = styled('div')(({ theme }) => ({
  textAlign: 'center',
  color: theme.palette.secondary['100'],
  fontSize: '0.875rem',
  fontWeight: theme.typography.fontWeightMedium,
  lineHeight: '21px',
  marginBottom: '45px',
}))

const ActionButtons = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '10px',
  [theme.breakpoints.up('md')]: {
    gap: '40px',
  },
}))

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: '4px',
  fontSize: '0.875rem',
  fontWeight: theme.typography.fontWeightMedium,
  height: '40px',
  lineHeight: '18px',
  minWidth: 'calc(100% - 55px)',
  [theme.breakpoints.up('md')]: {
    minWidth: 'auto',
  },
}))

const CancelButton = styled(StyledButton)(({ theme }) => ({
  border: `1.8px solid ${theme.palette.primary.main}`,
  color: theme.palette.primary.main,
  backgroundColor: theme.palette.common.white,
  '&:hover': {
    backgroundColor: `${alpha(theme.palette.primary.light, 0.4)}`,
  },
  [theme.breakpoints.up('md')]: {
    width: '110px',
  },
}))

const RemoveButton = styled(StyledButton)(({ theme }) => ({
  color: theme.palette.common.white,
  backgroundColor: theme.palette.error.main,
  boxShadow: `0px 4px 20px ${alpha(theme.palette.common.white, 0.4)}`,
  '&:hover': {
    backgroundColor: `${alpha(theme.palette.error.main, 0.85)}`,
  },
  [theme.breakpoints.up('md')]: {
    width: '104px',
  },
}))

const CreateButton = styled(StyledButton)(({ theme }) => ({
  color: theme.palette.common.white,
  backgroundColor: theme.palette.primary.main,
  boxShadow: `0px 4px 20px ${alpha(theme.palette.common.white, 0.4)}`,

  '&:hover': {
    backgroundColor: `${alpha(theme.palette.primary.main, 0.85)}`,
  },
  [theme.breakpoints.up('md')]: {
    width: 'auto',
    padding: '12px 12px',
  },
}))

interface CreateDeleteDialogProps {
  /** Title of the dialog */
  title: string
  /** Body text of the dialog */
  bodyText: string
  /** Text for the  action */
  actionText: string
  /** Function to handle  action */
  handleAction: (e: any) => void
  /** Function to toggle dialog */
  toggleDialog: (e: any) => void
  /** Boolean that tells if the dialog is open or not */
  isDialogOpen: boolean
  /** Boolean that tells if the dialog is for create or delete */
  isCreate?: boolean
}

export const CreateDeleteDialog = ({
  title,
  bodyText,
  actionText,
  handleAction,
  toggleDialog,
  isDialogOpen,
  isCreate = false,
}: CreateDeleteDialogProps) => {
  return (
    <CustomDialog
      onClose={toggleDialog}
      aria-labelledby={title}
      open={isDialogOpen}
    >
      <FlexContainer>
        <Title id={title}>{title}</Title>
        <BodyText>{bodyText}</BodyText>
        <ActionButtons>
          <CancelButton onClick={toggleDialog}>Cancel</CancelButton>
          {!isCreate && (
            <RemoveButton onClick={handleAction}>{actionText}</RemoveButton>
          )}
          {isCreate && (
            <CreateButton onClick={handleAction}>{actionText}</CreateButton>
          )}
        </ActionButtons>
      </FlexContainer>
    </CustomDialog>
  )
}
