import React from 'react'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import RightArrow from 'Assets/images/right_arrow.svg'

const LeftArrow = styled(RightArrow)(() => ({
  transform: 'rotate(180deg)',
}))

interface TableActionProps {
  /** page is the current page */
  page: number
  /** totalPages is the total number of table items */
  totalPages: number
  /** sets page number of prev or next click */
  onPageChange: (e, number) => void
}

export const TablePaginationActions = ({ 
  page, 
  totalPages,
  onPageChange 
}: TableActionProps) => {

    const handlePrevClick = (event) => {
      if (page === 0) {
        onPageChange(event, totalPages - 1)
      }  else {
        onPageChange(event, page - 1)
      }
    }

    const handleNextClick = (event) => {
      if (page === totalPages - 1) {
        onPageChange(event, 0)
      }  else {
        onPageChange(event, page + 1)
      }
    }

    return (
      <Box sx={{ flexShrink: 0 }}>
        <IconButton
          onClick={handlePrevClick}
          aria-label="previous page"
        >
          <LeftArrow/>
        </IconButton>
        <IconButton
          onClick={handleNextClick}
          aria-label="next page"
        >
          <RightArrow/>
        </IconButton>
    </Box>
    )

}