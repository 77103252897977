import React from 'react'
import { TablePaginationComponent } from 'Components/shared/table'

interface TablePaginationProps {
  /** rowsPerPage is the max number of rows per page */
  rowsPerPage: number
  /** currentPage is the current page in pagination */
  currentPage: number
  /** totalRecords is the total number of list items */
  totalRecords: number
  /** totalPages is the total number of pages */
  totalPages: number
  /** sets page number of prev or next click */
  handlePageChange: (e, number) => void
}

export const TablePagination = ({ 
  rowsPerPage, 
  currentPage, 
  totalRecords, 
  totalPages,
  handlePageChange
}: TablePaginationProps) => {

  return (
    <TablePaginationComponent 
      rowsPerPage={rowsPerPage}
      currentPage={currentPage} 
      totalRecords={totalRecords}
      totalPages={totalPages}
      handlePageChange={handlePageChange} 
    />
  )
}