import React, { forwardRef } from 'react'
import { styled } from '@mui/material/styles'
import { alpha } from '@mui/material'
import { Popover } from 'Components/shared/popover'

const FlexContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
}))

const MainContracts = styled('div')(() => ({
  maxWidth: '303px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}))

const OverflowContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: `1.5px solid ${alpha(theme.palette.primary.main, 0.2)}`,
  borderRadius: '13px',
  color: theme.palette.primary.main,
  cursor: 'default',
  fontSize: '0.75rem',
  fontWeight: theme.typography.fontWeightMedium,
  minHeight: '24px',
  lineHeight: '12px',
  textAlign: 'center',
  padding: '6px 9px',
  width: '32px',
}))

interface DestinationContractProps {
  /** contracts is an array of strings containing contract names  */
  contracts: string[]
  /** popoverId is the ID of each popover */
  popoverId?: number
  /** anchorEl is the HTML element that each popover anchors to */
  anchorEl?: HTMLElement
  /** anchorOrigin determines how popover is positioned 
  with respect to anchor element */
  anchorOrigin: {}
  /** Opens popover when the user hovers over each contract number  */
  handleOpen?: (e) => void
  /** Closes popover when user stops hovering */
  handleClose?: () => void
  /** isOverFlow is a boolean that checks for overflow */
  isOverFlow?: boolean
  /** ref is the element for each contract to be used
  to determine overflow */
  ref?: HTMLElement
}

export const DestinationContract = forwardRef<
  HTMLElement,
  DestinationContractProps
>(
  (
    { contracts, anchorEl, anchorOrigin, handleOpen, handleClose, isOverFlow },
    ref
  ) => {
    return (
      <FlexContainer>
        <MainContracts ref={ref}>{contracts.join(', ')}</MainContracts>
        {isOverFlow && (
          <OverflowContainer
            data-testid='overflow-container'
            onMouseEnter={(e) => {
              handleOpen(e)
            }}
            onMouseOut={handleClose}
          >
            {`${contracts.length}+`}
            <Popover
              anchorEl={anchorEl}
              anchorOrigin={anchorOrigin}
              open={Boolean(anchorEl)}
              popoverText={contracts.join('\n')}
            />
          </OverflowContainer>
        )}
      </FlexContainer>
    )
  }
)
