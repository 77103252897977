import React from 'react'
import { Table } from 'Components/shared/table'

interface DestinationProps {
  columnData: {
    /**  columnName is the name of the column */
    columnName: string
    /**  columnWidth is the width of the column */
    columnWidth?: number
    /** sortOrder can be 'ASC', 'DESC', or 'NONE', make this an ENUM */
    sortOrder?: string
  }[]
  /** rows is an array of arrays that contain the data for each row */
  rows: any[]
  /** handleRowClick is a function that is called when a row is clicked */
  handleRowClick: (rowIdx: number) => void
}

export const Destinations = ({ ...props }: DestinationProps) => {
  return <Table {...props} />
}
