import React, { useEffect } from 'react'
import { DownloadFile as DownloadFileComponent } from 'Components/shared/table'
import { useGetDownloadFileMutation } from 'Services/files/files'

interface DownloadFileProps {
  contractDeliveryId: string
  instId: string
}

const downloadFile = (url : string) => {
  return fetch(url).then(res => res.blob()).then(file => {
    let tempUrl = URL.createObjectURL(file)
    const aTag = document.createElement("a")
    aTag.href = tempUrl
    aTag.download = url.replace(/^.*[\\\/]/, '').split("?")[0]
    document.body.appendChild(aTag)
    aTag.click()
    URL.revokeObjectURL(tempUrl)
    aTag.remove()
  })
}

export const DownloadFile = ( { instId , contractDeliveryId }: DownloadFileProps) => {
  const [getDownloadFileUrl, result] = useGetDownloadFileMutation()

  useEffect(() => {
    if(result.data) {
      const url = result.data.data
      downloadFile(url)
    }
  }, [result.data])

  const handleOnClick = () => {
    getDownloadFileUrl({ ipedId: instId, contractDeliveryId: contractDeliveryId })
  };

  return (
    <DownloadFileComponent onClick={handleOnClick} />
  )
}