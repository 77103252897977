import { createApi } from '@reduxjs/toolkit/query/react'
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import { baseQuery } from '../getBaseQuery'

export interface Destination {
  id: string
  label: string
  connection_type: string
  lead_download_preference: string
  lead_download_is_zip: boolean
  contracts: Array<Contract>
}

interface ConnectionDetails {
  sftp_host: string
  sftp_port: string
  sftp_password?: string
  sftp_privatekey?: string
  sftp_username: string
}

interface GetDestinationsAPIResponse {
  data: {
    total_records: number
    total_pages: number
    records: Destination[]
  }
}

interface GetDestinationTransformedResponse {
  records: Destination[]
  total_records: number
  total_pages: number
}

interface AddDestination {
  ipedId: string
  label: string
  connection_type: string
  sftp_port: number
  path: string
  ssh_key_id: string
  host_name: string
  user_name: string
  con_details: ConnectionDetails
  lead_download_preference: string
  lead_download_is_zip: boolean
}

interface UpdateDestination {
  ipedId: string
  destinationId: string
  label: string
  connection_type: string
  sftp_port: number
  path: string
  ssh_key_id: string
  host_name: string
  user_name: string
  con_details: ConnectionDetails
  lead_download_preference: string
  lead_download_is_zip: boolean
}

interface Contract {
  id: string
  label: string
}

interface GetContractsForDestination {
  data?: {
    data?: Array<Contract>
  }
}

const DESTINATIONS = 'DESTINATIONS'

export const DestinationsApi = createApi({
  reducerPath: 'destinations',
  baseQuery,
  tagTypes: [DESTINATIONS],
  endpoints: (build) => ({
    getDestinations: build.query<
      GetDestinationTransformedResponse,
      { ipedId: string; page: number }
    >({
      queryFn: async (
        { ipedId, page },
        _queryApi,
        _extraOptions,
        fetchWithBQ
      ) => {
        const getDestinations = await fetchWithBQ({
          url: `institutions/${ipedId}/destinations?page=${page}`,
        })

        if (getDestinations?.error)
          return { error: getDestinations?.error as FetchBaseQueryError }

        const destinations = getDestinations?.data as GetDestinationsAPIResponse

        for (const destination of destinations?.data?.records) {
          const contract: GetContractsForDestination = await fetchWithBQ({
            url: `institutions/${ipedId}/destinations/${destination?.id}/contracts`,
          })
          destination.contracts = contract?.data?.data
        }

        return { data: { ...destinations?.data } }
      },
      providesTags: [DESTINATIONS],
    }),

    getAllDestinations: build.query<Destination[], { ipedId: string }>({
      queryFn: async ({ ipedId }, _queryApi, _extraOptions, fetchWithBQ) => {
        const firstPageDestinations = await fetchWithBQ({
          url: `institutions/${ipedId}/destinations?page=0`,
        })
        if (firstPageDestinations?.error)
          return { error: firstPageDestinations?.error as FetchBaseQueryError }

        let allDestinations =
          (firstPageDestinations?.data?.['data']?.[
            'records'
          ] as Destination[]) ?? []

        let totalPages = 0
        if (firstPageDestinations?.data?.['data']?.['total_pages'])
          totalPages = firstPageDestinations?.data?.['data']?.['total_pages']

        for (let page = 1; page < totalPages; page++) {
          const destinations = await fetchWithBQ({
            url: `institutions/${ipedId}/destinations?page=${page}`,
          })
          allDestinations = [
            ...allDestinations,
            ...destinations.data?.['data']?.['records'],
          ]
        }

        return { data: allDestinations }
      },
    }),

    /** Add new destination */
    addDestination: build.mutation<AddDestination, Partial<AddDestination>>({
      query: ({ ipedId, ...args }) => ({
        url: `institutions/${ipedId}/destinations`,
        method: 'POST',
        body: args,
      }),
      invalidatesTags: [DESTINATIONS],
    }),

    /** Update destination */
    updateDestination: build.mutation<
      UpdateDestination,
      Partial<UpdateDestination>
    >({
      query: ({ ipedId, destinationId, ...args }) => ({
        url: `institutions/${ipedId}/destinations/${destinationId}`,
        method: 'PATCH',
        body: args,
      }),
      invalidatesTags: [DESTINATIONS],
    }),

    /** Delete existing destination */
    deleteDestination: build.mutation<
      boolean,
      { ipedId: string; destinationId: string }
    >({
      query: (args) => ({
        url: `institutions/${args.ipedId}/destinations/${args.destinationId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [DESTINATIONS],
    }),
  }),
})

export const {
  useGetDestinationsQuery,
  useGetAllDestinationsQuery,
  useAddDestinationMutation,
  useDeleteDestinationMutation,
  useUpdateDestinationMutation,
} = DestinationsApi
