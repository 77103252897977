import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from '../getBaseQuery'

export interface IntrospectionAPIResponse {
  data: {
    supported_filter_operations: string[]
    supported_columns: string[]
  }
}

export const IntrospectionApi = createApi({
  reducerPath: 'introspection',
  baseQuery,
  endpoints: (build) => ({
    getSupportedColumns: build.query<string[], void>({
      query: () => ({ url: 'registrations/_introspection' }),
      transformResponse: (response: IntrospectionAPIResponse) =>
        response.data.supported_columns
          .map((column: any) => column.name)
          .sort((a, b) => a.localeCompare(b)),
    }),
  }),
})

export const { useGetSupportedColumnsQuery } = IntrospectionApi
