import React from 'react'
import { Radiogroup } from 'Components/shared/radiogroup'
import { DialogField } from 'Components/shared/dialog'
import { PasswordAuthenticationType } from 'Components/configuration/destinations/PasswordAuthenticationType'
import { SSHAuthenticationType } from 'Containers/configuration/destinations/SSHAuthenticationType'

interface AuthenticationTypeProps {
  ipedId: string
  authType?: string
  basicAuthUserName: string
  basicAuthPassword: string
  sshUserName: string
  sshKeyId: string
  setAuthenticationType?: (value: string) => void
  setDestinationBasicAuthUserName: (value: string) => void
  setDestinationBasicAuthPassword: (value: string) => void
  setDestinationSshUserName: (value: string) => void
  setDestinationSshKeyId: (value: string) => void
  control: any
  setValue?: any
}

export const AuthenticationType = ({
  ipedId,
  authType,
  basicAuthUserName,
  basicAuthPassword,
  sshUserName,
  sshKeyId,
  setAuthenticationType,
  setDestinationBasicAuthUserName,
  setDestinationBasicAuthPassword,
  setDestinationSshUserName,
  setDestinationSshKeyId,
  control,
  setValue
}: AuthenticationTypeProps) => {
  return (
    <>
      <DialogField
        id='authentication-type'
        label='Authentication Type'
        alignSelf={'flex-start'}
      >
        <Radiogroup
          ariaLabel='Authentication Type'
          currentValue={authType}
          handleChange={setAuthenticationType}
          values={['Password', 'SSH Key']}
        />

        {authType === 'Password' && (
          <PasswordAuthenticationType
            userName={basicAuthUserName}
            password={basicAuthPassword}
            setDestinationBasicAuthUserName={setDestinationBasicAuthUserName}
            setDestinationBasicAuthPassword={setDestinationBasicAuthPassword}
            control={control}
          />
        )}

        {authType === 'SSH Key' && (
          <SSHAuthenticationType
            ipedId={ipedId}
            sshUserName={sshUserName}
            sshKeyId={sshKeyId}
            setDestinationSshUserName={setDestinationSshUserName}
            setDestinationSshKeyId={setDestinationSshKeyId}
            control={control}
            setValue={setValue}
          />
        )}
      </DialogField>
    </>
  )
}
