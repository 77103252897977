import { createSelector } from '@reduxjs/toolkit'
import type { RootState } from 'Utils/store'
import { selectDropdownSlice } from 'Slices/selectDropdownSlice'
import CurrentlyEnrolledConfig from 'Configs/fields/currently_enrolled.json'
import { ContractSource } from 'Types/contractTypes'

const contractSource = ContractSource.candidates

export const CurrentlyEnrolledSlice = selectDropdownSlice(
  CurrentlyEnrolledConfig.column,
  CurrentlyEnrolledConfig.values,
  'setCurrentlyEnrolled',
  CurrentlyEnrolledConfig.meta
)

export const selectCurrentlyEnrolled = createSelector(
  ({ onDemandFilter: { prospectProfile } }: RootState) =>
    prospectProfile.currentlyEnrolled.selectedValue,
  (
    selectedValue: string
  ): {
    selectedValue: string
    values: string[]
    audienceTypes: string[]
    label: string
  } => {
    return {
      selectedValue,
      values: CurrentlyEnrolledConfig.values,
      audienceTypes: CurrentlyEnrolledConfig.source[contractSource].audience_types,
      label: CurrentlyEnrolledConfig.meta.label,
    }
  }
)

export const CurrentlyEnrolledAudienceTypes = CurrentlyEnrolledConfig.source[contractSource].audience_types

export const { setCurrentlyEnrolled } = CurrentlyEnrolledSlice.actions

export default CurrentlyEnrolledSlice.reducer
