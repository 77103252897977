import React from 'react'
import { styled } from '@mui/material/styles'
import Snackbar from '@mui/material/Snackbar'

const StyledSnackbar = styled(Snackbar)(({ theme, error }) => ({
  '& .MuiSnackbarContent-root': { 
    backgroundColor: error ? theme.palette.error.main : theme.palette.primary.dark,
    minWidth: 0
  },
  '& .MuiSnackbarContent-message': {
    margin: 'auto',
    textAlign: 'center',
  }
}))

interface SnackbarProps {
  /** status message displayed in snackbar */
  message: string
  /** determines whether to display snackbar */
  open: boolean
  /** determines what type of snackbar to display */
  error: boolean
  /** function that closes snackbar  */
  onClose: () => void
}

export const SnackbarComponent = ({ message, open, error, onClose }: SnackbarProps) => {
  return (
    <StyledSnackbar 
      autoHideDuration={4000}
      open={open}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      message={message}
      onClose={onClose}
      error={error}
    />
  )
}