import React, { useCallback, useEffect, memo } from 'react'
import { useToggle } from 'Hooks/useToggle'
import { Accordion } from 'Components/shared/accordion'
import { SidebarFieldWrapper } from 'Components/shared/sidebar'
import { useDispatch, useSelector } from 'react-redux'

import { setOnDemandGender, selectGender } from './GenderSlice'
import {
  setOnDemandAgeRange,
  setOnDemandAgeRangeSelection,
  setAgeRangeDefaultValue,
  selectAge,
} from './AgeSlice'
import { setOnDemandGreenLightValue, selectGreenlight } from './GreenlightSlice'

import { Slider } from 'Components/shared/slider'
import { SidebarField } from 'Components/shared/sidebar'
import { FilterSingleSelectDropdown } from 'Components/shared/singleselectdropdown'

import { GeographyFilter } from 'Components/connectedComponents/segments'
import { Geography } from './Geography'
import { VirtualizedMultiSelect } from 'Components/shared/multiselectdropdown'
import { Contract } from 'Services/contracts/contractsUtils'

interface DemographicsProps {
  chosenItem: Partial<Contract>
  filterData?: any
  segmentId?: string
  control?: any
  resetKey?: number
  zip_id?: string
}

export const Demographics = memo(
  ({
    chosenItem,
    filterData,
    segmentId,
    control,
    resetKey,
    zip_id
  }: DemographicsProps) => {
    const dispatch = useDispatch()
    const [expand, setExpand] = useToggle(false)

    const gender = useSelector(selectGender)
    const age = useSelector(selectAge)
    const greenlight = useSelector(selectGreenlight)

    /** Set default age based on the audience type */
    useEffect(() => {
      if (!segmentId) {
        const audienceType = chosenItem?.audience_type
        setAgeRangeDefaultValue(dispatch, audienceType)
      }
    }, [chosenItem?.id, resetKey])

    /** This useEffect is for setting filters in edit segment */
    useEffect(() => {
      if (segmentId) {
        if (filterData?.[gender.label]) {
          dispatch(setOnDemandGender(filterData[gender.label]))
        }
        if (filterData?.[age.label]) {
          const selections = filterData[age.label]
          dispatch(setOnDemandAgeRangeSelection({selections}))
        }
        if (filterData?.[greenlight.label]) {
          const greenlightVal = filterData[greenlight.label]
          dispatch(setOnDemandGreenLightValue(greenlightVal))
        }
      }
    }, [filterData])

    const dispatchAgeRange = useCallback(({ min, max }) => {
      dispatch(setOnDemandAgeRange({ min, max }))
    }, [])

    const dispatchGender = useCallback((values: string[]) => {
      dispatch(setOnDemandGender(values))
    }, [])

    return (
      <Accordion
        setExpand={setExpand}
        expand={expand}
        title='DEMOGRAPHICS'
        disabled={chosenItem?.id ? false : true}
      >
        <SidebarFieldWrapper>
          <Geography filterData={filterData} control={control} zip_id={zip_id} />
          {age.audienceTypes.includes(chosenItem?.audience_type) && (
            <>
            <VirtualizedMultiSelect
              label={age.label}
              counterLabel="Selections"
              values={age.values}
              selectedValues={age.selections}
              initialSelectedValues={age.selections}
              setSelectedValues={(selections) => dispatch(setOnDemandAgeRangeSelection({selections}))}
              />
            {age.selections?.includes('Specific Age Range') && <SidebarField id='age' label={age.label}>
              <Slider
                ariaLabel={age.ariaLabel}
                min={age.minAge}
                max={age.maxAge}
                selectedMin={age.selectedMinAge}
                selectedMax={age.selectedMaxAge}
                step={age.step}
                handleChange={(range: number[]) =>
                  dispatchAgeRange({ min: range[0], max: range[1] })
                }
              />
            </SidebarField>}
            </>
          )}
          {gender.audienceTypes.includes(chosenItem?.audience_type) && (
            <VirtualizedMultiSelect
              label={gender.label}
              counterLabel={gender.label}
              values={gender.values}
              selectedValues={gender.selectedValues}
              initialSelectedValues={gender.selectedValues}
              setSelectedValues={dispatchGender}
            />
          )}
          {greenlight.audienceTypes.includes(chosenItem?.audience_type) && (
            <FilterSingleSelectDropdown
              label={greenlight.label}
              values={greenlight.values}
              value={greenlight.selectedValue}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                const chosenValue = e.target.value
                dispatch(setOnDemandGreenLightValue(chosenValue))
              }}
            />
          )}
        </SidebarFieldWrapper>
      </Accordion>
    )
  }
)
