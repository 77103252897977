import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from '../getBaseQuery'

export interface Permission {
  name: string
  description: string
}

interface GetPermissionsResponse {
  data: Permission[]
}

export const PermissionsAPI = createApi({
  reducerPath: 'permissions',
  baseQuery,
  endpoints: (build) => ({
    getPermissions: build.query<Permission[], void>({
      query: () => ({ url: 'users/me/permissions' }),
      transformResponse: (response: GetPermissionsResponse): Permission[] => {
        return response.data
      },
    }),
  }),
})

export const { useGetPermissionsQuery } = PermissionsAPI
