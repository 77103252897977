import {useEffect, useState} from 'react'
import { useParams } from 'react-router-dom'

import { Permissions } from 'Configs/userPermissions'
import { useHasPermission } from 'Hooks/useHasPermission'
import { useCreateInstitutionSettingsMutation, useGetInstitutionSettingsQuery, useUpdateInstitutionSettingsMutation} from 'Services/global/institution'

export const useInstitutionSettings = () => {
  //this hook is necessary because the server/db seeding doesn't automatically create settings for an institution
  //the gist of it is that if the settings don't exist when we use the get, we 'update' with the POST method instead of the PATCH method
  const [institution_gdpr_consent, setIsGDPR] = useState(false)
  const { ipedId } = useParams()
  const { hasPermission: canSwitchInstitution } = useHasPermission(
    Permissions.SwitchInstitution
  )
  const { 
    data: {institution_gdpr_consent: isCurrentlyRequiringGDPR} = {institution_gdpr_consent: false}, 
    isLoading, 
    isError: isSettingsError, 
    error: {status:settingsErrorStatus} = {status: 200}, 
    refetch 
  } = useGetInstitutionSettingsQuery({ipedId})
  const isSettingsMissing = isSettingsError && settingsErrorStatus === 404
  const [updateInstitutionSettings, {isLoading: isUpdateSettingsLoading}] = isSettingsMissing 
    ? useCreateInstitutionSettingsMutation() : useUpdateInstitutionSettingsMutation()
  const saveSettings = () => {
    if (canSwitchInstitution) {
      updateInstitutionSettings({ipedId, institution_gdpr_consent })
    }
  }
  useEffect(() => {
    //used for changing institutions (once the fetch for the settings returns) while already on institution settings page
    if (isSettingsMissing) {
      setIsGDPR(false)
    } else if (isCurrentlyRequiringGDPR !== institution_gdpr_consent) setIsGDPR(isCurrentlyRequiringGDPR)
  }, [isSettingsError, settingsErrorStatus, isCurrentlyRequiringGDPR])
  
  useEffect(() => {
    //need to reftech when institution changes
    refetch()
  }, [ipedId])
  
  return {
    saveSettings,
    setIsGDPR,
    institution_gdpr_consent,
    isLoading: isLoading || isUpdateSettingsLoading,
    //if there is no missing settings check current state to server property, if missing settings then has only changed if it's been made true
    isChanged: (!isSettingsMissing && !!isCurrentlyRequiringGDPR !== institution_gdpr_consent) || (isSettingsMissing && institution_gdpr_consent),
  }
}