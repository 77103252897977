import { combineReducers } from '@reduxjs/toolkit'

import { verificationReducer } from './timebased/verification'
import { prospectProfileReducer } from './timebased/prospectprofile'
import { demographicsReducer } from './timebased/demographics'

export const appReducer = combineReducers({
  verification: verificationReducer,
  prospectProfile: prospectProfileReducer,
  demographics: demographicsReducer,
})

export const timeBasedReducer = (state, action) => {
  if (action.type === 'timeBasedFilter/reset') {
    return appReducer(undefined, action)
  }
  return appReducer(state, action)
}
