import React from 'react'
import { Table } from 'Components/shared/table'

interface SegmentQueueProps {
  columnData: {
    /**  columnName is the name of the column */
    columnName: string
    /**  columnWidth is the width of the column */
    columnWidth?: number
    /** sortOrder can be 'ASC', 'DESC', or 'NONE', make this an ENUM */
    sortOrder?: string
  }[]
  /** rows is an array of arrays that contain the data for each row */
  rows: any[]
  /** rowIndexes is an array of booleans that determines if a row is selected */
  rowIdxs: boolean[]
   /** selectedAll determines if all rows are selected */
  selectAll: boolean
  /** handleAllClick is a function that is called when the select all checkbox is clicked */
  handleAllClick: any
  /** handleRowClick is a function that is called when a row is clicked */
  handleRowClick: any
  /** handleCheckboxClick is a function that is called when a row checkbox is clicked */
  handleCheckboxClick: any
  /** setSortOrder is a function that sets the sort order */
  setSortOrder: any
}

export const SegmentQueue = ({ 
  columnData, 
  rows, 
  rowIdxs, 
  selectAll, 
  handleAllClick, 
  handleRowClick, 
  handleCheckboxClick,
  setSortOrder,
}: SegmentQueueProps) => {
  return (
    <Table 
      columnData={columnData} 
      rows={rows} 
      isSelectable={true} 
      selectedAll={selectAll} 
      handleAllClick={handleAllClick} 
      handleRowClick={handleRowClick}
      rowIndexes={rowIdxs}
      setSortOrder={setSortOrder}
      handleCheckboxClick={handleCheckboxClick}
    />
  )
}