import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { SegmentInput } from 'Components/contracts/segment'
import { useGetAllCandidatesContractsQuery } from 'Services/contracts/candidatesContracts'
import { useGetSegmentsForContractQuery } from 'Services/segments/segments'
import {
  setContract,
  setNoOfLeads,
  setSegmentName,
  setPricePerLead,
  setPricePerLeadString,
  setSegmentsForContract,
  setSegmentsLeadsTotal,
  selectNewOnDemandSegment,
} from './NewOnDemandSegmentSlice'
import { CombineBy, FilterBy, properties } from 'Filter/properties'
import { buildFilterQuery } from 'Filter/buildFilterQuery'
import { Loading } from 'Utils/loading'
import { ContractSource, ContractStatus } from 'Types/index'

export const pendingInProgressContractsQuery = () => ({
  query: {
    filterQuery: properties(
      [
        {
          column: 'source',
          op: FilterBy.EQ,
          value: ContractSource.candidates,
        },
        {
          column: 'status',
          op: FilterBy.IN,
          value: [ContractStatus.pending, ContractStatus.in_progress],
        },
      ],
      CombineBy.AND
    ),
  },
})

interface AddOnDemandProps {
  control: any
  setFormError: any
  clearFormErrors: any
  showSidebar: boolean
}

export const AddOnDemand = ({
  control,
  setFormError,
  clearFormErrors,
  showSidebar,
}: AddOnDemandProps) => {
  const dispatch = useDispatch()
  const { ipedId } = useParams()
  const {
    contract,
    segmentsLeadsTotal,
    noOfLeads,
    segmentsRelatedToContract,
    segmentName,
    pricePerLead,
    pricePerLeadString,
  } = useSelector(selectNewOnDemandSegment)

  let segmentNames = []
  let segmentLeadsTotal = 0
  const remainingLeads = contract?.lead_cap - segmentsLeadsTotal

  const { data: segments, isLoading: isLoadingSegments } =
    useGetSegmentsForContractQuery(
      {
        ipedId,
        contractId: contract?.id,
      },
      { skip: contract?.id == undefined }
    )

  const { data: relatedContracts, isLoading: isLoadingContracts } =
    useGetAllCandidatesContractsQuery(
      {
        ipedId,
        filterQuery: buildFilterQuery(pendingInProgressContractsQuery()),
      },
      { skip: showSidebar === false }
    )

  const setSelectedContract = (contract: any) => {
    dispatch(setContract(contract))
  }

  const handleNoOfLeadsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value
    if (input !== '') {
      if (!isNaN(Number(input))) dispatch(setNoOfLeads(Number(input)))
    } else {
      dispatch(setNoOfLeads(''))
    }
  }

  const handlePricePerLead = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setPricePerLeadString(e.target.value))
    dispatch(setPricePerLead(parseFloat(e.target.value)))
  }

  const handleSegmentNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setSegmentName(e.target.value))
  }

  /** Check and add duplicate segment name error */
  useEffect(() => {
    if (segmentsRelatedToContract.includes(segmentName)) {
      setFormError(
        'root.segmentName',
        {
          type: 'manual',
          message: 'This name is already in use on the same Contract',
        },

        { shouldFocus: true }
      )
    } else {
      clearFormErrors('root.segmentName')
    }
  }, [segmentName])

  /** Calculate the related segments and the total leads for a contract */
  useEffect(() => {
    if (segments && contract) {
      segments?.forEach((segment: any) => {
        segmentNames.push(segment.name)
        segmentLeadsTotal += segment.is_active ? segment.lead_total : 0
      })
      clearFormErrors('root.relatedContracts')
      dispatch(setSegmentsForContract(segmentNames))
      dispatch(setSegmentsLeadsTotal(segmentLeadsTotal))
    }
  }, [segments, contract])

  if (isLoadingContracts) return <Loading />

  return (
    <SegmentInput
      setSelectedContract={setSelectedContract}
      handleNoOfLeadsChange={handleNoOfLeadsChange}
      handlePricePerLeadChange={handlePricePerLead}
      handleSegmentNameChange={handleSegmentNameChange}
      relatedContracts={relatedContracts}
      chosenContract={contract}
      segmentName={segmentName}
      noOfLeads={noOfLeads}
      pricePerLead={pricePerLead}
      pricePerLeadString={pricePerLeadString}
      remainingLeads={remainingLeads}
      control={control}
      source={ContractSource.candidates}
    />
  )
}
