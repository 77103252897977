import { useGetPermissionsQuery } from 'Services/global/permissions'

export const useHasPermission = (permission: string) => {
  const { data: supportedColumns, isFetching } = useGetPermissionsQuery()
  const myPermissions = supportedColumns?.map((obj) => obj.name)
  const foundPermission = supportedColumns?.filter(
    (obj) => obj.name === permission
  )
  const hasPermission = foundPermission?.length !== 0
  return { myPermissions, hasPermission, isFetching }
}
