import React, { useCallback, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { ManageUsers as ManageUsersComponent } from 'Components/configuration/manageusers'
import { AddUser } from 'Containers/configuration/manageusers/adduser'
import { DeleteUser } from 'Containers/configuration/manageusers/deleteuser'
import {
  Body,
  BodyTitle,
  BodyActions,
  BodyHeader,
  BodyContent,
  BodyModals,
} from 'Components/shared/body'
import { DefaultButton } from 'Components/shared/buttons'
import { Loading } from 'Utils/loading'
import { useGetUsersQuery } from 'Services/configuration/users'
import { Permissions } from 'Configs/userPermissions'
import { useHasPermission } from 'Hooks/useHasPermission'
import { useToggle } from 'Hooks/useToggle'

export const ManageUsers = () => {
  let columnData = [
    { columnName: 'Name', columnWidth: 100, sortOrder: 'ASC' },
    { columnName: 'Email', columnWidth: 100, sortOrder: 'ASC' },
    { columnName: 'Type', columnWidth: 100, sortOrder: 'ASC' },
    /* An empty column for the delete action */
    { columnName: '', columnWidth: 10 },
  ]

  const [addUserDialog, setAddUserDialog] = useToggle(false)
  const { ipedId } = useParams()
  const { data: usersInfo, isLoading } = useGetUsersQuery(ipedId)
  const { hasPermission: canDeleteUser } = useHasPermission(
    Permissions.DeleteUser
  )

  const { hasPermission: canCreateUser } = useHasPermission(Permissions.AddUser)
  const addUser = useCallback((e: any) => {
    e.preventDefault()
    e.stopPropagation()
    setAddUserDialog()
  }, [])

  const flattenUsers = useMemo(() => {
    let rows = []
    if (usersInfo) {
      usersInfo.forEach((user: any) => {
        const fullName = user.first_name + ' ' + user.last_name
        rows.push([
          user.id,
          fullName,
          user.email,
          user.roles,
          canDeleteUser ? (
            <DeleteUser userId={user.id} userName={fullName} />
          ) : (
            ''
          ),
        ])
      })
    }
    return rows
  }, [usersInfo])

  if (isLoading) return <Loading />

  return (
    <Body>
      <BodyHeader>
        <BodyTitle>Manage Users</BodyTitle>
        {canCreateUser && (
          <BodyActions>
            <DefaultButton onClick={addUser}>Add User</DefaultButton>
          </BodyActions>
        )}
      </BodyHeader>
      <BodyContent>
        {usersInfo?.length > 0 && (
          <ManageUsersComponent columnData={columnData} rows={flattenUsers} />
        )}
      </BodyContent>
      <BodyModals>
        {addUserDialog && (
          <AddUser
            addUserDialog={addUserDialog}
            setAddUserDialog={setAddUserDialog}
          />
        )}
      </BodyModals>
    </Body>
  )
}
