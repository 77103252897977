import React from 'react'
import { useLoginStatusQuery } from 'Services/global/authentication'
import { Permissions } from 'Configs/userPermissions'
import { useHasPermission } from 'Hooks/useHasPermission'
import { Navigate } from 'react-router-dom'
import { Loading } from 'Utils/loading'

interface Props {
  children: React.ReactNode
  permission?: Permissions
}

export const CheckPermissions = ({ children, permission }: Props) => {
  const { hasPermission, isFetching } = useHasPermission(permission)
  const { data: loginInfo, isFetching: isLoggingIn } = useLoginStatusQuery()

  if (isLoggingIn || isFetching) return <Loading />

  /** If the user doesn't have permission, redirect to default page */
  return hasPermission ? (
    <>{children}</>
  ) : (
    <Navigate to={`/institution/${loginInfo?.ipedId}/inquiries`} />
  )
}
