import React, { useMemo } from 'react'
import { useGetSSHKeyPairsQuery } from 'Services/configuration/sshkeys'
import { SSHAuthenticationType as SSHAuthenticationTypeComponent } from 'Components/configuration/destinations/SSHAuthenticationType'
import { Loading } from 'Utils/loading'

interface SSHKeyProps {
  ipedId: string
  sshUserName: string
  sshKeyId: string
  setDestinationSshUserName: (value: string) => void
  setDestinationSshKeyId: (value: string) => void
  control: any
  setValue?: any
}

export const SSHAuthenticationType = ({ 
  ipedId,
  sshUserName,
  sshKeyId,
  setDestinationSshUserName,
  setDestinationSshKeyId,
  control,
  setValue
 }: SSHKeyProps) => {
  const { data: sshkeyData, isLoading } = useGetSSHKeyPairsQuery(ipedId)

  const flattenKeys = useMemo(() => {
    let sshKeyList = {}
    let currentSshName = ''
    
    if (sshkeyData) {
      sshkeyData.data?.forEach((keyPair: any) => {
        sshKeyList[keyPair.key_pair_name] = keyPair.id
        if(keyPair.id === sshKeyId) {
          currentSshName = keyPair.key_pair_name
          setValue('sshPair', currentSshName)
        }
      })
    }
  return { sshKeyList, currentSshName }
  },[sshkeyData])

  if (isLoading) return <Loading />

  return (
    <SSHAuthenticationTypeComponent 
      sshUserName={sshUserName}
      sshkeys={flattenKeys.sshKeyList} 
      sshKeyPairName={flattenKeys.currentSshName}
      setDestinationSshUserName={setDestinationSshUserName}
      setDestinationSshKeyId={setDestinationSshKeyId}
      control={control}
    />
  )
}
