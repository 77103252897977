export * from './ContractConfigurationSlice'
export * from './ContractDeduplicatesSlice'
export * from './ContractMethods'
export * from './ContractStateSlice'
export * from './ContractCustomActions'

export { default as ContractConfigurationReducer } from './ContractConfigurationSlice'
export { default as ContractDeduplicatesReducer } from './ContractDeduplicatesSlice'
export { default as ContractStateReducer } from './ContractStateSlice'

import { allDeliveryModelValues } from './ContractStateSlice'

export const allDeliveryModelKeys = ['lead_cap_reached', 'lead_cap_reached_weekly', 'lead_cap_reached_monthly']

export const deliveryModelKeyToValue = (key: string) => {
    return allDeliveryModelValues[allDeliveryModelKeys.indexOf(key)]
  }
  
  export const deliveryModelValueToKey = (value: string) => {
    return allDeliveryModelKeys[allDeliveryModelValues.indexOf(value)]
  }
