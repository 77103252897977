import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { FilterHeader } from 'Components/shared/segmentfilters'
import { Demographics } from 'Containers/shared/segmentfilters/timebased/demographics'
import { Verification } from 'Containers/shared/segmentfilters/timebased/verification'
import { ProspectProfile } from 'Containers/shared/segmentfilters/timebased/prospectprofile'
import { buildFilterQuery } from 'Filter/buildFilterQuery'
import { Loading } from 'Utils/loading'
import { setupDefaultValues } from 'Utils/segmentFilterDefaultValues'
import { useSegmentService, useSegmentData } from 'Hooks/segment'
import { useToggle } from 'Hooks/useToggle'
import { ContractSource, WebSocketMethods } from 'Types/index'
import { SidebarActionButtons } from 'Components/shared/segmentfilters/SidebarActionButtons'
import { messageSent } from 'Slices/websocketSlice'
import type { RootState } from 'Utils/store'
import { useSocket } from 'Hooks/useSocket'
import { excludeKeys } from 'Containers/shared/segmentfilters/segmentUtils'
import { DuplicateTimeBased } from 'Containers/inquiries/segment/DuplicateTimeBased'
import { mapDetailsToFilter } from 'Containers/shared/segmentfilters/segmentFilterMapping'
import { SegmentAction } from 'Types/index'
import { selectGeography } from 'Containers/shared/segmentfilters/timebased/demographics/GeographySlice'

interface TimebasedDuplicateFilterProps {
  /** Boolean to show/hide sidebar */
  showSidebar: boolean
  /** Logic to handle closing the sidebar */
  handleCloseSidebar: () => void
  /** Segment action */
  segmentAction?: SegmentAction
  /** Current contract */
  inquiryContract?: any
  /** Segment data */
  segmentData?: any
  /** Segment id */
  segmentId?: string
  /** Boolean to check if segment is loading */
  isSegmentFetching: boolean
}

export const TimebasedDuplicateFilter = ({
  showSidebar,
  handleCloseSidebar,
  segmentAction,
  inquiryContract,
  segmentData,
  segmentId,
  isSegmentFetching
}: TimebasedDuplicateFilterProps) => {
  const dispatch = useDispatch()
  const { ipedId } = useParams()
  const [resetKey, setResetKey] = useState(Date.now())
  const [filterData, setFilterData] = useState(null)
  const [fetchStudentsOnLoad, setFetchStudentsOnLoad] = useToggle(true)
  const { waitingForResponse: isFetchingStudentsAvailable, availableStudents } = useSelector(
    (state: RootState) => state.websocketStatus
  )
  const geography = useSelector(selectGeography)
  const { sendMessage } = useSocket()
  const { createSegment, isCreatingSegment } = useSegmentService()

  const {
    timeBasedFilter,
    timeBasedContract: contract,
    timeBasedNoOfLeads: noOfLeads,
    timeBasedSegmentName: segmentName,
  } = useSegmentData()

  const {
    control,
    formState: { errors: formErrors },
    setError: setFormError,
    clearErrors: clearFormErrors,
  } = useForm({
    mode: 'onChange',
  })

  const getAvailableStudents = () => {
    const message = {
      method: WebSocketMethods.students_available_count_contract_id,
      data: {
        contract_id: contract?.id,
        institution_id: ipedId,
        contract_source: ContractSource.inquiries,
        audience_type: contract?.audience_type,
        filter: buildFilterQuery(
          timeBasedFilter,
          excludeKeys(ContractSource.inquiries, contract?.audience_type),
          false
        ),
      },
      override_sources: ['inquiries']
    }

    if (geography.zipFile.id) {
      message.data['zip_id'] = geography.zipFile.id
      message.data['zip_filter_exclusive'] = false
    }
    sendMessage(JSON.stringify(message))
    dispatch(messageSent())
  }

  const resetFilters = () => {
    dispatch({
      type: 'timeBasedFilter/reset',
    })
    setResetKey(Date.now())
  }

  const duplicateSegment = () => {
    const filterQuery = buildFilterQuery(
      timeBasedFilter,
      excludeKeys(ContractSource.inquiries, contract?.audience_type),
      false
    )
    const segmentObj = {
      name: segmentName,
      ipedId,
      contract_id: contract.id,
      lead_total: Number(noOfLeads),
      filter: filterQuery,
    }
    if (geography.zipFile.id) {
      segmentObj['zip_id'] = geography.zipFile.id
      segmentObj['zip_filter_exclusive'] = false
    }
    createSegment(segmentObj)
      .unwrap()
      .then(() => {
        handleCloseSidebar()
      })
  }

  /** Disable form submission if any reqired field is not filled
   * (or) there are any form errors */
  const disableFormSubmit = Object.keys(formErrors).length > 0

  useEffect(() => {
    if (segmentData && contract) {
      const result = setupDefaultValues(
        ContractSource.inquiries,
        contract?.audience_type,
        false
      )

      /** Map the segment's filter object  */
      let data = mapDetailsToFilter(segmentData?.filter, result) as any
      data = Object.fromEntries(
        Object.entries(data).filter(
          ([key, value]) => Object.keys(value).length > 0
        )
      )

      filterData !== data && setFilterData(data)
    }
  }, [segmentData, contract])

  useEffect(() => {
    /** Fetch students automatically ONLY on load. Otherwise, user has to explicity click refresh */
    if (
      timeBasedFilter &&
      segmentData &&
      inquiryContract &&
      fetchStudentsOnLoad &&
      !isSegmentFetching
    ) {
      setFetchStudentsOnLoad(false)
      getAvailableStudents()
    }
  }, [timeBasedFilter, isSegmentFetching])

  const isLoading = isCreatingSegment || isFetchingStudentsAvailable

  const studentsCount = availableStudents?.hasOwnProperty('total_count') ? availableStudents.total_count : availableStudents

  return (
    <>
      {isLoading && <Loading />}
      <div
        style={{
          width: '396px',
        }}
      >
        <DuplicateTimeBased
          control={control}
          setFormError={setFormError}
          clearFormErrors={clearFormErrors}
          showSidebar={showSidebar}
          inquiryContract={inquiryContract}
          segmentData={segmentData}
        />

        <FilterHeader
          availableStudents={studentsCount || 0}
          isFetchingStudentsAvailable={isFetchingStudentsAvailable}
          resetFilters={resetFilters}
          disableHeader={contract?.id ? false : true}
          getAvailableStudents={getAvailableStudents}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
            marginBottom: '100px',
          }}
        >
        <Demographics
          chosenItem={contract}
          filterData={filterData}
          segmentId={segmentId}
          control={control}
          resetKey={resetKey}
        />
          <ProspectProfile
            chosenItem={contract}
            filterData={filterData}
            segmentId={segmentId}
            resetKey={resetKey}
          />
          <Verification filterData={filterData} chosenItem={contract} />
        </div>
        <SidebarActionButtons
          handleSegmentAction={duplicateSegment}
          handleCloseSidebar={handleCloseSidebar}
          disableFormSubmit={disableFormSubmit || isLoading}
          segmentAction={segmentAction}
        />
      </div>
    </>
  )
}
