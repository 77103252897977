import { createSelector } from '@reduxjs/toolkit'
import type { RootState } from 'Utils/store'
import { selectDropdownSlice } from 'Slices/selectDropdownSlice'
import NameVerifiedConfig from 'Configs/fields/name_verified.json'

export const NameCleanlinessSlice = selectDropdownSlice(
  NameVerifiedConfig.column,
  NameVerifiedConfig.values,
  'setTimeBasedNameCleanliness',
  NameVerifiedConfig.meta
)

export const selectNameCleanliness = createSelector(
  ({ timeBasedFilter: { verification } }: RootState) =>
    verification['nameCleanliness'].selectedValue,
  (selectedValue: string): { selectedValue: string; values: string[]; label } => {
    return { selectedValue, values: NameVerifiedConfig.values, label: NameVerifiedConfig.meta.label }
  }
)

export const { setTimeBasedNameCleanliness } = NameCleanlinessSlice.actions

export default NameCleanlinessSlice.reducer
