import React from 'react'
import { styled } from '@mui/material/styles'

const UserDataWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  [theme.breakpoints.up('md')]: {
    display: 'block',
    width: '100%',
  },
}))

const UserData = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  marginBottom: '52px',
  width: '100%',
  [theme.breakpoints.up('md')]: {
    marginBottom: '88px',
    alignItems: 'normal',
  },
}))

interface DialogFieldContainerProps {
  children: React.ReactNode
}

export const DialogFieldContainer = ({
  /* Dialog fields container */
  children,
}: DialogFieldContainerProps) => {
  return (
    <UserDataWrapper>
      <UserData>{children}</UserData>
    </UserDataWrapper>
  )
}
