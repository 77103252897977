import React from 'react'
import { CreateDeleteDialog } from 'Components/shared/createdeletedialog'

interface DeleteDestinationProps {
  /** Name of destination to be deleted */
  destinationName: string
  /** Function to handle delete destination action */
  handleDeleteDestination: (e) => void
  /** Boolean that determines whether to display delete destination dialog */
  deleteDestinationDialog: boolean
  /** Function to toggle showing delete destination dialog */
  setDeleteDestinationDialog: () => void
}

export const DeleteDestination = ({
  destinationName,
  handleDeleteDestination,
  setDeleteDestinationDialog,
  deleteDestinationDialog,
}: DeleteDestinationProps) => {
  return (
    <CreateDeleteDialog
      title='Delete Destination'
      bodyText={`Are you sure you want to permanently delete ${destinationName}?`}
      actionText={'Delete'}
      handleAction={(e: any) => handleDeleteDestination(e)}
      toggleDialog={setDeleteDestinationDialog}
      isDialogOpen={deleteDestinationDialog}
    />
  )
}
