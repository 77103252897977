import { createSelector } from '@reduxjs/toolkit'
import type { RootState } from 'Utils/store'
import { selectDropdownSlice } from 'Slices/selectDropdownSlice'
import AddressVerifiedConfig from 'Configs/fields/address_verified.json'

export const AddressVerifiedSlice = selectDropdownSlice(
  AddressVerifiedConfig.column,
  AddressVerifiedConfig.values,
  'setTimeBasedAddressVerified',
  AddressVerifiedConfig.meta
)

export const selectAddressVerified = createSelector(
  ({ timeBasedFilter: { verification } }: RootState) =>
    verification['addressVerified'].selectedValue,
  (selectedValue: string): { selectedValue: string; values: string[]; label } => {
    return { selectedValue, values: AddressVerifiedConfig.values, label: AddressVerifiedConfig.meta.label }
  }
)

export const { setTimeBasedAddressVerified } = AddressVerifiedSlice.actions

export default AddressVerifiedSlice.reducer
