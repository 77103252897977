export enum SegmentAction {
  new = 'New Segment',
  explore = 'Explore Segments',
  edit = 'Edit Segment',
  duplicate = 'Duplicate Segment',
}

export const SegmentActionLabel: { [key in SegmentAction] } = {
  [SegmentAction['new']]: 'Activate Segment',
  [SegmentAction['explore']]: 'Explore Segments',
  [SegmentAction['edit']]: 'Update Segment',
  [SegmentAction['duplicate']]: 'Duplicate Segment',
}
