import { combineReducers } from '@reduxjs/toolkit'

import AudienceTypeReducer from './InquiriesAudienceTypeSlice'
import TimePeriodReducer from './InquiriesTimePeriodSlice'
import InquiriesContractReducer from './InquiriesContractSlice'

const appReducer = combineReducers({
  audienceType: AudienceTypeReducer,
  timePeriod: TimePeriodReducer,
  inquiriesContract: InquiriesContractReducer
})

export const inquiriesFilterReducer = (state, action) => {
  if (action.type === 'inquiriesFilesFilter/reset') {
    return appReducer(undefined, action)
  }
  return appReducer(state, action)
}
