import React from 'react'
import { styled } from '@mui/material/styles'

const NoData = styled('div')(({ theme }) => ({
  marginTop: '68px',
  width: '100%',
  minHeight: '68px',
  background: theme.palette.common.white,
  borderRadius: '8px',
}))

interface BodyContentProps {
  /**  Data that needs to be displayed in the body */
  children?: React.ReactNode
}

export const BodyContent = ({ children }: BodyContentProps) => {
  return children ? (
    <React.Fragment>{children}</React.Fragment>
  ) : (
    <NoData aria-label='no-data' />
  )
}
