import React from 'react'
import { styled } from '@mui/material/styles'
import Popover from '@mui/material/Popover'

interface PopoverProps {
  /** anchorEl is the HTML element that each popover anchors to */
  anchorEl: HTMLElement
  /** anchorOrigin determines how popover is positioned 
  with respect to anchor element */
  anchorOrigin: {}
  /** popoverText is the text of the popover */
  popoverText: string
  /** Determines whether to display popover */
  open: boolean
}

const StyledPopover = styled(Popover)(({ theme }) => ({
  '& .MuiPopover-paper': {
    backgroundColor: theme.palette.secondary['100'],
    color: theme.palette.common.white,
    padding: '11px 16px',
    whiteSpace: 'pre-line',
  },
}))

export const PopoverComponent = ({
  anchorEl,
  anchorOrigin,
  popoverText,
  open,
}: PopoverProps) => {
  return (
    <StyledPopover
      anchorEl={anchorEl}
      open={open}
      anchorOrigin={anchorOrigin}
      style={{ pointerEvents: 'none' }}
      disableRestoreFocus
    >
      {popoverText}
    </StyledPopover>
  )
}
