export enum FilterBy {
  LIKE = 'like',
  LIKE_IN = 'like_in',
  LIKE_NOT_IN = 'like_nin',
  GT = 'gt',
  GE = 'ge',
  LT = 'lt',
  LE = 'le',
  EQ = 'eq',
  NE = 'ne',
  IN = 'in',
  N_IN = 'nin',
  RANGE = 'range',
  FUZZY = 'fuzzy',
  NULL = 'is_null'
}

export enum CombineBy {
  AND = 'AND',
  OR = 'OR',
}

export interface Property {
  column: string
  op: FilterBy
  value: string | number | boolean | string[] | number[] | boolean[]
}

export const properties = (
  properties: any[] | any,
  operation: CombineBy = CombineBy.OR
) => {
  return {
    properties,
    operation,
  }
}
