import React from 'react'
import { styled } from '@mui/material/styles'
import { Table } from 'Components/shared/table'

const FlexContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: '230px'
}))

const MessageContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '516px'
}))

const Message = styled('div')(({ theme }) => ({
  color: theme.palette.primary.dark,
  fontSize: '0.75rem',
  fontWeight: theme.typography.fontWeightMedium,
  lineHeight: '15px',
  paddingLeft: '25px'
}))

const ErrorMessage = styled(Message)(({ theme }) => ({
  color: theme.palette.error.main
}))

interface DeduplicatesProps {
  columnData: {
    /**  columnName is the name of the column */
    columnName: string
    /**  columnWidth is the width of the column */
    columnWidth?: number
    /** sortOrder can be 'ASC', 'DESC', or 'NONE', make this an ENUM */
    sortOrder?: string
  }[]
   /** rows is an array of arrays that contain the data for each row */
   rows: any[]
   /** boolean that indicates if file is over max size */
   invalidSize: boolean
}

export const Deduplicates = ({ columnData, rows, invalidSize }: DeduplicatesProps) => {
  return (
    <FlexContainer>
      <Table 
        width={'516px'}
        height={'108px'}
        columnData={columnData}
        rows={rows}
        variant='striped'
      />
      <MessageContainer>
        <Message>Maximum file size 50 MB</Message>
        {invalidSize && <ErrorMessage>Your file exceeds 50 MB</ErrorMessage>}
      </MessageContainer>
    </FlexContainer>
  )
}