import React from 'react'
import { styled } from '@mui/material/styles'

const Row = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '32px',
}))

const CustomLabel = styled('label')(({ theme, alignSelf }) => ({
  flex: '1',
  color: theme.palette.primary.dark,
  fontSize: '0.875rem',
  fontWeight: theme.typography.fontWeightMedium,
  lineHeight: '21px',
  alignSelf: alignSelf,
  textAlign: 'right',
}))

const ElementWrapper = styled('div')(() => ({
  display: 'flex',
  gap: '12px',
  flex: '3',
}))

interface DialogFieldProps {
  /* The id of the input element */
  id: string
  /* The label of the input element */
  label: string
  /* The HTML element that is related to the label */
  children: React.ReactNode
  /* The alignment of the label */
  alignSelf?: 'flex-start' | 'flex-end' | 'auto'
}

export const DatePickerField = ({
  id,
  label,
  children,
  alignSelf = 'auto',
}: DialogFieldProps) => {
  return (
    <Row>
      <CustomLabel htmlFor={id} alignSelf={alignSelf}>
        {label}
      </CustomLabel>
      <ElementWrapper>{children}</ElementWrapper>
    </Row>
  )
}
