import React from 'react'
import { styled } from '@mui/material/styles'

const FlexFieldContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  marginTop: '24px',
}))

const CustomLabel = styled('label')(({ theme, primary }) => ({
  color: primary ? theme.palette.primary.dark : theme.palette.secondary.main,
  fontSize: '0.75rem',
  fontWeight: theme.typography.fontWeightMedium,
  lineHeight: '16px',
  textAlign: 'left',
}))

const ElementWrapper = styled('div')(() => ({
  '& .MuiTextField-root': {
    width: '100%',
  },
}))

interface SidebarFieldProps {
  /* The id of the input element */
  id: string
  /* The label of the input element */
  label?: string
  /** primary determines which color from theme palette to use */
  primary?: boolean
  /* The HTML element that is related to the label */
  children: React.ReactNode
}

export const SidebarField = ({
  id,
  label,
  primary,
  children,
}: SidebarFieldProps) => {
  return (
    <FlexFieldContainer>
      <CustomLabel htmlFor={id} primary={primary}>
        {label}
      </CustomLabel>
      <ElementWrapper>{children}</ElementWrapper>
    </FlexFieldContainer>
  )
}
