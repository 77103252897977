import React from 'react'
import { styled } from '@mui/material/styles'
import { Input } from 'Components/shared/input'
import { SingleSelectDropdown } from 'Components/shared/singleselectdropdown'

const SSHAuthContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  width: '279px',
  marginTop: '30px',
  marginLeft: ' 10px',
}))

interface SSHAuthenticationTypeProps {
  sshUserName: string
  sshkeys: any
  sshKeyPairName?: string
  setDestinationSshUserName: (value: string) => void
  setDestinationSshKeyId: (value: string) => void
  control: any
}

export const SSHAuthenticationType = ({
  sshUserName,
  sshkeys,
  sshKeyPairName,
  setDestinationSshUserName,
  setDestinationSshKeyId,
  control,
}: SSHAuthenticationTypeProps) => {
  return (
    <SSHAuthContainer>
      <Input
        id='sshUserName'
        name='sshUserName'
        control={control}
        rules={{ required: true }}
        placeholder='SSH User Name'
        style={{ width: '253px' }}
        value={sshUserName}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setDestinationSshUserName(e.target.value)
        }}
      />
      <SingleSelectDropdown
        id='sshKeyPair'
        name='sshPair'
        data-testid='sshKeyPair'
        control={control}
        rules={{ required: true }}
        style={{ width: '253px' }}
        values={Object.keys(sshkeys)}
        value={sshKeyPairName}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
          setDestinationSshKeyId(sshkeys[e.target.value])
        }}
      />
    </SSHAuthContainer>
  )
}
