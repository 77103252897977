import React, { memo } from 'react'
import {
  Dialog,
  DialogActionButtons,
  DialogField,
  DialogFieldContainer,
} from 'Components/shared/dialog'

import { styled, useTheme } from '@mui/material/styles'
import { alpha } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import Button from '@mui/material/Button'
import { Input } from 'Components/shared/input'
import TextField from '@mui/material/TextField'
import { SingleSelectDropdown } from 'Components/shared/singleselectdropdown'
import { VirtualizedSingleSelect } from 'Components/shared/singleselectdropdown'
import { InstitutionResponse } from 'Services/global/institution'
import { Contract } from 'Services/contracts/contractsUtils'
import { Segment } from 'Services/segments/segmentUtils'

const StyledButton = styled(Button)(({ theme }) => ({
  border: `1.8px solid ${theme.palette.primary.main}`,
  borderRadius: '4px',
  fontSize: '0.875rem',
  fontWeight: theme.typography.fontWeightMedium,
  height: '40px',
  lineHeight: '18px',
  minWidth: 'calc(100% - 40px)',
  [theme.breakpoints.up('md')]: {
    minWidth: 'auto',
  },
}))

const CancelButton = styled(StyledButton)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  color: theme.palette.primary.main,
  lineHeight: '20px',
  '&:hover': {
    backgroundColor: `${alpha(theme.palette.primary.light, 0.4)}`,
  },
  [theme.breakpoints.up('md')]: {
    width: '110px',
  },
}))

const AddButton = styled(StyledButton)(({ theme }) => ({
  color: theme.palette.common.white,
  backgroundColor: theme.palette.primary.main,
  border: 'none',
  boxShadow: `0px 4px 20px ${alpha(theme.palette.common.white, 0.4)}`,
  '&:hover': {
    backgroundColor: `${alpha(theme.palette.primary.main, 0.85)}`,
  },
  '&.Mui-disabled': {
    color: theme.palette.common.white,
    backgroundColor: 'hsla(216, 79%, 72%, 1)',
  },
  [theme.breakpoints.up('md')]: {
    width: '155px',
  },
}))

const StyledInput = styled(TextField)(({ theme }) => ({
  '& .MuiAutocomplete-input': {
    paddingTop: '5.5px',
  },
  '& .MuiOutlinedInput-root': {
    position: 'relative',
    background: 'transparent',
    boxSizing: 'border-box',
    borderRadius: '4px',
    fontSize: '0.875rem',
    width: '396px',
    minHeight: '34px',
    padding: '5px 0 0 8px',
  },
}))

const liStyle = {
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: '12px',
  lineHeight: '15px',
  background: 'none',
  height: 'auto',
  width: 'auto',
  paddingLeft: '10px',
  whiteSpace: 'nowrap',
  display: 'inline-block',
}

interface AddSegmentProps {
  /** To show the add segment dialog or not */
  addSegmentDialog: boolean
  /** To set the Institution */
  setChosenInstitution: (value: string) => void
  /** To set the Contract */
  setChosenContract: (value: Contract) => void
  /** To set the Segment */
  setChosenSegment: (value: Segment) => void
  /** To set the Segment Lead Total */
  setSegmentLeadTotal: (value: number) => void
  /** To submit the add segment values */
  addSegmentValues: () => void
  /** Clean up actions when closing the dialog */
  closeDialog: () => void
  /** Boolean that determines whether to disable submit  */
  disableSubmit: boolean
  /** Object that registers component into React Hook Form for validation*/
  control?: any
  /** Array of institutions */
  institutionsInfo?: InstitutionResponse[]
  /** institution Object */
  institution: InstitutionResponse
  /** Array of contracts */
  relatedContracts: Contract[]
  /** Contract Object */
  contract: Contract
  /** Array of segments */
  segments: Segment[]
  /** Segment Object */
  segment: Segment
}

export const AddSegment = memo(
  ({
    addSegmentDialog,
    setChosenInstitution,
    setChosenContract,
    setChosenSegment,
    addSegmentValues,
    setSegmentLeadTotal,
    closeDialog,
    disableSubmit,
    control,
    institutionsInfo,
    institution,
    relatedContracts,
    contract,
    segments,
    segment,
  }: AddSegmentProps) => {
    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.up('md'))
    return (
      <Dialog onClose={closeDialog} open={addSegmentDialog} title='Add Segment'>
        <DialogFieldContainer>
          <DialogField id='add-partner' label='Partner'>
            <VirtualizedSingleSelect
              label={''}
              values={institutionsInfo?.map(
                (univ: InstitutionResponse) => `[ID ${univ.id}]  ${univ.name}`
              )}
              selectedValue={institution?.name}
              setSelectedValue={(value: string) => {
                setChosenInstitution(value)
              }}
              StyledInput={StyledInput}
              liStyle={liStyle}
            />
          </DialogField>

          <DialogField id='add-contract' label='Contract Related'>
            {relatedContracts?.length > 0 ? (
              <SingleSelectDropdown
                id='add-contract-related'
                name='contractsRelated'
                control={control}
                rules={{
                  required: true,
                }}
                value={contract?.label || ''}
                style={matches ? { width: '396px' } : {}}
                values={relatedContracts?.map(
                  (contract: Contract) => contract.label
                )}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                  const chosenContract = relatedContracts?.find(
                    (contract: Contract) => contract.label === e.target.value
                  )
                  if (chosenContract) {
                    setChosenContract(chosenContract)
                  }
                }}
              />
            ) : (
              <Input
                style={matches ? { width: '396px' } : {}}
                value={'No Contract Available'}
                disabled={true}
              />
            )}
          </DialogField>

          <DialogField id='add-segment' label='Segment Name'>
            {segments?.length > 0 ? (
              <SingleSelectDropdown
                id='add-segment-related'
                name='segmentRelated'
                control={control}
                rules={{
                  required: true,
                }}
                value={segment?.name || ''}
                style={matches ? { width: '396px' } : {}}
                values={segments?.map((segment: Segment) => segment.name)}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                  const chosenSegment = segments?.find(
                    (segment: Segment) => segment.name === e.target.value
                  )
                  if (chosenSegment) {
                    setChosenSegment(chosenSegment)
                  }
                }}
              />
            ) : (
              <Input
                style={matches ? { width: '396px' } : {}}
                value={'No Segments Available'}
                disabled={true}
              />
            )}
          </DialogField>
          <DialogField id='add-leads-total' label='# of Segment Leads Request'>
            <Input
              id='add-leads-total'
              name='leadsTotal'
              control={control}
              placeholder='0'
              style={matches ? { width: '160px' } : {}}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setSegmentLeadTotal(Number(e.target.value))
              }
              rules={{
                required: true,
                pattern: {
                  value: /^[0-9]+$/,
                  message: 'Must contain only numbers',
                },
              }}
            />
          </DialogField>
        </DialogFieldContainer>

        <DialogActionButtons>
          <CancelButton onClick={closeDialog}>Cancel</CancelButton>
          <AddButton disabled={disableSubmit} onClick={addSegmentValues}>
            Add Segment
          </AddButton>
        </DialogActionButtons>
      </Dialog>
    )
  }
)
