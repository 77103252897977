import React from 'react'
import { styled } from '@mui/material/styles'
import { alpha } from '@mui/material'
import Button from '@mui/material/Button'
import { Input, ErrorMessage } from 'Components/shared/input'
import { useState, useCallback } from 'react'
import { isValidEmail } from 'Utils/validation'
import EabLogo from 'Assets/images/eab_logo.svg'

const Container = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  minHeight: '670px',
}))

const PasswordReset = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  borderRadius: '8px',
  boxShadow: `0px 4px 8px ${alpha(theme.palette.secondary.main, 0.1)}`,
  padding: '25px 67px 36px 67px',
  width: 'min(501px, 80%)',
}))

const Heading = styled('div')(({ theme }) => ({
  color: theme.palette.secondary['100'],
  fontSize: '1.5rem',
  fontWeight: theme.typography.fontWeightBold,
  lineHeight: '30px',
}))

const SubHeading = styled('div')(({ theme }) => ({
  color: theme.palette.primary.dark,
  fontSize: '0.875rem',
  lineHeight: '18px',
  margin: '13px 0px 13px 0px',
}))

const InputFlexContainer = styled('div')(({ hidden }) => ({
  display: hidden ? 'none' : 'flex',
  flexDirection: 'column',
  gap: '16px',
  width: '100%',
  marginBottom: '33px',
}))

const ForgotButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  width: '100%',
  height: '48px',
  '&:hover': {
    backgroundColor: `${alpha(theme.palette.primary.main, 0.9)}`,
  },
}))

const ForgotBtnText = styled('span')(({ theme }) => ({
  color: theme.palette.common.white,
  fontSize: '0.875rem',
  height: '18px',
  lineheight: '18px',
  textalign: 'center',
}))

const LogoContainer = styled('span')(({ theme }) => ({
  color: theme.palette.secondary['100'],
  fontSize: '1.5rem',
  lineHeight: '24px',
  marginBottom: '20px',
}))

interface ForgotPasswordProps {
  onForgotPasswordClick?: (email: string) => void
  onBackToLoginClick?(): void
}

export const ForgotPassword = ({
  onForgotPasswordClick,
  onBackToLoginClick,
}: ForgotPasswordProps) => {
  const [email, setEmail] = useState('')
  const [validationError, setValidationError] = useState(undefined)
  const [formSubmitted, setFormSubmitted] = useState(false)

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value)
  }

  const handleForgotPasswordClick = useCallback(() => {
    if (!isValidEmail(email)) {
      setValidationError('Invalid email')
    } else {
      setValidationError(undefined)
      onForgotPasswordClick(email)
      setFormSubmitted(true)
    }
  }, [email])

  const handleBackToLoginClick = useCallback(() => {
    onBackToLoginClick()
  }, [])

  const border = validationError ? '1px solid #e34c4c' : undefined
  const subHeading = formSubmitted
    ? 'Thank you. If your email is in our system, you will receive an email with instructions'
    : 'Please enter your email address and we will email you more instructions'
  const buttonTxt = formSubmitted ? 'Back to login' : 'Reset Password'
  const forgotButtonCB = formSubmitted
    ? handleBackToLoginClick
    : handleForgotPasswordClick

  return (
    <Container>
      <PasswordReset>
        <LogoContainer>
          <EabLogo />
        </LogoContainer>
        <Heading>Password Reset</Heading>
        <SubHeading>{subHeading}</SubHeading>
        <InputFlexContainer hidden={formSubmitted}>
          <Input
            placeholder='Email'
            style={{ width: '100%' }}
            border={border}
            value={email}
            onChange={handleEmailChange}
          />
          {validationError && (
            <ErrorMessage style={{ margin: 0 }}>{validationError}</ErrorMessage>
          )}
        </InputFlexContainer>
        <ForgotButton onClick={forgotButtonCB}>
          <ForgotBtnText>{buttonTxt}</ForgotBtnText>
        </ForgotButton>
      </PasswordReset>
    </Container>
  )
}
