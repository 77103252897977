import React, { useCallback } from 'react'
import { useDeleteUserMutation } from 'Services/configuration/users'
import { CreateDeleteDialog } from 'Components/shared/createdeletedialog'
import { useToggle } from 'Hooks/useToggle'
import { Loading } from 'Utils/loading'
import Trash from 'Assets/images/trash.svg'

interface DeleteUserContainerProps {
  /** User id of the user to be deleted */
  userId: string
  /** User name of the user to be deleted */
  userName: string
}

export const DeleteUserContainer = ({
  userId,
  userName,
}: DeleteUserContainerProps) => {
  const [deleteUserDialog, setDeleteUserDialog] = useToggle(false)
  const [deleteUser, { isLoading: isDeletingUser }] = useDeleteUserMutation()

  const handleDeleteAction = useCallback((e: any) => {
    e.preventDefault()
    e.stopPropagation()
    setDeleteUserDialog()
  }, [])

  const handleDeleteUser = useCallback((e: any, userId: string) => {
    handleDeleteAction(e)
    deleteUser(userId)
  }, [])

  if (isDeletingUser) return <Loading />

  return (
    <>
      <Trash
        role='button'
        style={{ cursor: 'pointer' }}
        onClick={handleDeleteAction}
        onKeyDown={(e) =>
          (e.key === 'Enter' || e.key === ' ') && handleDeleteAction(e)
        }
      />
      {deleteUserDialog && (
        <CreateDeleteDialog
          title='Remove User'
          bodyText={`Are you sure you want to permanently remove ${userName}?`}
          actionText={'Remove'}
          handleAction={(e: any) => handleDeleteUser(e, userId)}
          toggleDialog={setDeleteUserDialog}
          isDialogOpen={deleteUserDialog}
        />
      )}
    </>
  )
}
