import { createSlice } from '@reduxjs/toolkit'

type WebsocketStatus = {
  waitingForResponse: boolean
  availableStudents: Object[]
  charts: Object[],
  requestId?: number,
  currentPage?: number
}

const initialState: WebsocketStatus = { 
  waitingForResponse: false, 
  availableStudents: [], 
  charts: [
    {id: 'chart1', chartType: 'bar', studentMetric: 'state_abbr', metricLabel: 'State', limit: 10, title: 'Top 10 Student States', modelFields: ['state_abbr']},
    {id: 'chart2', chartType: 'table', studentMetric: 'state_abbr', metricLabel: 'State', title: 'All Student States'},
    {id: 'chart3', chartType: 'table', studentMetric: 'state_abbr', metricLabel: 'State', secondMetric: 'high_school_grad_year', title: 'All Student States Grad Years'},
    {id: 'chart4', chartType: 'pie', studentMetric: 'high_school_grad_year', title: 'HS Grad Class'},
    {id: 'chart5', chartType: 'pie', studentMetric: 'gender', title: 'Gender'},
    {id: 'chart6', chartType: 'table', studentMetric: 'table_source', title: 'Enhanced Comparison', tableKey: {candidates: 'Traditional', smart_candidates: 'Enhanced'}
  }
]}

const websocketSlice = createSlice({
  name: 'websocketStatus',
  initialState,
  reducers: {
    messageSent: (state) => {
      state.waitingForResponse = true
      state.currentPage = 0
    },
    
    messageReceived: (state, action) => {
      if (action.payload?.pagination && action.payload?.pagination?.current_page !== 1 && action.payload?.request_id === state.requestId) {
        if(action.payload?.pagination.current_page > state.currentPage) {
          state.availableStudents = [...action.payload.data, ...state.availableStudents]
          state.currentPage = action.payload.pagination.current_page
          if(action.payload?.pagination.total_pages === action.payload?.pagination.current_page) {
            state.waitingForResponse = false
          }
        }
      } else if (action.payload?.pagination && action.payload?.pagination?.current_page === 1) {
        state.requestId = action.payload?.request_id
        state.availableStudents = action.payload.data
        state.currentPage = action.payload.pagination.current_page
        if(action.payload?.pagination.total_pages <= action.payload?.pagination.current_page) {
          state.waitingForResponse = false
        }
      } else {
        state.waitingForResponse = false
        state.availableStudents = action.payload
      }
    },
  },
})

export const { messageSent, messageReceived } = websocketSlice.actions

export default websocketSlice.reducer

export const selectVisualizationDataByChartId = ({charts, availableStudents}, chartId) => {
  if(chartId === undefined) return {}
  const {chartType, studentMetric, metricLabel, chartLabel, secondMetric, limit, tableKey} = charts.find(chart => chart.id === chartId)

  const availableStudentsByMetric = []
    const secondMetricSet = new Set()
    availableStudents?.length && availableStudents?.forEach((student) => {
        const {count} = student
        const metric = student[studentMetric]
        const secondary = student[secondMetric]
        const metricIndex = availableStudentsByMetric.findIndex(el => el.label === metric)
        if(secondary) secondMetricSet.add(secondary)
        if(metricIndex > -1) {
            availableStudentsByMetric[metricIndex].value += count
            if(secondMetric) availableStudentsByMetric[metricIndex][secondary] = count + (availableStudentsByMetric[metricIndex][secondary] || 0)
        } else {
            availableStudentsByMetric.push({id: `${metric}-metric-id`, value: count, label: metric, arcLabel: (item) => `${item.label} (${item.value})`,
            arcLabelMinAngle: 45, [secondary]: count})
        }
    })

    const isLimitedNumberVisualization = limit && availableStudentsByMetric.length > 0
    const isPieChart = chartType === 'pie'
    const hasTableKey = tableKey && Object.keys(tableKey).length > 0
    const studentTotalAsDenomenator = isPieChart && availableStudentsByMetric.reduce((acc, student) => acc + student.value, 0)

    const transfromedSeries = isLimitedNumberVisualization ? [...availableStudentsByMetric].sort((a, b) => b.value - a.value)?.slice(0, limit) 
        : isPieChart ? availableStudentsByMetric.map(studentType => (
            {...studentType, arcLabel: (item) => `${item.label} (${item.value})`, arcLabelMinAngle: 45, label: `${studentType.label} ${Math.round((studentType.value / studentTotalAsDenomenator) * 100)}%`}
        ))
        : hasTableKey ? availableStudentsByMetric.map((metric) =>({...metric, label: tableKey[metric.label]})) 
        : availableStudentsByMetric
        
    if(tableKey && transfromedSeries.length && transfromedSeries.length < 2) {
        const missingKey = Object.keys(tableKey).find(key => tableKey[key] !== transfromedSeries[0].label)
        transfromedSeries.push({label: tableKey[missingKey], value: 0})
    }

    return {
      studentsVisualizedForChart: transfromedSeries,
      metricLabel,
      chartLabel,
      chartType,
      secondMetricSet,
      secondMetric
    }
}

export const selectGroupByFields = ({charts}) => {
  return charts.map(chart => chart.studentMetric).filter((metric, index, array) => metric !== 'table_source' && array.indexOf(metric) === index)
}
