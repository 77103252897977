import React, { useEffect, useCallback, memo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  selectGpaTestScores,
  setOnDemandGpaTestScores,
  setSelectedValue,
} from './GpaTestScoresSlice'
import { GPATestScores } from 'Components/shared/segmentfilters/prospectprofile/GpaTestScores'
import { CombineBy } from 'Filter/properties'
import { selectGpa } from './GpaSlice'
import { Gpa } from './Gpa'
import { TestScores } from './TestScores'
import { selectTestScores } from './TestScoresSlice'
import { Radiogroup } from 'Components/shared/radiogroup'

interface Props {
  audienceType?: string
}

export const GpaTestScores = memo(({ audienceType }: Props) => {
  const dispatch = useDispatch()

  /** 'AND', 'OR' */
  const { selectedValue } = useSelector(selectGpaTestScores)

  /**  GPA state */
  const gpaState = useSelector(selectGpa)

  /** Test Scores state */
  const testScoresState = useSelector(selectTestScores)

  /** Build the GPATestScores combined filter query */
  useEffect(() => {
    dispatch(
      setOnDemandGpaTestScores({
        gpaFilterQuery: gpaState.filterQuery,
        testScoresFilterQuery: testScoresState.filterQuery,
      })
    )
  }, [selectedValue, gpaState, testScoresState])

  const dispatchSelectedValue = useCallback((selectedValue: CombineBy) => {
    dispatch(setSelectedValue(selectedValue))
  }, [])

  return (
    <div style={{ marginTop: '24px' }}>
      <GPATestScores>
        <div style={{ marginBottom: '26px' }}>
          <Gpa gpaState={gpaState} audienceType={audienceType}></Gpa>
        </div>
        <Radiogroup
          ariaLabel='GPA and Test Scores'
          currentValue={selectedValue}
          handleChange={dispatchSelectedValue}
          values={[CombineBy.AND, CombineBy.OR]}
        />
        <TestScores testScoresState={testScoresState}></TestScores>
      </GPATestScores>
    </div>
  )
})
