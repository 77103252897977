import React, { useEffect, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { ManageDestination } from 'Components/configuration/destinations/ManageDestination'
import { useToggle } from 'Hooks/useToggle'
import {
  setDestinationLabel,
  setDestinationType,
  setDestinationPath,
  setHostName,
  setLeadDownloadPreference,
  setisLeadDownloadZip,
  setAuthType,
  setBasicAuthUserName,
  setBasicAuthPassword,
  setSshUserName,
  setSshKeyId,
  setSftpPort,
  clearManageDestination,
  selectDestination,
} from './ManageDestinationSlice'
import { useUpdateDestinationMutation } from 'Services/configuration/destinations'
import { AuthenticationType } from './AuthenticationType'
import { Loading } from 'Utils/loading'

interface ManageDestinationProps {
  destinationIdFromServer: string
  destinationNameFromServer: string
  destinationTypeFromServer: string
  hostNameFromServer: string
  userNameFromServer: string
  sshKeyIdFromServer: string
  destinationPathFromServer: string
  leadDownloadPreferenceFromServer: string
  leadDownloadPreferenceIsZipFromServer: boolean
  manageDestinationDialog: boolean
  setManageDestinationDialog: () => void
  contractsFromServer: any[]
}

export const ManageDestinationContainer = ({
  destinationIdFromServer,
  destinationNameFromServer,
  destinationTypeFromServer,
  hostNameFromServer,
  userNameFromServer,
  sshKeyIdFromServer,
  destinationPathFromServer,
  leadDownloadPreferenceFromServer,
  leadDownloadPreferenceIsZipFromServer,
  manageDestinationDialog,
  setManageDestinationDialog,
  contractsFromServer,
}: ManageDestinationProps) => {
  const [deleteDestinationDialog, setDeleteDestinationDialog] = useToggle(false)

  const dispatch = useDispatch()
  const { ipedId } = useParams()

  const {
    label,
    destinationType,
    destinationPath,
    hostName,
    authType,
    basicAuthUserName,
    basicAuthPassword,
    sshUserName,
    sshKeyId,
    leadDownloadPreference,
    isLeadDownloadZip,
    sftp_port,
  } = useSelector(selectDestination)

  const {
    control,
    formState: { errors: formErrors, isValid },
    setValue,
    unregister,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      manageDestinationName: destinationNameFromServer,
      manageDestinationType: destinationTypeFromServer,
      manageHostname: hostNameFromServer,
      manageDestinationPath: destinationPathFromServer,
      manageLeadDownloadPreferences: leadDownloadPreferenceFromServer,
      basicUsername: userNameFromServer,
      basicPassword: '',
      sshUserName: userNameFromServer,
      manageDestinationPort: sftp_port,
    },
  })
  const destinationTypeMap = { SFTP: 'sql_sftp', Panto: 'sql_panto' }
  const [updateDestination, { isLoading: isUpdatingDestination }] =
    useUpdateDestinationMutation()

  //set all the destination values in redux
  useEffect(() => {
    dispatch(setDestinationLabel(destinationNameFromServer))
    dispatch(setDestinationType(destinationTypeFromServer))
    dispatch(setHostName(hostNameFromServer))
    dispatch(setDestinationPath(destinationPathFromServer))

    if (sshKeyIdFromServer != '') {
      dispatch(setSshUserName(userNameFromServer))
      dispatch(setAuthType('SSH Key'))
      dispatch(setSshKeyId(sshKeyIdFromServer))
    } else {
      dispatch(setBasicAuthUserName(userNameFromServer))
      dispatch(setAuthType('Password'))
    }

    dispatch(setLeadDownloadPreference(leadDownloadPreferenceFromServer))
    dispatch(setisLeadDownloadZip(leadDownloadPreferenceIsZipFromServer))
  }, [])

  const setLabel = useCallback((value: string) => {
    dispatch(setDestinationLabel(value))
  }, [])

  const setType = useCallback((type: string) => {
    dispatch(setDestinationType(type))
  }, [])

  const setPath = useCallback((path: string) => {
    dispatch(setDestinationPath(path))
  }, [])

  const setHost = useCallback((host: string) => {
    dispatch(setHostName(host))
  }, [])

  const setAuthenticationType = useCallback((type: string) => {
    dispatch(setAuthType(type))
    if (type === 'SSH Key') {
      unregister(['basicUsername', 'basicPassword'])
    }
  }, [])

  const setDestinationBasicAuthUserName = useCallback((userName: string) => {
    dispatch(setBasicAuthUserName(userName))
  }, [])

  const setDestinationBasicAuthPassword = useCallback((password: string) => {
    dispatch(setBasicAuthPassword(password))
  }, [])

  const setDestinationSshUserName = useCallback((userName: string) => {
    dispatch(setSshUserName(userName))
  }, [])

  const setDestinationSshKeyId = useCallback((key: string) => {
    dispatch(setSshKeyId(key))
  }, [])

  const setDownloadPreference = useCallback((preference: string) => {
    dispatch(setLeadDownloadPreference(preference))
  }, [])

  const setIsZip = useCallback((isZip: boolean) => {
    dispatch(setisLeadDownloadZip(isZip))
  }, [])

  const clearManageDestinationValues = useCallback(() => {
    dispatch(clearManageDestination())
  }, [])

  const setDestinationSftpPort = useCallback((port: number) => {
    dispatch(setSftpPort(port))
  }, [])

  const manageDestinationValues = useCallback(() => {
    const authConDetails =
      authType === 'Password'
        ? {
            sftp_username: basicAuthUserName,
            sftp_password: basicAuthPassword,
          }
        : {
            sftp_username: sshUserName,
          }

    updateDestination({
      ipedId,
      destinationId: destinationIdFromServer,
      label: label,
      connection_type: destinationTypeMap[destinationType],
      path: destinationPath,
      ssh_key_id: sshKeyId,
      host_name: hostName,
      sftp_port,
      user_name: authConDetails['sftp_username'],
      con_details: {
        sftp_host: hostName,
        sftp_port: sftp_port,
        ...authConDetails,
      },
      lead_download_preference: leadDownloadPreference,
      lead_download_is_zip: isLeadDownloadZip,
    })
    closeDialog()
  }, [
    authType,
    basicAuthUserName,
    basicAuthPassword,
    sshUserName,
    sshKeyId,
    sftp_port,
    label,
    ipedId,
    destinationIdFromServer,
    destinationType,
    destinationPath,
    hostName,
    leadDownloadPreference,
    isLeadDownloadZip,
  ])

  const flattenContracts = useMemo(() => {
    let contracts = []
    contractsFromServer?.forEach((contract: any) => {
      contracts.push([contract.id, contract.label])
    })
    return contracts
  }, [contractsFromServer])

  const closeDialog = useCallback(() => {
    setManageDestinationDialog()
    clearManageDestinationValues()
  }, [])

  const disableFormSubmit = !isValid || Object.keys(formErrors).length > 0

  if (isUpdatingDestination) return <Loading />

  return (
    <ManageDestination
      destinationId={destinationIdFromServer}
      destinationName={label}
      destinationType={destinationType}
      hostName={hostName}
      sftp_port={sftp_port}
      destinationPath={destinationPath}
      leadDownloadPreference={leadDownloadPreference}
      leadDownloadPreferenceIsZip={isLeadDownloadZip}
      connectedContracts={flattenContracts}
      manageDestinationDialog={manageDestinationDialog}
      setManageDestinationDialog={setManageDestinationDialog}
      closeDialog={closeDialog}
      setLabel={setLabel}
      setType={setType}
      setPath={setPath}
      setHost={setHost}
      setSftpPort={setDestinationSftpPort}
      setDownloadPreference={setDownloadPreference}
      setIsZip={setIsZip}
      manageDestinationValues={manageDestinationValues}
      deleteDestinationDialog={deleteDestinationDialog}
      setDeleteDestinationDialog={setDeleteDestinationDialog}
      disableSubmit={disableFormSubmit}
      control={control}
    >
      <AuthenticationType
        ipedId={ipedId}
        authType={authType}
        setAuthenticationType={setAuthenticationType}
        basicAuthUserName={basicAuthUserName}
        basicAuthPassword={basicAuthPassword}
        sshUserName={sshUserName}
        sshKeyId={sshKeyId}
        setDestinationBasicAuthUserName={setDestinationBasicAuthUserName}
        setDestinationBasicAuthPassword={setDestinationBasicAuthPassword}
        setDestinationSshUserName={setDestinationSshUserName}
        setDestinationSshKeyId={setDestinationSshKeyId}
        control={control}
        setValue={setValue}
      />
    </ManageDestination>
  )
}
