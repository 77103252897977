import React, { useCallback, memo } from 'react'
import { useDispatch } from 'react-redux'
import {
  setTimeBasedAnyScore,
  setTimeBasedSat,
  setTimeBasedAct,
  setTimeBasedSatOrAct,
  setTimeBasedSatAndAct,
} from './TestScoresSlice'
import { TestScores as TestScoresComponent } from 'Components/shared/segmentfilters/prospectprofile/TestScores'

interface TestScoresProps {
  testScoresState: any
}

export const TestScores = memo(({ testScoresState }: TestScoresProps) => {
  const dispatch = useDispatch()

  const dispatchAnyScore = useCallback(() => {
    dispatch(setTimeBasedAnyScore())
  }, [])

  const dispatchSat = useCallback(({ min, max }) => {
    dispatch(setTimeBasedSat({ min, max }))
  }, [])

  const dispatchAct = useCallback(({ min, max }) => {
    dispatch(setTimeBasedAct({ min, max }))
  }, [])

  const dispatchSatOrAct = useCallback(({ minSat, maxSat, minAct, maxAct }) => {
    dispatch(
      setTimeBasedSatOrAct({
        minSat,
        maxSat,
        minAct,
        maxAct,
      })
    )
  }, [])

  const dispatchSatAndAct = useCallback(
    ({ minSat, maxSat, minAct, maxAct }) => {
      dispatch(
        setTimeBasedSatAndAct({
          minSat,
          maxSat,
          minAct,
          maxAct,
        })
      )
    },
    []
  )

  return (
    <TestScoresComponent
      dispatchAnyScore={dispatchAnyScore}
      dispatchSat={dispatchSat}
      dispatchAct={dispatchAct}
      dispatchSatOrAct={dispatchSatOrAct}
      dispatchSatAndAct={dispatchSatAndAct}
      testScoresState={testScoresState}
    />
  )
})
