import React, { useCallback, memo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import {
  setInstitution,
  setSegment,
  setContract,
  setLeadTotal,
  clearAddSegment,
} from './AddSegmentSlice'
import { AddSegment } from 'Components/admin/hipLeads/AddSegment'
import { Contract } from 'Services/contracts/contractsUtils'
import { Segment } from 'Services/segments/segmentUtils'
import {
  useAddSegmentMutation,
  useGetAllActiveSegmentsQuery,
} from 'Services/admin/hipLeads'
import { useGetAllInquiriesContractsQuery } from 'Services/contracts/inquiriesContracts'
import {
  useGetInstitutionsInfoQuery,
  InstitutionResponse,
} from 'Services/global/institution'
import { useGetSegmentsForContractQuery } from 'Services/segments/segments'
import { Loading } from 'Utils/loading'
import { buildFilterQuery } from 'Filter/buildFilterQuery'
import { CombineBy, FilterBy, properties } from 'Filter/properties'
import type { RootState } from 'Utils/store'
import { AudienceTypeHelper, ContractSource, ContractStatus } from 'Types/index'

const contractsQuery = () => ({
  query: {
    filterQuery: properties(
      [
        {
          column: 'source',
          op: FilterBy.EQ,
          value: ContractSource.inquiries,
        },
        {
          column: 'status',
          op: FilterBy.IN,
          value: [ContractStatus.pending, ContractStatus.in_progress],
        },
        {
          column: 'audience_type',
          op: FilterBy.EQ,
          value: 'cappex',
        },
      ],
      CombineBy.AND
    ),
  },
})

interface AddSegmentContainerProps {
  /** To show the add segment dialog or not */
  addSegmentDialog: boolean
  /** To toggle the add segment dialog visibility */
  setAddSegmentDialog: () => void
  /** The filter query to use for the segments */
  filterQuery?: string | object
}

export const AddSegmentContainer = memo(
  ({
    addSegmentDialog,
    setAddSegmentDialog,
    filterQuery,
  }: AddSegmentContainerProps) => {
    const dispatch = useDispatch()
    const {
      control,
      formState: { errors: formErrors, isValid },
    } = useForm({
      mode: 'onChange',
    })

    const filteredSegments = []

    const institution = useSelector(
      (state: RootState) => state.addLeadSegment.institution,
      (oldValue: InstitutionResponse, newValue: InstitutionResponse) =>
        oldValue?.id === newValue?.id
    )
    const segment = useSelector(
      (state: RootState) => state.addLeadSegment.segment,
      (oldValue: Segment, newValue: Segment) => oldValue?.id === newValue?.id
    )

    const contract = useSelector(
      (state: RootState) => state.addLeadSegment.contract,
      (oldValue: Contract, newValue: Contract) => oldValue?.id === newValue?.id
    )

    const leadTotal = useSelector(
      (state: RootState) => state.addLeadSegment.leadTotal
    )

    const { data: institutionsInfo } = useGetInstitutionsInfoQuery()
    const { data: relatedContracts, isLoading: isLoadingContracts } =
      useGetAllInquiriesContractsQuery(
        {
          ipedId: institution?.id,
          filterQuery: buildFilterQuery(contractsQuery()),
        },
        { skip: !institution }
      )

    const { data: allActiveSegments, isFetching: isFetchingAllSegments } =
      useGetAllActiveSegmentsQuery({
        filterQuery,
      })

    const { data: contractSegments, isLoading: isLoadingSegments } =
      useGetSegmentsForContractQuery(
        {
          ipedId: institution?.id,
          contractId: contract?.id,
        },
        { skip: !contract }
      )
    const [addSegment, { isLoading }] = useAddSegmentMutation()

    if (allActiveSegments && contractSegments) {
      contractSegments.forEach((contractSegment) => {
        if (
          !allActiveSegments?.find(
            (segment) => segment.id === contractSegment.id
          )
        ) {
          filteredSegments.push(contractSegment)
        }
      })
    }
    const setChosenInstitution = useCallback(
      (institution: string) => {
        const instId = institution.match(/\d+/)
        const institutionObj = institutionsInfo?.find(
          (institution) => institution.id === instId?.[0]
        )
        dispatch(setInstitution(institutionObj))
      },
      [institutionsInfo]
    )

    const setChosenContract = useCallback((contract: Contract) => {
      dispatch(setContract(contract))
    }, [])

    const setChosenSegment = useCallback((segment: Segment) => {
      dispatch(setSegment(segment))
    }, [])

    const setSegmentLeadTotal = useCallback((leadTotal: number) => {
      dispatch(setLeadTotal(leadTotal))
    }, [])

    const clearValues = useCallback(() => {
      dispatch(clearAddSegment())
    }, [])

    const addSegmentValues = useCallback(() => {
      closeDialog()
      addSegment({
        segmentId: segment?.id,
        ipedId: institution?.id,
        lead_total: Number(leadTotal),
      })
    }, [segment?.id, institution?.id, leadTotal, contract?.id])

    const closeDialog = useCallback(() => {
      setAddSegmentDialog()
      clearValues()
    }, [])

    const validValues = contract?.id && segment?.id
    const disableFormSubmit =
      !validValues || !isValid || Object.keys(formErrors).length > 0

    return (
      <>
        {(isLoading ||
          isLoadingContracts ||
          isLoadingSegments ||
          isFetchingAllSegments) && <Loading />}
        <AddSegment
          addSegmentDialog={addSegmentDialog}
          setChosenInstitution={setChosenInstitution}
          setChosenContract={setChosenContract}
          setChosenSegment={setChosenSegment}
          setSegmentLeadTotal={setSegmentLeadTotal}
          addSegmentValues={addSegmentValues}
          closeDialog={closeDialog}
          disableSubmit={disableFormSubmit}
          control={control}
          institutionsInfo={institutionsInfo}
          institution={institution}
          relatedContracts={relatedContracts}
          contract={contract}
          segments={filteredSegments}
          segment={segment}
        />
      </>
    )
  }
)
