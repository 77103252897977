import { useState, useCallback } from 'react'

export const usePagination = () => {
  const [currentPage, setCurrentPage] = useState(0)

  const handlePageChange = useCallback((_, newPage: number) => {
    setCurrentPage(newPage)
  }, [])

  return { currentPage, handlePageChange, setCurrentPage }
}
