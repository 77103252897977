import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { DialogField } from 'Components/shared/dialog'
import { Input } from 'Components/shared/input'
import { VirtualizedMultiSelect } from 'Components/shared/multiselectdropdown'
import { SingleSelectDropdown } from 'Components/shared/singleselectdropdown'
import { ContractFormProps } from './types'
import {
  selectContractConfiguration,
  setContractConfiguration,
} from 'Slices/contracts/ContractConfigurationSlice'
import {
  deliveryModelKeyToValue,
  deliveryModelValueToKey,
  selectContractState,
} from 'Slices/contracts'

export const TimeBasedDeliveryFields = ({ control }: ContractFormProps) => {
  const theme = useTheme()
  const hasMdBreakpoint = useMediaQuery(theme.breakpoints.up('md'))
  const dispatch = useDispatch()
  const {
    contractType,
    contractSource,
    isDisabledContract,
    selectedDeliveryModel,
    selectedDeliveryCadence,
    selectedCap,
    leadCap
  } = useSelector(selectContractConfiguration)
  const {
    fieldsAvailableForContract,
    allDeliveryModels,
    allDeliveryCadence,
  } = useSelector(selectContractState)

  const disabledEditing = isDisabledContract || !contractSource || !contractType

  const handleSelectedDeliveryCadence = (selectedCadenceLabels: string[]) => {
    if (selectedCadenceLabels.length) {
      dispatch(
        setContractConfiguration({
          selectedDeliveryCadence: selectedCadenceLabels,
        })
      )
    }
  }

  useEffect(() => {
    if (!selectedDeliveryModel) {
      dispatch(
        setContractConfiguration({
          selectedDeliveryModel: deliveryModelValueToKey(allDeliveryModels[0]),
        })
      )
    }
    if (!selectedDeliveryCadence) {
      handleSelectedDeliveryCadence(['Weekly, Monday'])
    }
  }, [])

  return (
    <>
        {fieldsAvailableForContract.includes('delivery_cadence') && (
          <DialogField id='add-delivery-cadence' label='*Delivery Cadence'>
            <VirtualizedMultiSelect
              label=''
              counterLabel='Cadence'
              values={allDeliveryCadence || []}
              initialSelectedValues={selectedDeliveryCadence || ['Weekly, Monday']}
              selectedValues={selectedDeliveryCadence || ['Weekly, Monday']}
              setSelectedValues={handleSelectedDeliveryCadence}
              disabled={disabledEditing}
            />
          </DialogField>
        )}

        {fieldsAvailableForContract.includes('delivery_model') && (<>
          <DialogField id='add-delivery-model' label='*Delivery Model'>
          <SingleSelectDropdown
            id='add-delivery-model-select'
            name='deliveryModel'
            style={hasMdBreakpoint ? { width: '279px' } : {}}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              dispatch(
                setContractConfiguration({
                  selectedDeliveryModel: e.target.value,
                })
              )
            }
            value={deliveryModelKeyToValue(selectedDeliveryModel)}
            values={allDeliveryModels}
            disabled={disabledEditing}
          />
        </DialogField>
        { deliveryModelKeyToValue(selectedDeliveryModel) !== allDeliveryModels[0] && <DialogField id='add-delivery-cap' label='*Cap'>
        <Input
              id='add-delivery-cap'
              name='deliveryCap'
              placeholder='Add Delivery Cap'
              control={control}
              rules={{
                max: {
                  value: leadCap,
                  message: 'Must be less than or equal to the lead cap',
                },
                pattern: {
                  value: /^[0-9]+$/,
                  message: 'Must contain only numbers',
                },
                required: true,
              }}
              style={{ width: '401px' }}
              value={selectedCap}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                dispatch(
                  setContractConfiguration({ selectedCap: e.target.value })
                )
              }}
            />
          </DialogField>}
        </>
        )}


      </>
  )
}