import * as React from 'react'
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete'
import useMediaQuery from '@mui/material/useMediaQuery'
import ListSubheader from '@mui/material/ListSubheader'
import { SidebarField } from 'Components/shared/sidebar'
import Popper from '@mui/material/Popper'
import { useTheme, styled } from '@mui/material/styles'
import { VariableSizeList, ListChildComponentProps } from 'react-window'

const LISTBOX_PADDING = 16 // px

function renderRow(props: ListChildComponentProps) {
  const { data, index, style, liStyle } = props

  const dataSet = data[index]

  const inlineStyle = {
    ...style,
    ...liStyle,
    top: style.top as number,
  }

  if (dataSet.hasOwnProperty('group')) {
    return (
      <ListSubheader key={dataSet.key} component='div' style={inlineStyle}>
        {dataSet.group}
      </ListSubheader>
    )
  }

  return (
    <li {...dataSet[0]} style={inlineStyle}>
      {dataSet[1]}
    </li>
  )
}

const OuterElementContext = React.createContext({})

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext)
  return <div ref={ref} {...props} {...outerProps} />
})

function useResetCache(data: any) {
  const ref = React.useRef<VariableSizeList>(null)
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true)
    }
  }, [data])
  return ref
}

// Adapter for react-window
const ListboxComponent = React.forwardRef<HTMLDivElement, any>(
  function ListboxComponent({ liStyle, ...rest }, ref) {
    const { children, ...other } = rest

    const itemData: React.ReactChild[] = []
    ;(children as React.ReactChild[]).forEach(
      (item: React.ReactChild & { children?: React.ReactChild[] }) => {
        itemData.push(item)
        itemData.push(...(item.children || []))
      }
    )

    const theme = useTheme()
    const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
      noSsr: true,
    })
    const itemCount = itemData.length
    const itemSize = smUp ? 36 : 48

    const getChildSize = (child: React.ReactChild) => {
      if (child.hasOwnProperty('group')) {
        return 48
      }

      return itemSize
    }

    const getHeight = () => {
      if (itemCount > 8) {
        return 8 * itemSize
      }
      return itemData.map(getChildSize).reduce((a, b) => a + b, 0)
    }

    const gridRef = useResetCache(itemCount)

    return (
      <div ref={ref}>
        <OuterElementContext.Provider value={other}>
          <VariableSizeList
            itemData={itemData}
            height={getHeight() + 2 * LISTBOX_PADDING}
            // height={getHeight()}
            width='100%'
            ref={gridRef}
            outerElementType={OuterElementType}
            innerElementType='ul'
            itemSize={(index) => getChildSize(itemData[index])}
            overscanCount={5}
            itemCount={itemCount}
          >
            {(props) => renderRow({ ...props, liStyle: liStyle })}
          </VariableSizeList>
        </OuterElementContext.Provider>
      </div>
    )
  }
)

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
})

interface Props {
  /** label is the label of the dropdown */
  label?: string
  /** initialSelectedValues is the array of values that are selected in the dropdown */
  initialSelectedValue?: string
  /** values is the array of values to be displayed in the dropdown */
  values: string[]
  /** selectedValues is the array of values that are selected in the dropdown */
  selectedValue: string
  /** onChange is the function to be called on change of the dropdown */
  setSelectedValue: (value: string) => void
  /** disabled is the boolean to disable the dropdown */
  /** list item style */
  liStyle?: any
  /** Custom Input component */
  StyledInput: any
  /** disabled is the boolean to disable the dropdown */
  disabled?: boolean
}

const AutoComplete = ({
  values,
  initialSelectedValue,
  selectedValue,
  setSelectedValue,
  label,
  disabled,
  liStyle,
  StyledInput,
  ...props
}) => {
  return (
    <Autocomplete
      data-testid='autocomplete-search'
      sx={{ width: 200 }}
      disableListWrap
      disableClearable
      PopperComponent={StyledPopper}
      ListboxComponent={(listboxProps) => {
        const allProps = { ...listboxProps, ...{ 'data-testid': 'list-box' } }
        return <ListboxComponent {...allProps} liStyle={liStyle} />
      }}
      options={values}
      value={
        selectedValue
          ? selectedValue.replace(/\[.*?\]\s*/g, '')
          : initialSelectedValue
      }
      onChange={(_, checkedValue) => {
        setSelectedValue(checkedValue)
      }}
      renderInput={(params) => {
        const { InputProps, ...restParams } = params
        const { startAdornment, ...restInputProps } = InputProps
        return (
          <StyledInput
            {...restParams}
            placeholder={`Search ${label}`}
            InputProps={{
              ...restInputProps,
            }}
          />
        )
      }}
      renderOption={(props, option, state) =>
        [props, option, state.index] as React.ReactNode
      }
    />
  )
}

export function VirtualizedSingleSelect({
  values,
  initialSelectedValue,
  selectedValue,
  setSelectedValue,
  label,
  disabled,
  liStyle,
  StyledInput,
  ...props
}: Props) {
  return label ? (
    <SidebarField id={`label-${label}`} label={label}>
      <AutoComplete
        values={values}
        disabled={disabled}
        initialSelectedValue={initialSelectedValue}
        selectedValue={selectedValue}
        setSelectedValue={setSelectedValue}
        label={label}
        liStyle={liStyle}
        StyledInput={StyledInput}
        {...props}
      />
    </SidebarField>
  ) : (
    <AutoComplete
      values={values}
      disabled={disabled}
      initialSelectedValue={initialSelectedValue}
      selectedValue={selectedValue}
      setSelectedValue={setSelectedValue}
      label={label}
      liStyle={liStyle}
      StyledInput={StyledInput}
      {...props}
    />
  )
}
