import React, { useEffect } from 'react'
import { styled } from '@mui/material/styles'
import {
  ContractSection,
  ContractSectionHeader,
  ContractConfigurationFieldContainer,
} from 'Components/shared/contracts'
import {
  AddFileButton,
  Deduplicates as DeduplicatesComponent,
  DeduplicatesField,
} from 'Components/shared/contracts/deduplicates'
import { Loading } from 'Utils/loading'
import useFileUpload from 'Hooks/useFileUpload'
import { Permissions } from 'Configs/userPermissions'
import { useHasPermission } from 'Hooks/useHasPermission'
import { FileCategory } from 'Types/fileCategory'
import { useSelector } from 'react-redux'
import { ContractFormProps } from 'Components/connectedComponents/contracts/types'
import { useGetDeduplicatesForContractQuery } from 'Services/contracts/deduplicates'
import {
  selectContractConfiguration,
  selectDeduplicatesFile,
  setDedupState,
} from 'Slices/contracts'
import { useAppDispatch } from 'Utils/store'
import {
  setContractState,
} from 'Slices/contracts/ContractStateSlice'

const Added = styled('div')(({ theme }) => ({
  color: theme.palette.primary.dark,
}))

const columnData = [
  {
    columnName: 'File Name',
    columnWidth: 155,
    sortOrder: 'ASC',
  },
  {
    columnName: '',
    columnWidth: 20,
    sortOrder: 'ASC',
  },
]

export const ContractDeduplicates = ({ control }: ContractFormProps) => {
  const { fileName } = useSelector(selectDeduplicatesFile)
  
  const { contractId, contractInstitutionId } = useSelector(
    selectContractConfiguration
  )
  const {
    isInvalidSize,
    isFileAdded,
    isUploadingData,
    isUploadingFile,
    inputRef,
    handleAddFileClick,
    handleFileChange,
  } = useFileUpload(contractInstitutionId, FileCategory.ContractDeduplication)
  const dispatch = useAppDispatch()

  const { data: addedDeDuplicateFile, isLoading: isLoadingDeduplicates } =
    useGetDeduplicatesForContractQuery(
      {
        ipedId: contractInstitutionId,
        contractId,
      },
      {
        skip: !contractId || !contractInstitutionId,
      }
    )

   useEffect(() => {
    if (addedDeDuplicateFile?.data[0] && !fileName) {
      dispatch(
        setDedupState({
          fileId: addedDeDuplicateFile?.data[0].file_id, 
          fileName: addedDeDuplicateFile?.data[0].label, 
          deduplicateId: addedDeDuplicateFile?.data[0].id
        })
      )
    }
    }, [addedDeDuplicateFile])

  const { hasPermission: canUploadFile } = useHasPermission(
    Permissions.AddContractDedup
  )

  const rows = [['', fileName, isFileAdded ? <Added>Added</Added> : '']]

  if (isUploadingData || isUploadingFile || isLoadingDeduplicates)
    return <Loading />

  return (
    <ContractSection>
      <ContractSectionHeader label={'De-duplicates'} />
      <ContractConfigurationFieldContainer>
        <DeduplicatesField id='add-deduplicate' label='De-duplicates'>
          {canUploadFile && (
            <AddFileButton onClick={() => {dispatch(setContractState({ disableUpdate: false })); return handleAddFileClick();}
          }>
              <input
                ref={inputRef}
                type='file'
                onChange={handleFileChange}
                style={{ display: 'none' }}
                accept='.csv, .txt'
              />
            </AddFileButton>
          )}
        </DeduplicatesField>
        <DeduplicatesComponent
          columnData={columnData}
          rows={rows}
          invalidSize={isInvalidSize}
        />
      </ContractConfigurationFieldContainer>
    </ContractSection>
  )
}
