import { ContractSource } from './contractTypes'
import AudienceTypes from 'Configs/audienceTypes.json'

// This Enum is deprecated. Use AudienceTypes from Configs/audienceTypes.json instead
export enum AudienceType_Deprecated {
  all = 'all',
  adc = 'adc',
  cappex = 'cappex',
  transfer = 'transfer',
  grad_degree_seeker = 'grad_degree_seeker',
  greenlight = 'greenlight',
  business = 'business',
  healthcare = 'healthcare',
  none = '',
}

type AudienceType = {
  id: string
  name: string
}

export class AudienceTypeHelper {
  static getAudienceTypes(contractSource: ContractSource, config) {
    return config.source[contractSource].audience_types
  }

  static getAudienceTypeList(audienceTypes: AudienceType[]): string[] {
    return audienceTypes?.map((audienceType) => audienceType.name)
  }

  static getAudienceReadableList(audienceTypes: AudienceType[]): string[] {
    return ['All', ...audienceTypes.map((audienceType) => audienceType.name)]
  }

  static isNone(keyVal: string): boolean {
    return keyVal === AudienceType_Deprecated.none
  }

  static isAll(keyVal: string): boolean {
    return keyVal === AudienceType_Deprecated.all
  }

  static getReadableString(
    audienceTypes: AudienceType[],
    input: string
  ): string {
    const audienceType = audienceTypes?.find((type) => type.id === input)
    return audienceType ? audienceType?.name : 'All'
  }

  static getRandomAudienceType(contractSource: ContractSource) {
    // TODO randomness??
    Object.keys(AudienceTypes).forEach((audienceType) => {
      if (
        AudienceTypes[audienceType]?.contract_source[contractSource]
          ?.source_enabled
      ) {
        return AudienceTypes[audienceType].key
      }
    })
    return ''
  }

  static getNone(): string {
    return AudienceType_Deprecated.none
  }

  static getAll(): string {
    return AudienceType_Deprecated.all
  }

  static getAudienceType(audienceTypes: AudienceType[], input: string): string {
    const audienceType = audienceTypes?.find((type) => type.name === input)
    return audienceType ? audienceType?.id : 'all'
  }
}
