import { useSelector, shallowEqual } from 'react-redux'
import type { RootState } from 'Utils/store'
import { selectNewOnDemandSegment } from 'Containers/candidates/segment/NewOnDemandSegmentSlice'
import { selectNewAnnualSegment } from 'Containers/inquiries/segment/NewAnnualSegmentSlice'
import { selectSmartCandidates } from 'Containers/shared/segmentfilters/ondemand/smartcandidates/SmartCandidatesSlice'

export const useSegmentData = () => {
  const onDemandFilter = useSelector(
    (state: RootState) => state.onDemandFilter,
    shallowEqual
  )

  const timeBasedFilter = useSelector(
    (state: RootState) => state.timeBasedFilter
  )

  const {
    contract: timeBasedContract,
    noOfLeads: timeBasedNoOfLeads,
    segmentName: timeBasedSegmentName,
  } = useSelector(selectNewAnnualSegment)

  const { contract, noOfLeads, pricePerLead, segmentName } = useSelector(
    selectNewOnDemandSegment
  )
  const { isSmartCandidates } = useSelector(selectSmartCandidates)

  return {
    onDemandFilter,
    timeBasedFilter,
    contract,
    noOfLeads,
    pricePerLead,
    segmentName,
    isSmartCandidates,
    timeBasedContract,
    timeBasedNoOfLeads,
    timeBasedSegmentName,
  }
}
