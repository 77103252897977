import React, { useEffect } from 'react'
import Checkbox from '@mui/material/Checkbox'

import CheckboxUnchecked from 'Assets/images/checkbox_unchecked.svg'
import CheckboxChecked from 'Assets/images/checkbox_checked.svg'
import { useToggle } from 'Hooks/useToggle'
import { Accordion } from 'Components/shared/accordion'
import { useDispatch, useSelector } from 'react-redux'
import { Slider } from 'Components/shared/slider'
import { FilterSingleSelectDropdown } from 'Components/shared/singleselectdropdown'
import {
  MIN,
  MAX,
} from 'Containers/shared/segmentfilters/ondemand/smartcandidates/SmartCandidatesSlice'

import {
  selectSmartCandidates,
  setOnDemandSmartCandidatesValue,
  setOnDemandNoSmartCandidates,
  setOnlySmartCandidates,
} from './SmartCandidatesSlice'
import { Contract } from 'Services/contracts/contractsUtils'

interface SmartCandidatesProps {
  chosenItem: Partial<Contract>
  filterData?: any
  displayOnlyOption?: boolean
}

export const SmartCandidates = ({
  chosenItem,
  filterData,
  displayOnlyOption = false,
}: SmartCandidatesProps) => {
  const dispatch = useDispatch()
  const [expand, setExpand] = useToggle(false)

  const {
    values,
    selectedValue,
    selectedMin,
    selectedMax,
    min,
    max,
    step,
    ariaLabel,
    label,
  } = useSelector(selectSmartCandidates)

  /** This useEffect is for setting filters in edit segment */
  useEffect(() => {
    const value = filterData?.[label]
    if (Array.isArray(value)) {
      dispatch(
        setOnDemandSmartCandidatesValue(value)
      )
    } else {
      dispatch(setOnDemandNoSmartCandidates())
    }
  }, [filterData])

  return (
    <Accordion
      setExpand={setExpand}
      expand={expand}
      title='ENHANCED CANDIDATES'
      disabled={chosenItem?.id ? false : true}
    >
      <FilterSingleSelectDropdown
        label='Enhanced Candidates'
        values={values}
        value={selectedValue}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
          const chosenValue = e.target.value
          if (chosenValue === 'Yes') {
            dispatch(setOnDemandSmartCandidatesValue([MIN, MAX]))
          }
          else {
            dispatch(setOnDemandNoSmartCandidates())

          }
        }}
      />
      {selectedValue === 'Yes' && (
        <div style={{ marginTop: '16px', marginLeft: '10px' }}>
          <Slider
            ariaLabel={ariaLabel}
            min={min}
            max={max}
            selectedMin={selectedMin}
            selectedMax={selectedMax}
            step={step}
            handleChange={(range: number[]) =>
              dispatch(
                setOnDemandSmartCandidatesValue(range)
              )
            }
          />
          {displayOnlyOption && <div style={{ marginTop: '10px' }}>
          <Checkbox
            icon={<CheckboxUnchecked />}
            checkedIcon={<CheckboxChecked />}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              return dispatch(setOnlySmartCandidates(e.target.checked))
            }}
          />
            Enhanced Only
          </div>}
        </div>
      )}
    </Accordion>
  )
}
