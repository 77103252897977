import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit'
import { CombineBy, properties } from 'Filter/properties'
import type { RootState } from 'Utils/store'
import { ContractSource } from 'Types/index'
import GPATestScoresConfig from 'Configs/fields/gpa_test_scores.json'

const contractSource = ContractSource.inquiries

interface GPATestScores {
  selectedValue: CombineBy.AND | CombineBy.OR
  filterQuery: any
}

const initialState: GPATestScores = {
  selectedValue: CombineBy.AND,
  filterQuery: '',
}

export const gpaTestScoresSlice = createSlice({
  name: 'gpaTestScores',
  initialState,
  reducers: {
    setTimeBasedGpaTestScores(
      state,
      action: PayloadAction<{
        gpaFilterQuery: any
        testScoresFilterQuery: any
      }>
    ) {
      const { gpaFilterQuery, testScoresFilterQuery } = action.payload

      if (gpaFilterQuery && testScoresFilterQuery) {
        state.filterQuery = properties(
          [gpaFilterQuery, testScoresFilterQuery],
          state.selectedValue
        )
      } else if (gpaFilterQuery) {
        state.filterQuery = gpaFilterQuery
      } else if (testScoresFilterQuery) {
        state.filterQuery = testScoresFilterQuery
      }
    },
    setSelectedValue(state, action: PayloadAction<CombineBy>) {
      state.selectedValue = action.payload
    },
  },
})

export const selectGpaTestScores = createSelector(
  ({ timeBasedFilter: { prospectProfile } }: RootState) =>
    prospectProfile.gpaTestScores.selectedValue,
  (
    selectedValue: CombineBy
  ): {
    selectedValue: string
    audienceTypes: string[]
  } => {
    return { selectedValue, audienceTypes: GPATestScoresConfig.source[contractSource].audience_types }
  }
)

export const GPATestScoresAudienceTypes = GPATestScoresConfig.source[contractSource].audience_types;

export const { setTimeBasedGpaTestScores, setSelectedValue } =
  gpaTestScoresSlice.actions

  
export default gpaTestScoresSlice.reducer
