import React from 'react'
import { useSetActiveForSegmentMutation } from 'Services/admin/hipLeads'
import { SegmentActions as SegmentActionsComponent } from 'Components/admin/hipLeads/SegmentActions'
import { useToggle } from 'Hooks/useToggle'
import { Loading } from 'Utils/loading'

interface Props {
  currentSegment: any
  setShowEdit: (showEdit: boolean) => void
  setCurrentSegment: (segment: any) => void
}

export const SegmentActions = ({
  currentSegment,
  setShowEdit,
  setCurrentSegment,
}: Props) => {
  const { id, institution_id, segment_supp_status } = currentSegment
  const [showAction, setShowAction] = useToggle(false)

  const [setActiveForSegment, { isLoading }] = useSetActiveForSegmentMutation()

  const handleActivation = (
    institutionId: string,
    segmentId: string,
    status: string
  ) => {
    setActiveForSegment({
      ipedId: institutionId,
      segmentId,
      status,
    })
  }

  const handleEdit = () => {
    setShowEdit(true)
    setShowAction(false)
    setCurrentSegment(currentSegment)
  }

  if (isLoading) return <Loading />

  return (
    <SegmentActionsComponent
      institutionId={institution_id}
      segmentId={id}
      segmentStatus={segment_supp_status}
      showAction={showAction}
      toggleAction={setShowAction}
      handleActivation={handleActivation}
      handleEdit={handleEdit}
    />
  )
}
