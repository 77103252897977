import React, { useCallback, memo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { createSelector } from '@reduxjs/toolkit'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import {
  setUserName,
  setUserEmail,
  setUserRole,
  clearUser,
} from './AddUserSlice'
import { AddUser } from 'Components/configuration/manageusers/adduser'
import { useAddUserMutation } from 'Services/configuration/users'
import { useGetRolesQuery } from 'Services/configuration/roles'
import { Loading } from 'Utils/loading'
import type { RootState } from 'Utils/store'

interface AddUserContainerProps {
  /** To show the add user dialog or not */
  addUserDialog: boolean
  /** To toggle the add user dialog visibility */
  setAddUserDialog: () => void
}

const selectUser = createSelector(
  (state: RootState) => state.addUser.userName,
  (state: RootState) => state.addUser.userEmail,
  (state: RootState) => state.addUser.roles,
  (userName, userEmail, roles) => ({
    userName,
    userEmail,
    roles,
  })
)

export const AddUserContainer = memo(
  ({ addUserDialog, setAddUserDialog }: AddUserContainerProps) => {
    const dispatch = useDispatch()
    const { ipedId } = useParams()
    const {
      control,
      formState: { errors: formErrors, isValid },
    } = useForm({
      mode: 'onChange',
    })
    const { userName, userEmail, roles } = useSelector(selectUser)
    const [addUser, { isLoading: isAddingUser }] = useAddUserMutation()
    const { data: rolesData, isLoading: isGettingRoles } = useGetRolesQuery()

    const setName = useCallback((name: string) => {
      dispatch(setUserName(name))
    }, [])

    const setEmail = useCallback((email: string) => {
      dispatch(setUserEmail(email))
    }, [])

    const setType = useCallback((role: string) => {
      dispatch(setUserRole([role]))
    }, [])

    const clearUserValues = useCallback(() => {
      dispatch(clearUser())
    }, [])

    const addUserValues = useCallback(() => {
      closeDialog()
      const names = userName.split(' ')
      addUser({
        email: userEmail,
        sso_enabled: false,
        autogen_password: true,
        first_name: names[0],
        last_name: names[1] || '',
        roles: roles,
        iped_id: ipedId,
      })
    }, [userName, userEmail, roles, ipedId])

    const closeDialog = useCallback(() => {
      setAddUserDialog()
      clearUserValues()
    }, [])

    const disableFormSubmit = !isValid || Object.keys(formErrors).length > 0

    if (isAddingUser || isGettingRoles) return <Loading />

    return (
      <AddUser
        addUserDialog={addUserDialog}
        setName={setName}
        setEmail={setEmail}
        setType={setType}
        addUserValues={addUserValues}
        closeDialog={closeDialog}
        roles={rolesData}
        disableSubmit={disableFormSubmit}
        control={control}
      />
    )
  }
)
