import React, { useCallback, memo } from 'react'
import { useDispatch } from 'react-redux'
import { setTimeBasedGpaRange, setTimeBasedAnyGpa } from './GpaSlice'
import { Gpa as GpaComponent } from 'Components/shared/segmentfilters/prospectprofile/Gpa'

interface GpaProps {
  gpaState: any
  audienceType?: string
}

export const Gpa = ({ gpaState, audienceType }: GpaProps) => {
  const dispatch = useDispatch()

  const dispatchAnyGpa = useCallback((selections) => {
    dispatch(setTimeBasedAnyGpa({ audienceType, selections }))
  }, [audienceType])

  const dispatchGpaRange = useCallback(
    ({ min, max }) => {
      dispatch(setTimeBasedGpaRange({ min, max, audienceType }))
    },
    [audienceType]
  )

  return (
    <GpaComponent
      dispatchAnyGpa={dispatchAnyGpa}
      dispatchGpaRange={dispatchGpaRange}
      gpaState={gpaState}
    />
  )
}
