import { createSelector, AnyAction, Dispatch } from '@reduxjs/toolkit'
import { selectDropdownSlice } from 'Slices/selectDropdownSlice'
import type { RootState } from 'Utils/store'
import EmailVerifiedConfig from 'Configs/fields/email_verified.json'

export const EmailCleanlinessSlice = selectDropdownSlice(
  EmailVerifiedConfig.column,
  EmailVerifiedConfig.values,
  'setOnDemandEmailCleanliness',
  EmailVerifiedConfig.meta
)

export const selectEmailCleanliness = createSelector(
  ({ onDemandFilter: { verification } }: RootState) =>
    verification['emailCleanliness'].selectedValue,
  (
    selectedValue: string
  ): { selectedValue: string; values: string[]; label } => {
    return { selectedValue, values: EmailVerifiedConfig.values, label: EmailVerifiedConfig.meta.label }
  }
)

export const { setOnDemandEmailCleanliness } = EmailCleanlinessSlice.actions

export const setOnDemandEmailCleanlinessDefaultValue = (
  dispatch: Dispatch<AnyAction>,
  audienceType: string
) => {
  if (!audienceType) return
  if (EmailVerifiedConfig.default_selected[audienceType]) {
    dispatch(
      setOnDemandEmailCleanliness(EmailVerifiedConfig.default_selected[audienceType])
    )
  }
}

export default EmailCleanlinessSlice.reducer
