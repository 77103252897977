import React from 'react'
import {useSelector} from 'react-redux'
import { PieChart } from '@mui/x-charts/PieChart';
import { BarChart } from '@mui/x-charts/BarChart';
import { selectVisualizationDataByChartId } from 'Slices/websocketSlice'
import { TableVisualization } from 'Containers/shared/segmentdisplay';

export const StudentVisualization = ({chartId}) => {
    const {
        studentsVisualizedForChart, 
        metricLabel, 
        chartLabel, 
        chartType, 
        secondMetricSet, 
        secondMetric
    } = useSelector(({websocketStatus}) => selectVisualizationDataByChartId(websocketStatus, chartId))
    
    if (studentsVisualizedForChart?.length === 0) return <div>No data available</div>
    
    if(chartType === 'pie') {
        return (
            <PieChart
            series={[{data: studentsVisualizedForChart}]}
            height={400}
            width={400}
          />
        )
    }
    if(chartType === 'bar') {
        return (
            <BarChart
            dataset={studentsVisualizedForChart}
            yAxis={[{ scaleType: 'band', dataKey: 'label', label: `Student ${metricLabel}` }]}
            xAxis={[{ label: 'Students' }]}
            series={[{ dataKey: 'value', label: chartLabel}]}
            layout="horizontal"
            height={400}
            width={400}
          />
        )
    }
    if(chartType === 'table') {
        return (
            <TableVisualization rows={studentsVisualizedForChart} columns={Array.from(secondMetricSet)} title={secondMetric ? "High School Graduation Class" : ""} label={metricLabel} />
        )
    }
    return <div>No Visualization available</div>
}