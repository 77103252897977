import { createSelector, createSlice } from '@reduxjs/toolkit'
import type { RootState } from 'Utils/store'

const initialState = {
  contract: null,
  segmentsRelatedToContract: [],
  segmentName: '',
  segmentsLeadsTotal: 0,
  noOfLeads: null,
  pricePerLead: 0.0,
  pricePerLeadString: ''
}

export const NewOnDemandSegmentSlice = createSlice({
  name: 'newOnDemandSegment',
  initialState,
  reducers: {
    setContract: (state, action) => {
      state.contract = action.payload
    },
    setSegmentsLeadsTotal: (state, action) => {
      state.segmentsLeadsTotal = action.payload
    },

    setSegmentsForContract: (state, action) => {
      state.segmentsRelatedToContract = action.payload
    },
    setNoOfLeads: (state, action) => {
      state.noOfLeads = action.payload
    },
    setPricePerLead: (state, action) => {
      state.pricePerLead = action.payload
    },
    setPricePerLeadString: (state, action) => {
      state.pricePerLeadString = action.payload
    },
    setSegmentName: (state, action) => {
      state.segmentName = action.payload
    },
    clearNewOnDemandSegment: () => {
      return initialState
    },
  },
})

export const {
  setContract,
  setSegmentsForContract,
  setSegmentsLeadsTotal,
  setNoOfLeads,
  setPricePerLead,
  setPricePerLeadString,
  setSegmentName,
  clearNewOnDemandSegment,
} = NewOnDemandSegmentSlice.actions

export default NewOnDemandSegmentSlice.reducer

export const selectNewOnDemandSegment = createSelector(
  ({ newOnDemandSegment }: RootState) => newOnDemandSegment.contract,
  ({ newOnDemandSegment }: RootState) => newOnDemandSegment.segmentsLeadsTotal,
  ({ newOnDemandSegment }: RootState) => newOnDemandSegment.noOfLeads,
  ({ newOnDemandSegment }: RootState) =>
    newOnDemandSegment.segmentsRelatedToContract,
  ({ newOnDemandSegment }: RootState) => newOnDemandSegment.segmentName,
  ({ newOnDemandSegment }: RootState) => newOnDemandSegment.pricePerLead,
  ({ newOnDemandSegment }: RootState) => newOnDemandSegment.pricePerLeadString,
  (
    contract,
    segmentsLeadsTotal,
    noOfLeads,
    segmentsRelatedToContract,
    segmentName,
    pricePerLead,
    pricePerLeadString
  ) => ({
    contract,
    segmentsLeadsTotal,
    noOfLeads,
    segmentsRelatedToContract,
    segmentName,
    pricePerLead,
    pricePerLeadString
  })
)
