import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from '../getBaseQuery'
import { FileCategory } from 'Types/fileCategory'

interface UploadFile {
  ipedId: string
  name: string
  size_bytes: number
  is_downloadable: boolean
  category: FileCategory
}

export interface UploadFileApiResponse {
  data: {
    file_info: {
      id: string
      name: string
    }
    upload_url: string
  }
}

export interface Deduplicate {
  id: string
  file_id: string
  label: string
}

export interface GetDeduplicatesForContract {
  data: Deduplicate[]
}

interface File {
  url: string
  file: any
}

interface TransformedResponse {
  records: any[]
  total_pages: number
  total_records: number
}

interface GetZipCodesResponse {
  data: {
    records: any[]
    total_pages: number
    total_records: number
    current_page: number
    current_size: number
  }
}

const DEDUPLICATE = 'DEDUPLICATE'
const ZIP_CODES = 'ZIP_CODES'

export const DeduplicatesApi = createApi({
  reducerPath: 'deduplicates',
  baseQuery,
  tagTypes: [DEDUPLICATE, ZIP_CODES],
  endpoints: (build) => ({
    /** Gets upload url and file id */
    getUploadFileData: build.mutation<
      UploadFileApiResponse,
      Partial<UploadFile>
    >({
      query: ({ ipedId, ...args }) => ({
        url: `institutions/${ipedId}/files/_upload_url`,
        method: 'POST',
        body: args,
      }),
    }),

    /** Uploads file to presigned S3 url */
    uploadFileToS3: build.mutation<File, Partial<File>>({
      query: ({ url, file }) => ({
        url: url,
        method: 'PUT',
        body: file,
        credentials: 'omit',
      }),
    }),

    /** Update file status */
    updateFileStatus: build.mutation<null, { ipedId: string; fileId: string }>({
      query: ({ ipedId, fileId }) => ({
        url: `institutions/${ipedId}/files/${fileId}/_file_status_update`,
        method: 'PATCH',
      }),
      invalidatesTags: [ZIP_CODES],
    }),

    /** Adds file via fileId to be checked for duplicates */
    addDeduplicateFile: build.mutation<
      Deduplicate,
      { ipedId: string; contractId: string; fileId: string }
    >({
      query: ({ ipedId, contractId, fileId }) => ({
        url: `institutions/${ipedId}/contracts/${contractId}/files/${fileId}/deduplications`,
        method: 'POST',
      }),
      invalidatesTags: [DEDUPLICATE],
    }),

    /** Update file via fileId  and deduplicateId*/
    updateDeduplicateFile: build.mutation<
      Deduplicate,
      {
        ipedId: string
        contractId: string
        fileId: string
        deduplicateId: string
      }
    >({
      query: ({ ipedId, contractId, fileId, deduplicateId }) => ({
        url: `institutions/${ipedId}/contracts/${contractId}/files/${fileId}/deduplications/${deduplicateId}`,
        method: 'PUT',
      }),
      invalidatesTags: [DEDUPLICATE],
    }),

    /** Get deduplicates for a contract */
    getDeduplicatesForContract: build.query<
      GetDeduplicatesForContract,
      { ipedId: string; contractId: string }
    >({
      query: ({ ipedId, contractId }) => ({
        url: `institutions/${ipedId}/contracts/${contractId}/deduplications`,
        method: 'GET',
      }),
      providesTags: [DEDUPLICATE],
    }),

    /** Get all zip code files for the institution */
    getZipCodes: build.query<
      TransformedResponse,
      {
        ipedId: string
        page: number
        sortBy: string
        order: string
        filter?: string | object
      }
    >({
      query: ({ ipedId, page, sortBy, order, filter }) => ({
        url: `institutions/${ipedId}/files?page=${page}&size=10&sort_by=${sortBy}::${order}&filter=${filter}`,
      }),
      transformResponse: (
        response: GetZipCodesResponse
      ): TransformedResponse => {
        const zipCodeFiles: TransformedResponse = {
          total_pages: response.data.total_pages,
          total_records: response.data.total_records,
          records: response.data.records,
        }
        return zipCodeFiles
      },
      providesTags: [ZIP_CODES],
    }),

    deleteZipCodes: build.mutation<
      { message: string },
      { ipedId: string; fileId: string }
    >({
      query: ({ ipedId, fileId }) => ({
        url: `institutions/${ipedId}/files/${fileId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [ZIP_CODES],
    }),

    getZipCodeFile: build.query<
      { file: any },
      { ipedId: string; fileId: string }
    >({
      query: ({ ipedId, fileId }) => ({
        url: `institutions/${ipedId}/files/${fileId}`,
      }),
    }),
  }),
})

export const {
  useGetUploadFileDataMutation,
  useUploadFileToS3Mutation,
  useUpdateFileStatusMutation,
  useAddDeduplicateFileMutation,
  useUpdateDeduplicateFileMutation,
  useGetDeduplicatesForContractQuery,
  useGetZipCodesQuery,
  useGetZipCodeFileQuery,
  useDeleteZipCodesMutation,
} = DeduplicatesApi
