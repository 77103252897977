import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from '../getBaseQuery'
import { ContractSource, ContractType } from 'Types/index'

export interface ContractConfig {
  contract_source: string
  audience_type: string
  fields_to_export: string[]
  fields_available: string[]
  sections_available: string[]
  default_lookback_period: string
  allow_export_fields: boolean
}

interface GetConfigResponse {
  data: ContractConfig
}

const CONTRACT_CONFIG = 'CONTRACT_CONFIG'


export const ContractConfigAPI = createApi({
  reducerPath: 'contractConfig',
  baseQuery,
  tagTypes: [CONTRACT_CONFIG],
  endpoints: (build) => ({
    getContractConfig: build.query<
      ContractConfig,
      { contractSource: ContractSource; contractType: ContractType, audienceType: string }
    >({
      query: ({ contractSource, contractType, audienceType }) => ({
        url: `contracts/settings/${contractSource}/${contractType}/${audienceType}`,
      }),
      providesTags: [CONTRACT_CONFIG],
      transformResponse: (response: GetConfigResponse): ContractConfig => {
        return response?.data
      },
    }),
  }),
})

export const { useGetContractConfigQuery } = ContractConfigAPI
