import React from 'react'
import { styled } from '@mui/material/styles'

const Container = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '2px',
  marginTop: '14px',
  marginLeft: '10px',
}))

const Label = styled('div')(({ theme }) => ({
  fontSize: '0.6875rem',
  lineHeight: '14px',
  color: theme.palette.primary.dark,
}))

const Value = styled('div')(({ theme }) => ({
  fontSize: '0.875rem',
  lineHeight: '16px',
  color: theme.palette.secondary.main,
}))

interface SegmentDetailsSectionProps {
  label: string
  value: string | string[]
}

export const SegmentDetailsData = ({
  label,
  value,
}: SegmentDetailsSectionProps) => {
  return (
    <Container>
      <Label>{label}</Label>
      {!Array.isArray(value) && <Value>{value}</Value>}
      {Array.isArray(value) && <Value>{value.join(', ')}</Value>}
    </Container>
  )
}
