import React from 'react'
import { styled } from '@mui/material/styles'
import { TextButton } from 'Components/shared/buttons'
import { Loading } from 'Utils/loading'

const FlexRowContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '40px',
  width: '396px',
}))

const StyledStudents = styled('span')(({ theme }) => ({
  color: theme.palette.secondary['100'],
  fontSize: '1rem',
  lineHeight: '20px',
  marginRight: '13px',
}))

interface FilterHeaderProps {
  resetFilters: () => void
  getAvailableStudents: () => void
  availableStudents: number
  disableHeader: boolean
  isFetchingStudentsAvailable?: boolean
}

export const FilterHeader = ({
  resetFilters,
  getAvailableStudents,
  availableStudents,
  disableHeader,
  isFetchingStudentsAvailable,
}: FilterHeaderProps) => {
  return (
    <>
      {isFetchingStudentsAvailable && <Loading />}
      <FlexRowContainer>
        <div>
          <StyledStudents>
            {`${new Intl.NumberFormat().format(
              availableStudents
            )} Students available`}
          </StyledStudents>
          <TextButton
            onClick={getAvailableStudents}
            label={'Refresh'}
            disabled={disableHeader}
          />
        </div>
        <TextButton
          onClick={resetFilters}
          label={'Reset'}
          disabled={disableHeader}
        />
      </FlexRowContainer>
    </>
  )
}
