export enum LookBackPeriod {
  two_years = '2 Years',
  one_year = '1 Year',
  nine_months = '9 Months',
  six_months = '6 Months',
  thirty_days = '30 Days',
  sixty_days = '60 Days',
}

export namespace LookBackPeriod {
  export function toHumanName(days: number): string {
    if (days <= 60) {
      return `${days} Days`
    }
    if (days < 365) {
      const months = Math.floor(days / 30)
      return `${months} Months`
    }
    if (days == 365) {
      return '1 Year'
    }
    const years = Math.floor(days / 360) // why is this 360 and not 365?
    return `${years} Years`
  }

  export function toNumberOfDays(value: string): number {
    if (value.indexOf('Days') !== -1) {
      return parseInt(value.split(' ')[0])
    }
    if (value.indexOf('Months') !== -1) {
      return parseInt(value.split(' ')[0]) * 30
    }
    if (value == '1 Year') {
      return 365
    }
    if (value.indexOf('Years') !== -1) {
      return parseInt(value.split(' ')[0]) * 360
    }
    return 720
  }

  export function getHumanList(): string[] {
    return [
      LookBackPeriod.two_years,
      LookBackPeriod.one_year,
      LookBackPeriod.nine_months,
      LookBackPeriod.six_months,
      LookBackPeriod.thirty_days,
      LookBackPeriod.sixty_days,
    ]
  }
}
