import React, { useMemo, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import TableCell from '@mui/material/TableCell'
import { TablePagination } from 'Containers/shared/tablePagination'
import { TableFooter } from 'Components/shared/table'
import {
  Body,
  BodyTitle,
  BodyActions,
  BodyHeader,
  BodyContent,
  BodyModals,
} from 'Components/shared/body'
import { DefaultButton } from 'Components/shared/buttons'
import { Table, CustomTableCell } from 'Components/shared/table'
import { Loading } from 'Utils/loading'
import { usePagination } from 'Hooks/usePagination'
import { useGetActiveSegmentsQuery } from 'Services/admin/hipLeads'
import { AddSegmentContainer as AddSegment } from 'Containers/admin/hipLeads/AddSegment'
import { EditSegmentContainer as EditSegment } from 'Containers/admin/hipLeads/EditSegment'
import { SegmentActions } from './SegmentActions'
import { buildFilterQuery } from 'Filter/buildFilterQuery'
import { FilterBy } from 'Filter/properties'
import { ContractStatus } from 'Types/index'
import { Permissions } from 'Configs/userPermissions'
import { SearchComponent } from 'Components/connectedComponents/ui'
import { useHasPermission } from 'Hooks/useHasPermission'
import { useToggle } from 'Hooks/useToggle'

export const filterQuery = {
  query: {
    filterQuery: {
      properties: [
        {
          properties: [
            {
              column: 'contract_audience_type',
              op: 'eq',
              value: 'cappex',
            },
            {
              column: 'contract_status',
              op: FilterBy.IN,
              value: [ContractStatus.pending, ContractStatus.in_progress],
            },
            {
              column: 'segment_has_supplement',
              op: 'eq',
              value: true,
            },
            {
              column: 'contract_matching_source',
              op: 'eq',
              value: 'inquiries',
            },            
          ],
          operation: 'AND',
        },
      ],
      operation: 'AND',
    },
  },
}

export const ActiveSegments = () => {
  const { currentPage, handlePageChange, setCurrentPage } = usePagination()
  const [showEdit, setShowEdit] = useToggle(false)
  const [currentSegment, setCurrentSegment] = useState(null)
  const [sortOrder, setSortOrder] = useState('asc')
  const [sortBy, setSortBy] = useState('segment_supp_status')
  const [addSegmentDialog, setAddSegmentDialog] = useToggle(false)
  const [columnData, setColumnData] = useState([
    {
      columnName: 'College ID',
      columnWidth: 120,
    },
    {
      columnName: 'College Name',
      sortBy: 'institution_name',
      columnWidth: 400,
      sortOrder: sortOrder,
    },
    { columnName: 'Leads Requested', columnWidth: 90 },
    { columnName: 'Leads Matched', columnWidth: 90 },
    { columnName: 'Leads Needed', columnWidth: 90 },
    {
      columnName: 'Status',
      sortBy: 'segment_supp_status',
      columnWidth: 90,
      sortOrder: sortOrder,
    },
    { columnName: '', columnWidth: 70 },
  ])

  const {search: {value: searchString}} = useSelector(state => state)

  const encodedResult = buildFilterQuery(filterQuery)
  const {
    hasPermission: createSegmentSupplement,
    isFetching: isFetchingPermissions,
  } = useHasPermission(Permissions.CreateSegmentSupplement)

  const { data: recordsInfo, isFetching, refetch } = useGetActiveSegmentsQuery({
    page: currentPage,
    sortBy: sortBy,
    order: sortOrder,
    filter: filterQuery,
    searchString
  })

  const addSegment = useCallback((e: any) => {
    e.preventDefault()
    e.stopPropagation()
    setAddSegmentDialog()
  }, [])

  const flattenRecords = useMemo(() => {
    let rows = []
    if (recordsInfo) {
      recordsInfo.records?.forEach((record: any) => {
        rows.push([
          record.id,
          record.institution_id,
          record.institution_name,
          record.segment_supp_lead_total ?? 0,
          record.segment_supp_leads_delivered ?? 0,
          (record.segment_supp_lead_total ?? 0) -
            (record.segment_supp_leads_delivered ?? 0),
          record.segment_supp_status,
          createSegmentSupplement ? (
            <SegmentActions
              currentSegment={record}
              setShowEdit={setShowEdit}
              setCurrentSegment={setCurrentSegment}
            />
          ) : (
            ''
          ),
        ])
      })
    }
    return rows
  }, [recordsInfo])

  useEffect(
    () =>
      setColumnData(
        columnData.map((column) => {
          if (column.sortBy === sortBy) {
            return {
              ...column,
              sortOrder: sortOrder,
              sortBy: sortBy,
            }
          }
          return column
        })
      ),
    [sortBy, sortOrder]
  )

  useEffect(() => {
    setCurrentPage(0)
  }, [encodedResult])

  useEffect(() => {
    if(searchString && searchString.length > 2) {
      refetch()
    }
  }, [searchString])

  return (
    <Body>
      {(isFetching || isFetchingPermissions) && <Loading />}
      <BodyHeader>
        <BodyTitle>Active Segments</BodyTitle>
        <SearchComponent />
        {createSegmentSupplement && (
          <BodyActions>
            <DefaultButton onClick={addSegment}>Add Segment</DefaultButton>
          </BodyActions>
        )}
      </BodyHeader>

      <BodyContent>
        {recordsInfo?.total_records > 0 && (
          <>
            <Table
              columnData={columnData}
              rows={flattenRecords}
              setSortOrder={setSortOrder}
              setSortBy={setSortBy}
              tableOverflow={true}
              renderTableCell={(props) => {
                const row = props['row']
                const isActive = row.includes('active')
                const idx = props['idx']
                const opacity = isActive ? 1 : 0.5
                return (
                  <CustomTableCell
                    {...props}
                    cellStyle={{
                      overflow: idx === row.length - 1 ? 'visible' : 'hidden',
                      opacity: idx === row.length - 1 ? 1 : opacity,
                    }}
                  />
                )
              }}
            />
            <TableFooter>
              {recordsInfo.total_pages > 1 && (
                <TablePagination
                  rowsPerPage={10}
                  currentPage={currentPage}
                  handlePageChange={handlePageChange}
                  totalRecords={recordsInfo.total_records}
                  totalPages={recordsInfo.total_pages}
                />
              )}
            </TableFooter>
          </>
        )}
      </BodyContent>
      <BodyModals>
        {addSegmentDialog && (
          <AddSegment
            addSegmentDialog={addSegmentDialog}
            setAddSegmentDialog={setAddSegmentDialog}
            filterQuery={encodedResult}
          />
        )}
        {showEdit && (
          <EditSegment
            editSegmentDialog={showEdit}
            setEditSegmentDialog={setShowEdit}
            currentSegment={currentSegment}
          />
        )}
      </BodyModals>
    </Body>
  )
}
