import React, { useState, useEffect } from 'react'
import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'

const ActionButton = styled(Button)(({ theme }) => ({
  position: 'relative',
  marginRight: '50px',
  marginBottom: '12px',
  background: theme.palette.common.white,
  border: '1.5px solid #B1B9D1',
  borderRadius: '4px',
  fontWeight: theme.typography.fontWeightMedium,
  fontSize: '0.75rem',
  lineHeight: '15px',
  textAlign: 'center',
  padding: '7px 10px 7px 10px',
  color: theme.palette.primary.dark,
  width: '93px',
}))

const StyledList = styled(List)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightMedium,
  fontSize: '0.875rem',
  lineHeight: '21px',
  color: theme.palette.secondary.main,
  paddingTop: '0px',
  paddingBottom: '0px',
}))

const StyledPaper = styled(Paper)(({ theme }) => ({
  width: '224px',
  height: '529px',
  overflow: 'auto',
  border: `1.5px solid ${theme.palette.secondary['80']}`,
  borderRadius: '4px',
  boxShadow: 'none',
}))

const ListTitle = styled('div')(({ theme }) => ({
  fontWeight: theme.typography.fontWeightMedium,
  fontSize: '0.875rem',
  lineHeight: '21px',
  color: theme.palette.primary.dark,
  marginBottom: '7px',
}))

function not(a: readonly string[], b: readonly string[]) {
  return a.filter((value) => b.indexOf(value) === -1)
}

function intersection(a: readonly string[], b: readonly string[]) {
  return a.filter((value) => b.indexOf(value) !== -1)
}

interface TransferListProps {
  allItems: string[]
  exportedItems?: string[]
  exportItems: (selectedItems: string[]) => void
  disabled?: boolean
}

export const TransferList = ({
  allItems,
  exportedItems = [],
  exportItems,
  disabled = false,
}: TransferListProps) => {
  const [checked, setChecked] = useState<readonly string[]>([])
  const [left, setLeft] = useState<readonly string[]>(
    allItems?.filter((item: string) => !exportedItems?.includes(item))
  )
  const [right, setRight] = useState<readonly string[]>(exportedItems)

  const [leftChecked, setLeftChecked] = useState(intersection(checked, left))
  const [rightChecked, setRightChecked] = useState(intersection(checked, right))

  useEffect(() => {
    if (exportedItems?.length > 0) {
      setRight(exportedItems)
      setLeft(
        left?.filter((item: string) => !exportedItems?.includes(item)).sort()
      )
    }
  }, [exportedItems])

  useEffect(() => {
    setLeft(
      allItems?.filter((item: string) => !exportedItems?.includes(item)).sort()
    )
  }, [allItems])

  const handleToggle = (value: string) => () => {
    if (!disabled) {
      const currentIndex = checked.indexOf(value)
      const newChecked = [...checked]

      if (currentIndex === -1) {
        newChecked.push(value)
      } else {
        newChecked.splice(currentIndex, 1)
      }

      setChecked(newChecked)
      setLeftChecked(intersection(newChecked, left))
      setRightChecked(intersection(newChecked, right))
    }
  }

  const handleAllRight = () => {
    setRight(right.concat(left))
    exportItems(right.concat(left))
    setLeft([])
  }

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked))
    exportItems(right.concat(leftChecked))
    setLeft(not(left, leftChecked).sort())
    setChecked(not(checked, leftChecked))
    setLeftChecked([])
  }

  const handleCheckedLeft = () => {
    setLeft(left?.concat(rightChecked).sort())
    setRight(not(right, rightChecked))
    exportItems(not(right, rightChecked))
    setChecked(not(checked, rightChecked))
    setRightChecked([])
  }

  const handleAllLeft = () => {
    setLeft(left?.concat(right).sort())
    setRight([])
    exportItems([])
  }

  function handleMoveUp(array, values) {
    const newResult = [...array]
    let indexes = values.map((value) => newResult.indexOf(value))

    for (let i = 0; i < indexes.length; i++) {
      const index = indexes[i]
      if (index !== 0) {
        // swap items at index and index - 1
        ;[newResult[index], newResult[index - 1]] = [
          newResult[index - 1],
          newResult[index],
        ]
      }
    }
    setRight(newResult)
    exportItems(newResult)
    setChecked([])
  }

  function handleMoveDown(array, values) {
    const newResult = [...array]
    let indexes = values.map((value) => newResult.indexOf(value))

    for (let i = 0; i < indexes.length; i++) {
      const index = indexes[i]
      if (index !== array.length - 1) {
        // swap items at index and index + 1
        ;[newResult[index], newResult[index + 1]] = [
          newResult[index + 1],
          newResult[index],
        ]
      }
    }
    setRight(newResult)
    exportItems(newResult)
    setChecked([])
  }

  const customList = (items: readonly string[], checkedItems: string[]) => (
    <StyledPaper>
      <StyledList dense component='div' role='list'>
        {items?.map((value: string) => {
          const labelId = `transfer-list-item-${value}-label`
          return (
            <ListItem
              key={value}
              role='listitem'
              disabled={disabled}
              onClick={handleToggle(value)}
              style={{
                cursor: 'pointer',
                backgroundColor: checkedItems.includes(value)
                  ? 'hsl(0, 0%, 88%)'
                  : 'transparent',
                paddingBottom: '0px',
                paddingTop: '0px',
              }}
            >
              <ListItemText id={labelId} primary={`${value}`} />
            </ListItem>
          )
        })}
      </StyledList>
    </StyledPaper>
  )

  return (
    <Grid container spacing={2} justifyContent='center' alignItems='flex-start'>
      <Grid item style={{ paddingLeft: '0px' }}>
        <ListTitle>Fields Available</ListTitle>
        {customList(left, leftChecked)}
      </Grid>
      <Grid item style={{ width: '120px', marginTop: '29px' }}>
        <Grid container direction='column' alignItems='center'>
          <ActionButton
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0 || disabled}
            aria-label='move selected right'
          >
            Add
          </ActionButton>
          <ActionButton
            onClick={handleAllRight}
            disabled={left?.length === 0 || disabled}
            aria-label='move all right'
          >
            Add All
          </ActionButton>
        </Grid>
      </Grid>

      <Grid item style={{ paddingLeft: '0px' }}>
        <ListTitle>Fields to Export</ListTitle>
        {customList(right, rightChecked)}
      </Grid>
      <Grid item style={{ width: '120px', marginTop: '29px' }}>
        <Grid container direction='column' alignItems='flex-start'>
          <ActionButton
            onClick={() => handleMoveUp(right, rightChecked)}
            disabled={
              disabled ||
              right?.length === 0 ||
              rightChecked.length === 0 ||
              rightChecked.length > 1
            }
            aria-label='move up'
          >
            Move Up
          </ActionButton>
          <ActionButton
            onClick={() => handleMoveDown(right, rightChecked)}
            disabled={
              disabled ||
              right?.length === 0 ||
              rightChecked.length === 0 ||
              rightChecked.length > 1
            }
            aria-label='move all left'
          >
            Move Down
          </ActionButton>
          <ActionButton
            onClick={handleCheckedLeft}
            disabled={disabled || rightChecked.length === 0}
            aria-label='move selected left'
          >
            Remove
          </ActionButton>
          <ActionButton
            onClick={handleAllLeft}
            disabled={disabled || right?.length === 0}
            aria-label='move all left'
          >
            Remove All
          </ActionButton>
        </Grid>
      </Grid>
    </Grid>
  )
}
