import React, { useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { DeleteDestination as DeleteDestinationComponent } from 'Components/configuration/destinations/deleteDestination'
import { useDeleteDestinationMutation } from 'Services/configuration/destinations'
import { Loading } from 'Utils/loading'

interface DeleteDestinationProps {
  /** Destination id of destination to be deleted */
  destinationId: string
  /** Destination name of destination to be deleted */
  destinationName: string
  /** Boolean that determines whether to display delete destination dialog */
  deleteDestinationDialog: boolean
  /** Function to toggle showing delete destination dialog */
  setDeleteDestinationDialog: () => void
  /** Function to toggle showing manage destination dialog on delete */
  setManageDestinationDialog: () => void
}

export const DeleteDestination = ({
  destinationId,
  destinationName,
  deleteDestinationDialog,
  setDeleteDestinationDialog,
  setManageDestinationDialog,
}: DeleteDestinationProps) => {
  const { ipedId } = useParams()
  const [deleteDestination, { isLoading: isDeletingDestination }] =
    useDeleteDestinationMutation()

  const handleDeleteDestination = useCallback((e: any) => {
    e.preventDefault()
    deleteDestination({
      ipedId,
      destinationId: destinationId,
    })
    setDeleteDestinationDialog()
    setManageDestinationDialog()
  }, [])

  if (isDeletingDestination) return <Loading />

  return (
    <DeleteDestinationComponent
      destinationName={destinationName}
      deleteDestinationDialog={deleteDestinationDialog}
      setDeleteDestinationDialog={setDeleteDestinationDialog}
      handleDeleteDestination={handleDeleteDestination}
    />
  )
}
