import React, { useCallback, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import {
  Body,
  BodyTitle,
  BodyActions,
  BodyHeader,
  BodyContent,
  BodyModals,
} from 'Components/shared/body'
import { DefaultButton } from 'Components/shared/buttons'
import { SSHKeyManagement as SSHKeyManagementComponent } from 'Components/configuration/sshkeymanagement'
import { DeleteKey } from './deletekey'
import { CreateKey } from './createkey'
import { useGetSSHKeyPairsQuery } from 'Services/configuration/sshkeys'
import { Permissions } from 'Configs/userPermissions'
import { useHasPermission } from 'Hooks/useHasPermission'
import { Loading } from 'Utils/loading'
import { useToggle } from 'Hooks/useToggle'

const columnData = [
  { columnName: 'Key Pair Name', columnWidth: 200, sortOrder: 'ASC' },
  { columnName: 'Destinations Using', columnWidth: 300, sortOrder: 'ASC' },
  { columnName: '', columnWidth: 10, sortOrder: 'ASC' },
]

export const SSHKeyManagement = () => {
  const [createKeyDialog, setKeyDialog] = useToggle(false)
  const { ipedId } = useParams()
  const { hasPermission: canCreateKey } = useHasPermission(
    Permissions.CreateSshkey
  )
  const { hasPermission: canDeleteKey } = useHasPermission(
    Permissions.DeleteSshkey
  )

  const { data: sshKeysData, isLoading } = useGetSSHKeyPairsQuery(ipedId)

  const addKey = useCallback((e: any) => {
    e.preventDefault()
    e.stopPropagation()
    setKeyDialog()
  }, [])

  const flattenSSHKeys = useMemo(() => {
    let sshKeyNames = []
    let rows = []
    if (sshKeysData) {
      sshKeysData.data?.forEach((keyPair: any) => {
        sshKeyNames.push(keyPair.key_pair_name)
        rows.push([
          keyPair.id,
          keyPair.key_pair_name,
          keyPair.destinations.join(', '),
          keyPair.destinations.length > 0 ? (
            ''
          ) : canDeleteKey ? (
            <DeleteKey
              keyPairId={keyPair.id}
              keyPairName={keyPair.key_pair_name}
            />
          ) : (
            ''
          ),
        ])
      })
    }
    return { rows, sshKeyNames }
  }, [sshKeysData])

  if (isLoading) return <Loading />

  return (
    <Body>
      <BodyHeader>
        <BodyTitle>SSH Key Management</BodyTitle>
        <BodyActions>
          {canCreateKey && (
            <DefaultButton onClick={addKey}>Create New Key</DefaultButton>
          )}
        </BodyActions>
      </BodyHeader>
      <BodyContent>
        {sshKeysData?.data?.length > 0 && (
          <SSHKeyManagementComponent
            columnData={columnData}
            rows={flattenSSHKeys.rows}
          />
        )}
      </BodyContent>
      <BodyModals>
        {createKeyDialog && (
          <CreateKey
            setKeyDialog={setKeyDialog}
            createKeyDialog={createKeyDialog}
            sshKeysData={flattenSSHKeys.sshKeyNames}
          />
        )}
      </BodyModals>
    </Body>
  )
}
