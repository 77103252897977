import React, { useCallback, memo, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import { setLeadTotal, clearEditSegment } from './EditSegmentSlice'
import { EditSegment } from 'Components/admin/hipLeads/EditSegment'
import { useUpdateSegmentMutation } from 'Services/admin/hipLeads'
import { Loading } from 'Utils/loading'
import type { RootState } from 'Utils/store'

interface EditSegmentContainerProps {
  /** To show the edit segment dialog or not */
  editSegmentDialog: boolean
  /** To toggle the edit segment dialog visibility */
  setEditSegmentDialog: () => void
  /** Segment to be edited */
  currentSegment: any
}

export const EditSegmentContainer = memo(
  ({
    editSegmentDialog,
    setEditSegmentDialog,
    currentSegment,
  }: EditSegmentContainerProps) => {
    const dispatch = useDispatch()
    const {
      control,
      formState: { errors: formErrors, isValid },
    } = useForm({
      mode: 'onChange',
    })

    const leadTotal = useSelector(
      (state: RootState) => state.editLeadSegment.leadTotal
    )

    const [editSegment, { isLoading }] = useUpdateSegmentMutation()

    const setSegmentLeadTotal = useCallback((leadTotal: number) => {
      dispatch(setLeadTotal(leadTotal))
    }, [])

    const clearValues = useCallback(() => {
      dispatch(clearEditSegment())
    }, [])

    const editSegmentValues = useCallback(() => {
      closeDialog()
      editSegment({
        segmentId: currentSegment?.id,
        ipedId: currentSegment.institution_id,
        lead_total: Number(leadTotal),
      })
    }, [currentSegment, leadTotal])

    const closeDialog = useCallback(() => {
      setEditSegmentDialog()
      clearValues()
    }, [])

    //set all the destination values in redux
    useEffect(() => {
      if (currentSegment?.segment_supp_lead_total)
        dispatch(setLeadTotal(currentSegment?.segment_supp_lead_total))
    }, [])

    // const validValues = contract?.id && segment?.id
    const disableFormSubmit = !isValid || Object.keys(formErrors).length > 0

    return (
      <>
        {isLoading && <Loading />}
        <EditSegment
          editSegmentDialog={editSegmentDialog}
          setSegmentLeadTotal={setSegmentLeadTotal}
          leadTotal={leadTotal}
          editSegmentValues={editSegmentValues}
          closeDialog={closeDialog}
          disableSubmit={disableFormSubmit}
          control={control}
          currentSegment={currentSegment}
        />
      </>
    )
  }
)
