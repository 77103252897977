import React from 'react'
import { default as MUIDialog } from '@mui/material/Dialog'
import { styled, useTheme } from '@mui/material/styles'
import { alpha } from '@mui/material'

const CustomDialog = styled(MUIDialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    background: theme.palette.common.white,
    borderRadius: '8px',
    boxShadow: `0px 4px 20px ${alpha(theme.palette.common.black, 0.4)}`,
    padding: '32px',
    width: '90%',
    [theme.breakpoints.up('md')]: {
      padding: '56px 128px 40px 80px',
      /* this is required to override the default max-width of the dialog */
      maxWidth: '850px',
      width: '850px',
    },
  },
}))

const FlexContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}))

const Title = styled('div')(({ theme }) => ({
  color: theme.palette.secondary['100'],
  lineHeight: '30px',
  fontSize: '1.5rem',
  fontWeight: theme.typography.fontWeightBold,
  marginBottom: '32px',

  [theme.breakpoints.up('md')]: {
    marginBottom: '72px',
  },
}))

interface DialogProps {
  /** The content of the dialog */
  children: React.ReactNode
  /** Callback fired when the component requests to be closed. */
  onClose: () => void
  /** If true, the dialog is open. */
  open: boolean
  /** Dialog's title */
  title: string
}

export const Dialog = ({ children, onClose, open, title }: DialogProps) => {
  return (
    <CustomDialog onClose={onClose} open={open} aria-labelledby={title}>
      <FlexContainer>
        <Title id={title}>{title}</Title>
        {children}
      </FlexContainer>
    </CustomDialog>
  )
}
