import React, { memo } from 'react'
import { Slider } from 'Components/shared/slider'
import { VirtualizedMultiSelect } from 'Components/shared/multiselectdropdown'
import GPAConfig from 'Configs/fields/gpa.json'


interface GpaProps {
  gpaState: any
  dispatchAnyGpa: (selections) => void
  dispatchGpaRange: ({ min, max }) => void
}

const mapNullValues = (values) => values?.map((value) => {
  if (value === null) return GPAConfig.meta.nullLabel
  return value[0].toUpperCase() + value.slice(1)
})

export const Gpa = ({
  gpaState,
  dispatchAnyGpa,
  dispatchGpaRange,
}: GpaProps) => {
  const {
    selectedMinGpa,
    selectedMaxGpa,
    minGpa,
    maxGpa,
    step,
    ariaLabel,
    values,
    selectedValues,
    label,
  } = gpaState
  
  const valuesWithNull = mapNullValues(values)

  const selectedValuesWithNull = mapNullValues(selectedValues)

  return (
    <>
      <VirtualizedMultiSelect
              label={label}
              counterLabel={label}
              values={valuesWithNull}
              selectedValues={selectedValuesWithNull}
              initialSelectedValues={selectedValuesWithNull}
              setSelectedValues={(selections) => dispatchAnyGpa(selections)}
            />
      {selectedValues?.includes('Specific GPA Range') && (
        <div style={{ marginTop: '16px', marginLeft: '10px' }}>
          <Slider
            ariaLabel={ariaLabel}
            min={minGpa}
            max={maxGpa}
            selectedMin={selectedMinGpa}
            selectedMax={selectedMaxGpa}
            step={step}
            valueLabelFormat={(value: number) => typeof value === 'number' ? value?.toFixed(2) : value}
            handleChange={(range: number[]) =>
              dispatchGpaRange({ min: range[0], max: range[1] })
            }
          />
        </div>
      )}
    </>
  )
}
