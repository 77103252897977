import React from 'react'
import { styled } from '@mui/material/styles'

const ActionButtons = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '20px',
  [theme.breakpoints.up('md')]: {
    gap: '40px',
  },
}))

interface DialogActionButtonsProps {
  /** Dialog action buttons */
  children: React.ReactNode
}

export const DialogActionButtons = ({ children }: DialogActionButtonsProps) => {
  return <ActionButtons>{children}</ActionButtons>
}
