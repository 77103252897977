import React from 'react'
import { styled } from '@mui/material/styles'

interface MenuItemProps {
  /** Label of each menu item */
  label?: string
  /** Children of each menu item */
  children: React.ReactNode
}
const Container = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  rowGap: '6px',
}))

const Label = styled('div')(({ theme }) => ({
  color: theme.palette.primary.dark,
  fontSize: '0.875rem',
  fontWeight: theme.typography.fontWeightMedium,
  lineHeight: '19px',
}))

export const MenuItem = ({ label, children }: MenuItemProps) => {
  return (
    <Container>
      {label && <Label>{label}</Label>}
      {children}
    </Container>
  )
}
