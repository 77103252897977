import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { FilterHeader } from 'Components/shared/segmentfilters'
import { SmartCandidates } from 'Containers/shared/segmentfilters/ondemand/smartcandidates'
import { Demographics } from 'Containers/shared/segmentfilters/ondemand/demographics'
import { Verification } from 'Containers/shared/segmentfilters/ondemand/verification'
import { ProspectProfile } from 'Containers/shared/segmentfilters/ondemand/prospectprofile'
import { EditDuplicateOnDemand } from 'Containers/candidates/segment/EditDuplicateOnDemand'
import { buildFilterQuery } from 'Filter/buildFilterQuery'
import { Loading } from 'Utils/loading'
import { setupDefaultValues } from 'Utils/segmentFilterDefaultValues'
import { useSegmentService, useSegmentData } from 'Hooks/segment'
import { useToggle } from 'Hooks/useToggle'
import { ContractSource, ContractType, WebSocketMethods, SegmentAction } from 'Types/index'
import { SidebarActionButtons } from 'Components/shared/segmentfilters/SidebarActionButtons'
import { messageSent } from 'Slices/websocketSlice'
import type { RootState } from 'Utils/store'
import { useSocket } from 'Hooks/useSocket'
import { mapDetailsToFilter } from 'Containers/shared/segmentfilters/segmentFilterMapping'
import { useHasPermission } from 'Hooks/useHasPermission'
import { Permissions } from 'Configs/userPermissions'
import { selectGeography } from 'Containers/shared/segmentfilters/ondemand/demographics/GeographySlice'
import { useGetContractConfigQuery } from 'Services/global/contractConfig'
import { excludeKeys } from 'Containers/shared/segmentfilters/segmentUtils'
import { sk } from 'date-fns/locale'

interface OnDemandEditDuplicateFilterProps {
  /** Boolean to show/hide sidebar */
  showSidebar: boolean
  /** Logic for handling closing the sidebar */
  handleCloseSidebar: () => void
  /** Current contract */
  candidateContract?: any
  /** Segment data */
  segmentData?: any
  /** Segment id */
  segmentId?: string
  /** Segment action */
  segmentAction?: SegmentAction
  /** Boolean to check if segment is loading */
  isSegmentFetching: boolean
}

export const OnDemandEditDuplicateFilter = ({
  showSidebar,
  handleCloseSidebar,
  candidateContract,
  segmentData,
  segmentId,
  segmentAction,
  isSegmentFetching
}: OnDemandEditDuplicateFilterProps) => {
  const dispatch = useDispatch()
  const { ipedId } = useParams()
  const [resetKey, setResetKey] = useState(Date.now())
  const [filterData, setFilterData] = useState(null)
  const [fetchStudentsOnLoad, setFetchStudentsOnLoad] = useToggle(true)
  const { waitingForResponse: isFetchingStudentsAvailable, availableStudents } = useSelector(
    (state: RootState) => state.websocketStatus
  )
  const { sendMessage } = useSocket()
  const { createSegment, updateSegment, isUpdatingSegment } =
    useSegmentService()
  const geography = useSelector(selectGeography)
  const { hasPermission: canExploreSegments, myPermissions } = useHasPermission(
    Permissions.ViewEnhancedCandidatesExploreSegments
  )

  const {
    onDemandFilter,
    contract,
    noOfLeads,
    pricePerLead,
    segmentName,
    isSmartCandidates,
  } = useSegmentData()
  const {
    control,
    formState: { errors: formErrors },
    setError: setFormError,
    clearErrors: clearFormErrors,
  } = useForm({
    mode: 'onChange',
  })

  const { data: contractConfig } = useGetContractConfigQuery({
    contractSource: ContractSource.candidates,
    contractType: ContractType.on_demand,
    audienceType: contract?.audience_type,
  }, {skip: !contract?.audience_type})

  const getAvailableStudents = () => {
    const message = {
      method: WebSocketMethods.students_available_count_contract_id,
      data: {
        contract_id: contract?.id,
        is_smart_candidate: isSmartCandidates,
        institution_id: ipedId,
        contract_source: ContractSource.candidates,
        audience_type: contract?.audience_type,
        filter: buildFilterQuery(
          onDemandFilter,
          excludeKeys(ContractSource.candidates, contract?.audience_type),
          false
        )
      },
    }
    if (geography.zipFile.id) {
      message.data['zip_id'] = geography.zipFile.id
      message.data['zip_filter_exclusive'] = false
    }
    if(isSmartCandidates) {
      message.data['override_sources'] = ["candidates", "smart_candidates"]
    } else {
      message.data['override_sources'] = ["candidates"]
    }

    sendMessage(JSON.stringify(message))
    dispatch(messageSent())
  }

  const resetFilters = () => {
    dispatch({
      type: 'onDemandFilter/reset',
    })
    setResetKey(Date.now())
  }

  const duplicateSegment = () => {
    const filterQuery = buildFilterQuery(
      onDemandFilter,
      excludeKeys(ContractSource.candidates, contract?.audience_type),
      false
    )
    const segmentObj = {
      name: segmentName,
      ipedId,
      contract_id: contract.id,
      lead_total: Number(noOfLeads),
      price_per_lead: Number(pricePerLead),
      is_smart_candidates: isSmartCandidates,
      filter: filterQuery,
    }
    if (geography.zipFile.id) {
      segmentObj['zip_id'] = geography.zipFile.id
      segmentObj['zip_filter_exclusive'] = false
    }
    createSegment(segmentObj)
      .unwrap()
      .then(() => {
        handleCloseSidebar()
      })
  }

  const updateCurrentSegment = () => {
    const filterQuery = buildFilterQuery(onDemandFilter, [], false)
    const segmentObj = {
      name: segmentName,
      segmentId: segmentData?.id,
      ipedId,
      contract_id: contract.id,
      lead_total: Number(noOfLeads),
      price_per_lead: Number(pricePerLead),
      is_smart_candidates: isSmartCandidates,
      filter: filterQuery
    }
    if (geography.zipFile.id) {
      segmentObj['zip_id'] = geography.zipFile.id
      segmentObj['zip_filter_exclusive'] = false
    }
    updateSegment(segmentObj)
      .unwrap()
      .then(() => {
        handleCloseSidebar()
      })
  }

  /** Disable form submission if any reqired field is not filled
   * (or) there are any form errors */
  const disableFormSubmit = Object.keys(formErrors).length > 0

  useEffect(() => {
    if (segmentData && contract) {
      const result = setupDefaultValues(
        ContractSource.candidates,
        contract?.audience_type,
        false
      )

      /** Map the segment's filter object  */
      let data = mapDetailsToFilter(segmentData?.filter, result) as any
      data = Object.fromEntries(
        Object.entries(data).filter(
          ([key, value]) => value && Object.keys(value).length > 0
        )
      )

      setFilterData(data)
    }
  }, [segmentData, contract])

  useEffect(() => {
    /** Fetch students automatically ONLY on load. Otherwise, user has to explicity click refresh */
    if (
      onDemandFilter &&
      segmentData &&
      candidateContract &&
      fetchStudentsOnLoad &&
      !isSegmentFetching
    ) {
      setFetchStudentsOnLoad(false)
      getAvailableStudents()
    }
  }, [onDemandFilter, isSegmentFetching])

  const isLoading = isUpdatingSegment || isFetchingStudentsAvailable

  const studentsCount = availableStudents?.hasOwnProperty('total_count') ? availableStudents.total_count : availableStudents

  return (
    <>
      {isLoading && <Loading />}
      <div
        style={{
          width: '396px',
        }}
      >
        <EditDuplicateOnDemand
          control={control}
          setFormError={setFormError}
          clearFormErrors={clearFormErrors}
          showSidebar={showSidebar}
          candidateContract={candidateContract}
          segmentData={segmentData}
        />

        <FilterHeader
          availableStudents={studentsCount || 0}
          isFetchingStudentsAvailable={isFetchingStudentsAvailable}
          resetFilters={resetFilters}
          disableHeader={contract?.id ? false : true}
          getAvailableStudents={getAvailableStudents}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
            marginBottom: '100px',
          }}
        >
          {canExploreSegments &&
            contractConfig?.sections_available?.includes('SmartCandidates') && (
              <SmartCandidates chosenItem={contract} filterData={filterData} />
            )}

          <Demographics
            chosenItem={contract}
            filterData={filterData}
            segmentId={segmentId}
            control={control}
            resetKey={resetKey}
            zip_id={segmentData?.zip_id}
          />
          <ProspectProfile
            chosenItem={contract}
            filterData={filterData}
            segmentId={segmentId}
            resetKey={resetKey}
          />
          <Verification filterData={filterData} chosenItem={contract} />
        </div>
        <SidebarActionButtons
          handleSegmentAction={
            segmentAction === SegmentAction.edit
              ? updateCurrentSegment
              : duplicateSegment
          }
          handleCloseSidebar={handleCloseSidebar}
          disableFormSubmit={disableFormSubmit || isLoading}
          segmentAction={segmentAction}
        />
      </div>
    </>
  )
}
