import { createSelector } from '@reduxjs/toolkit'
import type { RootState } from 'Utils/store'
import { multiSelectSlice } from 'Slices/multiSelectSlice'
import ModalityConfig from 'Configs/fields/modality.json'
import { ContractSource } from 'Types/contractTypes'

const contractSource = ContractSource.candidates

const transformValues = function (selectedValues) {

  return selectedValues.map((value) => ModalityConfig.value_to_key[value.replaceAll(' ', '_')] === null ? '' : value)
}

export const ModalitySlice = multiSelectSlice(
  ModalityConfig.column,
  'setOnDemandModality',
  ModalityConfig.data,
  ModalityConfig.meta,
  transformValues
)

export const selectModality = createSelector(
  ({ onDemandFilter: { prospectProfile } }: RootState) =>
    prospectProfile.modality.selectedValues,
  (
    selectedValues: string[]
  ): {
    selectedValues: string[]
    values: string[]
    audienceTypes: string[]
    label: string
  } => {
    return {
      selectedValues,
      values: ModalityConfig.data,
      audienceTypes: ModalityConfig.source[contractSource].audience_types,
      label: ModalityConfig.meta.label,
    }
  }
)

export const { setOnDemandModality } = ModalitySlice.actions

export default ModalitySlice.reducer
