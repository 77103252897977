import React from 'react'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import { alpha } from '@mui/material'

const BodyActionBtnText = styled('span')(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: '0.875rem',
  fontWeight: theme.typography.fontWeightMedium,
  height: '20px',
  lineHeight: '20px',
  textAlign: 'center',
}))

const BodyActionButton = styled(Button)(({ theme }) => ({
  border: `1.8px solid ${theme.palette.primary.main}`,
  backgroundColor: theme.palette.common.white,
  minWidth: '140px',
  minHeight: '40px',
  padding: '10px 10px',
  '&:hover': {
    backgroundColor: `${alpha(theme.palette.primary.light, 0.2)}`,
  },
  '&:active': {
    position: 'relative',
    top: '1px',
    left: '1px',
  },
}))

interface DefaultButtonProps {
  /* The text to be displayed on the button */
  children: React.ReactNode
  /* The function to be called when the button is clicked */
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void
  /* Whether the button is disabled or not */
  disabled?: boolean
}

export const AddFileButton = ({
  children,
  onClick,
  disabled,
  ...props
}: DefaultButtonProps) => {
  return (
    <BodyActionButton
      onClick={onClick}
      onKeyDown={(e) => (e.key === 'Enter' || e.key === ' ') && onClick(e)}
      disabled={disabled}
      {...props}
    >
      <BodyActionBtnText>Upload File</BodyActionBtnText>
      {children}
    </BodyActionButton>
  )
}
