import React from 'react'
import { styled } from '@mui/material/styles'
import LoadingSvg from 'Assets/images/loading.svg'

const LoadingContainer = styled('div')(({ theme }) => ({
  position: 'fixed',
  top: 'calc(50% - 50px)',
  left: 'calc(50% - 20px)',
  zIndex: theme.zIndex.loading,
}))

export const Loading = () => {
  return (
    <LoadingContainer data-testid='loading-svg'>
      <LoadingSvg />
    </LoadingContainer>
  )
}
