import { createTheme } from '@mui/material/styles'

import palette from './palette'
import typography from './typography'
import zIndex from './zIndex'
import components from './components'

export const theme = createTheme({
  ...palette,
  ...typography,
  ...zIndex,
  ...components,
})
