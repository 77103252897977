import { combineReducers } from '@reduxjs/toolkit'

import GpaTestScoresReducer from './GpaTestScoresSlice'
import GpaReducer from './GpaSlice'
import TestScoresReducer from './TestScoresSlice'
import GraduationYearReducer from './GraduationYearSlice'
import IntendedAreaofStudyReducer from './IntendedAreaofStudySlice'
import LevelOfDegreeSeekingReducer from './LevelOfDegreeSeekingSlice'
import LastDegreeCompletedReducer from './LastDegreeCompletedSlice'
import CurrentlyEnrolledReducer from './CurrentlyEnrolledSlice'
import AreaOfInterestSlice from './AreaOfInterestSlice'
import AcademicDisciplineSlice from './AcademicDisciplineSlice'
import ExpectedTransferTermSlice from './ExpectedTransferTermSlice'
import ModalitySlice from './ModalitySlice'
import YearsOfWorkExperienceSlice from './YearsOfWorkExperienceSlice'
import StudyAbroadReducer from 'Slices/segments/StudyAbroad'
import ReligiousInstitutionReducer from 'Slices/segments/ReligiousInstitution'

export const prospectProfileReducer = combineReducers({
  gpaTestScores: GpaTestScoresReducer,
  gpa: GpaReducer,
  testScores: TestScoresReducer,
  graduationYear: GraduationYearReducer,
  areaOfInterest: AreaOfInterestSlice,
  academicDiscipline: AcademicDisciplineSlice,
  intendedAreaofStudy: IntendedAreaofStudyReducer,
  lastDegreeCompleted: LastDegreeCompletedReducer,
  levelOfDegreeSeeking: LevelOfDegreeSeekingReducer,
  currentlyEnrolled: CurrentlyEnrolledReducer,
  expectedTransferTerm: ExpectedTransferTermSlice,
  modality: ModalitySlice,
  experience: YearsOfWorkExperienceSlice,
  studyAbroad: StudyAbroadReducer,
  religiousInstitution: ReligiousInstitutionReducer
})
