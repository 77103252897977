import React from 'react'
import Drawer from '@mui/material/Drawer'
import { styled } from '@mui/material/styles'
import Slide from '@mui/material/Slide'
import { ArrowButton, SidebarHeaderWrapper } from './'
import { SegmentAction } from 'Types/index'

const StyledDrawer = styled(Drawer)(({ theme, open, width }) => ({
  '& .MuiDrawer-paper': {
    position: 'absolute',
    background: theme.palette.common.white,
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.4)',
    paddingLeft: '65px',
    paddingRight: '12px',
    paddingTop: '43px',
    width: open ? width : '60px',
    height: '100%',
  },
}))

const FlexContainerClosed = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '70px',
  position: 'absolute',
  left: '30%',
}))

const ClosedTitle = styled('div')(({ theme }) => ({
  color: theme.palette.secondary['100'],
  fontWeight: theme.typography.fontWeightMedium,
  fontSize: '0.625rem',
  lineHeight: '13px',
}))

const FlexContainerChildren = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}))

interface SidebarProps {
  /** sidebarTitle is the title for the sidebar */
  sidebarTitle: string[]
  /** sidebarWidth is the width of the fully open sidebar */
  sidebarWidth?: number
  /** showSidebar determines whether full sidebar is shown */
  showSidebar: boolean
  /** Toggle sidebar visibility */
  setShowSideBar: () => void
  /** Logic for handling closing the sidebar */
  handleCloseSidebar: () => void
  /** setCurrentTitle is the function that sets the current title */
  setCurrentTitle?: (title: SegmentAction) => void
  /**  Data that needs to be displayed in the open sidebar */
  children?: React.ReactNode
  /** header is the Header component type */
  header?: React.ReactElement
}

export const Sidebar = ({
  sidebarTitle,
  sidebarWidth = 524,
  header,
  children,
  showSidebar,
  setShowSideBar,
  handleCloseSidebar,
  setCurrentTitle = () => {},
}: SidebarProps) => {
  return (
    <StyledDrawer
      variant='permanent'
      anchor='left'
      open={showSidebar}
      width={sidebarWidth}
    >
      {!showSidebar && (
        <FlexContainerClosed open={showSidebar}>
          {sidebarTitle.map((title: SegmentAction, idx: number) => {
            return (
              <div key={idx}>
                <ArrowButton
                  showSidebar={showSidebar}
                  handleClick={() => {
                    setShowSideBar()
                    setCurrentTitle(title)
                  }}
                />
                <ClosedTitle>{title}</ClosedTitle>
              </div>
            )
          })}
        </FlexContainerClosed>
      )}
      {showSidebar && (
        <Slide direction='right' in={true} mountOnEnter unmountOnExit>
          <FlexContainerChildren>
            <SidebarHeaderWrapper
              showSidebar={showSidebar}
              handleCloseSidebar={handleCloseSidebar}
            >
              {header}
            </SidebarHeaderWrapper>
            {children}
          </FlexContainerChildren>
        </Slide>
      )}
    </StyledDrawer>
  )
}
