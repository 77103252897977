import React from 'react'

import { Sidebar as SidebarComponent } from 'Components/shared/sidebar'
import { SegmentAction } from 'Types/index'

interface SidebarProps {
  /** sidebarTitle is the title for the sidebar */
  sidebarTitle: string[]
  /** sidebarWidth is the width of the fully open sidebar */
  sidebarWidth?: number
  /**  Data that needs to be displayed in the open sidebar */
  children?: React.ReactNode
  /** header is the Header component type */
  header?: React.ReactElement
  /** showSidebar determines whether full sidebar is shown */
  showSidebar: boolean
  /** Toggle sidebar visibility */
  setShowSideBar: () => void
  /** Logic for handling closing the sidebar */
  handleCloseSidebar: () => void
  /** setCurrentTitle is the function that sets the current title */
  setCurrentTitle?: (title: SegmentAction) => void
}

export const Sidebar = ({
  sidebarTitle,
  sidebarWidth = 524,
  children,
  header,
  showSidebar,
  setShowSideBar,
  handleCloseSidebar,
  setCurrentTitle = () => {},
}: SidebarProps) => {
  return (
    <SidebarComponent
      sidebarTitle={sidebarTitle}
      sidebarWidth={sidebarWidth}
      header={header}
      showSidebar={showSidebar}
      setShowSideBar={setShowSideBar}
      handleCloseSidebar={handleCloseSidebar}
      setCurrentTitle={setCurrentTitle}
    >
      {children}
    </SidebarComponent>
  )
}
