import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit'
import type { RootState } from 'Utils/store'

interface SegmentQueueState {
  selectAll: boolean
  selectedSegments: {}
  selectedCheckboxIdxs: {}
}

const initialState: SegmentQueueState = {
  selectAll: false,
  selectedSegments: {},
  selectedCheckboxIdxs: {}
}

const SegmentQueueSlice = createSlice({
  name: 'segmentQueueSlice',
  initialState,
  reducers: {
    setSelectAll: (state, action: PayloadAction<boolean>) => {
      state.selectAll = action.payload
    },
    setSelectedSegments: (state, action: PayloadAction<{page: number, segments: any[]}>) => {
      const { page, segments } = action.payload
      if(!state.selectedSegments[page]) state.selectedSegments[page] = []
      
      state.selectedSegments[page] = segments
    },
    setSelectedCheckboxIdxs: (state, action: PayloadAction<{page: number, idxs: any[]}>) => {
      const { page, idxs } = action.payload
      if(!state.selectedCheckboxIdxs[page]) state.selectedCheckboxIdxs[page] = []

      state.selectedCheckboxIdxs[page] = idxs
    },
    clearSelected: () => {
      return initialState
    }
  }
})

export const selectSegmentQueue = createSelector(
  ( state : RootState) => state.segmentQueue.selectAll,
  ( state : RootState) => state.segmentQueue.selectedSegments,
  ( state : RootState) => state.segmentQueue.selectedCheckboxIdxs,
  (
    selectAll,
    selectedSegments,
    selectedCheckboxIdxs
  ): SegmentQueueState => {
    return {
      selectAll,
      selectedSegments,
      selectedCheckboxIdxs
    }
  }
)

export const {
  setSelectAll,
  setSelectedSegments,
  setSelectedCheckboxIdxs,
  clearSelected
} = SegmentQueueSlice.actions

export default SegmentQueueSlice.reducer