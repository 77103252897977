import React, { memo } from 'react'
import { default as MUISlider } from '@mui/material/Slider'
import { styled } from '@mui/material/styles'

interface SliderProps {
  /** sliderLabel is the label for the slider */
  sliderLabel?: string
  /** ariaLabel is the aria label for the slider */
  ariaLabel: string
  /** min is the minimum value for the slider */
  min: number
  /** max is the maximum value for the slider */
  max: number
  /** selectedMin is the minimum value selected in the slider */
  selectedMin: number
  /** selectedMax is the maximum value selected in the slider */
  selectedMax: number
  /** step is the step value for the slider */
  step: number
  /** handleChangae is the function to handle the change event of the slider */
  handleChange: (range: number[]) => void
  /** valueLabelFormat is the function to format the value label of the slider */
  valueLabelFormat?: (value: number) => string
}

const CustomSlider = styled(MUISlider)(({ theme }) => ({
  color: theme.palette.primary.main,
  height: '3px',
  width: '174px',
  borderRadius: '1px',
  // padding: '13px 0',

  '& .MuiSlider-thumb': {
    height: '20px',
    width: '20px',
    background: '#FFFFFF',
    border: '1.5px solid #D8DCE6',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
    '&:hover': {
      boxShadow: '0 0 0 8px rgba(58, 133, 137, 0.16)',
    },
  },
  '& .MuiSlider-track': {
    height: '3px',

    width: 'calc(100% - 20px)',
    alignSelf: 'center',
    position: 'relative',
  },
  '& .MuiSlider-rail': {
    color: theme.palette.grey[300],
    opacity: 1,
    height: 3,
  },
}))

const SliderLabel = styled('div')(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: theme.typography.fontWeightMedium,
  fontSize: '0.75rem',
  lineHeight: '15px',
  marginBottom: '11px',
}))

const RangeLabel = styled('div')(({ theme }) => ({
  color: theme.palette.primary.dark,
  fontWeight: theme.typography.fontWeightMedium,
  fontSize: '0.75rem',
  lineHeight: '15px',
  letterSpacing: '-0.05px',
  marginBottom: '6px',
}))

export const Slider = memo(
  ({
    sliderLabel,
    ariaLabel,
    min,
    max,
    selectedMin,
    selectedMax,
    step,
    handleChange,
    valueLabelFormat = (value: number) => value?.toString(),
  }: SliderProps) => {
    return (
      <div style={{ marginLeft: '10px' }}>
        {sliderLabel && <SliderLabel>{sliderLabel.toUpperCase()}</SliderLabel>}
        <RangeLabel>
          Min {valueLabelFormat(selectedMin)} - Max{' '}
          {valueLabelFormat(selectedMax)}
        </RangeLabel>
        <CustomSlider
          value={[selectedMin, selectedMax]}
          onChange={(_, newValue: number | number[]) =>
            handleChange(newValue as number[])
          }
          getAriaLabel={() => ariaLabel}
          min={min}
          max={max}
          step={step}
        />
      </div>
    )
  }
)
