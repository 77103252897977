import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface KeyPair {
  keyPairName: string
}

const initialState: KeyPair = { keyPairName: ''}

export const CreateKeySlice = createSlice({
  name: 'createSSHKeyPair',
  initialState,
  reducers: {
    setKeyPairName: (state, action: PayloadAction<string>) => {
      state.keyPairName = action.payload
    },
    clearKeyPair: (state) => {
      state.keyPairName = ''
    }
  }
})

export const { setKeyPairName, clearKeyPair } = CreateKeySlice.actions

export default CreateKeySlice.reducer