import { createApi } from '@reduxjs/toolkit/query/react'
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import { baseQuery } from '../getBaseQuery'

interface SSHKey {
  ipedId: string
  key_pair_name: string
}

export interface GetSSHKeyApiResponse {
  data: [
    {
      id: string
      key_pair_name: string
      ssh_credential_id: string
      destinations: string[]
    }
  ]
}

interface SSHKeyApiResponse {
  data: {
    id: string
    key_pair_name: string
    public_key: string
  }
}

interface GetSSHKeyDestination {
  data?: {
    data?: [label: string]
  }
}

const SSHKEYPAIRS = 'SSH_KEY_PAIRS'

export const SSHKeyApi = createApi({
  reducerPath: 'sshkeys',
  baseQuery,
  tagTypes: [SSHKEYPAIRS],
  endpoints: (build) => ({
    /** Gets all SHH Key pairs */
    getSSHKeyPairs: build.query<GetSSHKeyApiResponse, string>({
      queryFn: async (
        ipedId,
        _queryApi,
        _extraOptions,
        fetchWithBQ
      ): Promise<any> => {
        const getSSHKeyPairs = await fetchWithBQ({
          url: `institutions/${ipedId}/sshkeys`,
        })

        if (getSSHKeyPairs.error)
          return { error: getSSHKeyPairs.error as FetchBaseQueryError }

        const sshKeyPairs = getSSHKeyPairs.data as GetSSHKeyApiResponse

        for (const sshKeyPair of sshKeyPairs.data) {
          const destination: GetSSHKeyDestination = await fetchWithBQ({
            url: `/institutions/${ipedId}/sshkeys/${sshKeyPair.id}/destinations`,
          })
          let destinations = []
          destination.data?.data?.forEach((res: any) =>
            destinations.push(res.label)
          )
          sshKeyPair.destinations = destinations
        }
        return { data: sshKeyPairs }
      },
      providesTags: [SSHKEYPAIRS],
    }),

    /** Creates new SSH Key pair */
    createSSHKeyPair: build.mutation<SSHKeyApiResponse, SSHKey>({
      query: ({ ipedId, ...args }) => ({
        url: `institutions/${ipedId}/sshkeys`,
        method: 'POST',
        body: args,
      }),
      invalidatesTags: [SSHKEYPAIRS],
    }),

    /** Delete existing SSH Key Pair */
    deleteSSHKeyPair: build.mutation<
      boolean,
      { ipedId: string; keyId: string }
    >({
      query: ({ ipedId, keyId }) => ({
        url: `institutions/${ipedId}/sshkeys/${keyId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [SSHKEYPAIRS],
    }),
  }),
})

export const {
  useGetSSHKeyPairsQuery,
  useCreateSSHKeyPairMutation,
  useDeleteSSHKeyPairMutation,
} = SSHKeyApi
