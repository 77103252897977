import React, { memo, useState, useEffect } from 'react'
import Button from '@mui/material/Button'
import useMediaQuery from '@mui/material/useMediaQuery'
import { alpha } from '@mui/material'
import { styled, useTheme } from '@mui/material/styles'
import { useParams } from 'react-router-dom'

import { Dialog, DialogActionButtons, DialogField, DialogFieldContainer } from 'Components/shared/dialog'
import { Input } from 'Components/shared/input'
import { Loading } from 'Utils/loading'
import { buildFilterQuery } from 'Filter/buildFilterQuery'
import { contractLabelSearchQuery } from 'Services/contracts/contractsUtils'
import { useGetCandidatesContractsByIdQuery, useLazySearchContractByLabelQuery as useLazySearchCandidateContractByLabelQuery, useUpdateContractMutation as useUpdateCandidateContractMutation } from 'Services/contracts/candidatesContracts'
import { useGetInquiryContractByIdQuery, useLazySearchContractByLabelQuery, useUpdateContractMutation } from 'Services/contracts/inquiriesContracts'


const StyledButton = styled(Button)(({ theme }) => ({
  border: `1.8px solid ${theme.palette.primary.main}`,
  borderRadius: '4px',
  fontSize: '0.875rem',
  fontWeight: theme.typography.fontWeightMedium,
  height: '40px',
  lineHeight: '18px',
  minWidth: 'calc(100% - 40px)',
  [theme.breakpoints.up('md')]: {
    minWidth: 'auto',
  },
}))

const CancelButton = styled(StyledButton)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  color: theme.palette.primary.main,
  lineHeight: '20px',
  '&:hover': {
    backgroundColor: `${alpha(theme.palette.primary.light, 0.4)}`,
  },
  [theme.breakpoints.up('md')]: {
    width: '110px',
  },
}))

const AddButton = styled(StyledButton)(({ theme }) => ({
  color: theme.palette.common.white,
  backgroundColor: theme.palette.primary.main,
  border: 'none',
  boxShadow: `0px 4px 20px ${alpha(theme.palette.common.white, 0.4)}`,
  '&:hover': {
    backgroundColor: `${alpha(theme.palette.primary.main, 0.85)}`,
  },
  '&.Mui-disabled': {
    color: theme.palette.common.white,
    backgroundColor: 'hsla(216, 79%, 72%, 1)',
  },
  [theme.breakpoints.up('md')]: {
    width: '104px',
  },
}))

interface AddDestinationProps {
  closeDialog: () => void
  isOpen: boolean
}

export const ContractNameDialog = memo(
  ({
    closeDialog,
    isOpen
  }: AddDestinationProps) => {
    if (!isOpen) return null
    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.up('md'))
    const { contractId, ipedId, } = useParams()
    const isInquiries = window.location.pathname.includes('inquiries') || window.location.hash.includes('inquiries')
    const { 
      data: {source, contract_type, fields_to_export, lead_cap, salesforce_id, label: initialLabel, notes, start_date, end_date, audience_type, delivery_cadence, delivery_model, look_back_period, purchase_type}, isFetching 
    } = isInquiries ? useGetInquiryContractByIdQuery({ipedId, contractId}) : useGetCandidatesContractsByIdQuery({ipedId, contractId}, {skip: !contractId || contractId ==='new'})
    const [fetchContractRecords, { data: contractRecords }] = isInquiries ? useLazySearchContractByLabelQuery() :
    useLazySearchCandidateContractByLabelQuery()
    const [
        updateContract,
        { isLoading: isUpdatingContract, isSuccess: isContractUpdated },
      ] = isInquiries ? useUpdateContractMutation() : useUpdateCandidateContractMutation()
    const [contractName, setContractName] = useState(initialLabel)
    const handleUpdateContract = async () => {
      try {
        await updateContract({
            ipedId,
            contractId: contractId,
            contract: {
              source, contract_type, fields_to_export, lead_cap, salesforce_id, notes, start_date, end_date, audience_type, delivery_cadence, delivery_model, look_back_period, purchase_type,
              label: contractName,
            }
        })
        closeDialog()
      } catch (error) {
        console.error(error)
      }
    }
    useEffect(() => {
      if(contractName !== initialLabel) {
      fetchContractRecords({
        ipedId: ipedId,
        filterQuery: buildFilterQuery(contractLabelSearchQuery(contractName)),
      })
    }
    }, [contractName])
    if(isFetching) return <Loading />
    return (
      <Dialog
        onClose={closeDialog}
        open={isOpen}
        title='Rename Contract'
      >
        <DialogFieldContainer>
          <DialogField id='contract-name' label='Contract Name'>
            <Input
              id='contract-name'
              name='contractName'
              placeholder={initialLabel}
              defaultValue={initialLabel}
              style={matches ? { width: '421px' } : {}}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setContractName(e.target.value)
              }
            />
            {contractRecords?.data?.total_records > 0 &&  (
              <p style={{ color: 'red', fontSize: '12px' }}>
                Duplicate contract name. Please enter a unique name.
              </p>
            )}
          </DialogField>
        </DialogFieldContainer>
        <DialogActionButtons>
          <CancelButton onClick={closeDialog}>Cancel</CancelButton>
          <AddButton disabled={contractName === initialLabel || !contractName || isUpdatingContract || contractRecords?.data?.total_records > 0 } onClick={handleUpdateContract}>Save</AddButton>
        </DialogActionButtons>
      </Dialog>
    )
  }
)
