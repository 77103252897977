import { combineReducers, configureStore } from '@reduxjs/toolkit'
import type { PreloadedState } from '@reduxjs/toolkit'

/* Import the services */
import { AuthenticationApi } from 'Services/global/authentication'
import { InstitutionsApi } from 'Services/global/institution'
import { IntrospectionApi } from 'Services/global/introspection'
import { PermissionsAPI } from 'Services/global/permissions'
import { AudienceTypesAPI } from 'Services/global/audienceTypes'
import { ContractConfigAPI } from 'Services/global/contractConfig'
import { ZipCodesApi } from 'Services/configuration/zipCodes'
import { UsersApi } from 'Services/configuration/users'
import { RolesApi } from 'Services/configuration/roles'
import { FilesApi } from 'Services/files/files'
import { LeadFileStatusApi } from 'Services/admin/leadFileStatus'
import { HipLeadsApi } from 'Services/admin/hipLeads'
import { DestinationsApi } from 'Services/configuration/destinations'
import { CandidateContractsApi } from 'Services/contracts/candidatesContracts'
import { InquiriesContractsApi } from 'Services/contracts/inquiriesContracts'
import { SegmentsApi } from 'Services/segments/segments'
import { DeduplicatesApi } from 'Services/contracts/deduplicates'
import { SSHKeyApi } from 'Services/configuration/sshkeys'

/* Import the app redux slices */
import navReducer from 'Containers/header/NavSlice'
import UserInfoReducer from 'Containers/shared/login/UserInfoSlice'
import AddUserReducer from 'Containers/configuration/manageusers/adduser/AddUserSlice'
import AddDestinationReducer from 'Containers/configuration/destinations/addDestination/AddDestinationSlice'
import ManageDestinationReducer from 'Containers/configuration/destinations/ManageDestinationSlice'
import NewAnnualSegmentReducer from 'Containers/inquiries/segment/NewAnnualSegmentSlice'
import NewOnDemandSegmentReducer from 'Containers/candidates/segment/NewOnDemandSegmentSlice'
import SnackbarReducer from 'Containers/shared/snackbar/SnackbarSlice'
import CreateSSHKeyReducer from 'Containers/configuration/sshkeymanagement/createkey/CreateKeySlice'
import SegmentQueueReducer from 'Containers/admin/segmentqueue/SegmentQueueSlice'
import AddSegmentReducer from 'Containers/admin/hipLeads/AddSegmentSlice'
import EditSegmentReducer from 'Containers/admin/hipLeads/EditSegmentSlice'
import {
  ContractConfigurationReducer,
  ContractDeduplicatesReducer,
  ContractStateReducer,
} from 'Slices/contracts'
import WebsocketReducer from 'Slices/websocketSlice'
import SearchReducer from 'Slices/searchSlice'
import {
  timeBasedReducer,
  onDemandReducer,
} from 'Containers/shared/segmentfilters/'
import { inquiriesFilterReducer } from 'Containers/inquiries/files/filesfilter/'
import { candidatesFilterReducer } from 'Containers/candidates/files/filesfilter/'
/** Import middleware */
import { SnackbarMiddleware } from 'Middleware/SnackbarMiddleware'
import { UpdateContractChangesMiddleware } from 'Middleware/UpdateContractChangesMiddleware'
import { ForbiddenUnauthorizedMiddleware } from 'Middleware/ForbiddenUnauthorizedMiddleware'
import { SessionExpirationMiddleware } from 'Middleware/SessionExpirationMiddleware'
import { useDispatch } from 'react-redux'

/* Define the services to be used by the app */
const services = [
  AuthenticationApi,
  InstitutionsApi,
  PermissionsAPI,
  AudienceTypesAPI,
  ContractConfigAPI,
  IntrospectionApi,
  UsersApi,
  RolesApi,
  FilesApi,
  LeadFileStatusApi,
  ZipCodesApi,
  HipLeadsApi,
  DestinationsApi,
  InquiriesContractsApi,
  CandidateContractsApi,
  SegmentsApi,
  DeduplicatesApi,
  SSHKeyApi,
]

/* Define the App reducers */
const appReducers = {
  /* Header */
  navigation: navReducer,
  userInfo: UserInfoReducer,

  /* Configuration */
  addUser: AddUserReducer,
  addDestination: AddDestinationReducer,
  manageDestination: ManageDestinationReducer,
  createSSHKeyPair: CreateSSHKeyReducer,

  /* Segment filters */
  timeBasedFilter: timeBasedReducer,
  onDemandFilter: onDemandReducer,
  newAnnualSegment: NewAnnualSegmentReducer,
  newOnDemandSegment: NewOnDemandSegmentReducer,

  /* Files filters */
  inquiriesFilesFilter: inquiriesFilterReducer,
  candidatesFilesFilter: candidatesFilterReducer,

  /* Contracts */
  contractConfigurationSlice: ContractConfigurationReducer,
  contractDeduplicatesSlice: ContractDeduplicatesReducer,
  contractState: ContractStateReducer,

  /* Admin */
  segmentQueue: SegmentQueueReducer,
  addLeadSegment: AddSegmentReducer,
  editLeadSegment: EditSegmentReducer,

  /* Snackbar */
  snackbar: SnackbarReducer,

  websocketStatus: WebsocketReducer,
  search: SearchReducer,
}

const serviceReducers = services.reduce((acc, service) => {
  acc[service.reducerPath] = service.reducer
  return acc
}, {})

const serviceMiddleware = services.reduce((acc, service) => {
  acc.push(service.middleware)
  return acc
}, [])

const appReducer = combineReducers({
  ...serviceReducers,
  ...appReducers,
})

const authReducer = combineReducers({
  authentication: AuthenticationApi.reducer,
})

const authInstitutionReducer = combineReducers({
  authentication: AuthenticationApi.reducer,
  userInfo: UserInfoReducer,
})

export const rootReducer = (state, action) => {
  /** Reset all the redux state except authentication */
  if (action.type === 'resetExceptAuth') {
    appReducer(undefined, action)
    return authReducer(state, action)
  }
  /** Reset all the redux state except authentication and institution*/
  if (action.type === 'resetExceptAuthInstitution') {
    appReducer(undefined, action)
    return authInstitutionReducer(state, action)
  }
  return appReducer(state, action)
}

export const setupStore = (preloadedState?: PreloadedState<RootState>) =>
  configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      // adding the api middleware enables caching, invalidation, polling and other features of `rtk-query`
      [
        ...getDefaultMiddleware(),
        SnackbarMiddleware.middleware,
        UpdateContractChangesMiddleware.middleware,
        ForbiddenUnauthorizedMiddleware,
        SessionExpirationMiddleware.middleware,
        ...serviceMiddleware,
      ],
    preloadedState,
  })

export type RootState = any
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = AppStore['dispatch']
export const useAppDispatch = () => useDispatch<AppDispatch>()
