import { createSelector } from '@reduxjs/toolkit'
import type { RootState } from 'Utils/store'
import { multiSelectSlice } from 'Slices/multiSelectSlice'
import AreaOfStudyConfig from 'Configs/fields/area_of_study.json'
import { ContractSource } from 'Types/contractTypes'

const contractSource = ContractSource.inquiries

const transformValues = function (selectedValues) {
  return selectedValues.map((value) =>
    value === AreaOfStudyConfig.no_selection ? '' : value.split('-')[1].trim()
  )
}
export const IntendedAreaofStudySlice = multiSelectSlice(
  AreaOfStudyConfig.column,
  'setTimeBasedIntendedAreaofStudy',
  AreaOfStudyConfig.data,
  AreaOfStudyConfig.meta,
  transformValues
)

export const selectIntendedAreaofStudy = createSelector(
  ({ timeBasedFilter: { prospectProfile } }: RootState) =>
    prospectProfile.intendedAreaofStudy.selectedValues,
  (
    selectedValues: string[]
  ): {
    selectedValues: string[]
    values: string[]
    audienceTypes: string[]
    label: string
  } => {
    return {
      selectedValues,
      values: AreaOfStudyConfig.data,
      audienceTypes: AreaOfStudyConfig.source[contractSource].audience_types,
      label: AreaOfStudyConfig.meta.label,
    }
  }
)

export const { setTimeBasedIntendedAreaofStudy } =
  IntendedAreaofStudySlice.actions

export default IntendedAreaofStudySlice.reducer
