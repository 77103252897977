import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { ContractsContainer } from 'Containers/shared/contracts'
import { TimeBasedFilter } from 'Containers/shared/segmentfilters'
import { TimeBasedExploreSegmentsFilter } from 'Containers/shared/segmentfilters'
import { Sidebar } from 'Containers/shared/sidebar'
import { HeaderLabel } from 'Components/shared/sidebar'
import { useToggle } from 'Hooks/useToggle'

import {
  Body,
  BodyTitle,
  BodyActions,
  BodyHeader,
  BodySubHeader,
} from 'Components/shared/body'
import { clearNewAnnualSegment } from 'Containers/inquiries/segment/NewAnnualSegmentSlice'
import { DefaultButton } from 'Components/shared/buttons'
import { Permissions } from 'Configs/userPermissions'
import { useHasPermission } from 'Hooks/useHasPermission'
import { ContractSource, ContractStatus, SegmentAction } from 'Types/index'
import { useLazyGetSegmentQuery } from 'Services/segments/segments'
import { Loading } from 'Utils/loading'
import { useLazyGetInquiryContractByIdQuery } from 'Services/contracts/inquiriesContracts'
import { TimebasedDuplicateFilter } from 'Containers/shared/segmentfilters/TimeBasedDuplicateFilter'

export const Contracts = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { ipedId, segmentId } = useParams()
  const query = new URLSearchParams(useLocation().search)
  const segmentAction = query.get('action') as SegmentAction
  const [showSidebar, setShowSidebar] = useToggle(false)
  const [sidebarTitle, setSidebarTitle] = useState(SegmentAction.new)
  const { hasPermission: canAddSegment } = useHasPermission(
    Permissions.AddSegment
  )
  const { hasPermission: canCreateContract, isFetching } = useHasPermission(
    Permissions.CreateContract
  )

  const [fetchContract, { data: inquiryContract }] =
    useLazyGetInquiryContractByIdQuery()

  const [fetchSegment, { data: segmentData, isLoading: isSegmentLoading, isFetching: isSegmentFetching }] = useLazyGetSegmentQuery()

  const handleCloseSidebar = () => {
    dispatch({
      type: 'timeBasedFilter/reset',
    })
    dispatch(clearNewAnnualSegment())
    navigate(`/institution/${ipedId}/${ContractSource.inquiries}/contracts`)
    setShowSidebar()
  }

  /** If segmentId is set in the url or segment action changes,
   *  show the sidebar, change labels and fetch segment information
   */
  useEffect(() => {
    if (segmentId) {
      setShowSidebar()
      if (segmentAction === SegmentAction.edit) {
        setSidebarTitle(SegmentAction.edit)
      }
      if (segmentAction === SegmentAction.duplicate) {
        setSidebarTitle(SegmentAction.duplicate)
      }
      fetchSegment({
        ipedId,
        segmentId,
      })
    } else {
      setSidebarTitle(SegmentAction.new)
    }
  }, [segmentId, segmentAction])

  useEffect(() => {
    if (segmentData) {
      fetchContract({
        ipedId,
        contractId: segmentData?.['data'].contract_id,
      })
    }
  }, [segmentData])
  if (isFetching) return <Loading />
  return (
    <Body>
      <BodyHeader>
        <BodyTitle>Time-Based Contracts</BodyTitle>
        <BodyActions>
          {canCreateContract && (
            <DefaultButton
              onClick={() =>
                navigate(`/institution/${ipedId}/inquiries/contracts/new`)
              }
            >
              Add Contract
            </DefaultButton>
          )}
        </BodyActions>
      </BodyHeader>
      <ContractsContainer
        contractSource={ContractSource.inquiries}
        contractStatus={[ContractStatus.pending, ContractStatus.in_progress]}
      />
      <BodySubHeader>
        <BodyTitle>Expired Contracts</BodyTitle>
      </BodySubHeader>
      <ContractsContainer
        contractSource={ContractSource.inquiries}
        contractStatus={[ContractStatus.completed, ContractStatus.expired]}
      />
      <Sidebar
        sidebarTitle={
          canAddSegment
            ? [SegmentAction.new, SegmentAction.explore]
            : [SegmentAction.explore]
        }
        header={<HeaderLabel label={sidebarTitle} />}
        showSidebar={showSidebar}
        setShowSideBar={setShowSidebar}
        handleCloseSidebar={handleCloseSidebar}
        setCurrentTitle={setSidebarTitle}
      >
        {sidebarTitle === SegmentAction.new && (
          <TimeBasedFilter
            showSidebar={showSidebar}
            handleCloseSidebar={handleCloseSidebar}
          />
        )}
        {sidebarTitle === SegmentAction.duplicate && (
          <TimebasedDuplicateFilter
            showSidebar={showSidebar}
            handleCloseSidebar={handleCloseSidebar}
            segmentAction={segmentAction}
            inquiryContract={inquiryContract}
            segmentData={segmentData?.['data']}
            segmentId={segmentId}
            isSegmentFetching={isSegmentFetching}
          />
        )}
        {sidebarTitle === SegmentAction.explore && (
          <TimeBasedExploreSegmentsFilter />
        )}
      </Sidebar>
    </Body>
  )
}
