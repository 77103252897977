import React from 'react'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'

const StyledText = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: '0.75rem',
  fontWeight: theme.typography.fontWeightMedium,
  lineHeight: '14px',
  padding: '0px',
  minWidth: '31px',
  '&:active': {
    position: 'relative',
    top: '1px',
    left: '1px',
  },
}))

interface TextButtonProps {
  onClick: () => void
  label: string
  disabled?: boolean
}

export const TextButton = ({ onClick, label, disabled }: TextButtonProps) => {
  return (
    <StyledText disabled={disabled} onClick={onClick}>
      {label}
    </StyledText>
  )
}
