import React from 'react'

interface BodyModalsProps {
  /** This will include body modals */
  children: React.ReactNode
}

export const BodyModals = ({ children }: BodyModalsProps) => {
  return <React.Fragment>{children}</React.Fragment>
}
