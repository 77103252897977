import React from 'react'
import { styled } from '@mui/material/styles'

const Row = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '32px',
  maxWidth: '736px'
}))

const CustomLabel = styled('label')(({ theme, alignSelf }) => ({
  flex: '1',
  color: theme.palette.primary.dark,
  fontSize: '0.875rem',
  fontWeight: theme.typography.fontWeightMedium,
  lineHeight: '21px',
  alignSelf: alignSelf,
  textAlign: 'right',
}))

const ElementWrapper = styled('div')(() => ({
  display: 'flex',
  flex: '3',
  justifyContent: 'flex-end',
  maxWidth: '500px'
}))

export const DeduplicatesField = ({ id, label, children}) => {
  return (
    <Row>
      <CustomLabel htmlFor={id}>
        {label}
      </CustomLabel>
      <ElementWrapper>{children}</ElementWrapper>
    </Row>
  )
}