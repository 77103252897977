import React from 'react'
import { styled } from '@mui/material/styles'

const FlexContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  marginLeft: '80px',
}))

interface ContractFieldProps {
  children: React.ReactNode
}
export const ContractConfigurationFieldContainer = ({
  children,
}: ContractFieldProps) => {
  return <FlexContainer>{children}</FlexContainer>
}
