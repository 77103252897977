import { createSlice } from '@reduxjs/toolkit'
import { FilterBy } from 'Filter/properties'
import { ContractSource } from 'Types/index'

const initialState: any = {
  filterQuery: {
    column: 'source',
    op: FilterBy.EQ,
    value: ContractSource.candidates,
  },
}

export const CandidatesContractSlice = createSlice({
  name: 'candidatesContract',
  initialState,
  reducers: {
    setCandidatesContract: () => {
      return initialState
    },
  },
})

export const { setCandidatesContract } = CandidatesContractSlice.actions

export default CandidatesContractSlice.reducer
