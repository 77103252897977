import { createSelector } from '@reduxjs/toolkit'
import type { RootState } from 'Utils/store'
import { multiSelectSlice } from 'Slices/multiSelectSlice'
import AcademicDiscipline from 'Configs/fields/academic_discipline.json'
import { ContractSource } from 'Types/contractTypes'

const contractSource = ContractSource.candidates

const transformValues = function (selectedValues) {
  return selectedValues.map((value) => {
    const isHyphenated = value.indexOf('-') > -1
    return value === AcademicDiscipline.no_selection ? '' : isHyphenated ? value.split('-')[1]?.trim() : value
  }
  )
}

export const AcademicDisciplineSlice = multiSelectSlice(
  AcademicDiscipline.column,
  'setOnDemandAcademicDiscipline',
  AcademicDiscipline.data,
  AcademicDiscipline.meta,
  transformValues
)

export const selectAcademicDiscipline = createSelector(
  ({ onDemandFilter: { prospectProfile } }: RootState) =>
    prospectProfile.academicDiscipline.selectedValues,
  (
    selectedValues: string[]
  ): {
    selectedValues: string[]
    values: string[]
    audienceTypes: string[]
    label: string
  } => {
    return {
      selectedValues: selectedValues.map((value) => AcademicDiscipline.data.find(numberedValue => numberedValue.includes(value))),
      values: AcademicDiscipline.data,
      audienceTypes: AcademicDiscipline.source[contractSource].audience_types,
      label: AcademicDiscipline.meta.label,
    }
  }
)

export const { setOnDemandAcademicDiscipline } =
  AcademicDisciplineSlice.actions

export default AcademicDisciplineSlice.reducer
