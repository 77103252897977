import { CombineBy } from './properties'

function getFilterQuery(properties: any, excludeKeys: string[]): any[] {
  let filterQueries = []

  function isObject(value) {
    const type = typeof value
    return value != null && (type === 'object' || type === 'function')
  }

  for (const [key, value] of Object.entries(properties)) {
    if (isObject(value) && !excludeKeys.includes(key)) {
      if (value.hasOwnProperty('filterQuery') && value['filterQuery']) {
        filterQueries.push(value['filterQuery'])
      }
      filterQueries.push(...getFilterQuery(value, excludeKeys))
    }
  }

  return filterQueries
}

export const combineFilters = (properties, excludeKeys) => {
  return {
    properties: getFilterQuery(properties, excludeKeys),
    operation: CombineBy.AND,
  }
}

export function buildFilterQuery(
  properties: any,
  excludeKeys = [],
  encode = true
): string | object {
  const filterObject = combineFilters(properties, excludeKeys)
  return encode ? btoa(JSON.stringify(filterObject)) : filterObject
}
