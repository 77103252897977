import { createSlice } from '@reduxjs/toolkit'
import { FilterBy } from 'Filter/properties'
import { ContractSource } from 'Types/index'

const initialState: any = {
  filterQuery: {
    column: 'source',
    op: FilterBy.EQ,
    value: ContractSource.inquiries,
  },
}

export const InquiriesContractSlice = createSlice({
  name: 'inquiriesContract',
  initialState,
  reducers: {
    setInquiriesContract: () => {
      return initialState
    },
  },
})

export const { setInquiriesContract } = InquiriesContractSlice.actions

export default InquiriesContractSlice.reducer
