import React from 'react'
import { styled } from '@mui/material/styles'

const Header = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '40px',
  marginTop: '80px',
  width: '100%',
}))

interface BodySubHeaderProps {
  /** This will include body title, action buttons, etc.. */
  children: React.ReactNode
}

export const BodySubHeader = ({ children }: BodySubHeaderProps) => {
  return <Header>{children}</Header>
}
