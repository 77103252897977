import React from 'react'
import { styled } from '@mui/material/styles'

const Title = styled('span')(({ theme }) => ({
  color: theme.palette.secondary['100'],
  fontSize: '1.5rem',
  fontWeight: theme.typography.fontWeightBold,
  lineHeight: '30px',
}))

interface BodyTitleProps {
  /** Title of the body and their meta data */
  children: React.ReactNode
}

export const BodyTitle = ({ children }: BodyTitleProps) => {
  return <Title>{children}</Title>
}
