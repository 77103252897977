import GPATestScoresConfig from 'Configs/fields/gpa_test_scores.json'
import GPAConfig from 'Configs/fields/gpa.json'
import TestScoresConfig from 'Configs/fields/act.json'
import AgeConfig from 'Configs/fields/age.json'
import AreaOfInterest from 'Configs/fields/area_of_interest.json'
import AreaOfStudy from 'Configs/fields/area_of_study.json'
import CurrentlyEnrolledConfig from 'Configs/fields/currently_enrolled.json'
import ExpectedTransferTerm from 'Configs/fields/expected_transfer_term.json'
import GraduationyearConfig from 'Configs/fields/graduation_year.json'
import LastDegreeCompletedConfig from 'Configs/fields/last_degree_completed.json'
import ExperienceConfig from 'Configs/fields/years_of_work_experience.json'
import LevelOfDegreeSeekingConfig from 'Configs/fields/level_of_degree_seeking.json'
import GenderConfig from 'Configs/fields/gender.json'
import GreenlightConfig from 'Configs/fields/greenlight.json'
import MajorConfig from 'Configs/fields/majors.json'
import ModalityConfig from 'Configs/fields/modality.json'
import { AudienceTypeHelper, ContractSource } from 'Types/index'

function excludeKeyIfAudienceTypeNotIncluded(
  contractSource: any,
  config: any,
  audienceType: any,
  keyToExclude: string,
  keysToExclude: string[]
) {
  if (
    !AudienceTypeHelper.getAudienceTypes(contractSource, config).includes(
      audienceType
    )
  ) {
    keysToExclude.push(keyToExclude)
  }
}
export function excludeKeys(contractSource: ContractSource, audienceType) {
  const keysToExclude = []

  if (
    AudienceTypeHelper.getAudienceTypes(
      contractSource,
      GPATestScoresConfig
    ).includes(audienceType)
  ) {
    keysToExclude.push('gpa', 'testScores')
  } else {
    const isGpaAudienceType = AudienceTypeHelper.getAudienceTypes(
      contractSource,
      GPAConfig
    ).includes(audienceType)
    const isTestScoresAudienceType = AudienceTypeHelper.getAudienceTypes(
      contractSource,
      TestScoresConfig
    ).includes(audienceType)
    keysToExclude.push('gpaTestScores')
    if (!isGpaAudienceType) keysToExclude.push('gpa')
    if (!isTestScoresAudienceType) keysToExclude.push('testScores')
  }

  excludeKeyIfAudienceTypeNotIncluded(
    contractSource,
    AreaOfInterest,
    audienceType,
    'areaOfInterest',
    keysToExclude
  )
  excludeKeyIfAudienceTypeNotIncluded(
    contractSource,
    AreaOfStudy,
    audienceType,
    'intendedAreaofStudy',
    keysToExclude
  )
  excludeKeyIfAudienceTypeNotIncluded(
    contractSource,
    CurrentlyEnrolledConfig,
    audienceType,
    'currentlyEnrolled',
    keysToExclude
  )
  excludeKeyIfAudienceTypeNotIncluded(
    contractSource,
    ExpectedTransferTerm,
    audienceType,
    'expectedTransferTerm',
    keysToExclude
  )
  excludeKeyIfAudienceTypeNotIncluded(
    contractSource,
    AgeConfig,
    audienceType,
    'age',
    keysToExclude
  )
  excludeKeyIfAudienceTypeNotIncluded(
    contractSource,
    GenderConfig,
    audienceType,
    'gender',
    keysToExclude
  )
  excludeKeyIfAudienceTypeNotIncluded(
    contractSource,
    GraduationyearConfig,
    audienceType,
    'graduationYear',
    keysToExclude
  )
  excludeKeyIfAudienceTypeNotIncluded(
    contractSource,
    GreenlightConfig,
    audienceType,
    'greenlight',
    keysToExclude
  )
  excludeKeyIfAudienceTypeNotIncluded(
    contractSource,
    LastDegreeCompletedConfig,
    audienceType,
    'lastDegreeCompleted',
    keysToExclude
  )
  excludeKeyIfAudienceTypeNotIncluded(
    contractSource,
    LevelOfDegreeSeekingConfig,
    audienceType,
    'levelOfDegreeSeeking',
    keysToExclude
  )
  excludeKeyIfAudienceTypeNotIncluded(
    contractSource,
    MajorConfig,
    audienceType,
    'intendedMajors',
    keysToExclude
  )
  excludeKeyIfAudienceTypeNotIncluded(
    contractSource,
    ModalityConfig,
    audienceType,
    'modality',
    keysToExclude
  )
  excludeKeyIfAudienceTypeNotIncluded(
    contractSource,
    ExperienceConfig,
    audienceType,
    'experience',
    keysToExclude
  )

  return [...new Set(keysToExclude)]
}
