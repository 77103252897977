
const qs = require('qs')
export interface Segment {
  id: string
  created_at: string
  institution_id: string
  name: string
  contract_id: string
  filter: string
  lead_total: string
  is_active: boolean
  price_per_lead: string
}

interface GetSegmentApiResponse {
  data: Array<Segment>
  message: string
}

interface GetSegmentsApiResponse {
  data: Array<Segment>
}

interface SegmentQueue {
  id: string
  institution_id: string
  name: string
  contract_id: string
  filter: string
  lead_total: string
  is_active: boolean
  contract_information: {
    id: string
    label: string
    audience_type: string
  }
  institution_information: {
    name: string
  }
}

interface GetSegmentQueueApiResponse {
  data: {
    records: Array<SegmentQueue>
    total_pages: number
    total_records: number
  }
}

export interface GetSegmentQueueTransformed {
  records: Array<SegmentQueue>
  total_pages: number
  total_records: number
}

export const transformSegmentQueueResponse = (
  response: GetSegmentQueueApiResponse
) => {
  const segments: GetSegmentQueueTransformed = {
    total_pages: response.data.total_pages,
    total_records: response.data.total_records,
    records: response.data.records,
  }
  return segments
}

/** Get all segments in queue */

export const getSegmentQueue = ({ page, sortBy = 'institution_name', order, searchString }) => {
  const params = {}
  if (searchString) {
    params['filter'] = JSON.stringify({
      operation: 'OR',
      properties: [
        {
          column: 'contract_label',
          op: 'like',
          value: `%${searchString}%`,
        },
        {
          column: 'name',
          op: 'like',
          value: `%${searchString}%`,
        },
        {
          column: 'contract_purchase_type',
          op: 'like',
          value: `%${searchString}%`,
        },
        {
          column: 'institution_name',
          op: 'like',
          value: `%${searchString}%`,
        },
      ],
    })
  }
  return {
  url: `pending-segments/v2?page=${page}&sort_by=${sortBy}::${order}`,
  params,
}
}


/** Get all segments for a contract */

export const transformSegmentQueryResponse = (
  response: GetSegmentsApiResponse
): Array<Segment> => {
  return response.data
}

export const getSegmentsForContract = ({ ipedId, contractId }) => ({
  url: `institutions/${ipedId}/contracts/${contractId}/segments`,
})

export const getSegment = ({ ipedId, segmentId }) => ({
  url: `institutions/${ipedId}/segments/${segmentId}`,
})

/* Deactivate a segment */
export const deactivateSegment = ({ ipedId, segmentId, isActive }) => ({
  url: `institutions/${ipedId}/segments/${segmentId}`,
  body: { is_active: isActive },
  method: 'PATCH',
})

/** Create a new segment */
export const createSegment = ({ ipedId, ...args }) => ({
  url: `institutions/${ipedId}/segments`,
  body: args,
  method: 'POST',
})

/** Update a segment */
export const updateSegment = ({ ipedId, segmentId, ...args }) => ({
  url: `institutions/${ipedId}/segments/${segmentId}`,
  body: args,
  method: 'PUT',
})

/** Deletes a segment */

export const deleteSegment = (args) => ({
  url: `segments?${qs.stringify(args)}`,
  method: 'DELETE',
})

/** Process segment order */

export const processSegment = (args) => ({
  url: `pending-segments/_process`,
  body: args,
  method: 'POST',
})
