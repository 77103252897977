import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit'
import type { RootState } from 'Utils/store'

interface NavState {
  mainNavIdx: number
  subNavIdx: number
}

const initialState: NavState = { mainNavIdx: 0, subNavIdx: 0 }

export const NavSlice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    setNavigation: (_, action: PayloadAction<NavState>) => {
      return action.payload
    },
  },
})

export const selectNavigation = createSelector(
  (state: RootState) => state.navigation.mainNavIdx,
  (state: RootState) => state.navigation.subNavIdx,
  (mainNavIdx, subNavIdx) => ({ mainNavIdx, subNavIdx })
)

export const { setNavigation } = NavSlice.actions

export default NavSlice.reducer
