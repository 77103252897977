export default {
  typography: {
    allVariants: {
      fontFamily: 'CircularStd',
    },
    button: {
      textTransform: 'none',
      lineHeight: 'normal',
    },
  },
}
