import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { FilterBy, CombineBy, properties } from 'Filter/properties'
import { yearRangeToDateRange } from 'Utils/dateUtils'

interface SliderState {
  /* filterQuery is the query that is used to filter the data in the backend */
  filterQuery: any
  /* selectedMin is the selected minimium value in the slider */
  selectedMin: number
  /* selectedMax is the selected maximum value in the slider */
  selectedMax: number
  selections?: string[]
}

type NullFilterType = {
  column: string
  op: FilterBy
  value_macro: string
  value?: any
  meta: {section: string, label: string}
}

type SliderPayload = { min?: number; max?: number; selections?: string[]}

const deriveSelectionsAndRange = (range, previousSelections, meta) => {
  const rangeObject = {min: undefined, max: undefined}
  if (Array.isArray(range)) {
    if((range.includes('Any') || range.includes('any')) && (!previousSelections.includes('Any') && !previousSelections.includes('any'))) {
      return {selections: ['Any']}
    } else {
      for (let i = 0; i < range.length; i++) {
        if (Array.isArray(range[i])) {
          const minFirst = range[i][0] < range[i][1]
          rangeObject.min = minFirst ? range[i][0] : range[i][1];
          rangeObject.max = minFirst ? range[i][1] : range[i][0];
          const selections = [...range]
          selections[i] = meta?.rangeLabel || 'Specific Range'
          return {selections, rangeObject}
        }
      }
      if (range.includes(null) || range.includes(meta?.nullLabel)  || range.includes(meta?.rangeLabel) || range.length === 0) {
        return {selections: range.filter(value => value !== 'Any' && value !== 'any')}
      } else {
        const minFirst = range[0] < range[1]
        rangeObject.min = minFirst ? range[0] : range[1]
        rangeObject.max = minFirst ? range[1] : range[0];
        return {rangeObject, selections: [meta?.rangeLabel || 'Specific Range']}
      }
    }
  }
  return {selections: [typeof range === 'string' ? range : 'Any']}
}

export const buildSliderProperty = (
  column,
  range: Array<any>,
  meta: { section: string; label: string; selectedValue?: string, nullLabel?: string, rangeLabel?: string, nullValue?: any},
  selections: Array<string> = null,
) => {
  const getColumnValue = () => {
    switch (column) {
      case 'birth_date':
        return [-range[1], -range[0]]
      case 'high_school_grad_date':
        return yearRangeToDateRange(range[0], range[1])
      default:
        return range
    }
  }
  const rangeFilter = {
    column,
    op: FilterBy.RANGE,
    value: getColumnValue(),
    value_macro: column === 'birth_date' ? 'ADD_YR_CUR_DATE' : 'NO_OP',
    meta: {section: meta.section, label: meta.label},
  }
  if(selections && selections.length) {
    if(selections.includes('Any') || selections.includes('any')) return null
    if(selections.includes(meta?.nullLabel || 'Null')) {
      const nullFilter:NullFilterType = {column, op: meta?.nullValue ? FilterBy.EQ : FilterBy.NULL, meta: rangeFilter.meta, value_macro: 'NO_OP'}
      if(meta?.nullValue) nullFilter.value = meta.nullValue
      if(selections.includes(meta?.rangeLabel || 'Specific Range')) {
        return properties([rangeFilter, nullFilter], CombineBy.OR)
      }
      return nullFilter 
    }
  }
  return rangeFilter
}

export const sliderSlice = (
  column: string,
  action: string,
  range: any[],
  meta: { section: string; label: string },
  selections: Array<string> = null
) => {
  return createSlice({
    name: column,
    initialState: {
      filterQuery: '',
      selectedMin: range[0],
      selectedMax: range[1],
      selections
    } as SliderState,
    reducers: {
      [action]: (
        state,
        action: PayloadAction<SliderPayload>
      ) => {
        state.selectedMin = action.payload.min
        state.selectedMax = action.payload.max
        state.filterQuery = buildSliderProperty(
          column,
          [action.payload.min, action.payload.max],
          meta,
          state.selections
        )
      },
      [`${action}Selection`]: (
        state,
        action: PayloadAction<SliderPayload>
      ) => {
        const {selections, rangeObject} = deriveSelectionsAndRange(action.payload.selections, state.selections, meta)
        let minAndMax = [state.selectedMin, state.selectedMax]
        const minAndMaxObject = {}
        if(rangeObject) {
          minAndMax = [rangeObject.min, rangeObject.max]
          minAndMaxObject['selectedMin'] = rangeObject.min
          minAndMaxObject['selectedMax'] = rangeObject.max
        }
        return {...state, ...minAndMaxObject, selections, filterQuery: buildSliderProperty(
          column,
          minAndMax,
          meta,
          action.payload.selections
        )}
      }
    },
  })
}
