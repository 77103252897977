import React from 'react'
import { styled } from '@mui/material/styles'

const ContractContainer = styled('div')(({ theme }) => ({
  background: theme.palette.common.white,
  borderRadius: '8px',
  boxShadow: '0px 2px 4px 0px #0000001A',
  marginBottom: '48px',
  padding: '44px 0px',
  paddingLeft: '56px',
  width: '1074px',
  [theme.breakpoints.up('md')]: {
    display: 'block',
    width: '100%',
  },
}))

interface ContractSectionProps {
  /**  Contract content that needs to be displayed in the body */
  children: React.ReactNode
}

export const ContractSection = ({ children }: ContractSectionProps) => {
  return <ContractContainer>{children}</ContractContainer>
}
