import { GPAAudienceTypes as InquiriesGPAAudienceTypes } from 'Containers/shared/segmentfilters/timebased/prospectprofile/GpaSlice'
import { GPATestScoresAudienceTypes as InquiriesGPATestScoresAudienceTypes } from 'Containers/shared/segmentfilters/timebased/prospectprofile/GpaTestScoresSlice'
import { GPAAudienceTypes as CandidatesGPAAudienceTypes } from 'Containers/shared/segmentfilters/ondemand/prospectprofile/GpaSlice'
import { CurrentlyEnrolledAudienceTypes as InquiriesCurrentlyEnrolledAudienceTypes } from 'Containers/shared/segmentfilters/timebased/prospectprofile/CurrentlyEnrolledSlice'
import { CurrentlyEnrolledAudienceTypes as CandidatesCurrentlyEnrolledAudienceTypes } from 'Containers/shared/segmentfilters/ondemand/prospectprofile/CurrentlyEnrolledSlice'
import { ContractSource } from 'Types/index'
import { getLabel } from 'Containers/shared/segmentfilters/timebased/prospectprofile/GpaSlice'

export function setupDefaultValues(
  contractSource,
  audienceType,
  sectionLabel = true
) {
  let result = {
    'Enhanced Candidates': {},
    Demographic: {},
    'Academic Profile': {},
    Score: {},
    Engagement: {},
    Verification: {},
  }
  function setDefaultValueForLabel(section, label, value = 'Any') {
    sectionLabel ? (result[section][label] = value) : (result[label] = value)
  }

  /** Inquiries Contracts */
  if (contractSource === ContractSource.inquiries) {
    if (InquiriesGPAAudienceTypes.includes(audienceType)) {
      setDefaultValueForLabel('Score', getLabel(audienceType))
    }
    if (InquiriesGPATestScoresAudienceTypes.includes(audienceType)) {
      setDefaultValueForLabel('Score', 'SAT')
      setDefaultValueForLabel('Score', 'ACT')
      setDefaultValueForLabel('Score', getLabel(audienceType))
    }

    if (InquiriesCurrentlyEnrolledAudienceTypes.includes(audienceType)) {
      setDefaultValueForLabel('Engagement', 'Currently Enrolled')
    }
  }

  /** Candidates Contracts */
  if (contractSource === ContractSource.candidates) {
    setDefaultValueForLabel('Enhanced Candidates', 'Enhanced Candidates', 'No')

    if (CandidatesGPAAudienceTypes.includes(audienceType)) {
      setDefaultValueForLabel('Score', getLabel(audienceType))
    }
    if (CandidatesCurrentlyEnrolledAudienceTypes.includes(audienceType)) {
      setDefaultValueForLabel('Engagement', 'Currently Enrolled')
    }
  }

  /** Both Inquiries and Candidates contracts */
  setDefaultValueForLabel('Verification', 'Address Verified')
  setDefaultValueForLabel('Verification', 'Name Cleanliness Required')
  setDefaultValueForLabel('Verification', 'Email Cleanliness Required')

  return result
}
