import { createApi } from '@reduxjs/toolkit/query/react'
import type { FetchBaseQueryError, BaseQueryFn, FetchArgs } from '@reduxjs/toolkit/query'
import type { InstitutionAPIResponse } from './institution'
import type { Role } from 'Services/configuration/roles'
import type { Permission } from 'Services/global/permissions'
import { baseQuery } from '../getBaseQuery'

export interface LoginStatusAPIResponse {
  data: {
    is_logged_in: boolean
    user_info: {
      id: string
      first_name: string
      last_name: string
      email: string
      roles: Array<Role>
      permissions: Array<Permission>
      iped_id: string
    }
  }
}

export interface LoginStatusResponse {
  wsToken: string
  isLoggedIn: boolean
  firstName: string
  lastName: string
  email: string
  roles: Array<string>
  permissions: Array<string>
  ipedId: string
  institutionName: string
  institutionState: string
  institutionStateName: string
  institutionCountry: string
  id: string
}

type ErrorDataResponse = {data: {message: string}}

/** This tag is set in loginStatus query and it's used to invalidate the cache
 * in logout mutation */
const AUTHENTICATION = 'AUTHENTICATION'

export const AuthenticationApi = createApi({
  reducerPath: 'authentication',
  baseQuery: <BaseQueryFn<string | FetchArgs, unknown, ErrorDataResponse, {}>>(
    baseQuery
  ),
  tagTypes: [AUTHENTICATION],
  endpoints: (build) => ({
    /** Get the login status */
    loginStatus: build.query<LoginStatusResponse, void>({
      queryFn: async (
        _arg,
        _queryApi,
        _extraOptions,
        fetchWithBQ
      ): Promise<any> => {
        const loginStatus = await fetchWithBQ('users/auth/_login/status')

        if (loginStatus.error)
          return { error: loginStatus.error as FetchBaseQueryError }

        const user = loginStatus.data as LoginStatusAPIResponse
        let institution: InstitutionAPIResponse
        let wsToken: string

        if (user.data.user_info?.iped_id) {
          const institutionsResult = await fetchWithBQ(
            `institutions/${user.data.user_info?.iped_id}`
          )
          if (institutionsResult.error)
            return {
              error: institutionsResult.error as FetchBaseQueryError,
            }

          institution = institutionsResult.data as InstitutionAPIResponse
        }

        const token = await fetchWithBQ({
          url: 'users/auth/_generate_ws_token',
          method: 'POST',
        })

        if ((token as any)?.data?.error) {
          return { error: (token as any)?.data?.error as FetchBaseQueryError }
        }

        wsToken = (token as any)?.data?.data as string

        const loginStatusResponse: LoginStatusResponse = {
          wsToken: wsToken,
          isLoggedIn: user.data.is_logged_in,
          firstName: user.data.user_info?.first_name,
          lastName: user.data.user_info?.last_name,
          email: user.data.user_info?.email,
          roles: user.data.user_info?.roles?.map((role) => role.name),
          permissions: user.data.user_info?.permissions?.map((permission) => permission.name),
          ipedId: user.data.user_info?.iped_id,
          institutionName: institution?.data?.name,
          institutionState: institution?.data?.state,
          institutionStateName: institution?.data?.state_name,
          institutionCountry: institution?.data?.country,
          id: user.data.user_info?.id,
        }

        return { data: loginStatusResponse }
      },
      providesTags: [AUTHENTICATION],
    }),

    /** Logout */
    logOut: build.mutation<void, void>({
      query() {
        return {
          url: 'users/auth/_logout',
          method: 'POST',
        }
      },
      invalidatesTags: [AUTHENTICATION],
    }),

    logIn: build.mutation<void, { email: string; password: string }>({
      queryFn: async (
        { email, password },
        _queryApi,
        _extraOptions,
        fetchWithBQ
      ): Promise<any> => {
        const loginStatus = await fetchWithBQ({
          url: 'users/auth/cappex/_login',
          method: 'POST',
          body: JSON.stringify({ email, password }),
          headers: {
            'Content-Type': 'application/json',
          },
        })

        if (loginStatus.error)
          return { error: loginStatus.error as FetchBaseQueryError }

        return { data: loginStatus.data }
      },
      invalidatesTags: [AUTHENTICATION],
    }),

    forgotPassword: build.mutation<void, string>({
      query(email: string) {
        return {
          url: 'users/auth/cappex/_forgot_password',
          method: 'POST',
          body: JSON.stringify({ email }),
          headers: {
            'Content-Type': 'application/json',
          },
        }
      },
      invalidatesTags: [AUTHENTICATION],
    }),

    generatePasscode: build.mutation<{data: string}, string>({
      query(email: string) {
        return {
          url: 'users/auth/magic-link',
          method: 'POST',
          body: JSON.stringify({ email }),
          headers: {
            'Content-Type': 'application/json',
          },
        }
      },
    }),

    passcodeLogIn: build.mutation<void, {token: string, token_id: string}>({
      query({token, token_id}) {
        return {
          url: 'users/auth/one-time-code/_verify',
          method: 'POST',
          body: JSON.stringify({ token, token_session_id: token_id }),
          headers: {
            'Content-Type': 'application/json',
          },
        }
      },
      invalidatesTags: [AUTHENTICATION],
    }),
  }),
})

export const {
  useLoginStatusQuery,
  useLogOutMutation,
  useLogInMutation,
  useForgotPasswordMutation,
  useGeneratePasscodeMutation,
  usePasscodeLogInMutation,
} = AuthenticationApi
