export default {
  components: {
    MuiTextField: {
      variants: [
        {
          props: { disabled: true },
          style: {
            background: 'hsla(223, 64%, 96%, 1)',
          },
        },
      ],
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 400,
          '&.Mui-disabled': {
            background: 'hsla(223, 64%, 96%, 1)',
          },
        },
      },
    },
  },
}
