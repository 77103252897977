import { createApi } from '@reduxjs/toolkit/query/react'
import type { Role } from 'Services/configuration/roles'
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import { baseQuery } from '../getBaseQuery'

export interface User {
  id: string
  first_name: string
  last_name: string
  email: string
  roles: Role[] | String[]
  iped_id: string
  sso_enabled: boolean
  autogen_password: boolean
}

export interface GetUsers {
  data: User[]
}

interface AddUserAPIResponse {
  data: User
}

/** This tag is set in getUsers query and it's used to invalidate the cache
    in the mutations */
const USERS = 'USERS'

export const UsersApi = createApi({
  reducerPath: 'users',
  baseQuery,
  tagTypes: [USERS],
  endpoints: (build) => ({
    /** Get the users for an institution */
    getUsers: build.query<User[], string>({
      query: (ipedId: string) => ({
        url: `institutions/${ipedId}/users`,
      }),
      providesTags: [USERS],
      transformResponse: (response: GetUsers): User[] => {
        var users: User[] = []

        for (const user of response.data) {
          users.push({
            ...user,
            roles: user.roles?.map((role) => role.name),
          })
        }

        return users
      },
    }),

    /** Add new user */
    addUser: build.mutation<User, Partial<User>>({
      queryFn: async (
        _arg,
        _queryApi,
        _extraOptions,
        fetchWithBQ
      ): Promise<any> => {
        const addUser = await fetchWithBQ({
          url: 'users',
          method: 'POST',
          body: _arg,
        })

        if (addUser.error)
          return { error: addUser.error as FetchBaseQueryError }

        const user = addUser.data as AddUserAPIResponse

        const role = _arg.roles[0]
        const addRole = await fetchWithBQ({
          url: `users/${user.data.id}/roles`,
          method: 'PUT',
          body: [{ role_name: role }],
        })

        if (addRole.error)
          return { error: addUser.error as FetchBaseQueryError }

        return { data: { ...user.data, roles: [role] } }
      },
      invalidatesTags: [USERS],
    }),

    /** Delete existing user */
    deleteUser: build.mutation<boolean, string>({
      queryFn: async (
        _arg,
        _queryApi,
        _extraOptions,
        fetchWithBQ
      ): Promise<any> => {
        const deleteUser = await fetchWithBQ({
          url: `users/${_arg}`,
          method: 'DELETE',
          body: _arg,
        })

        if (deleteUser.error) return false

        const deleteRoles = await fetchWithBQ({
          url: `users/${_arg}/roles`,
          method: 'DELETE',
        })

        if (deleteRoles.error) return false

        return true
      },
      invalidatesTags: [USERS],
    }),
  }),
})

export const { useGetUsersQuery, useAddUserMutation, useDeleteUserMutation } =
  UsersApi
