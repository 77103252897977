import { combineReducers } from '@reduxjs/toolkit'

import GeographyReducer from './GeographySlice'
import AgeReducer from './AgeSlice'
import GenderReducer from './GenderSlice'

export const demographicsReducer = combineReducers({
  geography: GeographyReducer,
  age: AgeReducer,
  gender: GenderReducer,
})
