import { createListenerMiddleware, isAnyOf } from '@reduxjs/toolkit'
import { setContractConfiguration } from 'Slices/contracts/ContractConfigurationSlice'
import { setContractState } from 'Slices/contracts/ContractStateSlice'

import { DeduplicatesApi } from 'Services/contracts/deduplicates'

export const UpdateContractChangesMiddleware = createListenerMiddleware()

UpdateContractChangesMiddleware.startListening({
  /** These reducers are invoked when a client changes the state in inquiries update contract */
  matcher: isAnyOf(setContractConfiguration),
  effect: (action, listenerApi) => {
    if (action.payload) {
      if (!('isClean' in action.payload) || action.payload.isClean === false) {
        listenerApi.dispatch(setContractState({ disableUpdate: false }))
      }
    }
  },
})

UpdateContractChangesMiddleware.startListening({
  matcher: DeduplicatesApi.endpoints.getUploadFileData.matchFulfilled,
  effect: (action, listenerApi) => {
    if (action.payload) {
      listenerApi.dispatch(setContractState({ disableupdate: false }))
    }
  },
})
