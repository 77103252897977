import { createSelector, AnyAction, Dispatch,  } from '@reduxjs/toolkit'
import type { RootState } from 'Utils/store'
import { selectDropdownSlice } from 'Slices/selectDropdownSlice'
import NameVerifiedConfig from 'Configs/fields/name_verified.json'

export const NameCleanlinessSlice = selectDropdownSlice(
  NameVerifiedConfig.column,
  NameVerifiedConfig.values,
  'setOnDemandNameCleanliness',
  NameVerifiedConfig.meta
)

export const selectNameCleanliness = createSelector(
  ({ onDemandFilter: { verification } }: RootState) =>
    verification['nameCleanliness'].selectedValue,
  (
    selectedValue: string
  ): { selectedValue: string; values: string[]; label } => {
    return { selectedValue, values: NameVerifiedConfig.values, label: NameVerifiedConfig.meta.label }
  }
)

export const { setOnDemandNameCleanliness } = NameCleanlinessSlice.actions

export const setOnDemandNameCleanlinessDefaultValue = (
  dispatch: Dispatch<AnyAction>,
  audienceType: string
) => {
  if (!audienceType) return
  if (NameVerifiedConfig.default_selected[audienceType]) {
    dispatch(
      setOnDemandNameCleanliness(NameVerifiedConfig.default_selected[audienceType])
    )
  }
}

export default NameCleanlinessSlice.reducer
