import React from 'react'
import { styled } from '@mui/material/styles'

const Label = styled('div')(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontSize: '1.5rem',
  fontWeight: theme.typography.fontWeightBold,
  lineHeight: '30px',
}))

interface HeaderLabelProps {
  /** label is the text that needs to be displayed in the header */
  label?: string
}

export const HeaderLabel = ({ label = 'New Segment' }: HeaderLabelProps) => {
  return <Label>{label}</Label>
}
