import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit'
import type { RootState } from 'Utils/store'
import {
  AuthenticationApi,
  LoginStatusResponse,
} from 'Services/global/authentication'

const initialState = {
  wsToken: '',
  loginStatus: false,
  ipedId: '',
  institutionName: '',
  institutionStateName: '',
  institutionCountry: '',
  institutionState: '',
}

export interface PayloadObject {
  /** Websocket token */
  wsToken: string
  /** Login status of the user */
  loginStatus: boolean
  /** Current institution's iped id */
  ipedId: string
  /** Current institution's institution name */
  institutionName: string
  /** Name of the state in which current institution is located */
  institutionStateName: string
  /** Country in which current institution is located */
  institutionCountry: string
  /** Two letter state code of the state in which current institution
   * is located */
  institutionState: string
}

export const UserInfoSlice = createSlice({
  name: 'userInfo',
  initialState,
  reducers: {
    setInstitution: (_, action: PayloadAction<PayloadObject>) => {
      return action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      AuthenticationApi.endpoints.loginStatus.matchFulfilled,
      (
        state: PayloadObject,
        { payload }: PayloadAction<LoginStatusResponse>
      ) => {
        state.wsToken = payload.wsToken
        state.loginStatus = payload.isLoggedIn
        state.ipedId = payload.ipedId
        state.institutionName = payload.institutionName
        state.institutionCountry = payload.institutionCountry
        state.institutionState = payload.institutionState
        state.institutionStateName = payload.institutionStateName
      }
    )
  },
})

export const selectUserInfo = createSelector(
  (state: RootState) => state.userInfo.wsToken,
  (state: RootState) => state.userInfo.loginStatus,
  (state: RootState) => state.userInfo.ipedId,
  (state: RootState) => state.userInfo.institutionName,
  (state: RootState) => state.userInfo.institutionCountry,
  (state: RootState) => state.userInfo.institutionState,
  (state: RootState) => state.userInfo.institutionStateName,
  (
    wsToken,
    loginStatus,
    ipedId,
    institutionName,
    institutionCountry,
    institutionState,
    institutionStateName
  ): PayloadObject => ({
    wsToken,
    loginStatus,
    ipedId,
    institutionName,
    institutionCountry,
    institutionState,
    institutionStateName,
  })
)

export const { setInstitution } = UserInfoSlice.actions

export default UserInfoSlice.reducer
