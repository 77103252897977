import React, { memo } from 'react'
import { styled } from '@mui/material/styles'
import Radio, { RadioProps } from '@mui/material/Radio'
import { default as MUIRadioGroup } from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import { CombineBy } from 'Filter/properties'

interface FilterRadiogroupProps {
  ariaLabel: string
  currentValue: string | CombineBy
  handleChange: (value: string | CombineBy) => void
  values: Array<string | CombineBy>
}
const StyledRadioGroup = styled(MUIRadioGroup)(({ theme, checked }) => ({
  '& .MuiRadio-root': {
    padding: 0,
  },
  '& .MuiFormControlLabel-root': {
    marginRight: '24px',
  },
  '& .MuiFormControlLabel-label': {
    color: checked ? theme.palette.primary.main : theme.palette.primary.dark,
    fontSize: '0.75rem',
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: '16px',
  },
}))
const UncheckedIcon = styled('span')(({ theme }) => ({
  borderRadius: '50%',
  padding: 0,
  marginRight: '8px',
  marginLeft: '8px',
  width: '18px',
  height: '18px',
  border: '1.5px solid #D8DCE6',
}))

const CheckedIcon = styled(UncheckedIcon)({
  backgroundColor: 'white',
  padding: 0,
  marginRight: '8px',
  marginLeft: '8px',
  width: '18px',
  height: '18px',

  '&:before': {
    content: '""',
    display: 'block',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '8px',
    height: '8px',
    borderRadius: '50%',
    backgroundColor: '#085FE5',
  },
})

export const Radiogroup = memo(
  ({
    ariaLabel,
    currentValue,
    handleChange,
    values,
  }: FilterRadiogroupProps) => {
    const StyledRadio = (props: RadioProps) => {
      return (
        <Radio
          icon={<UncheckedIcon />}
          checkedIcon={<CheckedIcon />}
          {...props}
        />
      )
    }

    return (
      <FormControl>
        <StyledRadioGroup
          row
          aria-label={ariaLabel}
          name={ariaLabel}
          value={currentValue}
          onChange={(e) => handleChange(e.target.value)}
        >
          {values.map((value: string | CombineBy, idx: number) => (
            <FormControlLabel
              key={idx}
              value={value}
              control={<StyledRadio />}
              label={value}
              checked={currentValue === value}
            />
          ))}
        </StyledRadioGroup>
      </FormControl>
    )
  }
)
