import React, { useMemo, useEffect, useState } from 'react'
import { TablePagination } from 'Containers/shared/tablePagination'
import { TableFooter } from 'Components/shared/table'
import {
  Body,
  BodyTitle,
  BodyHeader,
  BodyContent,
} from 'Components/shared/body'
import { Table } from 'Components/shared/table'
import { Loading } from 'Utils/loading'
import { usePagination } from 'Hooks/usePagination'
import { useGetLeadFileStatusQuery } from 'Services/admin/leadFileStatus'
import { buildFilterQuery } from 'Filter/buildFilterQuery'
import moment from 'moment'

export const filterQuery = {
  query: {
    filterQuery: {
      properties: [
        {
          properties: [
            {
              column: 'segment_delivery_created_at',
              op: 'gt',
              value: moment()
                .startOf('day')
                .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
            },
            {
              column: 'segment_delivery_created_at',
              op: 'lt',
              value: moment().endOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
            },
          ],
          operation: 'AND',
        },
      ],
      operation: 'AND',
    },
  },
}

export const LeadFileStatus = () => {
  const { currentPage, handlePageChange } = usePagination()
  const [sortOrder, setSortOrder] = useState('asc')
  const [sortBy, setSortBy] = useState('college_name')
  const [columnData, setColumnData] = useState([
    {
      columnName: 'College ID',
      columnWidth: 120,
    },
    {
      columnName: 'College Name',
      sortBy: 'college_name',
      columnWidth: 250,
      sortOrder: sortOrder,
    },
    { columnName: 'Contract Related', columnWidth: 300 },
    { columnName: 'Segments Related', columnWidth: 250 },
    { columnName: 'Lead File', columnWidth: 190 },
  ])

  const { data: recordsInfo, isLoading } = useGetLeadFileStatusQuery({
    page: currentPage,
    sortBy: sortBy,
    order: sortOrder,
    filter: buildFilterQuery(filterQuery),
  })

  const transformStatus = (status: string) => {
    switch (status) {
      case 'success':
        return 'Success'
      case 'flagged':
        return 'Flagged'
      case 'no_records_matched':
        return 'No Records Matched'
      default:
        return status
    }
  }

  const flattenRecords = useMemo(() => {
    let rows = []
    if (recordsInfo) {
      recordsInfo.records?.forEach((record: any) => {
        rows.push([
          record.id,
          record.college_id,
          record.college_name,
          record.contract_related,
          record.segments_related,
          transformStatus(record.lead_file_status),
        ])
      })
    }
    return rows
  }, [recordsInfo])

  useEffect(
    () =>
      setColumnData(
        columnData.map((column) => {
          if (column.sortBy === sortBy) {
            return {
              ...column,
              sortOrder: sortOrder,
              sortBy: sortBy,
            }
          }
          return column
        })
      ),
    [sortBy, sortOrder]
  )

  if (isLoading) return <Loading />
  return (
    <Body>
      <BodyHeader>
        <BodyTitle>File Status</BodyTitle>
      </BodyHeader>

      <BodyContent>
        {recordsInfo?.total_records > 0 && (
          <>
            <Table
              columnData={columnData}
              rows={flattenRecords}
              setSortOrder={setSortOrder}
              setSortBy={setSortBy}
            />
            <TableFooter>
              {recordsInfo.total_pages > 1 && (
                <TablePagination
                  rowsPerPage={10}
                  currentPage={currentPage}
                  handlePageChange={handlePageChange}
                  totalRecords={recordsInfo.total_records}
                  totalPages={recordsInfo.total_pages}
                />
              )}
            </TableFooter>
          </>
        )}
      </BodyContent>
    </Body>
  )
}
