import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RolesApi } from 'Services/configuration/roles'

interface User {
  userName: string
  userEmail: string
  roles: Array<string>
}

const initialState: User = { userName: '', userEmail: '', roles: [] }

export const AddUserSlice = createSlice({
  name: 'adduser',
  initialState,
  reducers: {
    setUserName: (state, action: PayloadAction<string>) => {
      state.userName = action.payload
    },
    setUserEmail: (state, action: PayloadAction<string>) => {
      state.userEmail = action.payload
    },
    setUserRole: (state, action: PayloadAction<Array<string>>) => {
      state.roles = action.payload
    },
    clearUser: (state) => {
      state.userName = ''
      state.userEmail = ''
      state.roles = []
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      RolesApi.endpoints.getRoles.matchFulfilled,
      (state: User, { payload }: PayloadAction<string[]>) => {
        state.roles = [ payload[0] ]
      }
    )
  },    
})

export const { setUserName, setUserEmail, setUserRole, clearUser } =
  AddUserSlice.actions

export default AddUserSlice.reducer
