import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SnackbarComponent } from 'Components/shared/snackbar'
import { closeSnackbar, selectSnackbar } from './SnackbarSlice'

export const SnackbarContainer = () => {
  const { message, open, error } = useSelector(selectSnackbar)
  const dispatch = useDispatch()

  const handleClose = () => {
    dispatch(closeSnackbar());
  }

  return (
    <SnackbarComponent 
      message={message} 
      open={open} 
      error={error}
      onClose={handleClose}
    />
  )
}