import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit'
import type { RootState } from 'Utils/store'
import {
  DeduplicatesApi,
  UploadFileApiResponse,
  GetDeduplicatesForContract,
} from 'Services/contracts/deduplicates'
import { resetContractStates } from 'Slices/contracts'

export interface DedupFileStatus {
  fileId: string
  fileName: string
  deduplicateId?: string
}

const initialState: DedupFileStatus = {
  fileId: '',
  fileName: '',
  deduplicateId: null,
}

export const ContractDeduplicatesSlice = createSlice({
  name: 'contractDeduplicatesSlice',
  initialState,
  reducers: {
    setDedupState: (state, action: PayloadAction<DedupFileStatus>) => {
      for (const key in initialState) {
        if (action.payload.hasOwnProperty(key)) {
          state[key] = action.payload[key]
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetContractStates, () => {
      return initialState
    }),
      builder.addMatcher(
        DeduplicatesApi.endpoints.getUploadFileData.matchFulfilled,
        (
          state: DedupFileStatus,
          { payload }: PayloadAction<UploadFileApiResponse>
        ) => {
          if (payload?.data?.file_info?.id) {
            state.fileId = payload.data.file_info.id
            state.fileName = payload.data.file_info.name
          }
        }
      ),
      builder.addMatcher(
        DeduplicatesApi.endpoints.getDeduplicatesForContract.matchFulfilled,
        (
          state: DedupFileStatus,
          { payload }: PayloadAction<GetDeduplicatesForContract>
        ) => {
          if (payload?.data[0]?.file_id) {
            state.fileId = payload.data[0].file_id
            state.fileName = payload.data[0].label
            state.deduplicateId = payload?.data[0]?.id
          }
        }
      )
  },
})

export const selectDeduplicatesFile = createSelector(
  (state: RootState) => state.contractDeduplicatesSlice,
  (contractDeduplicatesSlice): DedupFileStatus => {
    return contractDeduplicatesSlice
  }
)

export const { setDedupState } =
  ContractDeduplicatesSlice.actions

export default ContractDeduplicatesSlice.reducer
