import React from 'react'
import { styled } from '@mui/material/styles'

const Header = styled('div')(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontSize: '1.5rem',
  fontWeight: theme.typography.fontWeightBold,
  lineHeight: '30px',
  marginBottom: '54px',
}))

interface ContractSectionHeaderProps {
  /** label is the name of the Contract content area */
  label: string
}

export const ContractSectionHeader = ({
  label,
}: ContractSectionHeaderProps) => {
  return <Header>{label}</Header>
}
