import React from 'react'
import {
  Dialog,
  DialogActionButtons,
  DialogField,
  DialogFieldContainer,
} from 'Components/shared/dialog'
import { Table } from 'Components/shared/table'

import { styled, useTheme } from '@mui/material/styles'
import { alpha } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import { filePathRegex } from 'Utils/validation'
import { Input } from 'Components/shared/input'
import { SingleSelectDropdown } from 'Components/shared/singleselectdropdown'
import { DeleteDestination } from 'Containers/configuration/destinations/deleteDestination'
import CheckboxUnchecked from 'Assets/images/checkbox_unchecked.svg'
import CheckboxChecked from 'Assets/images/checkbox_checked.svg'

const Divider = styled('div')(({ theme }) => ({
  height: '1px',
  width: '421px',
  marginTop: '20px',
  marginBottom: '10px',
  background: theme.palette.secondary['40'],
}))

const StyledButton = styled(Button)(({ theme }) => ({
  border: `1.8px solid ${theme.palette.primary.main}`,
  borderRadius: '4px',
  fontSize: '0.875rem',
  fontWeight: theme.typography.fontWeightMedium,
  height: '40px',
  lineHeight: '18px',
  minWidth: 'calc(100% - 40px)',
  [theme.breakpoints.up('md')]: {
    minWidth: 'auto',
  },
}))

const CancelButton = styled(StyledButton)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  color: theme.palette.primary.main,
  lineHeight: '20px',
  '&:hover': {
    backgroundColor: `${alpha(theme.palette.primary.light, 0.4)}`,
  },
  [theme.breakpoints.up('md')]: {
    width: '110px',
  },
}))

const SaveButton = styled(StyledButton)(({ theme }) => ({
  color: theme.palette.common.white,
  backgroundColor: theme.palette.primary.main,
  border: 'none',
  boxShadow: `0px 4px 20px ${alpha(theme.palette.common.white, 0.4)}`,
  '&:hover': {
    backgroundColor: `${alpha(theme.palette.primary.main, 0.85)}`,
  },
  '&.Mui-disabled': {
    color: theme.palette.common.white,
    backgroundColor: 'hsla(216, 79%, 72%, 1)',
  },
  [theme.breakpoints.up('md')]: {
    width: '110px',
  },
}))

const DeleteButton = styled(StyledButton)(({ theme }) => ({
  color: theme.palette.error.main,
  backgroundColor: theme.palette.common.white,
  border: `1.8px solid ${theme.palette.error.main}`,
  boxShadow: `0px 4px 20px ${alpha(theme.palette.common.white, 0.4)}`,
  '&:hover': {
    backgroundColor: `${alpha(theme.palette.error.main, 0.1)}`,
  },
  [theme.breakpoints.up('md')]: {
    width: '110px',
  },
}))

const TextField = styled('div')(({ theme }) => ({
  color: theme.palette.primary.dark,
  display: 'inline-block',
  fontSize: '0.875rem',
  fontWeight: theme.typography.fontWeightMedium,
  letterSpacing: '0.01em',
  lineHeight: '16px',
}))

interface ManageDestinationProps {
  destinationId: string
  destinationName: string
  destinationType: string
  hostName: string
  sftp_port: number
  destinationPath: string
  leadDownloadPreference: string
  leadDownloadPreferenceIsZip: boolean
  connectedContracts: any[]
  manageDestinationDialog: boolean
  setManageDestinationDialog: () => void
  deleteDestinationDialog: boolean
  setDeleteDestinationDialog: () => void

  closeDialog: () => void
  /** To set the destination label name */
  setLabel: (value: string) => void
  /** To set the connection type for destination */
  setType: (value: string) => void
  /** To set the destination path */
  setPath: (value: string) => void
  /** To set the hostname for destination */
  setHost: (value: string) => void
  setSftpPort: (value: number) => void
  /** To set the download preference of the user */
  setDownloadPreference: (value: string) => void
  /** Determines if download is zip of not */
  setIsZip: (value: boolean) => void
  /** Manage all destination values */
  manageDestinationValues: () => void
  /** Authentication Type component */
  children?: React.ReactNode
  /** Validation boolean that determines whether to disable submit  */
  disableSubmit: boolean
  /** Object that registers component into React Hook Form */
  control?: any
}

export const ManageDestination = ({
  destinationId,
  destinationName,
  destinationType,
  hostName,
  sftp_port,
  destinationPath,
  connectedContracts,
  manageDestinationDialog,
  leadDownloadPreference,
  leadDownloadPreferenceIsZip,
  setManageDestinationDialog,
  deleteDestinationDialog,
  setDeleteDestinationDialog,
  setLabel,
  setType,
  setPath,
  setHost,
  setSftpPort,
  setDownloadPreference,
  setIsZip,
  manageDestinationValues,
  disableSubmit,
  control,
  children,
}: ManageDestinationProps) => {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('md'))
  return (
    <Dialog
      onClose={setManageDestinationDialog}
      open={manageDestinationDialog}
      title='Manage Destination'
    >
      <DialogFieldContainer>
        <DialogField id='destination-name' label='Destination Name'>
          <Input
            id='destination-name'
            name='manageDestinationName'
            control={control}
            rules={{
              required: true,
              maxLength: {
                value: 100,
                message: 'Destination name should not exceed 100 characters',
              },
            }}
            value={destinationName}
            style={matches ? { width: '421px' } : {}}
            onChange={(e) => setLabel(e.target.value)}
          />
        </DialogField>

        <DialogField id='destination-type' label='Destination Type'>
          <SingleSelectDropdown
            id='destination-type'
            name='manageDestinationType'
              control={control}
              rules={{
                required: 'Type is a required field',
              }}
            value={destinationType}
            style={matches ? { width: '279px', height: '42px' } : {}}
            values={['SFTP']}
            onChange={(e: any) => setType(e.target.value)}
          />
        </DialogField>

        <DialogField id='host-name' label='Host Name'>
          <Input
            id='host-name'
            name='manageHostname'
            control={control}
            rules={{
              required: 'Hostname is a required field'
            }}
            value={hostName}
            style={matches ? { width: '279px' } : {}}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setHost(e.target.value)
            }
          />
        </DialogField>

        <DialogField id='destination-path' label='Destination Path'>
          <Input
            id='destination-path'
            name='manageDestinationPath'
            control={control}
            rules={{
              required: true,
              pattern: {
                value: filePathRegex,
                message: 'Must be a valid path beginning with /'
              }
            }}
            value={destinationPath}
            style={matches ? { width: '279px' } : {}}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setPath(e.target.value)
            }
          />
        </DialogField>

        <DialogField id='port' label='Port'>
          <Input
            id='port'
            type='number'
            name='manageDestinationPort'
            control={control}
            value={sftp_port}
            style={matches ? { width: '279px' } : {}}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setSftpPort(parseInt(e.target.value))
            }
          />
        </DialogField>

        <DialogField id='divider' label=''>
          <Divider />
        </DialogField>

        {children}

        <DialogField id='divider' label=''>
          <Divider />
        </DialogField>

        <DialogField
          id='lead-download-preferences'
          label='Lead Download Preferences'
          alignSelf={'flex-start'}
        >
          <SingleSelectDropdown
            id='lead-download-preferences'
            name='manageLeadDownloadPreferences'
              control={control}
              rules={{
                required: 'Lead Download Preferences is a required field',
              }}
            style={matches ? { width: '279px' } : {}}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              setDownloadPreference(e.target.value)
            }}
            value={leadDownloadPreference}
            values={['Comma Separated Values']}
          />
          <div style={{ marginTop: '10px' }}>
            <Checkbox
              icon={<CheckboxUnchecked />}
              checkedIcon={<CheckboxChecked />}
              defaultChecked={leadDownloadPreferenceIsZip}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setIsZip(e.target.checked)
              }}
            />
            <TextField>Download the file in ZIP format</TextField>
          </div>
        </DialogField>

        <DialogField id='divider' label=''>
          <Divider />
        </DialogField>

        <DialogField
          id='connected-contracts'
          label='Connected Contracts'
          alignSelf={'flex-start'}
        >
          {connectedContracts.length > 0 ? (
            <Table
              width={'401px'}
              height={'192px'}
              columnData={[
                {
                  columnName: 'Contract Name',
                  columnWidth: 401,
                  sortOrder: 'ASC',
                },
              ]}
              rows={connectedContracts}
              variant='striped'
            />
          ) : (
            <TextField>No Contracts are attached to this destination</TextField>
          )}
        </DialogField>
      </DialogFieldContainer>

      <DialogActionButtons>
        <DeleteButton onClick={setDeleteDestinationDialog}>Delete</DeleteButton>
        <CancelButton onClick={setManageDestinationDialog}>Cancel</CancelButton>
        <SaveButton disabled={disableSubmit} onClick={manageDestinationValues}>Save</SaveButton>
      </DialogActionButtons>

      {deleteDestinationDialog && (
        <DeleteDestination
          destinationId={destinationId}
          destinationName={destinationName}
          deleteDestinationDialog={deleteDestinationDialog}
          setDeleteDestinationDialog={setDeleteDestinationDialog}
          setManageDestinationDialog={setManageDestinationDialog}
        />
      )}
    </Dialog>
  )
}
