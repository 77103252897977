import React from 'react'
import { styled } from '@mui/material/styles'
import { alpha } from '@mui/material'

const BottomContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  backgroundColor: `${alpha(theme.palette.primary.light, 0.1)}`,
  minHeight: '100vh',
  paddingBottom: '32px',
}))

const BodyContainer = styled('div')(() => ({
  maxWidth: '1074px',
  margin: '0 auto',
  padding: '64px 0px',
}))

interface BodyProps {
  /** This would be all the components that make up the body */
  children?: React.ReactNode
}

export const Body = ({ children }: BodyProps) => {
  return (
    <BottomContainer>
      <BodyContainer>{children}</BodyContainer>
    </BottomContainer>
  )
}
