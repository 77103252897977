import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit'
import { CombineBy, FilterBy, properties } from 'Filter/properties'
import type { RootState } from 'Utils/store'
import SATConfig from 'Configs/fields/sat.json'
import ACTConfig from 'Configs/fields/act.json'

interface TestScores {
  /* Dropdown values */
  values: string[]
  /* chosen value in the dropdown */
  selectedValue: string
  /* filterQuery is the query that is used to filter the data in the backend */
  filterQuery: any
  /* selectedMinSat is the selected minimium SAT in the slider */
  selectedMinSat: number
  /* selectedMaxSat is the selected maximum SAT in the slider */
  selectedMaxSat: number
  /* selectedMinAct is the selected minimium ACT in the slider */
  selectedMinAct: number
  /* selectedMaxAct is the selected maximum ACT in the slider */
  selectedMaxAct: number
}
const VALUES = ['Any', 'SAT', 'ACT', 'SAT or ACT', 'SAT and ACT']

const buildSingleScore = (chosenScore, chosenMin, chosenMax) => {
  return {
    column: chosenScore,
    op: FilterBy.RANGE,
    value: [chosenMin, chosenMax],
    meta: chosenScore === SATConfig.column ? SATConfig.meta : ACTConfig.meta,
  }
}

const buildBothScores = (
  minSat: number = SATConfig.range.min,
  maxSat: number = SATConfig.range.max,
  minAct: number = ACTConfig.range.min,
  maxAct: number = ACTConfig.range.max,
  combineBy: CombineBy
) => {
  const satProperty = {
    column: SATConfig.column,
    op: FilterBy.RANGE,
    value: [minSat, maxSat],
    meta: SATConfig.meta,
  }
  const actProperty = {
    column: ACTConfig.column,
    op: FilterBy.RANGE,
    value: [minAct, maxAct],
    meta: ACTConfig.meta,
  }

  return properties([satProperty, actProperty], combineBy)
}

export const initialState: TestScores = {
  filterQuery: '',
  values: VALUES,
  selectedValue: VALUES[0],
  selectedMinSat: SATConfig.range.min,
  selectedMaxSat: SATConfig.range.max,
  selectedMinAct: ACTConfig.range.min,
  selectedMaxAct: ACTConfig.range.max,
}

export const TestScores = createSlice({
  name: 'TestScores',
  initialState,
  reducers: {
    setTimeBasedAnyScore() {
      return initialState
    },
    setTimeBasedSat(
      state,
      action: PayloadAction<{ min: number; max: number }>
    ) {
      const { min, max } = action.payload
      state.selectedValue = VALUES[1]
      state.selectedMinSat = min
      state.selectedMaxSat = max
      state.filterQuery = buildSingleScore(SATConfig.column, min, max)
    },
    setTimeBasedAct(
      state,
      action: PayloadAction<{ min: number; max: number }>
    ) {
      const { min, max } = action.payload

      state.selectedValue = VALUES[2]
      state.selectedMinAct = min
      state.selectedMaxAct = max
      state.filterQuery = buildSingleScore(ACTConfig.column, min, max)
    },
    setTimeBasedSatOrAct(
      state,
      action: PayloadAction<{
        minSat: number
        maxSat: number
        minAct: number
        maxAct: number
      }>
    ) {
      const { minSat, maxSat, minAct, maxAct } = action.payload
      state.selectedValue = VALUES[3]
      state.selectedMinSat = minSat
      state.selectedMaxSat = maxSat
      state.selectedMinAct = minAct
      state.selectedMaxAct = maxAct
      state.filterQuery = buildBothScores(
        minSat,
        maxSat,
        minAct,
        maxAct,
        CombineBy.OR
      )
    },
    setTimeBasedSatAndAct(
      state,
      action: PayloadAction<{
        minSat: number
        maxSat: number
        minAct: number
        maxAct: number
      }>
    ) {
      const { minSat, maxSat, minAct, maxAct } = action.payload
      state.selectedValue = VALUES[4]
      state.selectedMinSat = minSat
      state.selectedMaxSat = maxSat
      state.selectedMinAct = minAct
      state.selectedMaxAct = maxAct
      state.filterQuery = buildBothScores(
        minSat,
        maxSat,
        minAct,
        maxAct,
        CombineBy.AND
      )
    },
  },
})

const createFilterQuery = (
  selectedValue: string,
  selectedMinSat: number,
  selectedMaxSat: number,
  selectedMinAct: number,
  selectedMaxAct: number
) => {
  switch (selectedValue) {
    case VALUES[0]:
      return initialState.filterQuery
    case VALUES[1]:
      return buildSingleScore(SATConfig.column, selectedMinSat, selectedMaxSat)
    case VALUES[2]:
      return buildSingleScore(ACTConfig.column, selectedMinAct, selectedMaxAct)
    case VALUES[3]:
      return buildBothScores(
        selectedMinSat,
        selectedMaxSat,
        selectedMinAct,
        selectedMaxAct,
        CombineBy.OR
      )
    case VALUES[4]:
      return buildBothScores(
        selectedMinSat,
        selectedMaxSat,
        selectedMinAct,
        selectedMaxAct,
        CombineBy.AND
      )
    default:
      return initialState.filterQuery
  }
}

export const selectTestScores = createSelector(
  ({ timeBasedFilter: { prospectProfile } }: RootState) =>
    prospectProfile.testScores.selectedValue,
  ({ timeBasedFilter: { prospectProfile } }: RootState) =>
    prospectProfile.testScores.selectedMinSat,
  ({ timeBasedFilter: { prospectProfile } }: RootState) =>
    prospectProfile.testScores.selectedMaxSat,
  ({ timeBasedFilter: { prospectProfile } }: RootState) =>
    prospectProfile.testScores.selectedMinAct,
  ({ timeBasedFilter: { prospectProfile } }: RootState) =>
    prospectProfile.testScores.selectedMaxAct,
  (
    selectedValue: string,
    selectedMinSat: number,
    selectedMaxSat: number,
    selectedMinAct: number,
    selectedMaxAct: number
  ): {
    values: string[]
    selectedValue: string
    satAriaLabel: string
    actAriaLabel: string
    minSat: number
    maxSat: number
    minAct: number
    maxAct: number
    selectedMinSat: number
    selectedMaxSat: number
    satStep: number
    selectedMinAct: number
    selectedMaxAct: number
    actStep: number
    filterQuery: any
  } => {
    return {
      values: VALUES,
      selectedValue,
      minSat: SATConfig.range.min,
      maxSat: SATConfig.range.max,
      minAct: ACTConfig.range.min,
      maxAct: ACTConfig.range.max,
      satAriaLabel: SATConfig.ARIA_LABEL,
      actAriaLabel: ACTConfig.ARIA_LABEL,
      selectedMinSat,
      selectedMaxSat,
      satStep: SATConfig.range.step,
      selectedMinAct,
      selectedMaxAct,
      actStep: ACTConfig.range.step,
      filterQuery: createFilterQuery(
        selectedValue,
        selectedMinSat,
        selectedMaxSat,
        selectedMinAct,
        selectedMaxAct
      ),
    }
  }
)

export const {
  setTimeBasedAnyScore,
  setTimeBasedSat,
  setTimeBasedAct,
  setTimeBasedSatOrAct,
  setTimeBasedSatAndAct,
} = TestScores.actions

export default TestScores.reducer
