import React from 'react'
import { alpha } from '@mui/material'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import { default as MUIDialog } from '@mui/material/Dialog'
import { SegmentDetailsSection } from './SegmentDetailsSection'
import { SegmentDetailsData } from './SegmentDetailsData'
import { AudienceTypeHelper, ContractSource, ContractType } from 'Types/index'
import { Contract } from 'Services/contracts/contractsUtils'
import { useSelector } from 'react-redux'
import {
  selectContractConfiguration,
} from 'Slices/contracts'


const CustomDialog = styled(MUIDialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    background: theme.palette.common.white,
    borderRadius: '8px',
    boxShadow: `0px 4px 20px ${alpha(theme.palette.common.black, 0.4)}`,
    padding: '32px',
    width: '90%',
    [theme.breakpoints.up('md')]: {
      padding: '56px 64px 40px 122px',
      /* this is required to override the default max-width of the dialog */
      maxWidth: '850px',
      width: '850px',
    },
  },
}))

const SegmentName = styled('div')(({ theme }) => ({
  color: theme.palette.secondary['100'],
  lineHeight: '30px',
  fontSize: '2rem',
  fontWeight: theme.typography.fontWeightBold,
  marginBottom: '32px',

  [theme.breakpoints.up('md')]: {
    marginBottom: '39px',
  },
}))

const Header = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  marginBottom: '34px',
}))

const HeaderText = styled('div')(({ theme }) => ({
  fontWeight: theme.typography.fontWeightBold,
  fontSize: '1.5rem',
  lineHeight: '30px',
  color: theme.palette.secondary.main,
  minWidth: '230px',
}))

const StyledButton = styled(Button)(({ theme }) => ({
  border: `1.8px solid ${theme.palette.primary.main}`,
  borderRadius: '4px',
  fontSize: '0.875rem',
  fontWeight: theme.typography.fontWeightMedium,
  height: '40px',
  lineHeight: '18px',
  minWidth: 'calc(100% - 40px)',
  [theme.breakpoints.up('md')]: {
    minWidth: 'auto',
  },
}))

const TextButton = styled(StyledButton)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  color: theme.palette.primary.main,
  lineHeight: '20px',
  '&:hover': {
    backgroundColor: `${alpha(theme.palette.primary.light, 0.4)}`,
  },
  [theme.breakpoints.up('md')]: {
    width: '110px',
  },
}))

const ActionButton = styled(StyledButton)(({ theme }) => ({
  color: theme.palette.common.white,
  backgroundColor: theme.palette.primary.main,
  boxShadow: `0px 4px 20px ${alpha(theme.palette.common.white, 0.4)}`,
  '&:hover': {
    backgroundColor: `${alpha(theme.palette.primary.main, 0.85)}`,
  },
  [theme.breakpoints.up('md')]: {
    width: '134px',
  },
}))

const DeleteButton = styled(StyledButton)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  border: `1.8px solid ${theme.palette.error.main}`,
  color: theme.palette.error.main,
  lineHeight: '20px',
  '&:hover': {
    backgroundColor: `${alpha(theme.palette.error.light, 0.4)}`,
  },
  [theme.breakpoints.up('md')]: {
    width: '110px',
  },
}))

const ButtonRow = styled('div')(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '16px',
}))

interface SegmentDetailsProps {
  audienceTypes: { id: string; name: string }[]
  contract: Partial<Contract>
  contractSource: ContractSource
  chosenSegment: any
  segmentLeadCapTotal: string
  handleDeactivateSegment?: () => void
  handleDuplicateSegment?: (ipedId: string) => void
  handleDeleteSegment?: () => void
  handleEditSegment?: (ipedId: string) => void
  closeSegmentDetailsDialog: () => void
  segmentDetailsDialog: boolean
  segmentData: any
  isSegmentQueue?: boolean
  canUpdateSegment?: boolean
  disabledContract?: boolean
}

export const SegmentsDetails = ({
  audienceTypes,
  contract,
  contractSource,
  chosenSegment,
  segmentLeadCapTotal,
  handleDeactivateSegment,
  handleDuplicateSegment,
  handleDeleteSegment,
  handleEditSegment,
  segmentData,
  segmentDetailsDialog,
  closeSegmentDetailsDialog,
  isSegmentQueue,
  canUpdateSegment,
  disabledContract,
}: SegmentDetailsProps) => {
  const {
    contractType
  } = useSelector(selectContractConfiguration) 
  return (
    <CustomDialog
      onClose={closeSegmentDetailsDialog}
      open={segmentDetailsDialog}
      aria-labelledby={chosenSegment.name}
    >
      <SegmentName>{chosenSegment.name}</SegmentName>
      <Header>
        <HeaderText>Segment Criteria</HeaderText>
        <ButtonRow>
          <TextButton onClick={closeSegmentDetailsDialog}>Close</TextButton>
          {!disabledContract && isSegmentQueue && (
            <>
              <TextButton
                onClick={() => handleEditSegment(chosenSegment.institution_id)}
              >
                Edit
              </TextButton>

              <DeleteButton onClick={handleDeleteSegment}>Delete</DeleteButton>
            </>
          )}
          {!disabledContract && canUpdateSegment && (
            <TextButton
              onClick={() =>
                handleDuplicateSegment(chosenSegment.institution_id)
              }
            >
              Duplicate
            </TextButton>
          )}

          {!disabledContract &&
            canUpdateSegment &&
            contractType === ContractType.annual &&
            chosenSegment.is_active && (
              <ActionButton onClick={handleDeactivateSegment}>
                Deactivate
              </ActionButton>
            )}
        </ButtonRow>
      </Header>
      <SegmentDetailsData label={'Contract Related'} value={contract.label} />
      <SegmentDetailsData
        label={'Audience Type'}
        value={AudienceTypeHelper.getReadableString(
          audienceTypes,
          contract.audience_type
        )}
      />
      {contractSource === ContractSource.inquiries ? (
        <SegmentDetailsData
          label={'Segment Lead Cap'}
          value={segmentLeadCapTotal}
        />
      ) : (
        <SegmentDetailsData
          label={'Segment Lead Request'}
          value={segmentLeadCapTotal}
        />
      )}
      {contractSource === ContractSource.candidates && (
        <SegmentDetailsData
          label={'Price per Lead'}
          value={`$${chosenSegment.price_per_lead}`}
        />
      )}

      {Object.keys(segmentData).map((sectionName, idx) => {
        return (
          <>
            <SegmentDetailsSection label={sectionName} key={idx} />
            {Object.keys(segmentData[sectionName]).map((data, idx) => {
              return (
                <SegmentDetailsData
                  key={idx}
                  label={data}
                  value={segmentData[sectionName][data]}
                />
              )
            })}
          </>
        )
      })}
    </CustomDialog>
  )
}
