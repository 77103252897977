import { createSelector } from '@reduxjs/toolkit'
import type { RootState } from 'Utils/store'
import { multiSelectSlice } from 'Slices/multiSelectSlice'
import AreaOfInterestConfig from 'Configs/fields/area_of_interest.json'
import { ContractSource } from 'Types/contractTypes'
import { Dispatch, AnyAction } from 'redux'

const contractSource = ContractSource.candidates

const transformValues = function (selectedValues) {
  return selectedValues.map((value) =>
    value === AreaOfInterestConfig.no_selection
      ? ''
      : value.split('-')[1].trim()
  )
}

export const AreaOfInterestSlice = multiSelectSlice(
  AreaOfInterestConfig.column,
  'setOnDemandAreaOfInterest',
  AreaOfInterestConfig.data,
  AreaOfInterestConfig.meta,
  transformValues
)

export const selectAreaOfInterest = createSelector(
  ({ onDemandFilter: { prospectProfile } }: RootState) =>
    prospectProfile.areaOfInterest.selectedValues,
  (
    selectedValues: string[]
  ): {
    selectedValues: string[]
    values: string[]
    audienceTypes: string[]
    label: string
  } => {
    return {
      selectedValues,
      values: AreaOfInterestConfig.data,
      audienceTypes: AreaOfInterestConfig.source[contractSource].audience_types,
      label: AreaOfInterestConfig.meta.label,
    }
  }
)

export const setAreaOfInterestDefaultValue = (
  dispatch: Dispatch<AnyAction>,
  audienceType: string
) => {
  const config = AreaOfInterestConfig.source[contractSource]
  if (config?.default_selected[audienceType])
    dispatch(setOnDemandAreaOfInterest(config?.default_selected[audienceType]))
  else dispatch(setOnDemandAreaOfInterest([]))
}

export const { setOnDemandAreaOfInterest } = AreaOfInterestSlice.actions

export default AreaOfInterestSlice.reducer
