import React from 'react'
import Checkbox from '@mui/material/Checkbox'
import {
  Body,
  BodyTitle,
  BodyActions,
  BodyHeader,
  BodyContent,
} from 'Components/shared/body'
import { DefaultButton } from 'Components/shared/buttons'
import { Permissions } from 'Configs/userPermissions'
import { useHasPermission } from 'Hooks/useHasPermission'
import { Loading } from 'Utils/loading'
import { useInstitutionSettings } from 'Hooks/institution'
import CheckboxUnchecked from 'Assets/images/checkbox_unchecked.svg'
import CheckboxChecked from 'Assets/images/checkbox_checked.svg'

export const InstitutionSettings = () => {
  const { hasPermission: canSwitchInstitution } = useHasPermission(Permissions.SwitchInstitution)
  const { setIsGDPR, isChanged, isLoading, saveSettings, institution_gdpr_consent} = useInstitutionSettings()

  return (
    <Body>
      <BodyHeader>
        <BodyTitle>Institution Settings</BodyTitle>
        <BodyActions>
          {canSwitchInstitution && (
            <DefaultButton disabled={!isChanged} onClick={saveSettings}>Update</DefaultButton>
          )}
        </BodyActions>
      </BodyHeader>
      <BodyContent>
      {isLoading && (<Loading />)}
      <div style={{ marginTop: '10px' }}>
        <Checkbox
          icon={<CheckboxUnchecked />}
          checkedIcon={<CheckboxChecked />}
          checked={institution_gdpr_consent}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setIsGDPR(e.target.checked)
          }}
        />
          Is GDPR Consent required?
        </div>
      </BodyContent>
    </Body>
  )
}
