import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit'
import { FilterBy } from 'Filter/properties'
import type { RootState } from 'Utils/store'

interface AudienceTypeProps {
  /* selectedValue is the value that is currently selected in the dropdown */
  selectedValue: string
  /* filterQuery is the query that is used to filter the data in the backend */
  filterQuery: any
}

const initialState: AudienceTypeProps = {
  selectedValue: 'all',
  filterQuery: '',
}

export const AudienceTypeSlice = createSlice({
  name: 'candidatesAudienceType',
  initialState,
  reducers: {
    setAudienceType: (state, action: PayloadAction<string>) => {
      state.selectedValue = action.payload
      if (action.payload === 'all') {
        state.filterQuery = ''
      } else {
        state.filterQuery = {
          column: 'audience_type',
          op: FilterBy.EQ,
          value: state.selectedValue,
        }
      }
    },
  },
})

export const selectCandidatesAudienceType = createSelector(
  ({ candidatesFilesFilter }: RootState) =>
    candidatesFilesFilter.audienceType.selectedValue,

  (selectedValue: string): { selectedValue: string } => {
    return {
      selectedValue,
    }
  }
)

export const { setAudienceType } = AudienceTypeSlice.actions

export default AudienceTypeSlice.reducer
