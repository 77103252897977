import React, { useCallback, useEffect, memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useToggle } from 'Hooks/useToggle'
import { Accordion } from 'Components/shared/accordion'
import { SidebarFieldWrapper } from 'Components/shared/sidebar'
import {
  setOnDemandAddressVerified,
  selectAddressVerified,
} from './AddressVerifiedSlice'
import {
  setOnDemandNameCleanliness,
  selectNameCleanliness,
  setOnDemandNameCleanlinessDefaultValue,
} from './NameCleanlinessSlice'
import {
  setOnDemandEmailCleanliness,
  selectEmailCleanliness,
  setOnDemandEmailCleanlinessDefaultValue
} from './EmailCleanlinessSlice'
import { FilterSingleSelectDropdown } from 'Components/shared/singleselectdropdown'
import { Contract } from 'Services/contracts/contractsUtils'

interface VerificationProps {
  filterData?: any
  chosenItem: Partial<Contract>
}

export const Verification = memo(
  ({ filterData, chosenItem }: VerificationProps) => {
    const [expand, setExpand] = useToggle(false)
    const dispatch = useDispatch()

    const addressVerified = useSelector(selectAddressVerified)
    const nameCleanliness = useSelector(selectNameCleanliness)
    const emailCleanliness = useSelector(selectEmailCleanliness)

    useEffect(() => {
      const audienceType = chosenItem?.audience_type
      setOnDemandNameCleanlinessDefaultValue(dispatch, audienceType)
      setOnDemandEmailCleanlinessDefaultValue(dispatch, audienceType)
  }, [chosenItem?.id])

    /** This useEffect is for setting filters in edit segment */
    useEffect(() => {
      if (filterData?.[addressVerified.label])
        dispatch(setOnDemandAddressVerified(filterData[addressVerified.label]))
      if (filterData?.[nameCleanliness.label])
        dispatch(setOnDemandNameCleanliness(filterData[nameCleanliness.label]))
      if (filterData?.[emailCleanliness.label])
        dispatch(
          setOnDemandEmailCleanliness(filterData[emailCleanliness.label])
        )
    }, [filterData])

    const dispatchAddressVerified = useCallback((value: string) => {
      dispatch(setOnDemandAddressVerified(value))
    }, [])

    const dispatchNameCleanliness = useCallback((value: string) => {
      dispatch(setOnDemandNameCleanliness(value))
    }, [])

    const dispatchEmailCleanliness = useCallback((value: string) => {
      dispatch(setOnDemandEmailCleanliness(value))
    }, [])

    return (
      <Accordion
        setExpand={setExpand}
        expand={expand}
        title='VERIFICATION'
        disabled={chosenItem?.id ? false : true}
      >
        <SidebarFieldWrapper>
          <FilterSingleSelectDropdown
            label='Address Verified'
            values={addressVerified.values}
            value={addressVerified.selectedValue}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              dispatchAddressVerified(e.target.value)
            }}
          />

          <FilterSingleSelectDropdown
            label='Name Cleanliness Required'
            values={nameCleanliness.values}
            value={nameCleanliness.selectedValue}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              dispatchNameCleanliness(e.target.value)
            }}
          />
          <FilterSingleSelectDropdown
            label='Email Cleanliness Required'
            values={emailCleanliness.values}
            value={emailCleanliness.selectedValue}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              dispatchEmailCleanliness(e.target.value)
            }}
          />
        </SidebarFieldWrapper>
      </Accordion>
    )
  }
)
