import React, { memo } from 'react'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import CloseArrow from 'Assets/images/sidebar_close.svg'

const ToggleButton = styled(Button)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  border: '1.5px solid hsl(212, 76.5%, 93.3%)',
  borderRadius: '50%',
  height: '36px',
  minWidth: '36px',
}))

const OpenArrow = styled(CloseArrow)(() => ({
  transform: 'rotate(180deg)',
}))

interface ArrowButtonProps {
  /** showSidebar determines whether full sidebar is shown */
  showSidebar: boolean
  /** Handles toggle button */
  handleClick: () => void
}

export const ArrowButton = memo(
  ({ showSidebar, handleClick }: ArrowButtonProps) => {
    return (
      <ToggleButton onClick={handleClick}>
        {showSidebar ? <CloseArrow /> : <OpenArrow />}
      </ToggleButton>
    )
  }
)
