import React, { useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { AccountSettings } from 'Containers/header/accountsettings'
import { Header } from 'Components/header'
import {
  useLoginStatusQuery,
  useLogOutMutation,
} from 'Services/global/authentication'
import { setNavigation, selectNavigation } from './NavSlice'
import { useSocket } from 'Hooks/useSocket'
import NavConfig from './NavConfig.json'
import { Loading } from 'Utils/loading'

export const HeaderContainer = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { ipedId } = useParams()
  const initSocket = useSocket(() => {})
  const navConfigIdxs = useSelector(selectNavigation)
  const isAdmin = pathname.startsWith('/admin')
  const navConfig = isAdmin ? NavConfig.adminPortal : NavConfig.clientPortal

  const { data: loginInfo, isLoading: isLoggingIn } = useLoginStatusQuery()
  const [logOut, { isLoading: isLoggingOut, isSuccess: isLoggedOut }] =
    useLogOutMutation()

  const handleLogout = useCallback(() => {
    window.sessionStorage.clear()
    logOut()
  }, [])

  const handleNavClick = useCallback((e: any) => {
    e.stopPropagation()
    const dataIdx = e.target.getAttribute('data-idx')
    if (dataIdx) {
      const idxs = dataIdx.split('-')
      /** Reset all the redux state except authentication and institution*/
      dispatch({
        type: 'resetExceptAuthInstitution',
      })

      dispatch(
        setNavigation({
          mainNavIdx: Number(idxs[0]),
          subNavIdx: Number(idxs[1]),
        })
      )
    }
  }, [])

  useEffect(() => {
    let mainNavIdx = 0
    let subNavIdx = 0

    navConfig.forEach((nav, idx) => {
      if (pathname.includes(nav.url)) {
        mainNavIdx = idx
        nav.subNavs.forEach((subNav, subIdx) => {
          if (pathname.includes(nav.url + subNav.url)) {
            subNavIdx = subIdx
          }
        })
      }
    })

    /** Set path in session storage only if it's admin portal or t
     * he client portal has ipedId in the URL */

    if (isAdmin || ipedId) window.sessionStorage.setItem('path', pathname)

    dispatch(
      setNavigation({
        mainNavIdx: mainNavIdx,
        subNavIdx: subNavIdx,
      })
    )
  }, [pathname])

  useEffect(() => {
    if (!loginInfo?.isLoggedIn || isLoggedOut) {
      navigate('/')
    }
  }, [loginInfo?.isLoggedIn, isLoggedOut])

  if (!loginInfo?.isLoggedIn || isLoggingIn || isLoggingOut || isLoggedOut)
    return <Loading />

  return (
    <Header
      {...loginInfo}
      navConfig={navConfig}
      navConfigIdxs={navConfigIdxs}
      handleNavClick={handleNavClick}
      isAdmin={isAdmin}
      ipedId={ipedId}
    >
      <AccountSettings
        {...loginInfo}
        handleLogout={handleLogout}
        isAdmin={isAdmin}
      />
    </Header>
  )
}
