import React, { useCallback, memo, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { CreateKey as CreateKeyComponent } from 'Components/configuration/sshkeymanagement/createkey'
import { setKeyPairName, clearKeyPair } from './CreateKeySlice'
import { useCreateSSHKeyPairMutation } from 'Services/configuration/sshkeys'
import { Loading } from 'Utils/loading'
import type { RootState } from 'Utils/store'

interface CreateKeyProps {
  /** Boolean that determines whether to show create key dialog */
  createKeyDialog: boolean
  /** Function that toggles create key dialog */
  setKeyDialog: () => void
  /** Array containing all ssh key pair names */
  sshKeysData: string[]
}

const downloadContent = (fileName: string, content: string) => {
  const aTag = document.createElement('a')
  aTag.setAttribute(
    'href',
    'data:text/plain;charset=utf-8,' + encodeURIComponent(content)
  )
  aTag.setAttribute('download', fileName)
  document.body.appendChild(aTag)
  aTag.click()
  aTag.remove()
}

export const CreateKey = memo(
  ({ createKeyDialog, setKeyDialog, sshKeysData }: CreateKeyProps) => {
    const { ipedId } = useParams()
    const dispatch = useDispatch()
    const {
      control,
      formState: { errors: formErrors, isValid },
      setError: setFormError,
      clearErrors: clearFormErrors,
    } = useForm({
      mode: 'onChange',
    })
    const [createSSHKeyPair, { isLoading }] = useCreateSSHKeyPairMutation()

    const sshKeyPair = useSelector(
      ({ createSSHKeyPair }: RootState) => createSSHKeyPair.keyPairName
    )

    const setPairName = useCallback((name: string) => {
      dispatch(setKeyPairName(name))
    }, [])

    const clearKeyPairName = useCallback(() => {
      dispatch(clearKeyPair())
    }, [])

    const addKeyPair = useCallback(async () => {
      const result: any = await createSSHKeyPair({
        ipedId,
        key_pair_name: sshKeyPair,
      })
      if (result) {
        const fileName = result.data?.data?.key_pair_name + '.pub'
        const publicKey = result.data?.data?.public_key
        downloadContent(fileName, publicKey)
      }
      closeDialog()
    }, [sshKeyPair])

    const closeDialog = useCallback(() => {
      setKeyDialog()
      clearKeyPairName()
    }, [])

    /** Check and add duplicate key pair name error */
    useEffect(() => {
      if (sshKeysData.includes(sshKeyPair)) {
        setFormError(
          'root.keyPairName',
          {
            type: 'manual',
            message: 'This name is already in use',
          },

          { shouldFocus: true }
        )
      } else {
        clearFormErrors('root.keyPairName')
      }
    }, [sshKeyPair])

    const disableFormSubmit = !isValid || Object.keys(formErrors).length > 0

    if (isLoading) return <Loading />

    return (
      <CreateKeyComponent
        createKeyDialog={createKeyDialog}
        closeDialog={closeDialog}
        setPairName={setPairName}
        addKeyPair={addKeyPair}
        disableSubmit={disableFormSubmit}
        control={control}
      />
    )
  }
)
