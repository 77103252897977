import React, { useCallback, useEffect, useState, memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
  selectGeography,
  //all of these setting actions should be one action that takes a variable to determine which to set, and a secondary variable for the cases that need it
  //there is no reason they all need to be different actions, that then get duplicated
  //this should 16 seperate actions down to 1 once the redux is refactored
  //it should also take a good amount of business logic complexity out of this component
  setOnDemandAllUSA,
  setOnDemandInState,
  setOnDemandOutofState,
  setOnDemandSpecificStates,
  setOnDemandZipFile,
  setOnDemandZip5Codes,
  setOnDemandZip3Codes,
  setOnDemandOutsideUS,
} from 'Containers/shared/segmentfilters/ondemand/demographics/GeographySlice'

import {
  selectGeography as selectInquiriesGeography,
  setTimeBasedAllUSA,
  setTimeBasedInState,
  setTimeBasedOutofState,
  setTimeBasedSpecificStates,
  setTimeBasedZipFile,
  setTimeBasedZip5Codes,
  setTimeBasedZip3Codes,
  setTimeBasedOutsideUS,
} from 'Containers/shared/segmentfilters/timebased/demographics/GeographySlice'

import { Input } from 'Components/shared/input'
import { FilterSingleSelectDropdown } from 'Components/shared/singleselectdropdown'
import { VirtualizedMultiSelect } from 'Components/shared/multiselectdropdown'
import { useGetInstitutionsInfoQuery } from 'Services/global/institution'
import { useGetZipCodesQuery } from 'Services/configuration/zipCodes'
import { SingleSelectDropdown } from 'Components/shared/singleselectdropdown'
import { validateZip5Codes, validateZip3Codes } from 'Utils/zipCodes'
import { buildFilterQuery } from 'Filter/buildFilterQuery'
import { FileCategory } from 'Types/fileCategory'
import { Loading } from 'Utils/loading' 
import { isInquiries } from 'Utils/urlUtils' 

interface GeographyProps {
  control: any
  filterData?: any
  zip_id?: string
}

export const getFilesfilterQuery = {
  query: {
    filterQuery: {
      properties: [
        {
          column: 'category',
          op: 'eq',
          value: FileCategory.ZipSegmentFilter,
        },
      ],
      operation: 'AND',
    },
  },
}

export const GeographyFilter = memo(({ control, filterData, zip_id }: GeographyProps) => {
  const dispatch = useDispatch()
  const { ipedId } = useParams()
  const geography = useSelector(isInquiries ? selectGeography : selectInquiriesGeography)
  const { data } = useGetInstitutionsInfoQuery()
  const institution = data?.find((institution) => institution.id === ipedId)
  const { data: zipCodesData, isLoading } = useGetZipCodesQuery({
    ipedId,
    page: 0,
    sortBy: 'updated_at',
    order: 'desc',
    filter: buildFilterQuery(getFilesfilterQuery),
  })
  const [zipFiles, setZipFiles] = useState([])

  const setSelectedValue = useCallback((value: string) => {
    if (value === 'All USA') dispatch(isInquiries ? setOnDemandAllUSA() : setTimeBasedAllUSA())
    if (value === 'In-State')
      dispatch(isInquiries ? setOnDemandInState(institution?.state_name) : setTimeBasedInState(institution?.state_name))
    if (value === 'Out-of-State')
      dispatch(isInquiries ? setOnDemandOutofState(institution?.state_name) : setTimeBasedOutofState(institution?.state_name))
    if (value === 'Specific States/Territories')
      dispatch(isInquiries ? setOnDemandSpecificStates() : setTimeBasedSpecificStates())
    if (value === 'Zipcodes') dispatch(isInquiries ? setOnDemandZipFile() : setTimeBasedZipFile())
    else if (value === 'Zip Code 5') dispatch(isInquiries ? setOnDemandZip5Codes() : setTimeBasedZip5Codes())
    if (value === 'Zip Code 3') dispatch(isInquiries ? setOnDemandZip3Codes() : setTimeBasedZip3Codes())
    if (value === 'Outside the US') dispatch(isInquiries ? setOnDemandOutsideUS() : setTimeBasedOutsideUS())
  }, [])

  const setStates = useCallback((value: string[]) => {
    dispatch(isInquiries ? setOnDemandSpecificStates(value) : setTimeBasedSpecificStates(value))
  }, [])

  /** This useEffect is for setting filters in edit segment */
  useEffect(() => {
    if (zip_id && zipCodesData) {
      const zipFile = zipCodesData?.records?.find(
        (zipCode: any) => zipCode.id === zip_id
      )
      dispatch(isInquiries ? setOnDemandZipFile(zipFile) : setTimeBasedZipFile(zipFile))
    } else if (filterData?.[geography.label]) {
      const geographyData = filterData[geography.label]
      if (geographyData.selectedValue === 'All USA')
        dispatch(isInquiries ? setOnDemandAllUSA() : setTimeBasedAllUSA())
      else if (geographyData.selectedValue === 'In-State')
        dispatch(isInquiries ? setOnDemandInState(institution?.state_name) : setTimeBasedInState(institution?.state_name))
      else if (geographyData.selectedValue === 'Out-of-State')
        dispatch(isInquiries ? setOnDemandOutofState(institution?.state_name) : setTimeBasedOutofState(institution?.state_name))
      else if (geographyData.selectedValue === 'Specific States/Territories')
        dispatch(isInquiries ? setOnDemandSpecificStates(geographyData.states) : setTimeBasedSpecificStates(geographyData.states))
      else if (geographyData.selectedValue === 'Zipcodes')
        dispatch(isInquiries ? setOnDemandZipFile(geographyData.zipCodes) : setTimeBasedZipFile(geographyData.zipCodes))
      else if (geographyData.selectedValue === 'Zip Code 5')
        dispatch(isInquiries ? setOnDemandZip5Codes(geographyData.zipCodes) : setTimeBasedZip5Codes(geographyData.zipCodes))
      else if (geographyData.selectedValue === 'Zip Code 3')
        dispatch(isInquiries ? setOnDemandZip3Codes(geographyData.zipCodes) : setTimeBasedZip3Codes(geographyData.zipCodes))
      else if (geographyData.selectedValue === 'Outside the US')
        dispatch(isInquiries ? setOnDemandOutsideUS() : setTimeBasedOutsideUS())
    }
  }, [filterData, zip_id, zipCodesData])

  useEffect(() => {
    if (zipCodesData) {
      let rows = []
      zipCodesData.records?.forEach((zipCode: any) => {
        rows.push({ id: zipCode.id, name: zipCode.name })
      })
      setZipFiles(rows)
    }
  }, [zipCodesData])

  if (isLoading) {
    return <Loading />
  }

  return (
    <>
      <FilterSingleSelectDropdown
        label='Geography'
        values={geography.values}
        value={geography.selectedValue}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
          setSelectedValue(e.target.value)
        }}
      />
      {geography.selectedValue === 'Specific States/Territories' && (
        <div
          style={{
            marginLeft: '10px',
            marginTop: '10px',
          }}
        >
          <VirtualizedMultiSelect
            label=''
            counterLabel={'States'}
            values={geography.allUSAStatesTerritories}
            initialSelectedValues={geography.selectedStates}
            selectedValues={geography.selectedStates}
            setSelectedValues={setStates}
          />
        </div>
      )}
      {geography.selectedValue === 'Zipcodes' && (
        <div
          style={{
            marginLeft: '10px',
            marginTop: '10px',
          }}
        >
          <SingleSelectDropdown
            id='zipCodes'
            name='zipCodes'
            data-testid='zipCodes'
            rules={{ required: true }}
            style={{ width: '253px' }}
            values={zipFiles?.map((zipFile: any) => zipFile.name)}
            value={geography.zipFile.name}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              const zipFile = zipCodesData.records?.find(
                (zipCode: any) => zipCode.name === e.target.value
              )
              dispatch(isInquiries ? setOnDemandZipFile(zipFile) : setTimeBasedZipFile(zipFile))
            }}
          />
        </div>
      )}

      {geography.selectedValue === 'Zip Code 5' && (
        <div
          style={{
            marginLeft: '10px',
            marginTop: '10px',
          }}
        >
          <Input
            id='add-zip5-codes'
            placeholder='Add Zip Codes separated by commas'
            value={geography.zip5Codes}
            name='zip5Codes'
            control={control}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              dispatch(isInquiries ? setOnDemandZip5Codes(e.target.value) : setTimeBasedZip5Codes(e.target.value))
            }}
            rules={{
              validate: validateZip5Codes,
            }}
            multiline
            rows={5}
          />
        </div>
      )}

      {geography.selectedValue === 'Zip Code 3' && (
        <div
          style={{
            marginLeft: '10px',
            marginTop: '10px',
          }}
        >
          <Input
            id='add-zip3-codes'
            placeholder='Add Zip Codes separated by commas'
            value={geography.zip3Codes}
            name='zip3Codes'
            control={control}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              dispatch(isInquiries ? setOnDemandZip3Codes(e.target.value) : setTimeBasedZip3Codes(e.target.value))
            }}
            rules={{
              validate: validateZip3Codes,
            }}
            multiline
            rows={5}
          />
        </div>
      )}
    </>
  )
})
