import React from 'react'
import {
  IconButton,
  InputAdornment,
  TextField,
  TextFieldProps,
} from '@mui/material'
import { CancelOutlined, Search as SearchIcon } from '@mui/icons-material'
import { useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'

import { clearSearch, setSearch } from 'Slices/searchSlice'

export const SearchComponent = (props: TextFieldProps) => {
  const SEARCH_FIELD_NAME = 'search'

  const dispatch = useDispatch()

  const {
    register,
    reset,
    getValues,
    getFieldState,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
  })

  return (
    <TextField
      {...props}
      id='search'
      type='text'
      className='search'
      InputProps={{
        startAdornment: (
          <InputAdornment position='start'>
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment: getValues(SEARCH_FIELD_NAME) && (
          <InputAdornment position='end'>
            <IconButton
              size='small'
              aria-label='clear search'
              onClick={() => {
                reset({ [SEARCH_FIELD_NAME]: '' })
                dispatch(clearSearch())
              }}
            >
              <CancelOutlined />
            </IconButton>
          </InputAdornment>
        ),
      }}
      onKeyUp={() => {
        const { invalid } = getFieldState(SEARCH_FIELD_NAME)
        if (!invalid) {
          dispatch(setSearch(getValues(SEARCH_FIELD_NAME).toLowerCase().trim()))
        }
      }}
      error={!!errors?.search}
      helperText={errors?.search ? errors.search.message.toString() : ''}
      {...register(SEARCH_FIELD_NAME, {
        pattern: {
          value: /^.{3,}$/,
          message: 'Search must be at least 3 characters',
        },
      })}
    />
  )
}